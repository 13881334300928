import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Medicine } from './medicine.model';
import { MedicineActions, MedicineActionTypes } from './medicine.actions';

export interface State extends EntityState<Medicine> {
  // additional entities state properties
  selectedId?: string;
  isPosting: boolean;
}

export const adapter: EntityAdapter<Medicine> = createEntityAdapter<Medicine>({
  selectId: (item: Medicine) => item._id
});

export const initialState: State = adapter.getInitialState({
  isPosting: false
});

export function reducer(state = initialState, action: MedicineActions): State {
  switch (action.type) {
    case MedicineActionTypes.LoadMedicinesSuccess: {
      return adapter.upsertMany(action.payload.items, state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const getSelectedId = (state: State) => state.selectedId;
export const isPosting = (state: State) => state.isPosting;
