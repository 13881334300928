import {
	ActionPointTypes,
	PatientActionPoint,
	ResponseActionPoint,
} from "./../../store/shared.model";
import { Component, OnInit, Input, OnChanges, SimpleChanges } from "@angular/core";
import {
	MedicineActionPoint,
	ActionPoint,
	ActionPlan,
	ContactActionPoint,
	BLOCK_TYPES,
} from "src/app/store/shared.model";

import { ColorZone } from "src/app/store/org/org.model";
import {
	MEDICINE_FREQUENCIES,
	MedicineFrequency,
	Medicine,
	BIOLOGICAL_FREQUENCIES,
} from "src/app/store/medicine/medicine.model";
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "app-action-point-step",
	templateUrl: "./action-point-step.component.html",
	styleUrls: ["./action-point-step.component.scss"],
})
export class ActionPointStepComponent implements OnInit, OnChanges{
	@Input() type: "team" | "patient" = "patient";
	@Input() locked = false;
	@Input() medicineAction: MedicineActionPoint;
	@Input() generalAction: ActionPoint;
	@Input() contactAction: ContactActionPoint;
	@Input() patientAction: PatientActionPoint;
	@Input() responseAction: ResponseActionPoint;
	@Input() patientMedication: Medicine[];
	@Input() actionPlan: ActionPlan;
	@Input() dosageDimension: string;
	@Input() medicine: Medicine;
	@Input() medicationById: any[];
	@Input() colorZone: ColorZone;
	blockTypes = BLOCK_TYPES;
	actionPointTypes = ActionPointTypes;
	isTherapyResponse = false;
	dosageDimensions = [];
	dosageDimensionsOld = [
		{
			dosageDimension: "inhalaties",
			singular: "inhalatie",
			plural: "inhalaties",
		},
		{
			dosageDimension: "eenheden",
			singular: "eenheid",
			plural: "eenheden",
		},
		{
			dosageDimension: "zakjes",
			singular: "zakje",
			plural: "zakjes"
		},
		{
			dosageDimension: "tabletten",
			singular: "tablet",
			plural: "tabletten",
		},
		{
			dosageDimension: "injecties",
			singular: "injectie",
			plural: "injecties",
		},
		{
			dosageDimension: "capsules",
			singular: "capsule",
			plural: "capsules",
		},
		{
			dosageDimension: "druppels",
			singular: "druppel",
			plural: "druppels",
		},
		{
			dosageDimension: "watten",
			singular: "wat",
			plural: "watten"
		},
		{
			dosageDimension: "stuks",
			singular: "stuk",
			plural: "stuks"
		},
		{
			dosageDimension: "sachets",
			singular: "sachet",
			plural: "sachets"
		},
		{
			dosageDimension: "sprays",
			singular: "spray",
			plural: "sprays"
		},
		{
			dosageDimension: "zetpillen",
			singular: "zetpil",
			plural: "zetpillen",
		},
		{
			dosageDimension: "zuigtabletten",
			singular: "zuigtablet",
			plural: "zuigtabletten",
		},
		{
			dosageDimension: "verbanden",
			singular: "verband",
			plural: "verbanden",
		},
		{
			dosageDimension: "pleisters",
			singular: "pleister",
			plural: "pleisters",
		},
		{ dosageDimension: "geen", singular: "", plural: "" },
		// {
		//   dosageDimension: "eenheden",
		//   singular: "eenheid",
		//   plural: "eenheden",
		// },
	];

	frequencies: MedicineFrequency[] = [
		...MEDICINE_FREQUENCIES,
		...BIOLOGICAL_FREQUENCIES,
	];

	constructor(public translateService: TranslateService) {}
	ngOnChanges(changes: SimpleChanges): void {
		this.loadTranslations();
	}

	ngOnInit() {
		this.loadTranslations();
	}

	loadTranslations(): void {
		this.blockTypes = [
			{ type: ActionPointTypes.GENERAL, label: this.translateService.instant('actionDialog.blockTypes.general') },
			{ type: ActionPointTypes.CONTACT, label: this.translateService.instant('actionDialog.blockTypes.contact') },
			{ type: ActionPointTypes.PATIENT, label: this.translateService.instant('actionDialog.blockTypes.patient') },
			{ type: ActionPointTypes.RESPONSE, label: this.translateService.instant('actionDialog.blockTypes.response') },
		];

		this.frequencies = [
			// medicine frequencies
			{ title: this.translateService.instant('actionDialog.frequencies.ifNeeded'), value: -1 },
			{ title: this.translateService.instant('actionDialog.frequencies.1Day'), value: 1 },
			{ title: this.translateService.instant('actionDialog.frequencies.2Day'), value: 2 },
			{ title: this.translateService.instant('actionDialog.frequencies.3Day'), value: 3 },
			{ title: this.translateService.instant('actionDialog.frequencies.4Day'), value: 4 },
			{ title: this.translateService.instant('actionDialog.frequencies.5Day'), value: 5 },
			{ title: this.translateService.instant('actionDialog.frequencies.3Week'), value: 3 / 7 },
			// bio frequencies
			{ title: this.translateService.instant('actionDialog.bioFrequencies.2Week'), value: 1 / 14 },
			{ title: this.translateService.instant('actionDialog.bioFrequencies.4Week'), value: 1 / 28 },
			{ title: this.translateService.instant('actionDialog.bioFrequencies.8Week'), value: 1 / 56 },
		]


		const dosageDimensionIds: string[] = ["inhalaties", "tabletten", "capsules", "eenheden", "injecties", "druppels", "stuks", "zakjes", "sachets", "sprays", "zetpillen", "zuigtabletten", "watten", "verbanden", "pleisters", "geen"];
		const dosageDimensionTranslateKeys: string[] = ["inhalation", "tablets", "capsules", "units", "injections", "drops", "pieces", "bags", "sachets", "sprays", "suppositories", "lozenges", "wool", "bandages", "band-aids", "none"];

		this.dosageDimensions = [];
		for (let i: number = 0; i < dosageDimensionIds.length; i += 1) {
			const id: string = dosageDimensionIds[i];
			const key: string = dosageDimensionTranslateKeys[i];

			this.dosageDimensions.push({
				dosageDimension: id,
				...this.translateService.instant(`patientDetail.tabs.carePlan.actionPlan.dosageDimensions.${key}`)
			})
		}
		if (
			this.responseAction &&
			this.responseAction.actionType === ActionPointTypes.RESPONSE
		) {
			this.locked = false;

			if (this.type === "patient") {
				this.isTherapyResponse = true;
			}
		}
	}

	getBlockTypeLabel(action) {
		if (action.actionType) {
			return this.blockTypes.find(
				(blockType) => blockType.type === action.actionType
			).label;
		} else {
			return "No Type";
		}
	}

	get dosageValue() {
		if (!this.medicineAction) return;

		if (this.medicineAction.dosage % 1 === 0) {
			return this.medicineAction.dosage;
		} else {
			let customDosage = this.medicineAction.dosage.toString();
			if (customDosage.indexOf(".") > -1) {
				customDosage = customDosage.replace(/[.]/g, ",");
			}
			return customDosage;
		}
	}

	get action() {
		return (
			this.generalAction ||
			this.contactAction ||
			this.medicineAction ||
			this.patientAction ||
			this.responseAction
		);
	}

	get actionPlanLength() {
		return this.actionPlan[this.colorZone].actions.length > 1;
	}

	get description() {
		if (this.generalAction) {
			return this.generalAction.description;
		} else if (this.patientAction) {
			return this.translateService.instant(`patientDetail.tabs.carePlan.actionPlan.doctorCanAddNote`)
		} else if (this.responseAction) {
			let responsePeriod;

			const mapping = [
				{ value: "2 dagen", text: this.translateService.instant('evaluateAfterDialog.evaluateAfter.hours.2Days') },
				{ value: "3 dagen", text: this.translateService.instant('evaluateAfterDialog.evaluateAfter.hours.3Days') },
				{ value: "4 uur", text: this.translateService.instant('evaluateAfterDialog.evaluateAfter.hours.4Hours') },
				{ value: "6 uur", text: this.translateService.instant('evaluateAfterDialog.evaluateAfter.hours.6Hours') },
				{ value: "7 dagen", text: this.translateService.instant('evaluateAfterDialog.evaluateAfter.hours.7Days') },
				{ value: "10 dagen", text: this.translateService.instant('evaluateAfterDialog.evaluateAfter.hours.10Days') },
				{ value: "12 uur", text: this.translateService.instant('evaluateAfterDialog.evaluateAfter.hours.12Hours') },
				{ value: "14 dagen", text: this.translateService.instant('evaluateAfterDialog.evaluateAfter.hours.14Days') },
				{ value: "24 uur", text: this.translateService.instant('evaluateAfterDialog.evaluateAfter.hours.24Hours') },
			]
			if (this.actionPlan[this.colorZone].evaluateAfter) {
				responsePeriod = this.actionPlan[this.colorZone].evaluateAfter;
			} else {
				responsePeriod = this.responseAction.expectedResponsePeriod;
			}

			responsePeriod = mapping.find(m => m.value === responsePeriod).text;

			return `${this.responseAction.prefixText} ${responsePeriod} ${this.responseAction.affixText}`;
		} else {
			return false;
		}
	}

	get actionTitle() {
		if (!this.action) return;

		if (!this.medicineAction) {
			return this.action.title;
		}

		if (!this.actionPlan || !this.medicineAction) {
			return;
		}

		const action = this.medicineAction;

		const brand =
			this.medicationById &&
			this.medicationById[action.medicine] &&
			this.medicationById[action.medicine].brand;

		if (this.colorZone === "green") {
			return this.translateService.instant(`patientDetail.tabs.carePlan.actionPlan.take`) + " " + brand;
		}

		const previousActionFound = this.actionPlan.green.actions.find((actn) => {
			return (
				(actn as MedicineActionPoint).medicine ===
				(action as MedicineActionPoint).medicine
			);
		});

		if (!previousActionFound) {
			//   return "Start met " + brand;
			return this.translateService.instant(`patientDetail.tabs.carePlan.actionPlan.startWith`) + " " + brand;
		} else {
			if (
				action.frequency >
				(previousActionFound as MedicineActionPoint).frequency &&
				action.dosage > (previousActionFound as MedicineActionPoint).dosage
			) {
				return this.translateService.instant(`patientDetail.tabs.carePlan.actionPlan.increaseDoseAndFrequency`) + " " + brand;
			} else if (
				action.dosage > (previousActionFound as MedicineActionPoint).dosage
			) {
				return this.translateService.instant(`patientDetail.tabs.carePlan.actionPlan.increaseDose`) + " " + brand;
			} else if (
				action.frequency >
				(previousActionFound as MedicineActionPoint).frequency
			) {
				return this.translateService.instant(`patientDetail.tabs.carePlan.actionPlan.increaseFrequency`) + " " + brand;
			} else {
				return this.translateService.instant(`patientDetail.tabs.carePlan.actionPlan.continue`) + " " + brand;
			}
		}
	}

	getFrequencyTitle(value) {
		console.log(value);
		console.log(this.frequencies)
		const frequency = this.frequencies.find((freq) => {
			return freq.value === value;
		});

		if (frequency) {
			return frequency.title;
		} else {
			return "Not defined";
		}
	}

	getDimensionFormat(action: MedicineActionPoint, dimension: string) {
		const customAmount = action.dosage % 1 !== 0;
		const amount = action.dosage;

		const selectedDimension = this.dosageDimensions.find(
			(dosageDimension) => dosageDimension.dosageDimension === dimension
		);
		if (amount > 1 && !customAmount) {
			return selectedDimension.plural;
		} else {
			return selectedDimension !== undefined
				? selectedDimension.singular
				: selectedDimension.plural;
		}
	}

	getMedicationStrength(selectedId: string): string {
		if (!selectedId || !this.patientMedication) return;
		const medication = this.patientMedication.find(
			(medication) => medication._id === selectedId
		);
		return medication ? medication.strength : undefined;
	}
}
