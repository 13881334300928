import { Patient } from './../../store/patient/patient.model';
import { ConditionsDialogComponent } from './../../dialogs/conditions-dialog/conditions-dialog.component';
import {
  Conditions,
  ContactActionPoint,
  PatientActionPoint,
  ResponseActionPoint,
} from "./../../store/shared.model";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ColorZone, Action, ActionPlan } from "src/app/store/org/org.model";
import {
  MedicineActionPoint,
  ActionPoint,
  ActionPointTypes,
  ActionPointType,
} from "src/app/store/shared.model";
import { Observable } from "rxjs";
import { Medicine } from "src/app/store/medicine/medicine.model";
import * as fromRoot from "../../store";
import { Store } from "@ngrx/store";
import { Team } from "src/app/store/team/team.model";
import { MatDialog } from '@angular/material';
import { UpdateActionPlan } from 'src/app/store/patient/patient.actions';
import { take } from 'rxjs/operators';

@Component({
  selector: "app-action-plan",
  templateUrl: "./action-plan.component.html",
  styleUrls: ["./action-plan.component.scss"],
})
export class ActionPlanComponent implements OnInit {
  medicationById$: Observable<Medicine[]>;
  ActionPointTypes = ActionPointTypes;
  @Input() patient: Patient;
  @Input() type: "team" | "patient" = "patient";
  @Input() actionPlan: ActionPlan;
  @Input() team: Team;
  @Input() patientMedication: Medicine[];
  @Output() changeActionPlanOrder = new EventEmitter();
  @Output() createAction = new EventEmitter();
  @Output() editEvaluateAfter = new EventEmitter<{colorZone: ColorZone; enable: boolean}>();
  addText: "Actie" | "Block" = "Actie";

  constructor(private store: Store<fromRoot.State>, private dialog: MatDialog) {}

  ngOnInit() {
    this.medicationById$ = this.store.select(
      fromRoot.getSelectedPatientMedicationById
    );

    if (this.type === "team") {
      this.addText = "Block";
    }
  }

  get patientActionPlan() {
    return this.type === 'patient';
  }

  get teamActionPlan() {
    return this.type === 'team'
  }

  hasCustomConditions(colorZone: ColorZone) {
    if (!this.actionPlan) return false;

    const colorZoneProperties = this.actionPlan[colorZone];


    if (!colorZoneProperties && !colorZoneProperties.conditions) return false;

    if (colorZoneProperties && !colorZoneProperties.conditions || colorZoneProperties && colorZoneProperties.conditions === undefined) return false;

    let exceptions, additions = false;

    if (colorZoneProperties.conditions.exceptions && colorZoneProperties.conditions.exceptions.length > 0) {
      colorZoneProperties.conditions.exceptions.map(exception => {
        if (!exception.visible) {
          exceptions = true;
        }
      })
    }

    if (colorZoneProperties.conditions.additions && colorZoneProperties.conditions.additions.length > 0) {
      additions = true;
    }

    return exceptions || additions;
  }

  triggerChangeActionPlanOrder(colorZone: ColorZone, actions) {
    this.changeActionPlanOrder.emit({ colorZone, actions });
  }

  triggerCreateAction(colorZone: ColorZone, action, type: ActionPointType) {
    this.createAction.emit({ colorZone, action, type });
  }

  triggerGeneralAction(colorZone: ColorZone) {
    const hasGeneralAction = this.actionPlan[colorZone].actions.find(
      (action) => action.actionType === ActionPointTypes.GENERAL
    );

    this.triggerCreateAction(
      colorZone,
      hasGeneralAction,
      ActionPointTypes.GENERAL
    );
  }

  triggerEditEvaluateAfter() {
    // this.editEvaluateAfter.emit(true);
  }

  triggerAction(colorZone: ColorZone, action: MedicineActionPoint | ContactActionPoint | ResponseActionPoint | PatientActionPoint | ActionPoint) {
    if (action.actionType && action.actionType === ActionPointTypes.RESPONSE) {
      this.editEvaluateAfter.emit({colorZone, enable: true});
    }
  }

  getMedicine(action: MedicineActionPoint | ActionPoint) {
    if (!this.patientMedication) return;

    const medicine = this.patientMedication.find(
      (medicine) => medicine._id === (action as MedicineActionPoint).medicine
    );
    return medicine;
  }

  medicineAction(action: MedicineActionPoint | ActionPoint) {
    return (action.actionType &&
      action.actionType === ActionPointTypes.MEDICATION) ||
      (action as MedicineActionPoint).medicine
      ? (action as MedicineActionPoint)
      : undefined;
  }

  contactAction(action: ContactActionPoint) {
    return action.actionType && action.actionType === ActionPointTypes.CONTACT
      ? (action as ActionPoint)
      : undefined;
  }

  generalAction(action: ActionPoint) {
    return action.actionType && action.actionType === ActionPointTypes.GENERAL
      ? (action as ActionPoint)
      : undefined;
  }

  patientAction(action: PatientActionPoint) {
    return action.actionType && action.actionType === ActionPointTypes.PATIENT
      ? (action as PatientActionPoint)
      : undefined;
  }

  responseAction(action: ResponseActionPoint) {
    return action.actionType && action.actionType === ActionPointTypes.RESPONSE
      ? (action as ResponseActionPoint)
      : undefined;
  }

  hasGeneralAction(actions: Action[]) {
    return actions.find(
      (action) => action.actionType === ActionPointTypes.GENERAL
    );
  }

  toggleConditionsDialog(colorZone: ColorZone) {
    let conditions: Conditions = {
      additions: [],
      exceptions: []
    };

    if (this.patient.actionPlan[colorZone] && this.patient.actionPlan[colorZone].conditions) {
      conditions = JSON.parse(JSON.stringify(this.patient.actionPlan[colorZone].conditions));
    }

    const conditionsDialog = this.dialog.open(
      ConditionsDialogComponent,
      {
        data: {
          colorZone,
          conditions: conditions
        }
      }
    )

    conditionsDialog.afterClosed().pipe(take(1)).toPromise().then(value => {
      if (value && value.conditions) {
        const patch = {
          conditions: value.conditions
        };

        this.store.dispatch(
          new UpdateActionPlan({ id: this.patient._id, colorZone, patch })
        );
      }
    })
  }
}
