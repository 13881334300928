import { VerificationToken } from "./../store/authAdmin/authAdmin.model";
import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { Tokens, RefreshToken } from "../store/auth/auth.model";
import { Observable } from "rxjs";
import {
  ColorZone,
  Patient,
  ACS,
  ArchiveReason,
} from "../store/patient/patient.model";
import { PatientAction } from "../store/patientAction/patientAction.model";
import { Medicine } from "../store/medicine/medicine.model";
import { Message } from "../store/message/message.model";
import { Org } from "../store/org/org.model";
import { MonitoringSettings, MeasurementConfig } from "../store";
import { Alerts, Team } from "../store/team/team.model";
import { CareProvider } from "../store/careprovider/careprovider.model";
import {
  ActionPlan,
  MedicineActionPoint,
  ActionPoint,
  Measurement,
} from "../store/shared.model";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private apiEndpoint = "/api/careproviders/v1";
  private adminApiEndpoint = "/api/admins/v1";
  private authEndpoint = "/auth/careproviders/v1";
  private adminAuthEndpoint = "/auth/admins/v1";

  constructor(private customHttpService: HttpService) {}

  refreshtoken(body: { refreshtoken: RefreshToken }): Observable<Tokens> {
    return this.customHttpService.request({
      method: "post",
      url: this.authEndpoint + "/refresh-token",
      body,
      noAuth: true,
    });
  }

  refreshtokenAdmin(body: { refreshtoken: RefreshToken }): Observable<Tokens> {
    return this.customHttpService.request({
      method: "post",
      url: this.adminAuthEndpoint + "/refresh-token",
      body,
      noAuth: true,
    });
  }

  authorize(email: string, password: string): Observable<VerificationToken> {
    return this.customHttpService.request({
      method: "post",
      url: this.authEndpoint + "/authorize",
      basicAuth: "Basic " + window.btoa(email + ":" + password),
      noAuth: true,
    });
  }

  authorizeAdmin(email: string, password: string) {
    return this.customHttpService.request({
      method: "post",
      url: this.adminAuthEndpoint + "/authorize",
      basicAuth: "Basic " + window.btoa(email + ":" + password),
      noAuth: true,
    });
  }

  verifyAdmin(body: VerificationToken) {
    return this.customHttpService.request({
      method: "post",
      url: this.adminAuthEndpoint + "/verify-two-factor",
      body,
      noAuth: true,
    });
  }

  verify(body: VerificationToken) {
    return this.customHttpService.request({
      method: "post",
      url: this.authEndpoint + "/verify-two-factor",
      body,
      noAuth: true,
    });
  }

  requestPasswordChange(email: string): Observable<any> {
    return this.customHttpService.request({
      method: "post",
      url: this.authEndpoint + "/request-password-reset",
      body: { email },
      noAuth: true,
    });
  }

  changePassword(token, password): Observable<any> {
    return this.customHttpService.request({
      method: "post",
      url: this.authEndpoint + "/reset-password",
      body: { token, password },
      noAuth: true,
    });
  }

  patchPassword(
    careProviderId: string,
    newPassword: string,
    password?: string
  ) {
    return this.customHttpService.request({
      method: "post",
      body: { newPassword, password },
      url:
        this.apiEndpoint +
        "/careproviders/" +
        careProviderId +
        "/change-password",
    });
  }

  // CHATS
  getChats(params?: { skip?: string; limit?: string }): Observable<Patient[]> {
    return this.customHttpService.request({
      method: "get",
      url: this.apiEndpoint + "/teams/:teamId/chats",
      params,
    });
  }

  getChatMessages(
    patientId: string,
    params?: { skip?: string; limit?: string }
  ): Observable<Message[]> {
    return this.customHttpService.request({
      method: "get",
      url: this.apiEndpoint + "/teams/:teamId/chats/" + patientId + "/messages",
      params,
    });
  }

  createChatMessage(patientId: string, body: Message): Observable<Message> {
    return this.customHttpService.request({
      method: "post",
      url: this.apiEndpoint + "/teams/:teamId/chats/" + patientId + "/messages",
      body,
    });
  }

  setChatResolved(patientId: string): Observable<Patient> {
    return this.customHttpService.request({
      method: "post",
      url: this.apiEndpoint + "/teams/:teamId/chats/" + patientId + "/resolve",
    });
  }

  // TEAM - DASHBOARD
  setTeamAlert(body: Alerts): Observable<Team> {
    return this.customHttpService.request({
      method: "patch",
      url: this.apiEndpoint + "/teams/:teamId/alerts",
      body,
    });
  }

  // PATIENTS

  logout(careProviderId: string, clientId: string) {
    return this.customHttpService.request({
      method: "delete",
      url:
        this.apiEndpoint +
        "/careproviders/" +
        careProviderId +
        "/clients/" +
        clientId,
    });
  }

  logoutAdmin(adminId: string, clientId: string) {
    return this.customHttpService.request(
      {
        method: "delete",
        url:
          this.adminApiEndpoint + "/admins/" + adminId + "/clients/" + clientId,
      },
      true
    );
  }

  getAllPatients(params?: {
    skip?: string;
    limit?: string;
  }): Observable<Patient[]> {
    return this.customHttpService.request({
      method: "get",
      url: this.apiEndpoint + "/teams/:teamId/users",
      params,
    });
  }

  getUnresolvedPatients(params?: {
    skip?: string;
    limit?: string;
  }): Observable<Patient[]> {
    return this.customHttpService.request({
      method: "get",
      url: this.apiEndpoint + "/teams/:teamId/users/unresolved",
      params,
    });
  }

  getAllPatientActions(
    patientId: string,
    params?: { skip?: string; limit?: string }
  ): Observable<PatientAction[]> {
    return this.customHttpService.request({
      method: "get",
      url: this.apiEndpoint + "/teams/:teamId/users/" + patientId + "/actions",
      params,
    });
  }

  resolvePatientAction(
    patientId: string,
    actionId: string
  ): Observable<[PatientAction, Patient]> {
    return this.customHttpService.request({
      method: "post",
      url:
        this.apiEndpoint +
        "/teams/:teamId/users/" +
        patientId +
        "/actions/" +
        actionId +
        "/resolve",
    });
  }

  getCareProvider(careProviderId: string): Observable<CareProvider> {
    return this.customHttpService.request({
      method: "get",
      url: this.apiEndpoint + "/careproviders/" + careProviderId,
    });
  }

  getPatient(patientId: string): Observable<Patient> {
    return this.customHttpService.request({
      method: "get",
      url: this.apiEndpoint + "/teams/:teamId/users/" + patientId,
      params: { merged: false },
    });
  }

  createPatient(body: Patient): Observable<Patient> {
    return this.customHttpService.request({
      method: "post",
      url: this.apiEndpoint + "/teams/:teamId/users",
      body,
    });
  }

  invitePatient(id: string) {
    return this.customHttpService.request({
      method: "post",
      url: this.apiEndpoint + "/teams/:teamId/users/" + id + "/invite",
    });
  }

  resendInvite(id: string) {
    return this.customHttpService.request({
      method: "post",
      url: this.apiEndpoint + "/teams/:teamId/users/" + id + "/resend-invite",
    });
  }

  patchPatient(id: string, body: Patient): Observable<Patient> {
    return this.customHttpService.request({
      method: "patch",
      url: this.apiEndpoint + "/teams/:teamId/users/" + id,
      body,
      params: { merged: false },
    });
  }

  patchNotifyMeAt(
    id: string,
    notifyMeAt: {
      day: number;
      time: string;
    }
  ): Observable<Patient> {
    return this.customHttpService.request({
      method: "patch",
      url: this.apiEndpoint + "/teams/:teamId/users/" + id + "/notify",
      body: { notifyMeAt },
    });
  }

  archivePatient(id: string, reason: ArchiveReason) {
    return this.customHttpService.request({
      method: "post",
      url: this.apiEndpoint + "/teams/:teamId/users/" + id + "/archive",
      body: { reason },
    });
  }

  unarchivePatient(id: string) {
    return this.customHttpService.request({
      method: "post",
      url: this.apiEndpoint + "/teams/:teamId/users/" + id + "/unarchive",
    });
  }

  deletePatient(id: string) {
    return this.customHttpService.request({
      method: "post",
      url: this.apiEndpoint + "/teams/:teamId/users/" + id + "/remove",
      body: {},
    });
  }

  postPatientMedication(id: string, body: Medicine): Observable<Medicine[]> {
    return this.customHttpService.request({
      method: "post",
      url: this.apiEndpoint + "/teams/:teamId/users/" + id + "/medication",
      body,
    });
  }

  removePatientMedication(
    id: string,
    medicationId: string
  ): Observable<Patient> {
    return this.customHttpService.request({
      method: "delete",
      params: { merged: false },
      url:
        this.apiEndpoint +
        "/teams/:teamId/users/" +
        id +
        "/medication/" +
        medicationId,
    });
  }

  patchPatientAction(
    id: string,
    actionId: string,
    colorZone: ColorZone,
    body: MedicineActionPoint | ActionPoint
  ): Observable<ActionPlan> {
    return this.customHttpService.request({
      method: "patch",
      url:
        this.apiEndpoint +
        "/teams/:teamId/users/" +
        id +
        "/actionplan/actions/" +
        actionId,
      body,
      params: {
        colorZone,
      },
    });
  }

  postPatientAction(
    id: string,
    colorZone: ColorZone,
    body: MedicineActionPoint | ActionPoint
  ): Observable<ActionPlan> {
    return this.customHttpService.request({
      method: "post",

      url:
        this.apiEndpoint + "/teams/:teamId/users/" + id + "/actionplan/actions",
      body,
      params: {
        colorZone,
      },
    });
  }

  removePatientAction(
    id: string,
    colorZone: ColorZone,
    actionId: string
  ): Observable<ActionPlan> {
    return this.customHttpService.request({
      method: "delete",
      url:
        this.apiEndpoint +
        "/teams/:teamId/users/" +
        id +
        "/actionplan/actions/" +
        actionId,
      params: {
        colorZone,
      },
    });
  }

  patchPatientActionPlan(
    id: string,
    colorZone: ColorZone,
    body: { evaluateAfter?: string; useEpipen?: boolean }
  ): Observable<ActionPlan> {
    console.log("patchPatientActionPlan", colorZone, body);
    return this.customHttpService.request({
      method: "patch",
      url:
        this.apiEndpoint +
        "/teams/:teamId/users/" +
        id +
        "/actionplan/colorzones",
      body,
      params: {
        colorZone,
      },
    });
  }

  changePatientActionOrder(
    id: string,
    colorZone: ColorZone,
    body: { order: string[] }
  ): Observable<ActionPlan> {
    return this.customHttpService.request({
      method: "post",
      url:
        this.apiEndpoint +
        "/teams/:teamId/users/" +
        id +
        "/actionplan/change-action-order",
      body,
      params: {
        colorZone,
      },
    });
  }

  getTeam(): Observable<Team> {
    return this.customHttpService.request({
      method: "get",
      url: this.apiEndpoint + "/teams/:teamId",
    });
  }

  patchBaseActionPlan(baseActionPlan: ActionPlan): Observable<Team> {
    return this.customHttpService.request(
      {
        method: "patch",
        url: this.adminApiEndpoint + "/teams/:teamId/actionplan",
        body: { baseActionPlan },
      },
      true
    );
  }

  postBaseAction(
    colorZone: ColorZone,
    teamId: string,
    body: MedicineActionPoint | ActionPoint
  ): Observable<Team> {
    return this.customHttpService.request(
      {
        method: "post",

        url: this.adminApiEndpoint + "/teams/" + teamId + "/actionplan/actions",
        body,
        params: {
          colorZone,
        },
      },
      true
    );
  }

  patchBaseAction(
    actionId: string,
    colorZone: ColorZone,
    teamId: string,
    body: MedicineActionPoint | ActionPoint
  ): Observable<Team> {
    return this.customHttpService.request(
      {
        method: "patch",
        url:
          this.adminApiEndpoint +
          "/teams/" +
          teamId +
          "/actionplan/actions/" +
          actionId,
        body,
        params: {
          colorZone,
        },
      },
      true
    );
  }

  removeBaseAction(
    colorZone: ColorZone,
    teamId: string,
    actionId: string
  ): Observable<Team> {
    return this.customHttpService.request(
      {
        method: "delete",
        url:
          this.adminApiEndpoint +
          "/teams/" +
          teamId +
          "/actionplan/actions/" +
          actionId,
        params: {
          colorZone,
        },
      },
      true
    );
  }

  changeBaseActionOrder(
    colorZone: ColorZone,
    teamId: string,
    body: { order: string[] }
  ): Observable<Team> {
    return this.customHttpService.request(
      {
        method: "post",
        url:
          this.adminApiEndpoint +
          "/teams/" +
          teamId +
          "/actionplan/change-action-order",
        body,
        params: {
          colorZone,
        },
      },
      true
    );
  }

  patchMonitoringSettings(
    monitoringSettings: MonitoringSettings
  ): Observable<Team> {
    return this.customHttpService.request({
      method: "patch",
      url: this.apiEndpoint + "/teams/:teamId",
      body: { monitoringSettings },
    });
  }

  getAllMeasurements(): Observable<Measurement[]> {
    return this.customHttpService.request({
      method: "get",
      url: this.apiEndpoint + "/measurements",
    });
  }

  patchMeasurementConfig(config: MeasurementConfig): Observable<Team> {
    return this.customHttpService.request({
      method: "patch",
      url: this.apiEndpoint + "/teams/:teamId/measurements",
      body: config,
    });
  }

  getAllMedicines(params?: {
    skip?: string;
    limit?: string;
  }): Observable<Medicine[]> {
    return this.customHttpService.request({
      method: "get",
      url: this.apiEndpoint + "/medicines",
      params,
    });
  }

  getACS(): Observable<ACS> {
    return this.customHttpService.request({
      method: "get",
      url: this.apiEndpoint + "/acs",
    });
  }

  // USERS - ADMIN
  getUsersByStudyNumber(studyNumber: string): Observable<any> {
    return this.customHttpService.request(
      {
        method: "get",
        url: this.adminApiEndpoint + "/users/study",
        params: {
          studyNumber,
        },
      },
      true
    );
  }

  getAllUsersWithStudyNumber(): Observable<any> {
    return this.customHttpService.request(
      {
        method: "get",
        url: this.adminApiEndpoint + "/users/study/all",
      },
      true
    );
  }

  getAllACSResults(): Observable<any> {
    return this.customHttpService.request(
      {
        method: "get",
        url: this.adminApiEndpoint + "/users/study/acs/all",
      },
      true
    );
  }

  // ORGS - ADMIN

  getAllOrgsAdmin(params?: {
    skip?: string;
    limit?: string;
  }): Observable<Org[]> {
    return this.customHttpService.request(
      {
        method: "get",
        url: this.adminApiEndpoint + "/orgs",
        params,
      },
      true
    );
  }

  getOrgAdmin(orgId: string): Observable<Org> {
    return this.customHttpService.request(
      {
        method: "get",
        url: this.adminApiEndpoint + "/orgs/" + orgId,
      },
      true
    );
  }

  createOrgAdmin(body: Org): Observable<Org> {
    console.log("api create org");
    return this.customHttpService.request(
      {
        method: "post",
        url: this.adminApiEndpoint + "/orgs/",
        body,
      },
      true
    );
  }

  deleteOrgAdmin(id: string) {
    return this.customHttpService.request(
      {
        method: "delete",
        url: this.adminApiEndpoint + "/orgs/" + id,
      },
      true
    );
  }

  patchOrgAdmin(id: string, body: Org): Observable<Org> {
    return this.customHttpService.request(
      {
        method: "patch",
        url: this.adminApiEndpoint + "/orgs/" + id,
        body,
      },
      true
    );
  }

  // TEAMS - ADMIN

  getAllTeamsAdmin(params?: {
    skip?: string;
    limit?: string;
  }): Observable<Team[]> {
    return this.customHttpService.request(
      {
        method: "get",
        url: this.adminApiEndpoint + "/teams",
        params,
      },
      true
    );
  }

  getTeamAdmin(orgId: string, teamId: string): Observable<Team> {
    return this.customHttpService.request(
      {
        method: "get",
        url: this.adminApiEndpoint + "/orgs/" + orgId + "/teams" + teamId,
      },
      true
    );
  }

  createTeamAdmin(orgId: string, body: Team): Observable<Team> {
    return this.customHttpService.request(
      {
        method: "post",
        url: this.adminApiEndpoint + "/orgs/" + orgId + "/teams",
        body,
      },
      true
    );
  }

  deleteTeamAdmin(teamId: string, orgId: string) {
    return this.customHttpService.request(
      {
        method: "delete",
        url: this.adminApiEndpoint + "/orgs/" + orgId + "/teams" + teamId,
      },
      true
    );
  }

  patchTeamAdmin(orgId: string, teamId: string, body: Team): Observable<Team> {
    return this.customHttpService.request(
      {
        method: "patch",
        url: this.adminApiEndpoint + "/orgs/" + orgId + "/teams/" + teamId,
        body,
      },
      true
    );
  }

  // CAREPROVIDER - ADMIN

  // getAllCareProvidersAdmin(orgId: string, teamId: string, params?: { skip?: string; limit?: string }): Observable<CareProvider[]> {
  //   return this.customHttpService.request({
  //     method: 'get',
  //     url: this.adminApiEndpoint + '/orgs/' + orgId + '/teams/' + teamId + '/careproviders',
  //     params
  //   }, true);
  // }

  getAllCareProvidersAdmin(params?: {
    skip?: string;
    limit?: string;
  }): Observable<CareProvider[]> {
    return this.customHttpService.request(
      {
        method: "get",
        url: this.adminApiEndpoint + "/careproviders",
        params,
      },
      true
    );
  }

  getCareProviderAdmin(careProviderId: string): Observable<CareProvider> {
    return this.customHttpService.request(
      {
        method: "get",
        url: this.adminApiEndpoint + "/careproviders/" + careProviderId,
      },
      true
    );
  }

  createCareProviderAdmin(body: CareProvider): Observable<CareProvider> {
    return this.customHttpService.request(
      {
        method: "post",
        url: this.adminApiEndpoint + "/careproviders/create",
        body,
      },
      true
    );
  }

  deleteCareProviderAdmin(careProviderId: string) {
    return this.customHttpService.request(
      {
        method: "delete",
        url: this.adminApiEndpoint + "/careproviders/" + careProviderId,
      },
      true
    );
  }

  patchCareProviderAdmin(
    careProviderId: string,
    body: CareProvider
  ): Observable<CareProvider> {
    return this.customHttpService.request(
      {
        method: "patch",
        url: this.adminApiEndpoint + "/careproviders/" + careProviderId,
        body,
      },
      true
    );
  }
}
