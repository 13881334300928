import { ApiService } from "src/app/services/api.service";
import { ChangePasswordDialogComponent } from "./../../dialogs/change-password-dialog/change-password-dialog.component";
import { ConfirmDialogComponent } from "src/app/dialogs/confirm-dialog/confirm-dialog.component";
import { Component, OnInit } from "@angular/core";
import * as fromRoot from "../../store/index";
import * as authActions from "../../store/auth/auth.actions";
import { Store } from "@ngrx/store";
import { MatDialog, MatDialogRef } from "@angular/material";
import { Observable, BehaviorSubject } from "rxjs";
import { Team } from "src/app/store/team/team.model";
import { take, filter, withLatestFrom, map } from "rxjs/operators";
import { MonitoringSettingsDialogComponent } from "src/app/dialogs/monitoring-settings-dialog/monitoring-settings-dialog.component";
import { MeasurementConfigDialogComponent } from "src/app/dialogs/measurement-config-dialog/measurement-config-dialog.component";
import { CareProvider } from "src/app/store/careprovider/careprovider.model";
import { JwtHelperService } from "@auth0/angular-jwt";
import { MonitoringSettings } from "../../store/index";
import * as teamActions from "../../store/team/team.actions";
import {
  LoadTeamAction,
  UpdateMonitoringSettings,
  UpdateMeasurementConfig,
} from "src/app/store/settings/settings.actions";
import { ActionDialogComponent } from "src/app/dialogs/action-dialog/action-dialog.component";
import { Measurement } from "src/app/store/shared.model";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class SettingsViewComponent implements OnInit {
  team$: Observable<Team>;
  careProvider$: Observable<CareProvider>;
  measurements$: Observable<Measurement[]>;
  measurementDialog: MatDialogRef<MeasurementConfigDialogComponent>;
  patientDialog: MatDialogRef<MonitoringSettingsDialogComponent>;
  actionDialog: MatDialogRef<ActionDialogComponent>;
  monitoringToggle$ = new BehaviorSubject<boolean>(undefined);
  unlimitedPatients = 9999999;
  dialogId = "monitoring-settings";

  constructor(
    private dialog: MatDialog,
    private store: Store<fromRoot.State>,
    private jwtHelper: JwtHelperService,
    private apiService: ApiService,
	private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.initialize();

    this.careProvider$ = this.store.select(fromRoot.getCareProvider);

    this.team$ = this.store.select(fromRoot.getTeam).pipe(
      filter((t) => t !== undefined),
      map((t) => {
        const max =
          t.monitoringSettings.days.filter(
            (day) => day.maxPatients === this.unlimitedPatients
          ).length === t.monitoringSettings.days.length;
        this.monitoringToggle$.next(max);
        return t;
      })
    );

    this.measurements$ = this.store.select(fromRoot.getMeasurements).pipe(
      filter((m) => m !== undefined),
      withLatestFrom(this.team$),
      map(([measurements, team]) => {
        console.log(measurements);
        return measurements.filter((m) => {
          return (
            team.measurementConfigurations.findIndex(
              (c) => c.name === m.name
            ) === -1
          );
        });
      })
    );
  }

  async editMeasurementConfigs(
    config: fromRoot.MeasurementConfig
  ): Promise<void> {
    const measurements = await this.measurements$.pipe(take(1)).toPromise();
    this.team$.pipe(take(1)).subscribe(() => {
      this.measurementDialog = this.dialog.open(
        MeasurementConfigDialogComponent,
        { data: { measurementConfiguration: config, measurements } }
      );
    });
  }

  private initialize(): void {
    this.store.dispatch(new LoadTeamAction());

    this.store
      .select(fromRoot.getTokens)
      .pipe(filter((tokens) => tokens !== undefined))
      .pipe(filter((tokens) => tokens.accesstoken !== undefined))
      .pipe(take(1))
      .subscribe((tokens) => {
        const decoded = this.jwtHelper.decodeToken(tokens.accesstoken);
        this.store.dispatch(
          new authActions.CareProviderGetAction(decoded.careProvider._id)
        );
      });
  }

  editLimit() {
    this.team$.pipe(take(1)).subscribe((team) => {
      const monitoringSettings = team.monitoringSettings;
      this.patientDialog = this.dialog.open(MonitoringSettingsDialogComponent, {
        data: { monitoringSettings },
        id: this.dialogId,
      });
    });
  }

  toggleLimit(event) {
    if (event) {
      this.team$.pipe(take(1)).subscribe((team) => {
        const resetMonitoringSettings = {
          days: team.monitoringSettings.days.map((day) => {
            return { ...day, maxPatients: undefined };
          }),
        };
        this.patientDialog = this.dialog.open(
          MonitoringSettingsDialogComponent,
          {
            data: { monitoringSettings: resetMonitoringSettings },
            id: this.dialogId,
          }
        );
        this.patientDialog
          .afterClosed()
          .pipe(take(1))
          .subscribe((val) => {
            if (val === false) {
              this.monitoringToggle$.next(!val);
            } else {
              this.monitoringToggle$.next(false);
            }
          });
      });
    } else {
      const alertDialog = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: this.translateService.instant('settings.confirmDialog.title'), // "Limiet verwijderen",
          message: this.translateService.instant('settings.confirmDialog.message'),
          confirmText: this.translateService.instant('settings.confirmDialog.confirmtext'),
          cancelText: this.translateService.instant('settings.confirmDialog.cancelText'),
        },
      });

      alertDialog
        .afterClosed()
        .pipe(take(1))
        .subscribe((val) => {
          if (val) {
            this.team$.pipe(take(1)).subscribe((team) => {
              const maxMonitoringSettings = {
                days: team.monitoringSettings.days.map((day) => {
                  return {
                    weekDay: day.weekDay,
                    maxPatients: this.unlimitedPatients,
                  };
                }),
              };

              this.store.dispatch(
                new UpdateMonitoringSettings(
                  maxMonitoringSettings as MonitoringSettings
                )
              );
            });
          } else {
            this.monitoringToggle$.next(false);
          }
        });
    }
  }

  async changePassword() {
    const careProviderId = await (
      await this.careProvider$
        .pipe(
          filter((c) => c !== undefined),
          take(1)
        )
        .toPromise()
    )._id;
  }

  async toggleAlert(value, type) {
    const alert = { [type]: value };
    const response = await this.apiService
      .setTeamAlert(alert)
      .pipe(take(1))
      .toPromise();

    this.store.dispatch(
      new teamActions.UpdateTeamSuccess({
        changes: response,
        id: response._id,
      })
    );
    this.store.dispatch(new LoadTeamAction());
  }

  toggleMeasurement(measurement) {
    if (measurement.enabled) {
      const alertDialog = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: `${measurement.title}`,
          message: this.translateService.instant('settings.coronaDialog.message'),
          confirmText: this.translateService.instant('settings.coronaDialog.confirmText', { value: measurement.title}),
          cancelText: this.translateService.instant('settings.coronaDialog.cancelText'),
        },
      });

      alertDialog
        .afterClosed()
        .pipe(take(1))
        .subscribe((confirmed) => {
          if (confirmed) {
            this.store.dispatch(
              new UpdateMeasurementConfig({
                name: measurement.name,
                enabled: false,
              })
            );
          }
        });
    } else {
      this.editMeasurementConfigs(measurement);
    }
  }
}
