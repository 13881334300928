import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { Message } from "./message.model";
import { MessageActions, MessageActionTypes } from "./message.actions";
import { AuthActionTypes, AuthActions } from "../auth/auth.actions";

export interface State extends EntityState<Message> {
  // additional entities state properties
  selectedId?: string;
  isPosting: boolean;
  currentMessages: { [chatId: string]: string };
}

export const adapter: EntityAdapter<Message> = createEntityAdapter<Message>({
  selectId: (item: Message) => item._id
});

export const initialState: State = adapter.getInitialState({
  isPosting: false,
  currentMessages: {}
});

export function reducer(
  state = initialState,
  action: MessageActions | AuthActions
): State {
  switch (action.type) {
    case MessageActionTypes.AddMessage: {
      return {
        ...state,
        isPosting: true
      };
    }

    case MessageActionTypes.AddMessageSuccess: {
      return {
        ...adapter.addOne(action.payload.item, state),
        isPosting: false
      };
    }

    case MessageActionTypes.UpsertMessage: {
      return adapter.upsertOne(action.payload.item, state);
    }

    case MessageActionTypes.AddMessages: {
      return adapter.addMany(action.payload.items, state);
    }

    case MessageActionTypes.UpsertMessages: {
      return adapter.upsertMany(action.payload.items, state);
    }

    case MessageActionTypes.UpdateMessage: {
      return {
        ...state,
        isPosting: true
      };
    }

    case MessageActionTypes.UpdateMessageSuccess: {
      return {
        ...adapter.updateOne(action.payload, state),
        isPosting: false
      };
    }

    case MessageActionTypes.UpdateMessages: {
      return adapter.updateMany(action.payload.items, state);
    }

    case MessageActionTypes.SaveCurrentMessage: {
      state.currentMessages[action.payload.chatId] = action.payload.message;
      return {
        ...state
      };
    }

    case MessageActionTypes.DeleteMessage: {
      return {
        ...state,
        isPosting: true
      };
    }

    case MessageActionTypes.DeleteMessageSuccess: {
      return {
        ...adapter.removeOne(action.payload.id, state),
        isPosting: false
      };
    }

    case MessageActionTypes.DeleteMessages: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case MessageActionTypes.LoadMessagesSuccess: {
      return adapter.upsertMany(action.payload.items, state);
    }

    case MessageActionTypes.GetOneSuccess: {
      return adapter.upsertOne(action.payload, state);
    }

    case MessageActionTypes.ClearMessages: {
      return adapter.removeAll(state);
    }

    case MessageActionTypes.Select: {
      return {
        ...state,
        selectedId: action.payload
      };
    }

    case AuthActionTypes.LogoutSuccess: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = adapter.getSelectors();

export const getSelectedId = (state: State) => state.selectedId;
export const isPosting = (state: State) => state.isPosting;
export const getCurrentMessages = (state: State) => state.currentMessages;
