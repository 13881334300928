import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../store';
import * as authAdminActions from '../../store/authAdmin/authAdmin.actions';
import * as orgActions from '../../store/org/org.actions';
import * as careproviderAction from '../../store/careprovider/careprovider.actions';
import * as teamActions from '../../store/team/team.actions';
import { Router } from '@angular/router';
import { filter, take, map } from 'rxjs/operators';
import { StoreError } from '../../store/error/error.model';
import { Observable, Subscription, interval } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { MatSelectChange } from '@angular/material/select';

@Component({
	selector: 'app-admin-home',
	templateUrl: './admin-home.component.html',
	styleUrls: ['./admin-home.component.scss']
})
export class AdminHomePageComponent implements OnInit, OnDestroy {
	public languages: string[];
	public currentLang: string;
	alertCount$: Observable<number>;
	updateSub: Subscription;

	constructor(
		private store: Store<fromRoot.State>,
		private router: Router,
		private titleService: Title,
		private translateService: TranslateService) {

		this.languages = this.translateService.getLangs();
		this.currentLang = this.translateService.currentLang
	}

	onLanguageChange(e: MatSelectChange) {
		const newLang: string = e.value;

		this.currentLang = e.value;
		this.translateService.use(newLang);
		localStorage.setItem("defaultLang", newLang);
	}

	ngOnInit() {
		// TODO: This title need to change
		this.titleService.setTitle('LungBeat Admin');
		this.store.dispatch(new careproviderAction.LoadCareProviders());
		this.store.dispatch(new orgActions.LoadOrgs());
		this.store.dispatch(new teamActions.LoadTeams());
	}

	ngOnDestroy() {
	}

	logout() {
		this.store.dispatch(new authAdminActions.AdminLogoutAction());

		const loggedIn$ = this.store.select(fromRoot.getAdminTokens).pipe(map(tokens => tokens && tokens.accesstoken));

		loggedIn$
			.pipe(
				filter(loggedin => loggedin === undefined),
				take(1)
			)
			.subscribe(loggedIn => {
				if (!loggedIn) {
					// You are logged in
					this.router.navigate(['/admin/login']);
				}
			});
	}
}
