import { ConfirmDialogComponent } from 'src/app/dialogs/confirm-dialog/confirm-dialog.component';
import { InputDialogComponent } from './../input-dialog/input-dialog.component';
import { RootState } from './../../store/root';
import { ColorZone } from './../../store/patient/patient.model';
import { AnswerOption, Conditions, QuestionnaireMeasurement } from './../../store/shared.model';
import { AssetsService } from './../../services/assets.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-conditions-dialog',
  templateUrl: './conditions-dialog.component.html',
  styleUrls: ['./conditions-dialog.component.scss']
})
export class ConditionsDialogComponent implements OnInit {
  userConditions: Conditions;
  actionPlanMeasurement: QuestionnaireMeasurement;
  defaultConditions: AnswerOption[];
  questionId = '5fa1945b243448feb89b45d4'; // The ID for this question is fixed;

  constructor(
    @Inject(MAT_DIALOG_DATA)
  public data: {
    colorZone: ColorZone,
    conditions: Conditions
  }, private dialog: MatDialog, private store: Store<RootState>, public dialogRef: MatDialogRef<ConditionsDialogComponent>, private assetsService: AssetsService) { }

  ngOnInit() {
    this.actionPlanMeasurement = this.assetsService.getActionPlan();

    if (this.actionPlanMeasurement) {
      const question = this.actionPlanMeasurement.questions.find(question => question._id && question._id === this.questionId);
      this.defaultConditions = question ? question.multiSelectionProperties.answerOptions.filter(answer => answer.colorLabel && answer.colorLabel === this.data.colorZone) : undefined;
    }

    if (this.data.conditions) {
      this.userConditions = {...this.data.conditions};
    } else {
      this.userConditions = {};
    }
  }

  get colorZone() {
    return this.data.colorZone ? this.data.colorZone : '';
  }

  get colorZoneName() {
    if (!this.colorZone) return;

    switch (this.colorZone) {
      case 'yellow':
        return 'gele';
      case 'orange':
        return 'oranje';
      case 'red':
        return 'rode';
    }
  }

  get additions() {
    return this.userConditions && this.userConditions.additions ? this.userConditions.additions  : [];
  }

  removeAddition(index) {
    this.userConditions.additions.splice(index, 1);
  }

  getConditionVisbility(conditionId: string): boolean {
    if (!this.userConditions.exceptions) return true;
    const exception = this.userConditions.exceptions.find(exception => exception.conditionId === conditionId);
    return exception ? exception.visible : true;
  }

  changeDefaultCondition(conditionId: string): void {
    if (!this.userConditions.exceptions) {
      this.userConditions.exceptions = [{
        conditionId,
        visible: false
      }];

      return;
    }

    const exceptionIndex = this.userConditions.exceptions.findIndex(exception => exception.conditionId === conditionId);
    if (exceptionIndex === -1) {
      this.userConditions.exceptions.push({
        conditionId,
        visible: false,
      })
    } else {
      const exception = this.userConditions.exceptions[exceptionIndex];
      this.userConditions.exceptions[exceptionIndex].visible = !exception.visible;
    }
  }

  addCustomCondition(colorZone: ColorZone): void {
    const dialog = this.dialog.open(InputDialogComponent, {
      data: {
        colorZone,
        title: 'Extra klacht toevoegen',
        confirmBtnText: 'Extra klacht toevoegen',
        placeholderText: 'Beschrijf de klacht'
      }
    });

    dialog.afterClosed().subscribe(data => {
      if (data && data.value) {
        if (!this.userConditions.additions) {
          this.userConditions.additions = [{title: data.value}];
        } else {
          this.userConditions.additions.push({title: data.value});
        }
      }
    })
  }

  resetConditions() {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Reset klachten",
        message:
          "Weet u zeker dat u alle klachten wilt resetten?",
      },
    });

    confirmDialog.afterClosed().pipe(take(1)).subscribe(confirmed => {
      if (confirmed) {
        this.userConditions = {};
      }
    })
  }

  close() {
    this.dialogRef.close(false)
  }

  save() {
    const conditions = {...this.userConditions};
    this.dialogRef.close({conditions});
  }
}
