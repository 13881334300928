import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Medicine } from '../../store/medicine/medicine.model';
import { ColorZone, Patient } from '../../store/patient/patient.model';
import { StoreError } from '../../store/error/error.model';
import { filter, skip, take, map } from 'rxjs/operators';
import * as fromRoot from '../../store';
import * as errorActions from '../../store/error/error.actions';
import * as patientActions from '../../store/patient/patient.actions';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-use-epipen-dialog',
  templateUrl: './use-epipen-dialog.component.html',
  styleUrls: ['./use-epipen-dialog.component.scss']
})
export class UseEpipenDialogComponent implements OnInit, OnDestroy {
  dialogForm: FormGroup;
  actionControl: FormControl;
  isPosting: boolean;
  errorMessage: string;
  selectedMedicine: Medicine;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { useEpipen: boolean; patient: Patient; colorZone: ColorZone },
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<UseEpipenDialogComponent>,
    private store: Store<fromRoot.State>,
    private formBuilder: FormBuilder,
	private translateService: TranslateService
  ) { }

  ngOnDestroy() {
    this.store.dispatch(new errorActions.ClearErrors());
  }

  ngOnInit() {
    this.isPosting = false;
    this.store.dispatch(new errorActions.ClearErrors());

    let useEpipen = this.data.useEpipen ? (this.data.useEpipen as boolean) : undefined;
    if (!useEpipen) {
      useEpipen = false;
    }

    this.dialogForm = this.formBuilder.group({
      useEpipen: [useEpipen, Validators.required]
    });

    this.store
      .select(fromRoot.getErrors)
      .pipe(filter(errors => errors && errors.length > 0))
      .pipe(map(errors => errors[0]))
      .pipe(filter(error => error.apiError !== undefined))
      .subscribe((error: StoreError) => {
        this.isPosting = false;
        switch (error.apiError.code) {
          default:
            this.errorMessage = this.translateService.instant('useEpipenDialog.error') // 'Er is iets fout gegaan';
            break;
        }
      });
  }

  isPosting$() {
    return this.store
      .select(fromRoot.isPostingPatient)
      .pipe(filter(isPosting => isPosting === false))
      .pipe(take(1))
      .toPromise();
  }

  patchUseEpipen() {
    if (this.isPosting) {
      return;
    }
    const patch = this.dialogForm.value;
    this.isPosting = true;
    this.errorMessage = undefined;
    this.store.dispatch(
      new patientActions.UpdateActionPlan({ id: this.data.patient._id, colorZone: this.data.colorZone, patch })
    );
    this.isPosting$().then(() => {
      this.isPosting = false;
      this.dialogRef.close();
    });
  }

  cancel() {
    this.dialogRef.close();
  }
}
