import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Action as ActionPlanAction, ColorZone, Org } from './org.model';

export enum OrgActionTypes {
  LoadOrgs = '[Org] Load Orgs',
  LoadOrgsSuccess = '[Org] Load Orgs Success',
  GetOne = '[Org] Get One',
  GetOneSuccess = '[Org] Get One Success',
  AddOrg = '[Org] Add Org',
  AddOrgSuccess = '[Org] Add Org Complete',
  UpsertOrg = '[Org] Upsert Org',
  AddOrgs = '[Org] Add Orgs',
  UpsertOrgs = '[Org] Upsert Orgs',
  UpdateOrg = '[Org] Update Org',
  UpdateOrgSuccess = '[Org] Update Org Success',
  UpdateOrgs = '[Org] Update Orgs',
  DeleteOrg = '[Org] Delete Org',
  DeleteOrgSuccess = '[Org] Delete Org Success',
  ArchiveOrg = '[Org] Archive Org',
  ArchiveOrgSuccess = '[Org] Archive Org Success',
  DeleteOrgs = '[Org] Delete Orgs',
  ClearOrgs = '[Org] Clear Orgs',
  Select = '[Org] Select Org',
}

export class LoadOrgs implements Action {
  readonly type = OrgActionTypes.LoadOrgs;
  constructor(public payload?: { unresolvedOnly: boolean }) { }
}

export class LoadOrgsSuccess implements Action {
  readonly type = OrgActionTypes.LoadOrgsSuccess;

  constructor(public payload: { items: Org[] }) { }
}

export class GetOne implements Action {
  readonly type = OrgActionTypes.GetOne;

  constructor(public payload: string) { }
}

export class GetOneSuccess implements Action {
  readonly type = OrgActionTypes.GetOneSuccess;

  constructor(public payload: Org) { }
}

export class AddOrg implements Action {
  readonly type = OrgActionTypes.AddOrg;

  constructor(public payload: { item: Org }) { }
}

export class AddOrgSuccess implements Action {
  readonly type = OrgActionTypes.AddOrgSuccess;

  constructor(public payload: { item: Org }) { }
}

export class UpsertOrg implements Action {
  readonly type = OrgActionTypes.UpsertOrg;

  constructor(public payload: { item: Org }) { }
}

export class AddOrgs implements Action {
  readonly type = OrgActionTypes.AddOrgs;

  constructor(public payload: { items: Org[]; type: 'orgs' }) { }
}

export class UpsertOrgs implements Action {
  readonly type = OrgActionTypes.UpsertOrgs;

  constructor(public payload: { items: Org[] }) { }
}

export class UpdateOrg implements Action {
  readonly type = OrgActionTypes.UpdateOrg;

  constructor(public payload: { item: Org; id: string }) { }
}

export class UpdateOrgSuccess implements Action {
  readonly type = OrgActionTypes.UpdateOrgSuccess;

  constructor(public payload: Update<Org>) { }
}

export class UpdateOrgs implements Action {
  readonly type = OrgActionTypes.UpdateOrgs;

  constructor(public payload: { items: Update<Org>[] }) { }
}

export class DeleteOrg implements Action {
  readonly type = OrgActionTypes.DeleteOrg;

  constructor(public payload: { id: string }) { }
}

export class DeleteOrgSuccess implements Action {
  readonly type = OrgActionTypes.DeleteOrgSuccess;

  constructor(public payload: { id: string }) { }
}

export class ArchiveOrg implements Action {
  readonly type = OrgActionTypes.ArchiveOrg;

  constructor(public payload: { id: string, reason: string }) { }
}

export class ArchiveOrgSuccess implements Action {
  readonly type = OrgActionTypes.ArchiveOrgSuccess;

  constructor(public payload: { id: string }) { }
}

export class DeleteOrgs implements Action {
  readonly type = OrgActionTypes.DeleteOrgs;

  constructor(public payload: { ids: string[] }) { }
}

export class ClearOrgs implements Action {
  readonly type = OrgActionTypes.ClearOrgs;
}

export class Select implements Action {
  readonly type = OrgActionTypes.Select;

  constructor(public payload: string) { }
}

export type OrgActions =
  | LoadOrgs
  | LoadOrgsSuccess
  | GetOne
  | GetOneSuccess
  | AddOrg
  | AddOrgSuccess
  | UpsertOrg
  | AddOrgs
  | UpsertOrgs
  | UpdateOrg
  | UpdateOrgSuccess
  | UpdateOrgs
  | DeleteOrg
  | DeleteOrgSuccess
  | ArchiveOrg
  | ArchiveOrgSuccess
  | DeleteOrgs
  | ClearOrgs
  | Select;
