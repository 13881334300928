import { Component, OnInit, Output, Input, EventEmitter } from "@angular/core";
import { ChatReply } from "src/app/store/team/team.model";

@Component({
  selector: "app-standard-reply-selector",
  templateUrl: "./standard-reply-selector.component.html",
  styleUrls: ["./standard-reply-selector.component.scss"],
})
export class StandardReplySelectorComponent implements OnInit {
  @Input() replies: ChatReply[];
  @Output() selected = new EventEmitter();
  visible = false;

  constructor() {}

  ngOnInit() {}

  selectMessage(reply: ChatReply) {
    this.selected.emit(reply);
    this.visible = false;
  }
}
