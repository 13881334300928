import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { MatTableDataSource, Sort } from "@angular/material";
import { Observable, Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { Patient } from "src/app/store/patient/patient.model";
import { formatBirthDate, sortData } from "src/app/util/helper-functions";

@Component({
  selector: "app-patients-table",
  templateUrl: "./patients-table.component.html",
  styleUrls: ["./patients-table.component.scss"],
})
export class PatientsTableComponent implements OnInit, OnDestroy, OnChanges {
  @Input() patients$: Observable<Patient[]>;
  @Input() filterValue: string;
  @Output() edit = new EventEmitter();
  patients: Patient[];
  displayedColumns = [
    "combinedName",
    "patientNumber",
    "studyNumber",
    "dateOfBirth",
    "createdAt",
  ];
  dataSource = new MatTableDataSource<any>([]);
  formatBirthDate: (date: string) => string;
  patientSub: Subscription;
  sort: Sort;

  constructor() {}

  ngOnInit(): void {
    this.formatBirthDate = formatBirthDate;
    this.patientSub = this.patients$
      .pipe(filter((patients) => patients !== undefined))
      .subscribe((patients) => {
        this.patients = patients;
        this.sortData(this.sort);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filterValue) {
      this.applyFilter(changes.filterValue.currentValue);
    }
  }

  ngOnDestroy() {
    if (this.patientSub) {
      this.patientSub.unsubscribe();
    }
  }

  applyFilter(filterValue: string) {
    if (!filterValue) return;
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  filterPredicate(
    data: {
      firstName: string;
      lastName: string;
      studyNumber: string;
      dateOfBirth: string;
    },
    filterValue: string
  ) {
    if (!data || !data.firstName) {
      return false;
    }
    return (
      (
        data.firstName +
        data.lastName +
        formatBirthDate(data.dateOfBirth) +
        data.studyNumber
      )
        .trim()
        .toLowerCase()
        .indexOf(filterValue) !== -1
    );
  }

  sortData(sort: Sort) {
    this.dataSource = sortData(
      sort,
      this.dataSource,
      this.patients,
      this.filterPredicate
    );
  }

  editPatient(patient: Patient): void {
    this.edit.emit(patient);
  }
}
