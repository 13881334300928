import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { PatientAction } from './patientAction.model';
import { PatientActionActions, PatientActionActionTypes, LoadPatientActionsSuccess } from './patientAction.actions';
import { AuthActionTypes, AuthActions } from '../auth/auth.actions';

export interface State extends EntityState<PatientAction> {
  // additional entities state properties
  selectedPatientId?: string;
  isPosting: boolean;
}

export const adapter: EntityAdapter<PatientAction> = createEntityAdapter<PatientAction>({
  selectId: (item: PatientAction) => item._id
});

export const initialState: State = adapter.getInitialState({
  isPosting: false
});

export function reducer(state = initialState, action: PatientActionActions | AuthActions): State {
  switch (action.type) {
    case PatientActionActionTypes.LoadPatientActionsSuccess: {
      return adapter.upsertMany((action as LoadPatientActionsSuccess).payload.items, state);
    }

    case PatientActionActionTypes.ClearPatientActions: {
      return adapter.removeAll(state);
    }

    case PatientActionActionTypes.ResolveSuccess: {
      return adapter.updateOne(action.payload[0], state);
    }

    case PatientActionActionTypes.Select: {
      return {
        ...state,
        selectedPatientId: action.payload
      };
    }

    case AuthActionTypes.LogoutSuccess: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const getSelectedPatientId = (state: State) => state.selectedPatientId;
export const isPosting = (state: State) => state.isPosting;
