import { Selectors } from "./../../store/root";
import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import * as moment from "moment";
import { Store } from "@ngrx/store";
import { Patient, ETHNICITIES } from "./../../store/patient/patient.model";
import { StoreError } from "../../store/error/error.model";
import { filter, take, skip, map } from "rxjs/operators";
import * as fromRoot from "../../store";
import * as patientActions from "../../store/patient/patient.actions";
import * as errorActions from "../../store/error/error.actions";
import {
  formatBirthDate,
  formatPhoneNumber,
  getThresholdValueName,
} from "./../../util/helper-functions";
import * as settingsActions from "../../store/settings/settings.actions";
import { Observable } from "rxjs";
import { Team } from "src/app/store/team/team.model";
import { WEEKDAYS } from "../../store";
import { BehaviorSubject } from "rxjs";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-patient-dialog",
  templateUrl: "./patient-dialog.component.html",
  styleUrls: ["./patient-dialog.component.scss"],
})
export class PatientDialogComponent implements OnInit, OnDestroy {
  dialogForm: FormGroup;
  phoneForm: FormGroup;
  settingsForm: FormGroup;
  patientCharacteristicsForm: FormGroup;
  notifyMeAtForm: FormGroup;
  isPosting: boolean;
  errorMessage: string;
  notifyOptions = [];
  team$: Observable<Team>;
  weekdays = WEEKDAYS;
  ethnicities = ETHNICITIES;
  thresholdValues: number[] = [];
  defaultProperty = "default";
  defaultPropertyValue = "Standaard grenswaarde";
  minDate: moment.Moment;
  showOptionalSetting = false;
  optionalSettings: {
    instructionsBy: string[];
    delivery: string;
    sendOrderInstructionsScheduled: boolean;
  };
  sendOrderInstructionsScheduled$ = new BehaviorSubject<boolean>(false);
  showActions = false;
  getThresholdValueName: (num: number) => number = getThresholdValueName;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      patient: Patient;
      editThreshold?: boolean;
      device?: boolean;
      optionalSettings?: boolean;
      deviceId?: string;
      hideSpirometerForm?: boolean;
      hasDevice?: boolean;
    },
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PatientDialogComponent>,
    private router: Router,
    private store: Store<fromRoot.State>,
    private formBuilder: FormBuilder,
	private translateService: TranslateService
  ) {}

  ngOnDestroy() {
    this.store.dispatch(new errorActions.ClearErrors());
  }

  ngOnInit() {
    this.isPosting = false;
    this.minDate = moment().subtract(98, "years");

    if (!this.data.device) {
      this.showActions = true;
    }

    if (this.data.optionalSettings) {
      this.displayOptionalSettingsForm();
    }

    let curr = 1.0;
    for (let i = 0; curr <= 4.1; i++) {
      this.thresholdValues.push(parseFloat(curr.toFixed(1)));
      curr += 0.1;
    }

    this.store.dispatch(new errorActions.ClearErrors());

    let patient = this.data.patient
      ? (this.data.patient as Patient)
      : undefined;

    if (!patient) {
      patient = {
        firstName: "",
        lastName: "",
        dateOfBirth: "",
        phone: { phonenumber: "", countryCode: "NL" },
        patientCharacteristics: {
          weight: undefined,
          height: undefined,
          gender: undefined,
          ethnicity: undefined,
        },
        email: "",
        thresholdACS: { active: false, value: 1.0 },
        patientNumber: "",
        studyNumber: "",
        sendOrderInstructionsScheduled: false,
      };
    } else {
      this.store.dispatch(new settingsActions.LoadTeamAction());
      this.team$ = this.store.select(fromRoot.getTeam);
      this.team$
        .pipe(
          filter((team) => team !== undefined),
          take(1)
        )
        .subscribe((team) => {
          this.notifyOptions = team.monitoringSettings.days
            .filter((day) => day.taken < day.maxPatients)
            .map((day) => day.weekDay);
        });
    }

    this.patientCharacteristicsForm = this.formBuilder.group({
      weight: [
        patient.patientCharacteristics
          ? patient.patientCharacteristics.weight
          : "",
        Validators.compose([
          ...[this.data.device && Validators.required],
          Validators.pattern("[0-9]+"),
          Validators.maxLength(3),
          Validators.minLength(2),
        ]),
      ],
      height: [
        patient.patientCharacteristics
          ? patient.patientCharacteristics.height
          : "",
        Validators.compose([
          ...[this.data.device && Validators.required],
          Validators.pattern("[0-9]+"),
          Validators.maxLength(3),
          Validators.minLength(2),
        ]),
      ],
      gender: [
        patient.patientCharacteristics
          ? patient.patientCharacteristics.gender
          : "",
        ...[this.data.device && Validators.required],
      ],
      ethnicity: [
        patient.patientCharacteristics
          ? patient.patientCharacteristics.ethnicity
          : "",
        ...[this.data.device && Validators.required],
      ],
    });

    if (
      patient.measurements &&
      patient.measurements.find(
        (measurement) => measurement.name === "spirometer"
      )
    ) {
      const measurement = patient.measurements.find(
        (measurement) => measurement.name === "spirometer"
      );
      this.optionalSettings = {
        instructionsBy: measurement.measurementConfiguration.instructionsBy,
        delivery: measurement.measurementConfiguration.delivery,
        sendOrderInstructionsScheduled: patient.sendOrderInstructionsScheduled
          ? patient.sendOrderInstructionsScheduled
          : false,
      };

      this.sendOrderInstructionsScheduled$.next(
        patient.sendOrderInstructionsScheduled
          ? patient.sendOrderInstructionsScheduled
          : false
      );
    }

    this.settingsForm = this.formBuilder.group({
      instructionsBy: [
        this.optionalSettings ? this.optionalSettings.instructionsBy : "",
      ],
      delivery: [this.optionalSettings ? this.optionalSettings.delivery : ""],
      sendOrderInstructionsScheduled: [
        this.optionalSettings
          ? this.optionalSettings.sendOrderInstructionsScheduled
          : false,
      ],
    });

    this.phoneForm = this.formBuilder.group({
      phonenumber: new FormControl(
        formatPhoneNumber(patient.phone.phonenumber, patient.phone.countryCode),
        Validators.compose([
          Validators.required,
          this.mobilePhoneNumberValidator,
        ])
      ),
      countryCode: new FormControl(
        patient.phone.countryCode,
        Validators.required
      ),
    });

    if (this.data.patient && this.data.patient.notifyMeAt) {
      this.notifyMeAtForm = this.formBuilder.group({
        day: new FormControl(patient.notifyMeAt.day),
        time: new FormControl(patient.notifyMeAt.time),
      });
    }

    const currentThresholdValue =
      patient.thresholdACS && patient.thresholdACS.active
        ? patient.thresholdACS.value
        : this.defaultProperty;

    this.dialogForm = this.formBuilder.group({
      _id: [patient._id],
      firstName: [patient.firstName],
      lastName: [patient.lastName],
      dateOfBirth: new FormControl(
        formatBirthDate(patient.dateOfBirth),
        Validators.compose([
          Validators.required,
          Validators.pattern(
            "(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-[12][0-9][0-9][0-9]"
          ),
          this.birthDateValidator,
        ])
      ),
      phone: this.phoneForm,
      email: [
        patient.email,
        Validators.compose([Validators.required, Validators.email]),
      ],
      patientNumber: [patient.patientNumber, Validators.required],
      studyNumber: [patient.studyNumber],
      patientCharacteristics: this.patientCharacteristicsForm,
      thresholdACSValue: [currentThresholdValue, Validators.required],
      notifyMeAt: this.notifyMeAtForm,
    });

    this.store
      .select(fromRoot.getErrors)
      .pipe(filter((errors) => errors && errors.length > 0))
      .pipe(map((errors) => errors[0]))
      .pipe(filter((error) => error.apiError !== undefined))
      .subscribe((error: StoreError) => {
        this.isPosting = false;
        console.log("errpr", error.apiError.code);
        switch (error.apiError.code) {
          case 42203:
            this.errorMessage = this.translateService.instant('patientDialog.phoneAlreadyExists') //"Dit telefoonnummer staat al in de database";
            break;
          case 42210:
            this.errorMessage = this.translateService.instant('patientDialog.patientAlreadyExists') //`Patiënt bestaat al. <a href="/patienten/${error.apiError.message}">Bekijk patiënt</a>.`;
            break;
          default:
            this.errorMessage = this.translateService.instant('patientDialog.defaultError') //"Er is iets fout gegaan";
            break;
        }
      });
  }

  isPosting$() {
    return this.store
      .select(fromRoot.isPostingPatient)
      .pipe(filter((isPosting) => isPosting === false))
      .pipe(take(1))
      .toPromise();
  }

  get showForm() {
    if (this.data.device) {
      return !this.showOptionalSetting;
    } else {
      return true;
    }
  }

  get hasRecievedOrderInstructions() {
    return (
      this.data.patient.orderInstructionsSentAt !== null &&
      this.data.patient.orderInstructionsSentAt !== undefined
    );
  }

  displayOptionalSettingsForm() {
    this.showOptionalSetting = true;
    this.showActions = true;
  }

  changeInstructionsBy(
    event,
    careprovider: "longfunctie" | "verpleegkundige" | "arts"
  ) {
    const currentValue = this.settingsForm.get("instructionsBy").value;

    if (!currentValue) {
      this.settingsForm.controls["instructionsBy"].setValue([careprovider]);
    }

    if (currentValue && event.checked) {
      this.settingsForm.controls["instructionsBy"].setValue([
        ...currentValue,
        careprovider,
      ]);
    }

    if (currentValue && !event.checked) {
      const newList = currentValue.filter((value) => value !== careprovider);
      this.settingsForm.controls["instructionsBy"].setValue([...newList]);
    }
  }

  checkInstructionsBy(careprovider) {
    return this.optionalSettings && this.optionalSettings.instructionsBy
      ? this.optionalSettings.instructionsBy.find((val) => val === careprovider)
      : false;
  }

  toggleSentOrderinstructions() {
    // this.optionalSettings.sendOrderInstructionsScheduled = !this.optionalSettings.sendOrderInstructionsScheduled

    const currentValue = this.settingsForm.get(
      "sendOrderInstructionsScheduled"
    ).value;
    this.sendOrderInstructionsScheduled$.next(!currentValue);
    this.settingsForm.controls["sendOrderInstructionsScheduled"].setValue(
      !currentValue
    );
  }

  async createPatient() {
    if (this.isPosting) {
      return;
    }
    this.isPosting = true;
    this.errorMessage = undefined;
    const selectedTeam = await this.store
      .select(fromRoot.getCareProviderSelectedTeam)
      .pipe(take(1))
      .toPromise();
    const patient = this.parseForm(this.dialogForm.value);
    patient.team = selectedTeam.teamId;
    patient.organization = selectedTeam.orgId;
    this.store.dispatch(new patientActions.AddPatient({ item: patient }));

    this.isPosting$().then(() => {
      this.isPosting = false;
      this.dialogRef.close();
      this.store
        .select(fromRoot.getSelectedPatientId)
        .pipe(take(1))
        .subscribe((selectedId) => {
          this.router.navigate([
            "patienten/" + selectedId,
            {
              tab: "plan",
            },
          ]);
        });
    });
  }

  async patchPatient() {
    if (this.isPosting) {
      return;
    }
    this.isPosting = true;
    this.errorMessage = undefined;

    const patient = this.parseForm(this.dialogForm.value);

    if (
      patient.sendOrderInstructionsScheduled &&
      this.hasRecievedOrderInstructions
    ) {
      const alertDialog = await this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: this.translateService.instant('patientDialog.confirmDialogTitle'), // "Bestelverzoek versturen",
          message: this.translateService.instant('confirmDialogMessage', { 
			date: moment( this.data.patient.orderInstructionsSentAt).format("DD-MM-YYYY"), 
			time: moment( this.data.patient.orderInstructionsSentAt).format( "HH:mm") 
		  }),
          confirmText: this.translateService.instant('patientDialog.confirmDialogConfirm'),// "Versturen",
          cancelText: this.translateService.instant('patientDialog.confirmDialogCancel') //"Annuleer",
        },
      });

      let confirmed = true;

      await alertDialog
        .afterClosed()
        .pipe(take(1))
        .toPromise()
        .then((val) => {
          confirmed = val;
        });

      console.log("confirmed?", confirmed);

      if (!confirmed) {
        this.isPosting = false;
        return;
      }
    }

    this.store.dispatch(
      new patientActions.UpdatePatient({
        item: patient,
        id: patient._id,
        patchNotifyMeAt:
          this.data.patient.notifyMeAt &&
          this.data.patient.notifyMeAt.day !==
            this.dialogForm.value.notifyMeAt.day,
      })
    );

    this.isPosting$().then(() => {
      this.isPosting = false;
      let response;

      if (this.data.editThreshold || this.data.device) {
        response = patient;
      }

      this.dialogRef.close(response);
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  getFormControlErrors(controlName: string) {
    return this.dialogForm.get(controlName).errors;
  }

  private parseForm(formValue) {
    if (!formValue) {
      return formValue;
    }

    if (formValue.thresholdACSValue) {
      let active =
        formValue.thresholdACSValue === this.defaultProperty ? false : true;
      let value =
        formValue.thresholdACSValue === this.defaultProperty
          ? 1.0
          : formValue.thresholdACSValue;
      formValue.thresholdACS = { active, value };
      delete formValue.thresholdACSValue;
    }

    if (this.data.device && !this.data.hasDevice) {
      const optionalSettings = this.settingsForm.value;
      const spirometerMeasurement = {
        name: "spirometer",
        measurementId: this.data.deviceId,
        monitoringEnabled: true,
        enabled: true,
        measurementConfiguration: {
          ...(optionalSettings.instructionsBy
            ? { instructionsBy: optionalSettings.instructionsBy }
            : {}),
          ...(optionalSettings.delivery
            ? { delivery: optionalSettings.delivery }
            : {}),
          // ...(optionalSettings.sendOrderInstructionsScheduled
          //   ? { sendOrderInstructionsScheduled: optionalSettings.sendOrderInstructionsScheduled }
          //   : {sendOrderInstructionsScheduled: false}),
        },
      };

      formValue.sendOrderInstructionsScheduled =
        optionalSettings.sendOrderInstructionsScheduled;

      if (this.data.patient && this.data.patient.measurements !== undefined) {
        formValue["measurements"] = [
          ...this.data.patient.measurements,
          spirometerMeasurement,
        ];
      } else {
        formValue["measurements"] = [spirometerMeasurement];
      }
    }

    if (this.data.device && this.data.hasDevice) {
      const optionalSettings = this.settingsForm.value;
      formValue["measurements"] = this.data.patient.measurements;

      const measurementIndex = this.data.patient.measurements.findIndex(
        (measurement) => measurement.measurementId === this.data.deviceId
      );

      const updatedPatientSpirometer = {
        ...this.data.patient.measurements[measurementIndex],
        monitoringEnabled: true,
        name: "spirometer",
        enabled: true,
        measurementConfiguration: {
          ...(optionalSettings.instructionsBy
            ? { instructionsBy: optionalSettings.instructionsBy }
            : {}),
          ...(optionalSettings.delivery
            ? { delivery: optionalSettings.delivery }
            : {}),
          // ...(optionalSettings.sendOrderInstructionsScheduled
          //   ? { sendOrderInstructionsScheduled: optionalSettings.sendOrderInstructionsScheduled }
          //   : {sendOrderInstructionsScheduled: false}),
        },
      };
      formValue.sendOrderInstructionsScheduled =
        optionalSettings.sendOrderInstructionsScheduled;

      formValue.measurements[measurementIndex] = updatedPatientSpirometer;
    }

    if (formValue.phone) {
      if (formValue.phone.phonenumber.substr(0, 2) === "04") {
        formValue.phone.countryCode = "BE";
      }
    }

    if (formValue.dateOfBirth) {
      formValue.dateOfBirth = moment(
        formValue.dateOfBirth,
        "DD-MM-YYYY"
      ).format("YYYY-MM-DD");
    }
    return formValue;
  }

  private birthDateValidator(control: FormControl) {
    const birthDate = control.value;
    const minDate = moment().subtract(98, "years");
    const momentDate = moment(birthDate, "DD-MM-YYYY");

    if (!momentDate.isValid()) {
      return { error: "invalid date" };
    }

    if (momentDate.isBefore(minDate)) {
      return { minDate: minDate.format("DD-MM-YYYY") };
    }

    return null;
  }

  private mobilePhoneNumberValidator(control: FormControl) {
    const phoneNumber = control.value;
    if (
      phoneNumber.substr(0, 2) !== "06" &&
      phoneNumber.substr(0, 2) !== "04"
    ) {
      return { error: "invalid phonenumber" };
    }
    return null;
  }
}
