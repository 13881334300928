import { ChatReply } from "src/app/store/team/team.model";
import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  ViewChild,
  Input,
} from "@angular/core";
import { trigger, transition, style, animate } from "@angular/animations";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as fromRoot from "../../store";
import * as patientActions from "../../store/patient/patient.actions";
import * as messageActions from "../../store/message/message.actions";
import * as errorActions from "../../store/error/error.actions";
import { Store } from "@ngrx/store";
import { Patient } from "../../store/patient/patient.model";
import { Message } from "../../store/message/message.model";
import { Router } from "@angular/router";
import {
  formatBirthDate,
  formatPhoneNumber,
  getColor,
} from "./../../util/helper-functions";
import { StoreError } from "../../store/error/error.model";
import {
  filter,
  skip,
  take,
  map,
  takeUntil,
  delay,
  tap,
  distinctUntilChanged,
} from "rxjs/operators";
import {
  Observable,
  Subject,
  Subscription,
  interval,
  BehaviorSubject,
  of,
} from "rxjs";
import { MatDialog } from "@angular/material";
import { ConfirmDialogComponent } from "src/app/dialogs/confirm-dialog/confirm-dialog.component";
import { CareProvider } from "src/app/store/careprovider/careprovider.model";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-chat",
  templateUrl: "./chat.component.html",
  styleUrls: ["./chat.component.scss"],
  animations: [
    trigger("enterAnimation", [
      transition(":enter", [
        style({ transform: "translateX(-30%)", opacity: 0 }),
        animate(
          "500ms ease-out",
          style({ transform: "translateX(0)", opacity: 1 })
        ),
      ]),
      transition(":leave", [
        style({ transform: "translateX(0)", opacity: 1 }),
        animate(
          "500ms ease-in",
          style({ transform: "translateX(100%)", opacity: 0 })
        ),
      ]),
    ]),
    trigger("toast", [
      transition(":enter", [
        style({ transform: "translateY(30%)", opacity: 0 }),
        animate(
          "150ms ease-in",
          style({ transform: "translateY(0)", opacity: 1 })
        ),
      ]),
      transition(":leave", [
        style({ transform: "translateY(0)", opacity: 1 }),
        animate(
          "150ms ease-out",
          style({ transform: "translateY(30%)", opacity: 0 })
        ),
      ]),
    ]),
  ],
})
export class ChatViewComponent implements OnInit, OnDestroy {
  @ViewChild("messages") messageContainer: ElementRef;
  createMessageForm: FormGroup;
  messages$: Observable<Message[]>;
  patient$: Observable<Patient>;
  replies$: Observable<ChatReply[]>;
  careProvider$: Observable<CareProvider>;
  destroy$: Subject<boolean> = new Subject<boolean>();
  @Input() selectedChat: Patient;
  isPostingMessage$: Promise<boolean>;
  isPostingMessage: boolean;
  isPostingPatient$: Promise<boolean>;
  toastMessage$ = new BehaviorSubject<string>(null);
  isPostingPatient: boolean;
  errorMessage: string;
  shownChat: null;
  showStandardReplyBtn = false;
  deeplink: string;
  standardReplyBtnText = "ik doe mee";
  statusBtn: true;
  getColor = getColor;
  copying = false;
  messagesSelected: { [key: string]: Message } = {};
  textToCopy = "textToCopy";
  formatBirthDate: (date: string) => string;
  formatPhoneNumber: (phonenumber: string, countryCode: string) => string = formatPhoneNumber;

  constructor(
	private translateService: TranslateService,
    private formBuilder: FormBuilder,
    private store: Store<fromRoot.State>,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    console.log("init");
    this.formatBirthDate = formatBirthDate;
    this.isPostingMessage = false;
    this.isPostingPatient = false;
    this.store.dispatch(new errorActions.ClearErrors());
    this.replies$ = this.store.select(
      fromRoot.getCareProviderSelectedTeamReplies
    );
    this.patient$ = this.store.select(fromRoot.getSelectedPatient);
    this.messages$ = this.store.select(fromRoot.getSelectedMessages);
    this.careProvider$ = this.store.select(fromRoot.getCareProvider);
    this.isPostingMessage$ = this.store
      .select(fromRoot.isPostingMessage)
      .pipe(filter((isPosting) => isPosting === false))
      .pipe(skip(1))
      .pipe(take(1))
      .toPromise();

    this.isPostingPatient$ = this.store
      .select(fromRoot.isPostingPatient)
      .pipe(filter((isPosting) => isPosting === false))
      .pipe(skip(1))
      .pipe(take(1))
      .toPromise();

    this.store
      .select(fromRoot.getErrors)
      .pipe(filter((errors) => errors && errors.length > 0))
      .pipe(map((errors) => errors[0]))
      .pipe(filter((error) => error.apiError !== undefined))
      .subscribe((error: StoreError) => {
        this.isPostingMessage = false;
        this.isPostingPatient = false;
        switch (error.apiError.code) {
          default:
            this.errorMessage = this.translateService.instant("patientDetail.tabs.messages.error");
            break;
        }
      });
    this.messages$
      .pipe(
        takeUntil(this.destroy$),
        filter((messages) => messages !== undefined && messages.length > 0),
        distinctUntilChanged(
          (previous, current) =>
            previous[previous.length - 1]._id ===
            current[current.length - 1]._id
        ),
        delay(0)
      )
      .subscribe((_) => this.pinToBottom());

    this.createMessageForm = this.formBuilder.group({
      text: [""],
    });

    this.store
      .select(fromRoot.getCurrentMessage(this.selectedChat._id))
      .pipe(take(1))
      .toPromise()
      .then((message) => {
        console.log("message", message);
        if (message) {
          this.createMessageForm.controls.text.setValue(message);
        }
      });

    this.store.dispatch(new messageActions.Select(this.selectedChat._id));
    this.store.dispatch(new patientActions.Select(this.selectedChat._id));
    this.store.dispatch(
      new messageActions.LoadMessages({ id: this.selectedChat._id })
    );
  }

  ngOnDestroy() {
    if (
      this.createMessageForm.controls.text.value &&
      this.createMessageForm.controls.text.value.length > 0
    ) {
      this.store.dispatch(
        new messageActions.SaveCurrentMessage({
          chatId: this.selectedChat._id,
          message: this.createMessageForm.controls.text.value,
        })
      );
    }
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  get selectedCount() {
    return Object.keys(this.messagesSelected).length;
  }

  checkChange(event) {
    console.log(event);
    if (event.selected) {
      this.messagesSelected[event.index] = event.message;
    } else {
      delete this.messagesSelected[event.index];
    }
  }

  selectReply(reply) {
    let selectedReply = { ...reply };

    if (reply.message.includes("astmakompas://")) {
      this.showStandardReplyBtn = true;
      if (reply.message.includes("&title=")) {
        const title = reply.message.match(/(&title=)([a-zA-Z0-9])+/g);
        if (title && title.length > 0) {
          this.standardReplyBtnText = title[0].replace("&title=", "");
        }
      }
      this.deeplink = reply.message.match(
        /(astmakompas:\/\/)([a-zA-Z0-9._=&?])+/g
      );
      const updatedMessage = selectedReply.message.replace(this.deeplink, "");
      selectedReply.message = updatedMessage;
    } else {
      this.showStandardReplyBtn = false;
    }

    this.createMessageForm.controls.text.setValue(selectedReply.message);
  }

  removeStandardReplyBtn() {}

  async copyText() {
    const patient = await this.patient$.pipe(take(1)).toPromise();
    this.textToCopy = Object.keys(this.messagesSelected).reduce(
      (cum, current) => {
        const msg = this.messagesSelected[current];
        const sender = msg.careProvider
          ? `\n${this.translateService.instant("patientDetail.tabs.messages.senderCaretaker")}: ${msg.careProvider.firstName} ${msg.careProvider.lastName}`
          : `\n${this.translateService.instant("patientDetail.tabs.messages.senderPatient")}: ${patient.firstName} ${patient.lastName}\n${this.translateService.instant("patientDetail.tabs.messages.number")}: ${patient.patientNumber}`;
        const dateTime = `\n${this.translateService.instant("patientDetail.tabs.messages.sendTime")}: ${msg.createdAt.toLocaleDateString()}`;
        const text = `\n${this.translateService.instant("patientDetail.tabs.messages.message")}:\n"` + msg.text + '"';
        return (
          cum +
          "────────────────────" +
          sender +
          dateTime +
          text +
          "\n────────────────────\n\n"
        );
      },
      ""
    );
    this.copy(this.textToCopy);
  }

  async copy(textToCopy: any) {
    let textArea: HTMLTextAreaElement;

    const isOS = () => {
      return navigator.userAgent.match(/ipad|iphone/i);
    };

    const createTextArea = (txt) => {
      textArea = document.createElement("textArea") as HTMLTextAreaElement;
      textArea.innerHTML = txt;
      document.body.appendChild(textArea);
    };

    const selectText = () => {
      let range;
      let selection;

      if (isOS()) {
        range = document.createRange();
        range.selectNodeContents(textArea);
        selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        textArea.setSelectionRange(0, 999999);
      } else {
        textArea.select();
      }
    };

    const copyToClipboard = () => {
      document.execCommand("copy");
      document.body.removeChild(textArea);
    };

    createTextArea(textToCopy);
    selectText();
    copyToClipboard();
    this.toastMessage$.next(this.translateService.instant("patientDetail.tabs.messages.chatsCopied"));
    await of().pipe(delay(2000)).toPromise();
    this.toastMessage$.next(null);
  }

  // copyDivToClipboard(element: ElementRef) {
  //   const range = document.createRange();
  //   range.selectNode(element.nativeElement);
  //   window.getSelection().removeAllRanges(); // clear current selection
  //   window.getSelection().addRange(range); // to select text
  //   document.execCommand('copy');
  //   window.getSelection().removeAllRanges(); // to deselect
  // }

  async setAnswered(chatId) {
    if (this.isPostingPatient) {
      return;
    }
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: this.translateService.instant("patientDetail.tabs.messages.setAnsweredTitle"), //"Vraag afhandelen",
        message: this.translateService.instant("patientDetail.tabs.messages.setAnsweredMessage"),
        confirmText: this.translateService.instant("patientDetail.tabs.messages.setAnsweredConfirm"),
      },
    });
    const confirmation = await confirmDialog
      .afterClosed()
      .pipe(take(1))
      .toPromise();
    if (!confirmation) {
      return;
    }
    this.isPostingPatient = true;
    this.errorMessage = undefined;

    this.store.dispatch(new patientActions.SetChatResolved({ id: chatId }));

    this.isPostingPatient$.then(() => {
      this.isPostingPatient = false;
    });
  }

  lineBreaks(text: string) {
    return text.replace(new RegExp("\n", "g"), "<br>");
  }

  copyChats() {
    this.copying = true;
  }

  createChatMessage(chatId) {
    if (this.isPostingMessage) {
      return;
    }

    this.isPostingMessage = true;
    this.errorMessage = undefined;

    const text = this.showStandardReplyBtn
      ? this.createMessageForm.value.text + " " + this.deeplink
      : this.createMessageForm.value.text;

    const chatMessage = {
      text,
    };

    this.store.dispatch(
      new messageActions.AddMessage({ id: chatId, item: chatMessage })
    );

    this.isPostingMessage$.then(() => {
      this.isPostingMessage = false;
      this.createMessageForm.reset();
      this.showStandardReplyBtn = false;
    });
  }

  editPatient(patientId) {
    this.router.navigate(["patienten/" + patientId, { chat: true }]);
  }

  pinToBottom() {
    console.log("this.messageContainer", this.messageContainer);
    if (this.messageContainer === undefined) {
      return;
    }
    const scrollHeight = this.messageContainer.nativeElement.scrollHeight;
    console.log(
      "scrollHeight ",
      scrollHeight,
      "this.messageContainer.nativeElement",
      this.messageContainer.nativeElement
    );
    this.messageContainer.nativeElement.scrollTop = scrollHeight;
  }
}
