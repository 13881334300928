import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, mergeMap } from 'rxjs/operators';
import {
  TeamActionTypes,
  AddTeam,
  AddTeamSuccess,
  LoadTeams,
  LoadTeamsSuccess,
  UpdateTeamSuccess,
  UpdateTeam,
  GetOne,
  GetOneSuccess,
  Select,
} from './team.actions';
import { ApiService } from '../../services/api.service';

import * as errorActions from '../error/error.actions';
import * as fromRoot from '../';
import { Store } from '@ngrx/store';

import { v4 as uuid } from 'uuid';
import { empty } from 'rxjs';
import { Team } from './team.model';

@Injectable()
export class TeamEffects {
  @Effect()
  add$ = this.actions$.pipe(
    ofType(TeamActionTypes.AddTeam),
    switchMap((action: AddTeam) => {
      return this.api.createTeamAdmin(action.payload.orgId, action.payload.team).pipe(
        mergeMap(response => {
          return [new Select(response._id), new AddTeamSuccess({ item: response })];
        }),
        catchError(err => {
          this.store.dispatch(
            new errorActions.AddError({
              error: {
                id: uuid(),
                created: new Date(),
                content: err,
                source: action
              }
            })
          );
          return empty();
        })
      );
    })
  );

  @Effect()
  getOne$ = this.actions$.pipe(
    ofType(TeamActionTypes.GetOne),
    switchMap((action: GetOne) => {
      return this.api.getTeamAdmin(action.payload.orgId, action.payload.teamId).pipe(
        map((verifyResponse: Team) => {
          return new GetOneSuccess(verifyResponse);
        }),
        catchError(err => {
          this.store.dispatch(
            new errorActions.AddError({
              error: {
                id: uuid(),
                created: new Date(),
                content: err,
                source: action
              }
            })
          );
          return empty();
        })
      );
    })
  );

  @Effect()
  load$ = this.actions$.pipe(
    ofType(TeamActionTypes.LoadTeams),
    mergeMap((action: LoadTeams) => {
      const apiCall = this.api.getAllTeamsAdmin();
      return apiCall.pipe(
        map((teams: Team[]) => {
          return new LoadTeamsSuccess({ items: teams });
        }),
        catchError(err => {
          this.store.dispatch(
            new errorActions.AddError({
              error: {
                id: uuid(),
                created: new Date(),
                content: err,
                source: action
              }
            })
          );
          return empty();
        })
      );
    })
  );


  @Effect()
  update$ = this.actions$.pipe(
    ofType(TeamActionTypes.UpdateTeam),
    switchMap((action: UpdateTeam) => {
      return this.api.patchTeamAdmin(action.payload.orgId, action.payload.teamId, action.payload.team).pipe(
        map(verifyResponse => {
          return new UpdateTeamSuccess({ changes: verifyResponse, id: action.payload.teamId });
        }),
        catchError(err => {
          this.store.dispatch(
            new errorActions.AddError({
              error: {
                id: uuid(),
                created: new Date(),
                content: err,
                source: action
              }
            })
          );
          return empty();
        })
      );
    })
  );


  // @Effect()
  // delete$ = this.actions$.pipe(
  //   ofType(TeamActionTypes.DeleteTeam),
  //   switchMap((action: DeleteTeam) => {
  //     return this.api.deleteTeam(action.payload.teamId).pipe(
  //       map(() => {
  //         return new DeleteTeamSuccess({ id: action.payload.teamId });
  //       }),
  //       catchError(err => {
  //         this.store.dispatch(
  //           new errorActions.AddError({
  //             error: {
  //               id: uuid(),
  //               created: new Date(),
  //               content: err,
  //               source: action
  //             }
  //           })
  //         );
  //         return empty();
  //       })
  //     );
  //   })
  // );

  constructor(private actions$: Actions, private api: ApiService, private store: Store<fromRoot.State>) { }
}
