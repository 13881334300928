import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { ArchiveReason, ColorZone, Patient } from './patient.model';
import { Medicine } from './../medicine/medicine.model';
import { MedicineActionPoint, ActionPoint, Conditions } from '../shared.model';

export enum PatientActionTypes {
  LoadPatients = '[Patient] Load Patients',
  LoadPatientsSuccess = '[Patient] Load Patients Success',
  LoadChats = '[Patient] Load Chats',
  LoadChatsSuccess = '[Patient] Load Chats Success',
  GetOne = '[Patient] Get One',
  GetOneSuccess = '[Patient] Get One Success',
  AddPatient = '[Patient] Add Patient',
  AddPatientSuccess = '[Patient] Add Patient Complete',
  UpsertPatient = '[Patient] Upsert Patient',
  AddPatients = '[Patient] Add Patients',
  UpsertPatients = '[Patient] Upsert Patients',
  UpdatePatient = '[Patient] Update Patient',
  UpdatePatientSuccess = '[Patient] Update Patient Success',
  AddMedication = '[Patient] Add Medication',
  AddMedicationSuccess = '[Patient] Add Medication Success',
  RemoveMedication = '[Patient] Remove Medication',
  RemoveMedicationSuccess = '[Patient] Remove Medication Success',
  AddAction = '[Patient] Add Action',
  AddActionSuccess = '[Patient] Add Action Success',
  UpdateAction = '[Patient] Update Action',
  UpdateActionSuccess = '[Patient] Update Action Success',
  UpdateActionPlan = '[Patient] Update ActionPlan',
  UpdateActionPlanSuccess = '[Patient] Update ActionPlan Success',
  RemoveAction = '[Patient] Remove Action',
  RemoveActionSuccess = '[Patient] Remove Action Success',
  UpdateActionOrder = '[Patient] Update Order Action',
  UpdateActionOrderSuccess = '[Patient] Update Action Order Success',
  UpdatePatients = '[Patient] Update Patients',
  DeletePatient = '[Patient] Delete Patient',
  DeletePatientSuccess = '[Patient] Delete Patient Success',
  ArchivePatient = '[Patient] Archive Patient',
  ArchivePatientSuccess = '[Patient] Archive Patient Success',
  UnarchivePatient = '[Patient] Unarchive Patient',
  UnarchivePatientSuccess = '[Patient] Unarchive Patient Success',
  DeletePatients = '[Patient] Delete Patients',
  ClearPatients = '[Patient] Clear Patients',
  Select = '[Patient] Select Patient',
  SetChatResolved = '[Patient] Set Message Answered',
  SetChatResolvedSuccess = '[Patient] Set Message Answered Success',
  Invite = '[Patient] Invite',
  InviteSuccess = '[Patient] Invite Success'
}

export class LoadPatients implements Action {
  readonly type = PatientActionTypes.LoadPatients;
  constructor(public payload?: { unresolvedOnly: boolean }) { }
}

export class LoadPatientsSuccess implements Action {
  readonly type = PatientActionTypes.LoadPatientsSuccess;

  constructor(public payload: { items: Patient[]}) { }
}

export class LoadChats implements Action {
  readonly type = PatientActionTypes.LoadChats;
  constructor() { }
}

export class LoadChatsSuccess implements Action {
  readonly type = PatientActionTypes.LoadChatsSuccess;

  constructor(public payload: { items: Patient[] }) { }
}

export class GetOne implements Action {
  readonly type = PatientActionTypes.GetOne;

  constructor(public payload: string) { }
}

export class GetOneSuccess implements Action {
  readonly type = PatientActionTypes.GetOneSuccess;

  constructor(public payload: Patient) { }
}

export class AddPatient implements Action {
  readonly type = PatientActionTypes.AddPatient;

  constructor(public payload: { item: Patient }) { }
}

export class AddPatientSuccess implements Action {
  readonly type = PatientActionTypes.AddPatientSuccess;

  constructor(public payload: { item: Patient }) { }
}

export class UpsertPatient implements Action {
  readonly type = PatientActionTypes.UpsertPatient;

  constructor(public payload: { item: Patient }) { }
}

export class AddPatients implements Action {
  readonly type = PatientActionTypes.AddPatients;

  constructor(public payload: { items: Patient[]; type: 'patients' }) { }
}

export class UpsertPatients implements Action {
  readonly type = PatientActionTypes.UpsertPatients;

  constructor(public payload: { items: Patient[] }) { }
}

export class UpdatePatient implements Action {
  readonly type = PatientActionTypes.UpdatePatient;

  constructor(public payload: { item: Patient; id: string, patchNotifyMeAt?: boolean }) { }
}

export class UpdatePatientSuccess implements Action {
  readonly type = PatientActionTypes.UpdatePatientSuccess;

  constructor(public payload: Update<Patient>) { }
}

export class AddMedication implements Action {
  readonly type = PatientActionTypes.AddMedication;

  constructor(public payload: { item: Medicine; id: string }) { }
}

export class AddMedicationSuccess implements Action {
  readonly type = PatientActionTypes.AddMedicationSuccess;

  constructor(public payload: Update<Patient>) { }
}

export class RemoveMedication implements Action {
  readonly type = PatientActionTypes.RemoveMedication;

  constructor(public payload: { id: string; medicineId: string }) { }
}

export class RemoveMedicationSuccess implements Action {
  readonly type = PatientActionTypes.RemoveMedicationSuccess;

  constructor(public payload: Update<Patient>) { }
}

export class AddAction implements Action {
  readonly type = PatientActionTypes.AddAction;

  constructor(public payload: { item: MedicineActionPoint | ActionPoint; colorZone: ColorZone; id: string }) { }
}

export class AddActionSuccess implements Action {
  readonly type = PatientActionTypes.AddActionSuccess;

  constructor(public payload: Update<Patient>) { }
}

export class UpdateAction implements Action {
  readonly type = PatientActionTypes.UpdateAction;

  constructor(public payload: { item: MedicineActionPoint | ActionPoint; colorZone: ColorZone; id: string; actionId: string }) { }
}

export class UpdateActionSuccess implements Action {
  readonly type = PatientActionTypes.UpdateActionSuccess;

  constructor(public payload: Update<Patient>) { }
}

export class RemoveAction implements Action {
  readonly type = PatientActionTypes.RemoveAction;

  constructor(public payload: { id: string; colorZone: ColorZone; actionId: string }) { }
}

export class RemoveActionSuccess implements Action {
  readonly type = PatientActionTypes.RemoveActionSuccess;

  constructor(public payload: Update<Patient>) { }
}

export class UpdateActionPlan implements Action {
  readonly type = PatientActionTypes.UpdateActionPlan;

  constructor(public payload: { patch: { evaluateAfter?: string; useEpipen?: boolean, conditions?: Conditions }; colorZone: ColorZone; id: string }) { }
}

export class UpdateActionPlanSuccess implements Action {
  readonly type = PatientActionTypes.UpdateActionPlanSuccess;

  constructor(public payload: Update<Patient>) { }
}

export class Invite implements Action {
  readonly type = PatientActionTypes.Invite;

  constructor(public payload: { id: string }) { }
}

export class InviteSuccess implements Action {
  readonly type = PatientActionTypes.InviteSuccess;

  constructor(public payload: Update<Patient>) { }
}

export class UpdateActionOrder implements Action {
  readonly type = PatientActionTypes.UpdateActionOrder;

  constructor(public payload: { order: string[]; colorZone: ColorZone; id: string }) { }
}

export class UpdateActionOrderSuccess implements Action {
  readonly type = PatientActionTypes.UpdateActionOrderSuccess;

  constructor(public payload: Update<Patient>) { }
}

export class UpdatePatients implements Action {
  readonly type = PatientActionTypes.UpdatePatients;

  constructor(public payload: { items: Update<Patient>[] }) { }
}

export class DeletePatient implements Action {
  readonly type = PatientActionTypes.DeletePatient;

  constructor(public payload: { id: string }) { }
}

export class DeletePatientSuccess implements Action {
  readonly type = PatientActionTypes.DeletePatientSuccess;

  constructor(public payload: { id: string }) { }
}

export class ArchivePatient implements Action {
  readonly type = PatientActionTypes.ArchivePatient;

  constructor(public payload: { id: string, reason: ArchiveReason }) { }
}

export class ArchivePatientSuccess implements Action {
  readonly type = PatientActionTypes.ArchivePatientSuccess;

  constructor(public payload: Update<Patient>) { }
}

export class UnarchivePatient implements Action {
  readonly type = PatientActionTypes.UnarchivePatient;

  constructor(public payload: { id: string }) { }
}

export class UnarchivePatientSuccess implements Action {
  readonly type = PatientActionTypes.UnarchivePatientSuccess;

  constructor(public payload: Update<Patient>) { }
}

export class DeletePatients implements Action {
  readonly type = PatientActionTypes.DeletePatients;

  constructor(public payload: { ids: string[] }) { }
}

export class ClearPatients implements Action {
  readonly type = PatientActionTypes.ClearPatients;
}

export class Select implements Action {
  readonly type = PatientActionTypes.Select;

  constructor(public payload: string) { }
}

export class SetChatResolved implements Action {
  readonly type = PatientActionTypes.SetChatResolved;

  constructor(public payload: { id: string }) { }
}

export class SetChatResolvedSuccess implements Action {
  readonly type = PatientActionTypes.SetChatResolvedSuccess;

  constructor(public payload: Update<Patient>) { }
}

export type PatientActions =
  | LoadPatients
  | LoadPatientsSuccess
  | LoadChats
  | LoadChatsSuccess
  | GetOne
  | GetOneSuccess
  | AddPatient
  | AddPatientSuccess
  | UpsertPatient
  | AddPatients
  | UpsertPatients
  | UpdatePatient
  | UpdatePatientSuccess
  | AddMedication
  | AddMedicationSuccess
  | RemoveMedication
  | RemoveMedicationSuccess
  | AddAction
  | AddActionSuccess
  | UpdateAction
  | UpdateActionSuccess
  | RemoveAction
  | RemoveActionSuccess
  | UpdateActionPlan
  | UpdateActionPlanSuccess
  | UpdateActionOrder
  | UpdateActionOrderSuccess
  | UpdatePatients
  | DeletePatient
  | DeletePatientSuccess
  | ArchivePatient
  | ArchivePatientSuccess
  | UnarchivePatient
  | UnarchivePatientSuccess
  | DeletePatients
  | ClearPatients
  | Select
  | SetChatResolved
  | SetChatResolvedSuccess
  | Invite
  | InviteSuccess;
