import {
  ChatReply,
  ReminderType,
  ReminderTypes,
} from "./../../store/team/team.model";
import { ChatReplyDialogComponent } from "./../../dialogs/chat-reply-dialog/chat-reply-dialog.component";
import {
  ContactActionPoint,
  ActionPoint,
  PatientActionPoint,
  ResponseActionPoint,
} from "./../../store/shared.model";
import { TeamDialogComponent } from "../../dialogs/admin-team-dialog/admin-team-dialog.component";

import { Component, OnInit, OnDestroy } from "@angular/core";

import * as fromRoot from "../../store";
import * as orgActions from "../../store/org/org.actions";
import * as careproviderAction from "../../store/careprovider/careprovider.actions";
import * as teamActions from "../../store/team/team.actions";
import { Store } from "@ngrx/store";

import { Team } from "../../store/team/team.model";
import {
  MatTableDataSource,
  MatDialogRef,
  MatDialog,
  Sort,
} from "@angular/material";
import { Router, ActivatedRoute } from "@angular/router";
import {
  formatBirthDate,
  getColor,
  sortData,
} from "../../util/helper-functions";
import { Observable, Subscription } from "rxjs";
import { filter, map, take } from "rxjs/operators";
import { CareProvider } from "src/app/store/careprovider/careprovider.model";
import { CareProviderDialogComponent } from "src/app/dialogs/admin-careprovider-dialog/admin-careprovider-dialog.component";
import { Title } from "@angular/platform-browser";
import { Org, ColorZone } from "src/app/store/org/org.model";
import { MedicineActionPoint } from "src/app/store/shared.model";
import { ActionDialogComponent } from "src/app/dialogs/action-dialog/action-dialog.component";
import { UpdateBaseActionOrder } from "src/app/store/settings/settings.actions";
import { AdminReminderDialogComponent } from "src/app/dialogs/admin-reminder-dialog/admin-reminder-dialog.component";

@Component({
  selector: "app-admin-team-detail",
  templateUrl: "./admin-team-detail.component.html",
  styleUrls: ["./admin-team-detail.component.scss"],
})
export class TeamDetailViewComponent implements OnInit, OnDestroy {
  careProviders$: Observable<CareProvider[]>;
  team$: Observable<Team>;
  teams$: Observable<Team[]>;
  orgs$: Observable<Org[]>;
  careProviders: CareProvider[];
  displayedColumns = ["combinedName", "email"];
  dataSource = new MatTableDataSource<any>([]);
  careProviderSub: Subscription;
  teamDialog: MatDialogRef<TeamDialogComponent>;
  careProviderDialog: MatDialogRef<CareProviderDialogComponent>;
  actionDialog: MatDialogRef<ActionDialogComponent>;
  sort: Sort;
  selectedIndex = 0;
  ReminderTypes = ReminderTypes;
  getColor = getColor;
  formatBirthDate: (date: string) => string;

  constructor(
    private store: Store<fromRoot.State>,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.store.dispatch(new careproviderAction.LoadCareProviders());
    this.store.dispatch(new orgActions.LoadOrgs());
    this.store.dispatch(new teamActions.LoadTeams());
    this.store.dispatch(
      new teamActions.Select(this.route.snapshot.params.teamId)
    );

    this.formatBirthDate = formatBirthDate;
    this.teams$ = this.store.select(fromRoot.getTeams).pipe(take(1));
    this.orgs$ = this.store.select(fromRoot.getOrgs).pipe(take(1));
    this.team$ = this.store.select(fromRoot.getSelectedTeam);
    this.team$
      .pipe(
        filter((t) => t !== undefined),
        take(1)
      )
      .subscribe((t) => {
        console.log("admin team", t);
        this.titleService.setTitle("LungBeat Admin - Team " + t.name);
      });
    this.careProviders$ = this.store.select(fromRoot.getCareProviders).pipe(
      filter((careProviders) => careProviders !== undefined),
      map((careProviders) => {
        return careProviders
          .filter((careprovider) =>
            (careprovider.teams as string[]).includes(
              this.route.snapshot.params.teamId
            )
          )
          .sort((careProviderA, careProviderB) => {
            // Sort by alphabetically
            const nameA = careProviderA.lastName.toLowerCase();
            const nameB = careProviderB.lastName.toLowerCase();
            return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
          });
      })
    );

    this.careProviderSub = this.careProviders$
      .pipe(filter((careProviders) => careProviders !== undefined))
      .subscribe((careProviders) => {
        this.careProviders = careProviders;
        this.sortData(this.sort);
      });
  }

  ngOnDestroy() {
    if (this.careProviderSub) {
      this.careProviderSub.unsubscribe();
    }
  }

  sortData(sort: Sort) {
    this.dataSource = sortData(sort, this.dataSource, this.careProviders);
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  editTeam(team) {
    this.teamDialog = this.dialog.open(TeamDialogComponent, {
      data: { team, orgId: this.route.snapshot.params.orgId },
    });
  }

  async editCareProvider(careProvider) {
    const teams = await this.teams$.toPromise();
    const orgs = await this.orgs$.toPromise();
    this.careProviderDialog = this.dialog.open(CareProviderDialogComponent, {
      data: {
        careProvider,
        teamId: this.route.snapshot.params.teamId,
        orgId: this.route.snapshot.params.orgId,
        teams,
        orgs,
      },
    });
  }

  createCareProvider() {
    this.careProviderDialog = this.dialog.open(CareProviderDialogComponent, {
      data: {
        teamId: this.route.snapshot.params.teamId,
        orgId: this.route.snapshot.params.orgId,
      },
    });
  }

  toggleStandardReaction(team: Team, reply?: ChatReply) {
    const chatReplyDialog = this.dialog.open(ChatReplyDialogComponent, {
      data: {
        orgId: this.route.snapshot.params.orgId,
        team,
        reply: reply ? reply : undefined,
      },
    });
  }

  // returns true if lastACS.createdAt > 8 days
  skippedACS(createdAt: Date): boolean {
    return Date.now() - createdAt.getTime() > 8 * 24 * 60 * 60 * 1000;
  }

  back() {
    this.router.navigate([
      "admin/organisaties/" + this.route.snapshot.params.orgId,
    ]);
  }

  createAction({
    colorZone,
    action,
  }: {
    colorZone: ColorZone;
    action:
      | PatientActionPoint
      | ContactActionPoint
      | ActionPoint
      | ResponseActionPoint;
  }) {
    console.log("createAction", action);
    this.team$.pipe(take(1)).subscribe((team) => {
      this.actionDialog = this.dialog.open(ActionDialogComponent, {
        data: {
          colorZone,
          team,
          action,
        },
        panelClass: colorZone,
      });
    });
  }

  changeActionPlanOrder({
    colorZone,
    actions,
  }: {
    colorZone: ColorZone;
    actions: MedicineActionPoint[];
  }) {
    const actionIds = actions.map((action) => action._id);
    console.log(actions);
    this.team$.pipe(take(1)).subscribe((team) => {
      this.store.dispatch(
        new UpdateBaseActionOrder({
          order: actionIds,
          colorZone,
          teamId: team._id,
          id: team._id,
        })
      );
    });
  }

  editEvaluateAfter() {}

  selectedIndexChange(val: number) {
    this.selectedIndex = val;
  }

  editReminder(team: Team, reminderType: ReminderType) {
    const dialog = this.dialog.open(AdminReminderDialogComponent, {
      data: {
        team,
        reminderType,
      },
    });
  }
}
