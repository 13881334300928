import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { Tokens } from '../../store/auth/auth.model';
import { delay } from 'rxjs/operators';

import { StoreError } from '../../store/error/error.model';
import { Observable, Subscription, of } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordPageComponent implements OnInit, OnDestroy {
  changePasswordForm: FormGroup;
  message: string;
  loading = false;
  tokens$: Observable<Tokens>;
  error$: Observable<StoreError>;
  tokensSub: Subscription;
  errorSub: Subscription;
  formSub: Subscription;
  returnUrl: string;
  email: string;
  token: string;
  success: boolean;
  public loggedIn$: Observable<boolean>;

  constructor(
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public api: ApiService,
    private router: Router,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.email = this.route.snapshot.params.email;
    this.token = this.route.snapshot.params.token;
    this.changePasswordForm = this.formBuilder.group({
      password: ['']
    });
    this.formSub = this.changePasswordForm.valueChanges.subscribe(_ =>
      this.success = false
    );
  }

  ngOnDestroy() {
    if (this.formSub) {
      this.formSub.unsubscribe();
    }
  }

  get password() {
    return this.changePasswordForm.get('password');
  }

  async changePassword() {
    const password = this.changePasswordForm.value.password;
    if (this.loading) {
      return;
    }
    this.loading = true;
    try {
      await this.api.changePassword(this.token, password).toPromise();
      this.success = true;
      await of().pipe(delay(1700)).toPromise();
      this.router.navigate(['/login']);
    } catch (e) {
      this.success = false;
      console.log(e);
    }
    this.loading = false;

  }
}
