import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Store } from '@ngrx/store';
import { filter, map, take } from 'rxjs/operators';
import { StoreError } from 'src/app/store/error/error.model';
import { Reminder, ReminderType, ReminderTypes, Team } from 'src/app/store/team/team.model';
import * as fromRoot from "../../store";
import * as errorActions from "../../store/error/error.actions";
import * as teamActions from "../../store/team/team.actions";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-admin-reminder-dialog',
  templateUrl: './admin-reminder-dialog.component.html',
  styleUrls: ['./admin-reminder-dialog.component.scss']
})
export class AdminReminderDialogComponent implements OnInit, OnDestroy {
  ReminderTypes = ReminderTypes;
  reminderForm: FormGroup;
  reminder: Reminder;
  isPosting = false;
  errorMessage: string;

  constructor(
	private translateService: TranslateService,
    private store: Store<fromRoot.State>,
    public dialogRef: MatDialogRef<AdminReminderDialogComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
      public data: {
        team: Team,
        reminderType: ReminderType
      },
    ) { }

  ngOnInit() {
    this.store.dispatch(new errorActions.ClearErrors());

    console.log('team', this.data.team);

    if (this.data.team.reminders) {
      const reminders = this.data.team.reminders;
      const reminderType = this.data.reminderType;
      this.reminder = reminders[reminderType] ? reminders[reminderType] : null;
    }

    this.reminderForm = this.formBuilder.group({
      interval: [this.reminder ? this.reminder.interval : 0, [Validators.required]],
      autoSend: [this.reminder ? this.reminder.autoSend : false, [Validators.required]],
      active: [this.reminder ? this.reminder.active : false, [Validators.required]],
      ...(this.data.reminderType === ReminderTypes.QUESTIONNAIRE_SKIPPED_REMINDER && {message: [this.reminder ? this.reminder.message : '']})
    });

    this.store
      .select(fromRoot.getErrors)
      .pipe(filter((errors) => errors && errors.length > 0))
      .pipe(map((errors) => errors[0]))
      .pipe(filter((error) => error.apiError !== undefined))
      .subscribe((error: StoreError) => {
        this.isPosting = false;
        switch (error.apiError.code) {
          default:
            this.errorMessage = "Er is iets fout gegaan";
            break;
        }
      });
  }

  ngOnDestroy(): void {
    this.store.dispatch(new errorActions.ClearErrors());
  }

  get isInvitationReminder() {
    return this.data.reminderType === ReminderTypes.INVITATION_REMINDER;
  }

  get questionnaireSkippedReminder() {
    return this.data.reminderType === ReminderTypes.QUESTIONNAIRE_SKIPPED_REMINDER;
  }

  get title(): string {
    if (!this.data.reminderType) return;

    switch (this.data.reminderType) {
      case ReminderTypes.INVITATION_REMINDER:
        return this.translateService.instant('adminReminderDialog.invitationReminder') // 'Uitnodigingsherinnering';
      case ReminderTypes.QUESTIONNAIRE_SKIPPED_REMINDER:
        return this.translateService.instant('adminReminderDialog.chatReminder') //'Chatherinnering voor verwijdering';
      case ReminderTypes.ACCOUNT_REMOVAL_REMINDER:
        return this.translateService.instant('adminReminderDialog.accountRemovalReminder') //'Verwijderingsmelding';
      default:
        return this.translateService.instant('adminReminderDialog.defaultReminder') //'Reminder aanpassen';
    }
  }

  isPosting$() {
    return this.store
      .select(fromRoot.isPostingTeam)
      .pipe(filter((isPosting) => isPosting === false))
      .pipe(take(1))
      .toPromise();
  }

  patch() {
    if (this.isPosting) {
      return;
    }

    this.isPosting = true;
    this.errorMessage = undefined;

    const team = this.parseData();

    this.store.dispatch(
      new teamActions.UpdateTeam({
        orgId: team.organization,
        teamId: team._id,
        team: team,
      })
    );

    this.isPosting$().then(() => {
      this.isPosting = false;
      this.dialogRef.close();
    });
  }

  parseData() {
    const team = this.data.team;
    const reminderType = this.data.reminderType;
    const reminder = team.reminders ? team.reminders[reminderType] : null;
    const formValues = this.reminderForm.value;

    if (reminder) {
      team.reminders[reminderType] = {
        ...reminder,
        interval: formValues.interval,
        autoSend: formValues.autoSend,
        active: formValues.active,
        ...(this.questionnaireSkippedReminder && {message: formValues.message})
      }
    } else {
      team['reminders'] = {
        ...(team.reminders && {...team.reminders}),
        [reminderType]: formValues
      }
    }

    return team;
  }

  cancel() {
    this.dialogRef.close();
  }
}
