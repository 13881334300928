import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, concatMap, mergeMap } from 'rxjs/operators';
import { PatientActionActionTypes, LoadPatientActions, LoadPatientActionsSuccess, Resolve, ResolveSuccess } from './patientAction.actions';
import { ApiService } from '../../services/api.service';

import * as errorActions from '../error/error.actions';
import * as fromRoot from '../';
import { Store } from '@ngrx/store';

import { v4 as uuid } from 'uuid';
import { empty } from 'rxjs';
import { PatientAction } from './patientAction.model';
import { LoadPatients, UpdatePatient } from '../patient/patient.actions';
import { Patient } from '../patient/patient.model';

@Injectable()
export class PatientActionEffects {
  @Effect()
  load$ = this.actions$.pipe(
    ofType(PatientActionActionTypes.LoadPatientActions),
    mergeMap((action: LoadPatientActions) => {
      return this.api.getAllPatientActions(action.payload.patientId, {limit: '200'}).pipe(
        map((verifyResponse: PatientAction[]) => {
          return new LoadPatientActionsSuccess({ items: verifyResponse });
        }),
        catchError(err => {
          this.store.dispatch(
            new errorActions.AddError({
              error: {
                id: uuid(),
                created: new Date(),
                content: err,
                source: action
              }
            })
          );
          return empty();
        })
      );
    })
  );

  @Effect()
  resolve$ = this.actions$.pipe(
    ofType(PatientActionActionTypes.Resolve),
    mergeMap((action: Resolve) => {
      return this.api.resolvePatientAction(action.payload.patientId, action.payload.actionId).pipe(
        map((verifyResponse: [PatientAction, Patient]) => {
          return new ResolveSuccess([{ id: verifyResponse[0]._id, changes: verifyResponse[0] }, verifyResponse[1], action.payload.thresholdACS]);
        }),
        catchError(err => {
          this.store.dispatch(
            new errorActions.AddError({
              error: {
                id: uuid(),
                created: new Date(),
                content: err,
                source: action
              }
            })
          );
          return empty();
        })
      );
    })
  );


  @Effect()
  resolveSuccess$ = this.actions$.pipe(
    ofType(PatientActionActionTypes.ResolveSuccess),
    map((action: ResolveSuccess) => {
      if (action.payload[2]) {
        return new UpdatePatient({ id: action.payload[1]._id, item: {...action.payload[1], thresholdACS: action.payload[2] } });
      } else {
        return new UpdatePatient({ id: action.payload[1]._id, item: action.payload[1] });
      }
    })
  );

  constructor(private actions$: Actions, private api: ApiService, private store: Store<fromRoot.State>) { }
}
