import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  AuthActionTypes,
  TokensAction,
  ClientAction,
  CareProviderAction,
  CareProviderGetAction,
  LoginAction,
  LoginSuccessAction,
  RefreshTokenAction,
  LogoutSuccessAction,
  PatchPassword,
  PatchPasswordSuccess,
  VerificationTokenAction,
  VerifyAction,
  VerifySuccessAction,
} from './auth.actions';

import { ApiService } from '../../services/api.service';
import * as errorActions from '../error/error.actions';
import * as fromRoot from '..';

import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { empty, of, EMPTY } from 'rxjs';
import { AccessToken, Tokens, VerificationToken } from './auth.model';

import { JwtHelperService } from '@auth0/angular-jwt';
import { v4 as uuid } from 'uuid';
import { Team } from '../team/team.model';
import { CareProvider } from '../careprovider/careprovider.model';

@Injectable()
export class AuthEffects {
  @Effect()
  login$ = this.actions$.pipe(
    ofType(AuthActionTypes.Login),
    switchMap((action: LoginAction) => {
      return this.api
        .authorize(action.payload.email, action.payload.password)
        .pipe(
          mergeMap((tokens: VerificationToken) => {
            return [new VerificationTokenAction(tokens)];
          }),
          catchError(err => {
            this.store.dispatch(
              new errorActions.AddError({
                error: {
                  id: uuid(),
                  created: new Date(),
                  content: err,
                  source: action
                }
              })
            );
            return EMPTY;
          })
        );
    })
  );

  @Effect()
  verify$ = this.actions$.pipe(
    ofType(AuthActionTypes.Verify),
    switchMap((action: VerifyAction) => {
      return this.api.verify(action.payload).pipe(
        mergeMap((tokens: Tokens) => {
          return [
              new TokensAction(tokens),
              new LoginSuccessAction(tokens),
            ];
        }),
        catchError(err => {
          this.store.dispatch(
            new errorActions.AddError({
              error: {
                id: uuid(),
                created: new Date(),
                content: err,
                source: action
              }
            })
          );
          return empty();
        })
      );
    })
  );


  @Effect()
  patchPassword$ = this.actions$.pipe(
    ofType(AuthActionTypes.PatchPassword),
    switchMap((action: PatchPassword) => {
      const password = action.payload.password || undefined;
      return this.api.patchPassword(action.payload.careProviderId, action.payload.newPassword, password).pipe(
        map(_ => {
          return new PatchPasswordSuccess();
        }),
        catchError(err => {
          this.store.dispatch(
            new errorActions.AddError({
              error: {
                id: uuid(),
                created: new Date(),
                content: err,
                source: action
              }
            })
          );
          return EMPTY;
        })
      );
    })
  );

  @Effect()
  tokens$ = this.actions$.pipe(
    ofType(AuthActionTypes.Tokens),
    map((action: TokensAction) => {
      // Store tokens in storage
      localStorage.setItem('tokens', JSON.stringify(action.payload));
      return action.payload;
    }),
    switchMap(tokens =>
      of(this.jwtHelper.decodeToken(tokens.accesstoken) as AccessToken)
    ),
    switchMap(({ client, careProvider }) => {
      return [
        new CareProviderGetAction(careProvider._id),
        new ClientAction({ _id: client._id }),
        new CareProviderAction(careProvider),
      ];
    })
  );

  @Effect()
  getCareProvider$ = this.actions$.pipe(
    ofType(AuthActionTypes.CareProviderGet),
    switchMap((action: CareProviderGetAction) => {
      return this.api.getCareProvider(action.payload).pipe(
        map((careProvider: CareProvider) => {
          return new CareProviderAction(careProvider);
        }),
        catchError(err => {
          this.store.dispatch(
            new errorActions.AddError({
              error: {
                id: uuid(),
                created: new Date(),
                content: err,
                source: action
              }
            })
          );
          return EMPTY;
        })
      );
    })
  );

  @Effect()
  logout$ = this.actions$.pipe(
    ofType(AuthActionTypes.Logout),
    switchMap(() => {
      // Remove tokens in storage
      const tokens = JSON.parse(localStorage.getItem('tokens'));
      const decodedToken = this.jwtHelper.decodeToken(
        tokens.accesstoken
      ) as AccessToken;

      localStorage.removeItem('tokens');
      localStorage.removeItem('selectedTeam');

      return this.api
        .logout(
          decodedToken.careProvider
            ? decodedToken.careProvider._id
            : decodedToken.admin._id,
          decodedToken.client._id
        )
        .pipe(
          map(() => {
            return new LogoutSuccessAction();
          }),
          catchError(() => {
            return [new LogoutSuccessAction()];
          })
        );
    })
  );

  @Effect()
  refreshtoken$ = this.actions$.pipe(
    ofType(AuthActionTypes.RefreshToken),
    switchMap((action: RefreshTokenAction) => {
      return this.api.refreshtoken({ refreshtoken: action.payload }).pipe(
        map(verifyResponse => {
          return new TokensAction(verifyResponse);
        }),
        catchError(err => {
          return [
            new errorActions.AddError({
              error: {
                id: uuid(),
                created: new Date(),
                content: err,
                source: action
              }
            })
          ];
        })
      );
    })
  );

  constructor(
    private actions$: Actions,
    private api: ApiService,
    private jwtHelper: JwtHelperService,
    private store: Store<fromRoot.State>
  ) { }
}
