import { Action } from '@ngrx/store';

import { Client, Tokens, RefreshToken, VerificationToken } from './auth.model';
import { MonitoringSettings, MeasurementConfig } from '..';
import { Team } from '../team/team.model';
import { CareProvider } from '../careprovider/careprovider.model';
import { Update } from '@ngrx/entity';

// tslint:disable:max-classes-per-file

export enum AuthActionTypes {
  Signup = '[Auth] Signup',
  SignupSuccessAction = '[Auth] Signup Success',
  Activate = '[Auth] Activate',
  ActivateSuccess = '[Auth] Activate Success',
  Login = '[Auth] Login',
  LoginSuccess = '[Auth] Login Success',
  Logout = '[Auth] Logout',
  LogoutSuccess = '[Auth] Logout Success',
  Verify = '[Auth] Verify',
  VerifySuccess = '[Auth] Verify Success',
  ChangePassword = '[Auth] Change Password',
  ChangePasswordSuccess = '[Auth] Change Password Succes',
  PatchPassword = '[Auth] Patch Password',
  PatchPasswordSuccess = '[Auth] Patch Password Succes',
  Client = '[Auth] Client',
  VerificationToken = '[Auth] Verfication Token',
  Tokens = '[Auth] Tokens',
  RefreshToken = '[Auth] Refresh Token',
  CareProvider = '[Auth] CareProvider',
  CareProviderGet = '[Auth] Get CareProvider',
  ReloadTokenData = '[Auth] Reload Token Data',
  DeleteAccountAction = '[Auth] Delete Account',
  ClearState = '[Auth] Clear State',
}

export class SignupAction implements Action {
  readonly type = AuthActionTypes.Signup;

  constructor(public payload: CareProvider) { }
}

export class SignupSuccessAction implements Action {
  readonly type = AuthActionTypes.SignupSuccessAction;

  constructor(public payload: any) { }
}

export class ActivateAction implements Action {
  readonly type = AuthActionTypes.Activate;

  constructor(public payload: string) { }
}

export class ActivateSuccessAction implements Action {
  readonly type = AuthActionTypes.ActivateSuccess;

  constructor(public payload: any) { }
}

export class LoginAction implements Action {
  readonly type = AuthActionTypes.Login;

  constructor(public payload: { email: string; password: string }) { }
}

export class LoginSuccessAction implements Action {
  readonly type = AuthActionTypes.LoginSuccess;

  constructor(public payload: Tokens) { }
}

export class VerifyAction implements Action {
  readonly type = AuthActionTypes.Verify;

  constructor(public payload: VerificationToken) { }
}

export class VerifySuccessAction implements Action {
  readonly type = AuthActionTypes.VerifySuccess;
  constructor() { }
}

export class VerificationTokenAction implements Action {
  readonly type = AuthActionTypes.VerificationToken;

  constructor(public payload: VerificationToken) { }
}

export class LogoutAction implements Action {
  readonly type = AuthActionTypes.Logout;

  constructor() { }
}

export class LogoutSuccessAction implements Action {
  readonly type = AuthActionTypes.LogoutSuccess;

  constructor() { }
}

export class ChangePassword implements Action {
  readonly type = AuthActionTypes.ChangePassword;

  constructor(public payload: { token: string, password: string }) { }
}

export class ChangePasswordSuccess implements Action {
  readonly type = AuthActionTypes.ChangePasswordSuccess;

  constructor() { }
}

export class PatchPassword implements Action {
  readonly type = AuthActionTypes.PatchPassword;

  constructor(public payload: { careProviderId: string, newPassword: string, password?: string }) { }
}

export class PatchPasswordSuccess implements Action {
  readonly type = AuthActionTypes.PatchPasswordSuccess;
}

export class ClientAction implements Action {
  readonly type = AuthActionTypes.Client;

  constructor(public payload: Client) { }
}

export class TokensAction implements Action {
  readonly type = AuthActionTypes.Tokens;

  constructor(public payload: Tokens) { }
}

export class RefreshTokenAction implements Action {
  readonly type = AuthActionTypes.RefreshToken;

  constructor(public payload: RefreshToken) { }
}

export class CareProviderAction implements Action {
  readonly type = AuthActionTypes.CareProvider;

  constructor(public payload: CareProvider) { }
}

export class DeleteAccountAction implements Action {
  readonly type = AuthActionTypes.DeleteAccountAction;

  constructor(public payload: { user: string; password: string }) { }
}

export class CareProviderGetAction implements Action {
  readonly type = AuthActionTypes.CareProviderGet;

  constructor(public payload: string) { }
}

export class ReloadTokenData implements Action {
  readonly type = AuthActionTypes.ReloadTokenData;

  constructor(public payload: Partial<CareProvider>) { }
}

export class ClearStateAction implements Action {
  readonly type = AuthActionTypes.ClearState;

  constructor(public payload: undefined) { }
}

export type AuthActions =
  | SignupAction
  | SignupSuccessAction
  | ActivateAction
  | ActivateSuccessAction
  | LoginAction
  | LoginSuccessAction
  | LogoutAction
  | LogoutSuccessAction
  | VerifyAction
  | VerifySuccessAction
  | VerificationTokenAction
  | PatchPassword
  | PatchPasswordSuccess
  | TokensAction
  | RefreshTokenAction
  | ClientAction
  | CareProviderAction
  | CareProviderGetAction
  | ReloadTokenData
  | ClearStateAction
  | DeleteAccountAction;
