import {
	ContactActionPoint,
	ResponseActionPoint,
	PatientActionPoint,
	BLOCK_TYPES,
	RESPONSE_TIMES,
} from "./../../store/shared.model";
import {
	MEDICINE_FREQUENCIES,
	MedicineFrequency,
	BIOLOGICAL_FREQUENCIES,
} from "./../../store/medicine/medicine.model";
import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material";
import {
	FormBuilder,
	FormGroup,
	FormControl,
	FormArray,
	Validators,
} from "@angular/forms";
import { Store } from "@ngrx/store";
import { Medicine } from "../../store/medicine/medicine.model";
import { ColorZone, Patient } from "../../store/patient/patient.model";
import { StoreError } from "../../store/error/error.model";
import { filter, skip, take, map } from "rxjs/operators";
import * as fromRoot from "../../store";
import * as errorActions from "../../store/error/error.actions";
import * as patientActions from "../../store/patient/patient.actions";
import {
	MedicineActionPoint,
	ActionPoint,
	ActionPointType,
	ActionPointTypes,
} from "src/app/store/shared.model";
import { Team } from "src/app/store/team/team.model";
import {
	UpdateBaseAction,
	AddBaseAction,
	RemoveBaseAction,
} from "src/app/store/settings/settings.actions";
import { clean } from "src/app/util/helper-functions";
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "app-action-dialog",
	templateUrl: "./action-dialog.component.html",
	styleUrls: ["./action-dialog.component.scss"],
})
export class ActionDialogComponent implements OnInit, OnDestroy {
	medicineActionForm: FormGroup;
	generalActionForm: FormGroup;
	contactActionForm: FormGroup;
	responseActionForm: FormGroup;
	patientActionForm: FormGroup;
	contactInfoForm: FormArray;
	phoneForm: FormGroup;
	ActionPointTypes = ActionPointTypes;
	type: ActionPointType = ActionPointTypes.MEDICATION;
	actionControl: FormControl;
	isPosting: boolean;
	errorMessage: string;
	selectedMedicine: Medicine;
	dosageType: string;
	contactInfoTemplate = [
		{
			title: "",
			info: "",
			number: "",
		},
	];
	selectedBlockType = ActionPointTypes.GENERAL;
	blockTypesList = BLOCK_TYPES;
	responseTimes: { key: string, value: string }[];
	// responseTimes = RESPONSE_TIMES;
	frequencies: MedicineFrequency[] = MEDICINE_FREQUENCIES;
	fequenciesBiological: MedicineFrequency[] = BIOLOGICAL_FREQUENCIES;

	constructor(
		@Inject(MAT_DIALOG_DATA)
		public data: {
			action?:
			| PatientActionPoint
			| ContactActionPoint
			| ResponseActionPoint
			| MedicineActionPoint
			| ActionPoint;
			patient?: Patient;
			team?: Team;
			colorZone: ColorZone;
			type?: ActionPointType;
			fromMedicineDialog?: boolean;
			addedMedicine?: Medicine;
		},
		public dialog: MatDialog,
		public dialogRef: MatDialogRef<ActionDialogComponent>,
		private store: Store<fromRoot.State>,
		private formBuilder: FormBuilder,
		private translateService: TranslateService
	) {

		let translations = this.translateService.instant('responseTimes');
		let map = {
			"4Hours": "4 uur",
			"6Hours": "6 uur",
			"12Hours": "12 uur",
			"24Hours": "24 uur",
			"2Days": "2 dagen",
			"3Days": "3 dagen",
			"7Days": "7 dagen",
			"10Days": "10 dagen",
			"14Days": "14 dagen"
		}
		this.responseTimes = Object.keys(translations).map(el => {
			return {
				key: map[el],
				value: map[el]
			}
		});


		this.blockTypesList = [
			{ type: ActionPointTypes.GENERAL, label: this.translateService.instant('actionDialog.blockTypes.general') },
			{ type: ActionPointTypes.CONTACT, label: this.translateService.instant('actionDialog.blockTypes.contact') },
			{ type: ActionPointTypes.PATIENT, label: this.translateService.instant('actionDialog.blockTypes.patient') },
			{ type: ActionPointTypes.RESPONSE, label: this.translateService.instant('actionDialog.blockTypes.response') },
		];

		this.frequencies = [
			{ title: this.translateService.instant('actionDialog.frequencies.ifNeeded'), value: -1 },
			{ title: this.translateService.instant('actionDialog.frequencies.1Day'), value: 1 },
			{ title: this.translateService.instant('actionDialog.frequencies.2Day'), value: 2 },
			{ title: this.translateService.instant('actionDialog.frequencies.3Day'), value: 3 },
			{ title: this.translateService.instant('actionDialog.frequencies.4Day'), value: 4 },
			{ title: this.translateService.instant('actionDialog.frequencies.5Day'), value: 5 },
			{ title: this.translateService.instant('actionDialog.frequencies.3Week'), value: 3 / 7 },
		];

		this.fequenciesBiological = [
			{ title: this.translateService.instant('actionDialog.bioFrequencies.2Week'), value: 1 / 14 },
			{ title: this.translateService.instant('actionDialog.bioFrequencies.4Week'), value: 1 / 28 },
			{ title: this.translateService.instant('actionDialog.bioFrequencies.8Week'), value: 1 / 56 },
		]
	}

	ngOnDestroy() {
		this.store.dispatch(new errorActions.ClearErrors());
	}

	ngOnInit() {
		if (!this.data.team && this.data.type) {
			this.type = this.data.type;
		}

		if (this.data.team) {
			this.type = this.data.action
				? this.data.action.actionType
				: this.selectedBlockType;
		}

		this.isPosting = false;
		this.store.dispatch(new errorActions.ClearErrors());
		const action = this.data.action;

		let medicineAction =
			(action && action.actionType === undefined) ||
				(action && action.actionType === ActionPointTypes.MEDICATION)
				? (action as MedicineActionPoint)
				: undefined;

		let generalAction =
			action && action.actionType === ActionPointTypes.GENERAL
				? (action as ActionPoint)
				: undefined;

		let contactAction =
			action && action.actionType === ActionPointTypes.CONTACT
				? (action as ContactActionPoint)
				: undefined;

		let responseAction =
			action && action.actionType === ActionPointTypes.RESPONSE
				? (action as ResponseActionPoint)
				: undefined;

		let patientAction =
			action && action.actionType === ActionPointTypes.PATIENT
				? (action as PatientActionPoint)
				: undefined;

		if (!medicineAction) {
			medicineAction = {
				medicine: "",
				frequency: null,
				dosage: null,
				customDosage: null,
				note: "",
			};
		}

		if (!generalAction) {
			generalAction = {
				title: this.data.team === undefined ? "Opmerkingen" : "",
				description: null,
				note: "",
			};
		}

		if (!contactAction) {
			contactAction = {
				title: "",
				contactInfo: this.contactInfoTemplate,
			};
		}

		if (!responseAction) {
			responseAction = {
				title: "",
				affixText: "",
				prefixText: "",
				expectedResponsePeriod: "",
			};
		}

		if (!patientAction) {
			patientAction = {
				showNotes: false,
				showMedication: false,
			};
		}

		if (this.data.fromMedicineDialog) {
			this.selectedMedicine = this.data.addedMedicine;
		}

		if (medicineAction.medicine && medicineAction.medicine.length > 0) {
			this.selectMedicine(medicineAction.medicine);
		}

		this.contactInfoForm = this.formBuilder.array(
			contactAction.contactInfo.map((c) => {
				return this.createContactForm(c);
			})
		);

		let medicineDosage;
		if (medicineAction.dosage && medicineAction.dosage % 1 !== 0) {
			medicineDosage = "custom";
		} else {
			medicineDosage = medicineAction.dosage;
		}

		this.medicineActionForm = this.formBuilder.group({
			medicine: [medicineAction.medicine],
			...(this.data.fromMedicineDialog && {
				medicine: [this.data.addedMedicine],
			}),
			frequency: [medicineAction.frequency],
			dosage: [medicineDosage],
			customDosage: [
				medicineAction.dosage,
				[
					Validators.pattern("[0-9]{1,3}([,.][0-9]{1,2})"),
					Validators.maxLength(5),
					Validators.minLength(3),
				],
			],
			note: [medicineAction.note],
		});

		this.generalActionForm = this.formBuilder.group({
			title: [generalAction.title],
			description: [generalAction.description],
		});

		this.contactActionForm = this.formBuilder.group({
			title: [contactAction.title],
			contactInfo: this.contactInfoForm,
		});

		this.responseActionForm = this.formBuilder.group({
			title: [responseAction.title],
			affixText: [responseAction.affixText],
			prefixText: [responseAction.prefixText],
			expectedResponsePeriod: [responseAction.expectedResponsePeriod],
		});

		this.patientActionForm = this.formBuilder.group({
			showNotes: [patientAction.showNotes],
			showMedication: [patientAction.showMedication],
		});

		this.store
			.select(fromRoot.getErrors)
			.pipe(filter((errors) => errors && errors.length > 0))
			.pipe(map((errors) => errors[0]))
			.pipe(filter((error) => error.apiError !== undefined))
			.subscribe((error: StoreError) => {
				this.isPosting = false;
				switch (error.apiError.code) {
					default:
						this.errorMessage = "Er is iets fout gegaan";
						break;
				}
			});
	}

	isPosting$() {
		return this.data.patient
			? this.store
				.select(fromRoot.isPostingPatient)
				.pipe(filter((isPosting) => isPosting === false))
				.pipe(take(1))
				.toPromise()
			: this.store
				.select(fromRoot.isPostingSettings)
				.pipe(filter((isPosting) => isPosting === false))
				.pipe(take(1))
				.toPromise();
	}

	get form() {
		switch (this.type) {
			case ActionPointTypes.MEDICATION:
				return this.medicineActionForm;
			case ActionPointTypes.GENERAL:
				return this.generalActionForm;
			case ActionPointTypes.CONTACT:
				return this.contactActionForm;
			case ActionPointTypes.RESPONSE:
				return this.responseActionForm;
			case ActionPointTypes.PATIENT:
				return this.patientActionForm;
			default:
				return this.patientActionForm;
		}
	}

	get customDosageDisplay() {
		return this.medicineActionForm.get("dosage").value === "custom";
	}

	get selectedDosageType() {
		return this.selectedMedicine ? this.selectedMedicine.dosageType : false;
	}

	get displayDosageInput() {
		return (
			this.selectedDosageType === "subcutaan" ||
			this.selectedDosageType === "intraveneus"
		);
	}

	selectMedicine(medicineId?: string, formUpdate = false) {
		if (this.type !== ActionPointTypes.MEDICATION) return undefined;

		if (formUpdate) {
			this.resetMedicineForm();
		}

		const selectedMedicineId =
			medicineId || this.medicineActionForm.get("medicine").value;
		this.selectedMedicine = this.data.patient.medication.find(
			(medicine) => medicine._id === selectedMedicineId
		);
	}

	selectBlockType(event) {
		this.type = event.value;
	}

	resetMedicineForm() {
		this.form.controls["frequency"].setValue(null);
		this.form.controls["dosage"].setValue(null);
		this.form.controls["note"].setValue(null);
	}

	createAction() {
		if (this.isPosting) {
			return;
		}
		this.isPosting = true;
		this.errorMessage = undefined;
		const action = this.format(this.form.value);

		if (this.data.patient) {
			action["actionType"] = this.type;
			this.store.dispatch(
				new patientActions.AddAction({
					id: this.data.patient._id,
					colorZone: this.data.colorZone,
					item: action,
				})
			);
		} else {
			action["actionType"] = this.type;
			action["baseAction"] = true;

			this.store.dispatch(
				new AddBaseAction({
					colorZone: this.data.colorZone,
					teamId: this.data.team._id,
					item: action,
				})
			);
		}

		this.isPosting$().then(() => {
			this.isPosting = false;
			this.dialogRef.close();
		});
	}

	patchAction(actionId) {
		if (this.isPosting || !actionId) {
			return;
		}
		this.isPosting = true;
		this.errorMessage = undefined;

		const action = this.format(this.form.value);

		if (this.data.patient) {
			action["actionType"] = action.actionType || this.type;
			this.store.dispatch(
				new patientActions.UpdateAction({
					id: this.data.patient._id,
					colorZone: this.data.colorZone,
					actionId,
					item: action,
				})
			);
		} else {
			action["actionType"] = this.type;
			this.store.dispatch(
				new UpdateBaseAction({
					colorZone: this.data.colorZone,
					teamId: this.data.team._id,
					actionId,
					item: action,
				})
			);
		}

		this.isPosting$().then(() => {
			this.isPosting = false;
			this.dialogRef.close();
		});
	}

	removeAction(actionId) {
		if (this.isPosting || !actionId) {
			return;
		}
		this.isPosting = true;
		this.errorMessage = undefined;

		if (this.data.patient) {
			this.store.dispatch(
				new patientActions.RemoveAction({
					id: this.data.patient._id,
					colorZone: this.data.colorZone,
					actionId,
				})
			);
		} else {
			this.store.dispatch(
				new RemoveBaseAction({
					colorZone: this.data.colorZone,
					teamId: this.data.team._id,
					actionId,
				})
			);
		}

		this.isPosting$().then(() => {
			this.isPosting = false;
			this.dialogRef.close();
		});
	}

	createContactForm(c) {
		return new FormGroup({
			title: new FormControl(c.title),
			info: new FormControl(c.info),
			number: new FormControl(c.number),
		});
	}

	deleteContactInfo(form: FormArray, i: number) {
		if (form.controls.length > 1) {
			(form as FormArray).removeAt(i);
		}
	}

	addContactInfo(form: FormArray) {
		form.push(this.createContactForm(this.contactInfoTemplate[0]));
	}

	pad(padData) {
		return ("0" + padData).slice(-2);
	}

	cancel() {
		this.dialogRef.close();
	}

	format(form) {
		if (form.dosage !== undefined) {
			if (form.dosage === "custom") {
				let customDosageValue = form.customDosage;
				if (customDosageValue.indexOf(",") > -1) {
					customDosageValue = customDosageValue.replace(/,/g, ".");
					console.log("has comma", customDosageValue);
				}
				console.log("customDosageValue", customDosageValue);
				form.dosage = customDosageValue;
			}
		}

		if (form.contactInfo !== undefined && form.contactInfo.length > 0) {
			form.contactInfo.map((contact) => {
				const formattedContact = clean(contact);
				return formattedContact;
			});
		}
		return form;
	}
}
