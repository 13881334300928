import { Action } from '@ngrx/store';
import { PatientAction } from './patientAction.model';
import { Update } from '@ngrx/entity';
import { Patient } from '../patient/patient.model';

export enum PatientActionActionTypes {
  LoadPatientActions = '[PatientAction] Load PatientActions',
  LoadPatientActionsSuccess = '[PatientAction] Load PatientActions Success',
  ClearPatientActions = '[PatientAction] Clear PatientActions',
  Select = '[PatientAction] Select PatientAction',
  Resolve = '[PatientAction] Resolve',
  ResolveSuccess = '[PatientAction] Resolve Succes',
}

export class LoadPatientActions implements Action {
  readonly type = PatientActionActionTypes.LoadPatientActions;
  constructor(public payload: { patientId: string }) { }
}

export class LoadPatientActionsSuccess implements Action {
  readonly type = PatientActionActionTypes.LoadPatientActionsSuccess;

  constructor(public payload: { items: PatientAction[] }) { }
}

export class ClearPatientActions implements Action {
  readonly type = PatientActionActionTypes.ClearPatientActions;
}

export class Select implements Action {
  readonly type = PatientActionActionTypes.Select;

  constructor(public payload: string) { }
}

export class Resolve implements Action {
  readonly type = PatientActionActionTypes.Resolve;

  constructor(public payload: { patientId: string, actionId: string, thresholdACS?: {value: number, active: boolean} }) { }
}

export class ResolveSuccess implements Action {
  readonly type = PatientActionActionTypes.ResolveSuccess;

  constructor(public payload: [Update<PatientAction>, Patient, {value: number, active: boolean} | undefined]) { }
}

export type PatientActionActions = LoadPatientActions | LoadPatientActionsSuccess | ClearPatientActions | Select | Resolve | ResolveSuccess;
