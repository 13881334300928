import { Component, OnInit, Inject, OnDestroy, Input } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
  MatCheckboxChange,
} from "@angular/material";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { CustomMedicineDialogComponent } from "../../dialogs/custom-medicine-dialog/custom-medicine-dialog.component";
import { Store } from "@ngrx/store";
import { Medicine } from "../../store/medicine/medicine.model";
import { Patient, ColorZone } from "../../store/patient/patient.model";
import { StoreError } from "../../store/error/error.model";
import { filter, map, startWith, take, skip, tap } from "rxjs/operators";
import * as fromRoot from "../../store";
import * as medicineActions from "../../store/medicine/medicine.actions";
import * as errorActions from "../../store/error/error.actions";
import * as patientActions from "../../store/patient/patient.actions";
import { Observable } from "rxjs";
import { ActionDialogComponent } from "../../dialogs/action-dialog/action-dialog.component";
import { ActionPointTypes, ActionPlan } from "src/app/store/shared.model";

@Component({
  selector: "app-medicine-dialog",
  templateUrl: "./medicine-dialog.component.html",
  styleUrls: ["./medicine-dialog.component.scss"],
})
export class MedicineDialogComponent implements OnInit, OnDestroy {
  dialogForm: FormGroup;
  medicineControl: FormControl;
  medicines$: Observable<Medicine[]>;
  medicines: Medicine[];
  isPosting: boolean;
  inputValue: string;
  errorMessage: string;
  selectedMedicine: Medicine;
  filteredMedicines$: Observable<Medicine[]>;
  customMedicationDialog: MatDialogRef<CustomMedicineDialogComponent>;
  greenSelected: boolean;
  yellowSelected: boolean;
  orangeSelected: boolean;
  redSelected: boolean;
  actionDialog: MatDialogRef<ActionDialogComponent>;
  selectedActionPlan$: Observable<ActionPlan>;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { medicine?: Medicine; patient: Patient },
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<MedicineDialogComponent>,
    private store: Store<fromRoot.State>,
    private formBuilder: FormBuilder
  ) {}

  ngOnDestroy() {
    this.store.dispatch(new errorActions.ClearErrors());
  }

  ngOnInit() {
    this.isPosting = false;
    this.medicines$ = this.store.select(fromRoot.getMedicines);
    this.selectedActionPlan$ = this.store
      .select(fromRoot.getSelectedPatient)
      .pipe(
        filter((p) => p !== undefined),
        map((patient) => patient.actionPlan)
      );

    this.store.dispatch(new errorActions.ClearErrors());
    this.store.dispatch(new medicineActions.LoadMedicines());
    let medicine = this.data.medicine
      ? (this.data.medicine as Medicine)
      : undefined;

    this.medicines$
      .pipe(filter((medicines) => medicines !== undefined))
      .pipe(take(1))
      .subscribe((medicines) => {
        this.medicines = medicines;

        if (medicine) {
          if (medicine.userCustom) {
            this.selectedMedicine = medicine;
          } else {
            this.selectedMedicine = this.medicines.find(
              (med) => med._id === medicine._id
            );
          }
        } else {
          medicine = {
            _id: "",
            name: "",
            brand: "",
            strength: "",
            dosageForm: "",
            packageForm: "",
            type: "",
          };
        }

        this.medicineControl = new FormControl(
          this.formBuilder.group({
            _id: medicine._id,
            name: medicine.name,
            brand: medicine.brand,
            strength: medicine.strength,
            dosageForm: medicine.dosageForm,
            packageForm: medicine.packageForm,
            type: medicine.type,
          }),
          Validators.required
        );

        this.dialogForm = this.formBuilder.group({
          medicine: this.medicineControl,
        });

        this.filteredMedicines$ = this.medicineControl.valueChanges.pipe(
          startWith(""),
          map((value) => this._filter(value))
        );

        this.store
          .select(fromRoot.getErrors)
          .pipe(filter((errors) => errors && errors.length > 0))
          .pipe(map((errors) => errors[0]))
          .pipe(filter((error) => error.apiError !== undefined))
          .subscribe((error: StoreError) => {
            this.isPosting = false;
            switch (error.apiError.code) {
              default:
                this.errorMessage = "Er is iets fout gegaan";
                break;
            }
          });
      });
  }

  addToMedicine(event: MatCheckboxChange, selected: ColorZone) {
    switch (selected) {
      case "green":
        this.greenSelected = event.checked;
        break;
      case "yellow":
        this.yellowSelected = event.checked;
        break;
      case "orange":
        this.orangeSelected = event.checked;
        break;
      case "red":
        this.redSelected = event.checked;
        break;
      default:
        this.greenSelected = false;
        this.yellowSelected = false;
        this.orangeSelected = false;
        this.redSelected = false;
    }
  }

  isPosting$() {
    return this.store
      .select(fromRoot.isPostingPatient)
      .pipe(filter((isPosting) => isPosting === false))
      .pipe(take(1))
      .toPromise();
  }

  addCustomMedicine() {
    this.dialogRef.close();
    this.customMedicationDialog = this.dialog.open(
      CustomMedicineDialogComponent,
      {
        data: this.data,
      }
    );
  }

  changeSelectedMedicine(event) {
    if (!event || !event.option || !event.option.value) {
      return;
    }
    this.dialogForm.reset();
    this.inputValue = "";
    this.selectedMedicine = event.option.value;
  }

  createMedication() {
    const rand = Math.random();

    if (this.isPosting) {
      return;
    }

    this.isPosting = true;
    this.errorMessage = undefined;
    this.store.dispatch(
      new patientActions.AddMedication({
        id: this.data.patient._id,
        item: this.selectedMedicine,
      })
    );
    this.isPosting$().then(async () => {
      this.isPosting = false;

      // add dialog for green actionplan
      if (this.greenSelected === true) {
        const greenDialog = this.openDialog("green");
        await greenDialog.afterClosed().toPromise();
      }

      // add dialog for yellow actionplan
      if (this.yellowSelected === true) {
        const yellowDialog = this.openDialog("yellow");
        await yellowDialog.afterClosed().toPromise();
      }

      // add dialog for orange actionplan
      if (this.orangeSelected === true) {
        const orangeDialog = this.openDialog("orange");
        await orangeDialog.afterClosed().toPromise();
      }

      if (this.redSelected === true) {
        const redDialog = this.openDialog("red");
        await redDialog.afterClosed().toPromise();
      }

      this.dialogRef.close();
    });
  }

  openDialog(color: "green" | "yellow" | "orange" | "red") {
    return this.dialog.open(ActionDialogComponent, {
      data: {
        colorZone: color,
        patient: this.data.patient,
        action: "ActionPoint",
        type: ActionPointTypes.MEDICATION,
        fromMedicineDialog: true,
        addedMedicine: this.selectedMedicine,
      },
      panelClass: color,
    });
  }

  removeMedication(medicineId) {
    if (this.isPosting) {
      return;
    }
    this.isPosting = true;
    this.errorMessage = undefined;

    this.store.dispatch(
      new patientActions.RemoveMedication({
        id: this.data.patient._id,
        medicineId,
      })
    );
    this.isPosting$().then(() => {
      this.dialogRef.close();
      // this.isPosting = false;
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  private _filter(value: string): Medicine[] {
    this.inputValue = value;
    if (!value || !value.toLowerCase) {
      return;
    }
    const filterValue = value.toLowerCase();
	console.log(this.medicines)
    return this.medicines.filter((option) => {
      const matchingString = (
        option.name +
        " " +
        option.brand +
        " " +
        option.dosageForm +
        " " +
        option.strength
      ).toLocaleLowerCase();
      return matchingString.indexOf(filterValue) !== -1;
    });
  }

  displayFn(medicine?: Medicine): string | undefined {
    return medicine && medicine.name
      ? medicine.name + " " + medicine.brand
      : undefined;
  }
}
