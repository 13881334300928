import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Message } from './message.model';
import { Medicine } from '../medicine/medicine.model';

export enum MessageActionTypes {
  LoadMessages = '[Message] Load Messages',
  LoadMessagesSuccess = '[Message] Load Messages Success',
  GetOne = '[Message] Get One',
  GetOneSuccess = '[Message] Get One Success',
  AddMessage = '[Message] Add Message',
  AddMessageSuccess = '[Message] Add Message Complete',
  UpsertMessage = '[Message] Upsert Message',
  AddMessages = '[Message] Add Messages',
  UpsertMessages = '[Message] Upsert Messages',
  UpdateMessage = '[Message] Update Message',
  UpdateMessageSuccess = '[Message] Update Message Success',
  UpdateMessages = '[Message] Update Messages',
  DeleteMessage = '[Message] Delete Message',
  DeleteMessageSuccess = '[Message] Delete Message Success',
  DeleteMessages = '[Message] Delete Messages',
  SaveCurrentMessage = '[Message] Save Current Message',
  ClearMessages = '[Message] Clear Messages',
  Select = '[Message] Select Message'
}

export class LoadMessages implements Action {
  readonly type = MessageActionTypes.LoadMessages;
  constructor(public payload: { id: string }) {}
}

export class LoadMessagesSuccess implements Action {
  readonly type = MessageActionTypes.LoadMessagesSuccess;

  constructor(public payload: { items: Message[] }) {}
}

export class GetOne implements Action {
  readonly type = MessageActionTypes.GetOne;

  constructor(public payload: string) {}
}

export class GetOneSuccess implements Action {
  readonly type = MessageActionTypes.GetOneSuccess;

  constructor(public payload: Message) {}
}

export class AddMessage implements Action {
  readonly type = MessageActionTypes.AddMessage;

  constructor(public payload: { id: string; item: Message }) {}
}

export class AddMessageSuccess implements Action {
  readonly type = MessageActionTypes.AddMessageSuccess;

  constructor(public payload: { item: Message }) {}
}

export class UpsertMessage implements Action {
  readonly type = MessageActionTypes.UpsertMessage;

  constructor(public payload: { item: Message }) {}
}

export class AddMessages implements Action {
  readonly type = MessageActionTypes.AddMessages;

  constructor(public payload: { items: Message[]; type: 'patients' }) {}
}

export class UpsertMessages implements Action {
  readonly type = MessageActionTypes.UpsertMessages;

  constructor(public payload: { items: Message[] }) {}
}

export class UpdateMessage implements Action {
  readonly type = MessageActionTypes.UpdateMessage;

  constructor(public payload: { item: Message; id: string }) {}
}

export class UpdateMessageSuccess implements Action {
  readonly type = MessageActionTypes.UpdateMessageSuccess;

  constructor(public payload: Update<Message>) {}
}

export class UpdateMessages implements Action {
  readonly type = MessageActionTypes.UpdateMessages;

  constructor(public payload: { items: Update<Message>[] }) {}
}

export class SaveCurrentMessage implements Action {
  readonly type = MessageActionTypes.SaveCurrentMessage;
  constructor(public payload: { chatId: string; message: string }) {}
}

export class DeleteMessage implements Action {
  readonly type = MessageActionTypes.DeleteMessage;

  constructor(public payload: { id: string }) {}
}

export class DeleteMessageSuccess implements Action {
  readonly type = MessageActionTypes.DeleteMessageSuccess;

  constructor(public payload: { id: string }) {}
}

export class DeleteMessages implements Action {
  readonly type = MessageActionTypes.DeleteMessages;

  constructor(public payload: { ids: string[] }) {}
}

export class ClearMessages implements Action {
  readonly type = MessageActionTypes.ClearMessages;
}

export class Select implements Action {
  readonly type = MessageActionTypes.Select;

  constructor(public payload: string) {}
}

export type MessageActions =
  | LoadMessages
  | LoadMessagesSuccess
  | GetOne
  | GetOneSuccess
  | AddMessage
  | AddMessageSuccess
  | UpsertMessage
  | AddMessages
  | UpsertMessages
  | UpdateMessage
  | UpdateMessageSuccess
  | UpdateMessages
  | SaveCurrentMessage
  | DeleteMessage
  | DeleteMessageSuccess
  | DeleteMessages
  | ClearMessages
  | Select;
