import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import * as fromRoot from '../../store';
import * as authActions from '../../store/auth/auth.actions';
import { Store, ActionsSubject } from '@ngrx/store';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { PasswordRepeatValidator } from 'src/app/util/helper-functions';
import { filter, take, skip, map, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { StoreError } from 'src/app/store/error/error.model';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnInit, OnDestroy {
  changePasswordForm: FormGroup;
  matcher = new PasswordRepeatValidator();
  isPosting$ = new BehaviorSubject<boolean>(false);
  error$: Observable<StoreError>;
  destroy$ = new Subject();

  constructor(
	private translateService: TranslateService,
    public dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    public dialog: MatDialog,
    private action$: ActionsSubject,
    @Inject(MAT_DIALOG_DATA) public data: { careProviderId: string, createPassword: boolean },
    private store: Store<fromRoot.State>, private formBuilder: FormBuilder) {
    this.changePasswordForm = this.formBuilder.group({
      ...(!this.data.createPassword && {
        password: ['', [Validators.required]],
      }),
      newPassword: ['', [Validators.required, Validators.minLength(6)]],
      newPasswordRepeat: ['', [Validators.required]]
    }, { validator: this.checkPasswords });
  }

  ngOnInit() {
    this.error$ = this.store.select(fromRoot.getErrors).pipe(
      skip(1),
      filter(errors => {
        return (
          errors !== undefined && errors[0] && errors[0].type === authActions.AuthActionTypes.PatchPassword
        );
      }),
      map(errors => errors[0])
    );


    this.action$.pipe(filter(action => action.type === authActions.AuthActionTypes.PatchPasswordSuccess), take(1), takeUntil(this.destroy$)).subscribe(_ => {
      this.isPosting$.next(false);
      this.dialogRef.close();
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  checkPasswords(group: FormGroup) {
    const pass = group.controls.newPassword.value;
    const confirmPass = group.controls.newPasswordRepeat.value;

    return pass === confirmPass ? {} : { notSame: true };
  }

  onSubmit(): void {
    const newPassword = (this.changePasswordForm.value).newPasswordRepeat;
    const careProviderId = this.data.careProviderId;
    const password = (this.changePasswordForm.value).password;

    this.isPosting$.next(true);
    this.store.dispatch(new authActions.PatchPassword({ careProviderId, newPassword, password }));

    if (!this.data.createPassword) {
      this.error$.pipe(take(1), takeUntil(this.destroy$)).subscribe(err => {
        console.log(err);
        this.dialog.open(ConfirmDialogComponent, {
          data: {
            messageOnly: true,
            title: this.translateService.instant('changePasswordDialog.error'),
            message:
              err.status === 401
                ? this.translateService.instant('changePasswordDialog.oldPasswordIsWrong') 
                : this.translateService.instant('changePasswordDialog.unknownError')
          }
        });
        this.changePasswordForm.controls['password'].setErrors({'invalid': true});
        this.isPosting$.next(false);
      });
    }

  }

  get password() {
    return this.changePasswordForm.get('password');
  }

  get newPassword() {
    return this.changePasswordForm.get('newPassword');
  }

}
