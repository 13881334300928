import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {
	constructor(private translateService: TranslateService) {}

  transform(value: any, args?: any): any {
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      if (seconds < 29) {
        // return 'Zojuist geplaatst';
		return this.translateService.instant('timeAgoPipe.justPlaced');
      }
      const intervals = {
        jaar: 31536000,
        maand: 2592000,
        week: 604800,
        dag: 86400,
        uur: 3600,
        minuut: 60,
        seconde: 1
      };
    //   const plurals = ['jaar', 'maanden', 'weken', 'dagen', 'uur', 'minuten', 'seconden'];
	let plurals = Object.values(this.translateService.instant('timeAgoPipe.intervalPlurals'))
      let counter;
      let index = 0;
      // tslint:disable-next-line: forin
      for (const i in intervals) {
        counter = Math.floor(seconds / intervals[i]);
        if (counter > 0) {
          if (counter === 1) {
            return counter + ' ' + i + ` ${this.translateService.instant('timeAgoPipe.ago')}`; // Single
          } else {
            return counter + ' ' + plurals[index] + ` ${this.translateService.instant('timeAgoPipe.ago')}`; // Plurals
          }
        }
        index++;
      }
    }
    return value;
  }
}
