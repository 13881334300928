import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Message } from "src/app/store/message/message.model";
import { Observable } from "rxjs";
import { Patient } from "src/app/store/patient/patient.model";

@Component({
  selector: "app-chat-message",
  templateUrl: "./chat-message.component.html",
  styleUrls: ["./chat-message.component.scss"],
})
export class ChatMessageComponent implements OnInit {
  @Input() message: Message;
  @Input() messagesSelected: { [key: string]: Message } = {};
  @Input() selectedCount: number;
  @Input() patient$: Observable<Patient>;
  @Input() copying = false;
  @Input() index: number;
  @Output() select = new EventEmitter();
  hasDeeplink = false;
  deeplinkTitle = "ik doe mee";
  selected = false;

  constructor() {}

  ngOnInit() {
    const deeplink = this.message.text.match(
      /(astmakompas:\/\/)([a-zA-Z0-9._=&?])+/g
    );

    this.hasDeeplink = deeplink && deeplink.length > 0;

    if (this.hasDeeplink) {
      if (this.message.text.includes("&title=")) {
        const title = this.message.text.match(/(&title=)([a-zA-Z0-9])+/g);
        if (title && title.length > 0) {
          this.deeplinkTitle = title[0].replace("&title=", "");
        }
      }
      const updatedMessage = this.message.text.replace(deeplink[0], "");
      this.message.text = updatedMessage;
    }
  }

  lineBreaks(text: string) {
    return text.replace(new RegExp("\n", "g"), "<br>");
  }

  selectMessage(event) {
    this.selected = event.checked;
    this.select.emit({
      selected: this.selected,
      index: this.index,
      message: this.message,
    });
  }
}
