import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  AuthActionTypes,
  AdminLoginSuccessAction,
  AdminTokensAction,
  AdminLoginAction,
  AdminClientAction,
  AdminLogoutSuccessAction,
  AdminLogoutAction,
  AdminRefreshTokenAction,
  AdminVerificationTokenAction,
  AdminVerifyAction,
} from './authAdmin.actions';

import { ApiService } from '../../services/api.service';
import * as errorActions from '../error/error.actions';
import * as fromRoot from '..';

import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { empty, of } from 'rxjs';
import { AccessToken, Tokens, VerificationToken } from './authAdmin.model';

import { JwtHelperService } from '@auth0/angular-jwt';
import { v4 as uuid } from 'uuid';

@Injectable()
export class AuthAdminEffects {

  @Effect()
  login$ = this.actions$.pipe(
    ofType(AuthActionTypes.AdminLogin),
    switchMap((action: AdminLoginAction) => {
      console.log('authorizeAdmin', action.payload);
      return this.api.authorizeAdmin(action.payload.email, action.payload.password).pipe(
        mergeMap((token: VerificationToken) => {
          return [new AdminVerificationTokenAction(token)];
        }),
        catchError(err => {
          this.store.dispatch(
            new errorActions.AddError({
              error: {
                id: uuid(),
                created: new Date(),
                content: err,
                source: action
              }
            })
          );
          return empty();
        })
      );
    })
  );

  @Effect()
  verify$ = this.actions$.pipe(
    ofType(AuthActionTypes.AdminVerify),
    switchMap((action: AdminVerifyAction) => {
      console.log('authorizeAdmin', action.payload);
      return this.api.verifyAdmin(action.payload).pipe(
        mergeMap((tokens: Tokens) => {
          return [new AdminTokensAction(tokens), new AdminLoginSuccessAction(tokens)];
        }),
        catchError(err => {
          this.store.dispatch(
            new errorActions.AddError({
              error: {
                id: uuid(),
                created: new Date(),
                content: err,
                source: action
              }
            })
          );
          return empty();
        })
      );
    })
  );

  @Effect()
  tokens$ = this.actions$.pipe(
    ofType(AuthActionTypes.AdminTokens),
    map((action: AdminTokensAction) => {
      // Store tokens in storage
      localStorage.setItem('adminTokens', JSON.stringify(action.payload));
      return action.payload;
    }),
    switchMap(tokens => of(this.jwtHelper.decodeToken(tokens.accesstoken) as AccessToken)),
    switchMap(({ client, careProvider }) => {
      return [new AdminClientAction({ _id: client._id })];
    })
  );

  @Effect()
  logout$ = this.actions$.pipe(
    ofType(AuthActionTypes.AdminLogout),
    switchMap((action: AdminLogoutAction) => {
      console.log('logging out');
      // Remove tokens in storage
      const tokens = JSON.parse(localStorage.getItem('adminTokens'));
      const decodedToken = this.jwtHelper.decodeToken(tokens.accesstoken) as AccessToken;

      localStorage.removeItem('adminTokens');
      console.log('tokens removed');
      return this.api.logoutAdmin(decodedToken.admin ? decodedToken.admin._id : decodedToken.admin._id,
        decodedToken.client._id).pipe(
          map((res) => {
            console.log('logout call success', res);
            return new AdminLogoutSuccessAction();
          }),
          catchError((err) => {
            console.log('logout error', err);
            return [new AdminLogoutSuccessAction()];
          })
        );
    })
  );

  @Effect()
  refreshtoken$ = this.actions$.pipe(
    ofType(AuthActionTypes.AdminRefreshToken),
    switchMap((action: AdminRefreshTokenAction) => {
      return this.api.refreshtokenAdmin({ refreshtoken: action.payload }).pipe(
        map(verifyResponse => {
          return new AdminTokensAction(verifyResponse);
        }),
        catchError(err => {
          return [
            new errorActions.AddError({
              error: {
                id: uuid(),
                created: new Date(),
                content: err,
                source: action
              }
            })
          ];
        })
      );
    })
  );

  constructor(
    private actions$: Actions,
    private api: ApiService,
    private jwtHelper: JwtHelperService,
    private store: Store<fromRoot.State>
  ) { }
}
