import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

import * as fromRoot from '../../store';
import * as authActions from '../../store/auth/auth.actions';
import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { CareProvider } from 'src/app/store/careprovider/careprovider.model';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileViewComponent implements OnInit, AfterViewInit, OnDestroy {
  careProvider$: Observable<CareProvider>;

  constructor(private store: Store<fromRoot.State>, private jwtHelper: JwtHelperService) { }

  ngOnInit() {
    this.loadCareProvider();
    this.careProvider$ = this.store.select(fromRoot.getCareProvider);
  }

  ngAfterViewInit() { }

  ngOnDestroy() { }

  private loadCareProvider() {
    this.store
      .select(fromRoot.getTokens)
      .pipe(filter(tokens => tokens !== undefined))
      .pipe(filter(tokens => tokens.accesstoken !== undefined))
      .pipe(take(1))
      .subscribe(tokens => {
        const decoded = this.jwtHelper.decodeToken(tokens.accesstoken);
        this.store.dispatch(new authActions.CareProviderGetAction(decoded.careProvider._id));
      });
  }
}
