import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { Observable } from "rxjs";

export enum LANG_TYPES {
	NL = "C188034B-2935-4F60-AEFA-5E0173E159F6",
	EN = "B09650DD-AD82-41C4-9BEC-6E4CFEFD9E74"
}


@Injectable({
	providedIn: "root",
})
export class FAApiService {
	private API_CODE: string = "_JBhV0VNCyuMIiE-SOJqqjYvCX2rZ_F-PSomfAfVwrzlAzFue6EtHA=="
	private URL: string = "https://fa-dev-app-02.azurewebsites.net/api"

	constructor(private customHttpService: HttpService) {}

	public getLabels(languageKey: LANG_TYPES): Observable<[]> {
		return this.customHttpService.request({
			method: 'get',
			url: `${this.URL}/LabelAPI/Dashboard/${languageKey}`,
			noAuth: true,
			params: {
				"code": this.API_CODE
			}
		})
	}

}