import { IHttpService, AuthRequestOptions } from "../interfaces/http-interface";
import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subscription, Subscriber, of, throwError } from "rxjs";

import { Store } from "@ngrx/store";
import * as fromRoot from "../store";
import * as authActions from "../store/auth/auth.actions";
import * as authAdminActions from "../store/authAdmin/authAdmin.actions";
import * as errorActions from '../store/error/error.actions';
import { mergeMap, switchMap, map, catchError, take } from "rxjs/operators";
import { Http, RequestOptionsArgs, Response, Headers } from "@angular/http";
import { JwtHelperService } from "@auth0/angular-jwt";
import { v4 as uuid } from 'uuid';

/**
 * Available options for each api endpoint.
 */

@Injectable({
  providedIn: "root"
})
export class HttpService implements IHttpService, OnDestroy {
  private waitForAuthRefresh: Observable<boolean>;
  private waitForAdminAuthRefresh: Observable<boolean>;
  private subscriptions: Subscription[] = [];
  private decodedToken: {
    careProvider: { _id: string; team: string; organization: string };
    client: { _id: string };
  };

  constructor(
    private store: Store<fromRoot.State>,
    private http: Http,
    private jwtHelper: JwtHelperService
  ) {
    this.waitForAuthRefresh = this.createAuthRefreshObservable();
    this.waitForAdminAuthRefresh = this.createAuthRefreshObservable(true);
  }

  private createAuthRefreshObservable(admin = false) {
    return Observable.create((observer: Subscriber<boolean>) => {
      const refreshSub = this.store
        .select(admin ? fromRoot.getAuthAdminState : fromRoot.getAuthState)
        .subscribe(state => {
          if (state.tokens) {
            const refreshDone = state.refreshing !== true;
            let tokenValid;
            try {
              tokenValid =
                this.jwtHelper.isTokenExpired(state.tokens.accesstoken, 1) ===
                false;
            } catch (error) {
              tokenValid = false;
            }
            if (refreshDone && tokenValid) {
              this.decodedToken = this.jwtHelper.decodeToken(
                state.tokens.accesstoken
              );
              observer.next(true);
              observer.complete();
            }
            if (!tokenValid) {
              this.refreshToken(state, admin);
            }
          }
        });
      this.subscriptions.push(refreshSub);
      return refreshSub;
    });
  }

  private refreshToken(authState: any, admin = false) {
    if (authState.refreshing !== true) {
      this.store.dispatch(
        admin
          ? new authAdminActions.AdminRefreshTokenAction(
              authState.tokens.refreshtoken
            )
          : new authActions.RefreshTokenAction(authState.tokens.refreshtoken)
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }


  public requestTest<T>(options: AuthRequestOptions, admin=false): Observable<T> {
	return this.http.get(options.url, { params: options.params }) as any
  }

  /**
   * Maps our custom options object to Angular Http request options.
   */

  public request<T>(options: AuthRequestOptions, admin = false): Observable<T> {
    options.headers = new Headers();
    options.headers.append("Cache-Control", "no-cache");
    options.headers.append("Pragma", "no-cache");
    options.headers.append("Expires", "Sat, 01 Jan 2000 00:00:00 GMT");
    // options.headers.append('If-Modified-Since', '0');

    if (options.basicAuth) {
      options.headers.append("Authorization", options.basicAuth);
    }
    if (options.body) {
      options.body = JSON.stringify(options.body);
      options.headers.append("Content-Type", "application/json");
    }

    if (!options.noAuth) {
      const refreshObs = admin
        ? this.waitForAdminAuthRefresh
        : this.waitForAuthRefresh;
      return refreshObs.pipe(
        mergeMap(() => {
          const tokens$ = admin
            ? this.store.select(fromRoot.getAdminTokens)
            : this.store.select(fromRoot.getTokens);
          return tokens$.pipe(
            take(1),
            switchMap(tokens => {
              const selectedTeam = JSON.parse(
                localStorage.getItem("selectedTeam")
              );

              options.headers.append(
                "Authorization",
                "Bearer " + tokens.accesstoken
              );

              if (selectedTeam) {
                options.url = options.url.replace(
                  ":teamId",
                  selectedTeam.teamId
                );
              }
              return this.makeRequest(options.url, options).pipe(
                catchError(err => {
                  return throwError(err);
                })
              );
            })
          );
        })
      );
    }

    return this.makeRequest(options.url, options).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  /**
   * Creates the http request.
   */
  private makeRequest(url: string, options: RequestOptionsArgs) {
    return this.http.request(url, options).pipe(
      map(res => {
        return parseJsonDates(res);
      }),
      catchError(err => {
        console.log("err", err);
        this.store.dispatch(
          new errorActions.AddError({
            error: {
              id: uuid(),
              created: new Date(),
              content: err,
              source: {type: 'httpService'}
            }
          })
        );

        return throwError(err);
      })
    );
  }
}

/**
 * Parses Json from an string or http Response using a reviver function
 */
export function parseJsonDates(res: string | Response): any {
  try {
    const text = typeof res === "string" ? res : res.text();
    return JSON.parse(text, reviveDateTime);
  } catch (error) {
    return {};
  }
}

/**
 * Reviver function that transforms ISO 8601 (UTC) datetime strings
 * like '2017-06-01T13:51:26Z' into Date objects
 */
function reviveDateTime(key: any, value: any): any {
  if (
    typeof value === "string" &&
    /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)Z?$/.test(value)
  ) {
    return new Date(value);
  }

  return value;
}
