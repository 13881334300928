import {
  ActionReducer,
  ActionReducerMap,
  MetaReducer,
  createSelector,
} from "@ngrx/store";
import { environment } from "../../environments/environment";
import * as fromRouter from "@ngrx/router-store";

/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */

import * as fromAuth from "../store/auth/auth.reducer";
import * as fromSettings from "../store/settings/settings.reducer";
import * as fromAuthAdmin from "../store/authAdmin/authAdmin.reducer";
import * as fromError from "../store/error/error.reducer";
import * as fromPatient from "../store/patient/patient.reducer";
import * as fromPatientAction from "../store/patientAction/patientAction.reducer";
import * as fromMedicine from "../store/medicine/medicine.reducer";
import * as fromMessage from "./message/message.reducer";
import * as fromOrg from "../store/org/org.reducer";
import * as fromTeam from "../store/team/team.reducer";
import * as fromCareProvider from "../store/careprovider/careprovider.reducer";
import { routerReducer } from "@ngrx/router-store";
import { MeasurementResultTypes } from './patientAction/patientAction.model';
import { getBestFev1Result } from '../util/helper-functions';

export const WEEKDAYS = [
  "zondag",
  "maandag",
  "dinsdag",
  "woensdag",
  "donderdag",
  "vrijdag",
  "zaterdag",
];

export interface MeasurementConfig {
  measurement?: string;
  title?: string;
  name: string;
  enabled: boolean;
  notificationSettings?: {
    every: string;
    at: string;
  };
}

export interface MonitoringSettings {
  days: [
    {
      maxPatients: number;
      weekDay: number;
    },
    {
      maxPatients: number;
      weekDay: number;
    },
    {
      maxPatients: number;
      weekDay: number;
    },
    {
      maxPatients: number;
      weekDay: number;
    },
    {
      maxPatients: number;
      weekDay: number;
    },
    {
      maxPatients: number;
      weekDay: number;
    },
    {
      maxPatients: number;
      weekDay: number;
    }
  ];
}

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface State {
  auth: fromAuth.State;
  settings: fromSettings.State;
  authAdmin: fromAuthAdmin.State;
  error: fromError.State;
  router: fromRouter.RouterReducerState;
  patient: fromPatient.State;
  patientAction: fromPatientAction.State;
  medicine: fromMedicine.State;
  message: fromMessage.State;
  org: fromOrg.State;
  team: fromTeam.State;
  careProvider: fromCareProvider.State;
}

/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const reducers: ActionReducerMap<State> = {
  auth: fromAuth.reducer,
  settings: fromSettings.reducer,
  authAdmin: fromAuthAdmin.reducer,
  error: fromError.reducer,
  router: routerReducer,
  patient: fromPatient.reducer,
  patientAction: fromPatientAction.reducer,
  medicine: fromMedicine.reducer,
  message: fromMessage.reducer,
  org: fromOrg.reducer,
  team: fromTeam.reducer,
  careProvider: fromCareProvider.reducer,
};

// console.log all actions
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state: State, action: any): any => {
    const result = reducer(state, action);
    return result;
  };
}

export const metaReducers: MetaReducer<State>[] = !environment.production
  ? []
  : [];

/**
 * Map sub state selectors to main state
 */

export const getAuthState = (state: State) => state.auth;
export const getSettingsState = (state: State) => state.settings;
export const getAuthAdminState = (state: State) => state.authAdmin;
export const getErrorState = (state: State) => state.error;
export const getPatientState = (state: State) => state.patient;
export const getOrgState = (state: State) => state.org;
export const getTeamState = (state: State) => state.team;
export const getCareProviderState = (state: State) => state.careProvider;
export const getPatientActionState = (state: State) => state.patientAction;
export const getMedicineState = (state: State) => state.medicine;
export const getMessageState = (state: State) => state.message;

export const getErrors = createSelector(getErrorState, fromError.selectAll);

export const getTokens = createSelector(getAuthState, fromAuth.getTokens);
export const getMeasurements = createSelector(
  getSettingsState,
  fromSettings.getMeasurements
);

export const getMeasurementByName = (name: string) =>
  createSelector(getMeasurements, (measurements) => {
    const hasMeasurement = measurements.find(
      (measurement) => measurement.name === name
    );
    return hasMeasurement ? hasMeasurement : undefined;
  });

export const isPostingSettings = createSelector(
  getSettingsState,
  fromSettings.isPosting
);

export const getAdminTokens = createSelector(
  getAuthAdminState,
  fromAuthAdmin.getTokens
);

export const getTeam = createSelector(getSettingsState, fromSettings.getTeam);

export const getCareProvider = createSelector(
  getAuthState,
  fromAuth.getCareProvider
);

export const getCareProviderSelectedTeam = createSelector(
  getSettingsState,
  fromSettings.getSelectedTeam
);

export const getCareProviderTeam = createSelector(
  getSettingsState,
  fromSettings.getTeam
);

export const getCareProviderSelectedTeamReplies = createSelector(
  getCareProviderTeam,
  (team) => {
    return team.standardChatReplies ? team.standardChatReplies : undefined;
  }
);

export const getLoginRequested = createSelector(
  getAuthState,
  fromAuth.loginRequested
);

export const isPostingAuthAdmin = createSelector(
  getAuthAdminState,
  fromAuthAdmin.isPosting
);

export const getAdminVerification = createSelector(
  getAuthAdminState,
  fromAuthAdmin.getVerification
);

export const getVerification = createSelector(
  getAuthState,
  fromAuth.getVerification
)

export const isPostingAuth = createSelector(getAuthState, fromAuth.isPosting);

export const getTeamCareProviders = createSelector(getTeam, (team) => {
  return team.careProviders ? team.careProviders : [];
});

export const getChats = createSelector(
  createSelector(getPatientState, fromPatient.selectAll),
  (patients) => {
    const chats = patients.filter(
      (patient) => patient.lastChatMessage !== undefined
    );
    return chats.sort((chatA, chatB) => {
      if (
        chatA.lastChatMessage.unAnswered &&
        !chatB.lastChatMessage.unAnswered
      ) {
        return -1;
      } else {
        return (
          chatB.lastChatMessage.createdAt.getTime() -
          chatA.lastChatMessage.createdAt.getTime()
        );
      }
    });
  }
);

export const getUnreadChats = createSelector(
  createSelector(getPatientState, fromPatient.selectAll),
  (patients) => {
    const unread = patients.filter(
      (patient) =>
        patient.lastChatMessage !== undefined &&
        patient.lastChatMessage.unAnswered
    );
    return unread === undefined ? 0 : unread.length;
  }
);

export const getAlertAndMessagesCount = createSelector(
  createSelector(getPatientState, fromPatient.selectAll),
  (patients) => {
    let count = 0;
    patients.forEach((patient) => {
      count =
        count +
        (patient.unresolvedActionsCount || 0) +
        (patient.unresolvedMessagesCount || 0);
    });
    return count;
  }
);

export const getMessageEntities = createSelector(
  getMessageState,
  fromMessage.selectEntities
);

export const getSelectedChatId = createSelector(
  getMessageState,
  fromMessage.getSelectedId
);

export const getCurrentMessages = createSelector(
  getMessageState,
  fromMessage.getCurrentMessages
);

export const isPostingMessage = createSelector(
  getMessageState,
  fromMessage.isPosting
);

export const getSelectedMessages = createSelector(
  getMessageEntities,
  getSelectedChatId,
  (entities, selectedChatId) => {
    const selectMessageEntities = [];
    Object.entries(entities).forEach(([key, value]) => {
      if (value.user === selectedChatId) {
        selectMessageEntities.push(value);
      }
    });
    return selectMessageEntities.sort((a, b) => {
      return (a.createdAt.getTime() < b.createdAt.getTime() ? -1 : 1) * 1;
    });
  }
);

export const getCurrentMessage = (chatId) =>
  createSelector(getCurrentMessages, (messages) =>
    messages[chatId] ? messages[chatId] : undefined
  );

export const getPatients = createSelector(
  getPatientState,
  fromPatient.selectAll
);

export const getPatientEntities = createSelector(
  getPatientState,
  fromPatient.selectEntities
);

export const getSelectedPatientId = createSelector(
  getPatientState,
  fromPatient.getSelectedId
);

export const isPostingPatient = createSelector(
  getPatientState,
  fromPatient.isPosting
);

export const getActivePatients = createSelector(
  getPatients,
  patients => patients.filter(patient => !patient.isArchived)
)

export const getArchivedPatients = createSelector(
  getPatients,
  patients => patients.filter(patient => patient.isArchived)
)

export const getSelectedPatient = createSelector(
  getPatientEntities,
  getSelectedPatientId,
  (entities, selectedId) => {
    return selectedId && entities[selectedId];
  }
);

export const getSelectedPatientMedicationById = createSelector(
  getPatientEntities,
  getSelectedPatientId,
  (entities, selectedId) => {
    if (!selectedId) {
      return;
    }
    const medication = entities[selectedId].medication;
    const medicationMapped = [];
    medication.forEach((med) => {
      medicationMapped[med._id] = med;
    });
    return medicationMapped;
  }
);

export const getPatientActions = createSelector(
  getPatientActionState,
  fromPatientAction.selectAll
);

export const getPatientActionEntities = createSelector(
  getPatientActionState,
  fromPatientAction.selectEntities
);

export const getSelectedPatientActionPatientId = createSelector(
  getPatientActionState,
  fromPatientAction.getSelectedPatientId
);

export const getPatientActionByMeasurementResultId = (selectedId: string) => createSelector(getPatientActions, (patientActions) => {
  const selectedPatientAction = patientActions.find(patientAction => patientAction.measurementResult !== undefined && patientAction.measurementResult._id === selectedId);
  return selectedPatientAction;
});

export const getPreviousDeviceMeasurementResults = (selectedId: string) => createSelector(getPatientActions, (patientActions) => {
  const currentPatientAction = patientActions.find(patientAction => patientAction.measurementResult !== undefined && patientAction.measurementResult._id === selectedId);
  const selectedMeasurementResults = patientActions.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
      .filter(patientAction => patientAction.__t === 'UserActionMeasurement' && patientAction.measurementResult.name === MeasurementResultTypes.SPIROMETER && patientAction.measurementResult._id !== selectedId && patientAction.createdAt < currentPatientAction.createdAt);
  return selectedMeasurementResults.slice(0, 2).map(action => action.measurementResult);
})

export const getBestDeviceMeasurementResult = (selectedId: string) => createSelector(getPatientActions, (patientActions) => {
  const devicePatientActions = patientActions.filter(patientAction => patientAction.__t === 'UserActionMeasurement' && patientAction.measurementResult.name === MeasurementResultTypes.SPIROMETER);
  const bestDeviceMeasurementResultIndex = (devicePatientActions.map(patientAction => getBestFev1Result(patientAction)) as number[]).reduce((iMax, x, i, arr) => x > arr[iMax] ? i : iMax, 0);
  const bestDeviceMeasurementResult = devicePatientActions[bestDeviceMeasurementResultIndex];
  console.log('bestDeviceMeasurementResult', bestDeviceMeasurementResultIndex, bestDeviceMeasurementResult);
  return bestDeviceMeasurementResult ? bestDeviceMeasurementResult.measurementResult : undefined;
})

export const getSelectedPatientActions = createSelector(
  getPatientActionEntities,
  getSelectedPatientActionPatientId,
  (entities, selectedPatientId) => {
    const selectPatientEntities = [];
    Object.entries(entities).forEach(([key, value]) => {
      if (value.user === selectedPatientId) {
        selectPatientEntities.push(value);
      }
    });
    return selectPatientEntities.sort((a, b) => {
      if (a.createdAt.getTime() >= b.createdAt.getTime()) {
        return 1;
      }
      return -1;
    });
  }
);

export const getMedicines = createSelector(
  getMedicineState,
  fromMedicine.selectAll
);

// Orgs - ADMIN

export const getOrgs = createSelector(getOrgState, fromOrg.selectAll);

export const getOrgEntities = createSelector(
  getOrgState,
  fromOrg.selectEntities
);

export const getSelectedOrgId = createSelector(
  getOrgState,
  fromOrg.getSelectedId
);

export const isPostingOrg = createSelector(getOrgState, fromOrg.isPosting);

export const getSelectedOrg = createSelector(
  getOrgEntities,
  getSelectedOrgId,
  (entities, selectedId) => {
    return selectedId && entities[selectedId];
  }
);

// Teams - ADMIN

export const getTeams = createSelector(getTeamState, fromTeam.selectAll);

export const getTeamsEntities = createSelector(
  getTeamState,
  fromTeam.selectEntities
);

export const getSelectedTeamId = createSelector(
  getTeamState,
  fromTeam.getSelectedId
);

export const isPostingTeam = createSelector(getTeamState, fromTeam.isPosting);

export const getSelectedTeam = createSelector(
  getTeamsEntities,
  getSelectedTeamId,
  (entities, selectedId) => {
    return selectedId && entities[selectedId];
  }
);

// CareProviders - ADMIN

export const getCareProviders = createSelector(
  getCareProviderState,
  fromCareProvider.selectAll
);

export const getCareProvidersEntities = createSelector(
  getCareProviderState,
  fromCareProvider.selectEntities
);

export const getSelectedCareProviderId = createSelector(
  getCareProviderState,
  fromCareProvider.getSelectedId
);

export const isPostingCareProvider = createSelector(
  getCareProviderState,
  fromCareProvider.isPosting
);

export const getSelectedCareProvider = createSelector(
  getCareProvidersEntities,
  getSelectedCareProviderId,
  (entities, selectedId) => {
    return selectedId && entities[selectedId];
  }
);
