export interface ActionPoint {
  actionType?: ActionPointType;
  _id?: string;
  title?: string;
  description?: string;
  note?: string;
  order?: "before" | "after";
  baseAction?: boolean;
}

export interface MedicineActionPoint extends ActionPoint {
  medicine: string;
  dosage: number;
  customDosage: string;
  frequency: number;
}

export interface PatientActionPoint extends ActionPoint {
  showNotes: boolean;
  showMedication: boolean;
}

export interface ContactActionPoint extends ActionPoint {
  contactInfo?: {
    title: string;
    info: string;
    number: string;
  }[];
}

export interface ResponseActionPoint extends ActionPoint {
  expectedResponsePeriod: string;
  prefixText: string;
  affixText: string;
}

export interface ColorZoneData {
  actions: Array<
    | MedicineActionPoint
    | PatientActionPoint
    | ContactActionPoint
    | ResponseActionPoint
    | ActionPoint
  >;
  evaluateAfter?: string;
  useEpipen?: boolean;
  settings?: PatientActionPoint;
  before?: Array<
    | MedicineActionPoint
    | PatientActionPoint
    | ContactActionPoint
    | ResponseActionPoint
    | ActionPoint
  >;
  after?: Array<
    | MedicineActionPoint
    | PatientActionPoint
    | ContactActionPoint
    | ResponseActionPoint
    | ActionPoint
  >;
  conditions?: Conditions
}

export interface Conditions {
  additions?: {title: string}[];
  exceptions?: {conditionId: string, visible: boolean}[];
}

export interface ActionPlan {
  green?: ColorZoneData;
  yellow?: ColorZoneData;
  orange?: ColorZoneData;
  red?: ColorZoneData;
}

export enum ActionPointTypes {
  MEDICATION = "medication",
  GENERAL = "general",
  PATIENT_GENERAL = "patientGeneral",
  CONTACT = "contactInfo",
  PATIENT = "patientActionPlan",
  RESPONSE = "therapyResponse",
}

export type ActionPointType =
  | ActionPointTypes.MEDICATION
  | ActionPointTypes.GENERAL
  | ActionPointTypes.PATIENT_GENERAL
  | ActionPointTypes.PATIENT
  | ActionPointTypes.RESPONSE
  | ActionPointTypes.CONTACT;

export const RESPONSE_TIMES = [
  "4 uur",
  "6 uur",
  "12 uur",
  "24 uur",
  "2 dagen",
  "3 dagen",
  "7 dagen",
  "10 dagen",
  "14 dagen",
];

export const BLOCK_TYPES = [
  { type: ActionPointTypes.GENERAL, label: "Algemeen" },
  { type: ActionPointTypes.CONTACT, label: "Contactinformatie" },
  { type: ActionPointTypes.PATIENT, label: "Notitie & medicatie" },
  { type: ActionPointTypes.RESPONSE, label: "Verbetertijd" },
];

export interface OptionsDialogOption {
  value?: string;
  title?: string;
  description?: string;
  showInput?: boolean;
}

export interface Measurement {
  _id: string;
  measurementType: string;
  name: string;
  title: string;
  header: string;
}

export interface QuestionnaireQuestion {
  _id?: string;
  title: string;
  questionType: 'singleSelection' | 'multiSelection';
  singleSelectionProperties?: {
    answerOptions?: AnswerOption[];
  };
  multiSelectionProperties?: {
    answerRequired?: boolean;
    allowCustomAnswer?: boolean;
    customAnswerPlaceholder?: string;
    lastAnswerDeselectsAll?: boolean;
    answerOptions?: AnswerOption[];
  }
}

export interface AnswerOption {
  _id?: string;
      title?: string;
      colorLabel?: string;
      skipQuestions?: number[]
}

export interface ResultViewData {
  calculatedResultValue: number;
  title: string;
  text: string
}

export interface QuestionnaireMeasurement extends Measurement {
  questions: QuestionnaireQuestion[];
  resultViewData: ResultViewData[]
}

export interface DeviceMeasurement extends Measurement {
  device: {
    deviceId: string;
    device: "spirometer" | "bloodpressure" | "smartinhaler";
    name: string;
    brand: string;
    instructionUrl: string;
    manualMeasurement: true;
  };
}
