import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material";
import { FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import * as moment from "moment";
import { Store } from "@ngrx/store";
import { Team } from "./../../store/team/team.model";
import { StoreError } from "../../store/error/error.model";
import { filter, take, map } from "rxjs/operators";
import * as fromRoot from "../../store";
import * as teamActions from "../../store/team/team.actions";
import * as errorActions from "../../store/error/error.actions";

@Component({
  selector: "app-admin-team-dialog",
  templateUrl: "./admin-team-dialog.component.html",
  styleUrls: ["./admin-team-dialog.component.scss"],
})
export class TeamDialogComponent implements OnInit, OnDestroy {
  dialogForm: FormGroup;
  phoneForm: FormGroup;
  deviceForm: FormGroup;
  isPosting: boolean;
  errorMessage: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { orgId: string; team: Team },
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<TeamDialogComponent>,
    private router: Router,
    private store: Store<fromRoot.State>,
    private formBuilder: FormBuilder
  ) {}

  ngOnDestroy() {
    this.store.dispatch(new errorActions.ClearErrors());
  }

  ngOnInit() {
    this.isPosting = false;

    this.store.dispatch(new errorActions.ClearErrors());

    let team = this.data.team ? (this.data.team as Team) : undefined;

    if (!team) {
      team = {
        name: "",
        email: "",
        onboardingQuestionaire: "",
      };
    }

    this.deviceForm = this.formBuilder.group({
      spirometer: [
        team.devices !== undefined ? team.devices.spirometer : false,
      ],
      order_by_patient: [
        team.devices !== undefined ? team.devices.order_by_patient : false,
      ],
      order_message: [
        team.devices !== undefined && team.devices.order_message !== undefined
          ? team.devices.order_message
          : "",
      ],
      order_subject: [
        team.devices !== undefined && team.devices.order_subject !== undefined
          ? team.devices.order_subject
          : "",
      ],
    });

    this.dialogForm = this.formBuilder.group({
      name: [team.name],
      email: [team.email],
      devices: this.deviceForm,
      onboardingQuestionaire: [team.onboardingQuestionaire],
      // organization: [team.organization]
    });

    this.store
      .select(fromRoot.getErrors)
      .pipe(filter((errors) => errors && errors.length > 0))
      .pipe(map((errors) => errors[0]))
      .pipe(filter((error) => error.apiError !== undefined))
      .subscribe((error: StoreError) => {
        this.isPosting = false;
        switch (error.apiError.code) {
          default:
            this.errorMessage = "Er is iets fout gegaan";
            break;
        }
      });
  }

  isPosting$() {
    return this.store
      .select(fromRoot.isPostingTeam)
      .pipe(filter((isPosting) => isPosting === false))
      .pipe(take(1))
      .toPromise();
  }

  createTeam() {
    if (this.isPosting) {
      return;
    }
    this.isPosting = true;
    this.errorMessage = undefined;
    const team = this.parseForm(this.dialogForm.value);
    this.store.dispatch(
      new teamActions.AddTeam({ orgId: this.data.orgId, team })
    );

    this.isPosting$().then(() => {
      this.isPosting = false;
      this.dialogRef.close();
      this.store
        .select(fromRoot.getSelectedTeamId)
        .pipe(take(1))
        .subscribe((selectedId) => {
          this.router.navigate([
            "admin/organisaties/" + this.data.orgId + "/teams/" + selectedId,
          ]);
        });
    });
  }

  patchTeam() {
    if (this.isPosting) {
      return;
    }
    this.isPosting = true;
    this.errorMessage = undefined;
    const team = this.parseForm(this.dialogForm.value);
    this.store.dispatch(
      new teamActions.UpdateTeam({
        orgId: this.data.orgId,
        teamId: this.data.team._id,
        team,
      })
    );
    this.isPosting$().then(() => {
      this.isPosting = false;
      this.dialogRef.close();
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  private parseForm(formValue) {
    if (!formValue) {
      return formValue;
    }
    if (formValue.dateOfBirth) {
      formValue.dateOfBirth = moment(
        formValue.dateOfBirth,
        "DD-MM-YYYY"
      ).format("YYYY-MM-DD");
    }
    return formValue;
  }
}
