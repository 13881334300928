import { AuthActions, AuthActionTypes } from './authAdmin.actions';
import { Client, Tokens, VerificationToken } from './authAdmin.model';

export interface State {
  client?: Client;
  tokens?: Tokens;
  verification?: VerificationToken;
  refreshing?: boolean;
  loading?: boolean;
  activated?: boolean;
  loginRequested?: boolean;
  isPosting?: boolean;
}

const tokens = JSON.parse(localStorage.getItem('adminTokens'));

export const initialState: State = {
  tokens: tokens ? tokens : undefined,
  isPosting: false
};

export function reducer(state = initialState, action: AuthActions): State {
  switch (action.type) {
    case AuthActionTypes.AdminClient:
      return {
        ...state,
        client: action.payload
      };

    case AuthActionTypes.AdminTokens:
      return {
        ...state,
        refreshing: false,
        tokens: action.payload
      };

    case AuthActionTypes.AdminRefreshToken:
      return {
        ...state,
        refreshing: true
      };

    case AuthActionTypes.AdminVerificationToken:
      return {
        ...state,
        verification: action.payload
      }

    case AuthActionTypes.AdminClearState: {
      return {};
    }

    case AuthActionTypes.AdminLogoutSuccess: {
      return {};
    }

    default:
      return state;
  }
}

export const getState = (state: State) => state;

export const isPosting = (state: State) => state.isPosting;

export const getClient = (state: State) => state.client;

export const getTokens = (state: State) => state.tokens;

export const getVerification = (state: State) => state.verification;

export const isRefreshing = (state: State) => state.refreshing;

export const isLoading = (state: State) => state.loading;

export const loginRequested = (state: State) => state.loginRequested;
