import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

import * as fromRoot from '../../store';
import * as authActions from '../../store/auth/auth.actions';
import * as settingsActions from '../../store/settings/settings.actions';
import * as errorActions from '../../store/error/error.actions';
import { Tokens, VerificationToken } from '../../store/auth/auth.model';
import { take, map, filter, delay, skip } from 'rxjs/operators';

import { StoreError } from '../../store/error/error.model';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/dialogs/confirm-dialog/confirm-dialog.component';
import { CareProvider } from 'src/app/store/careprovider/careprovider.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginPageComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  verifyForm: FormGroup;
  message: string;
  loading = false;
  verifyLoading = false;
  tokens$: Observable<Tokens>;
  verification$: Observable<VerificationToken>;
  error$: Observable<StoreError>;
  verifyError$: Observable<StoreError>;
  careProvider$: Observable<CareProvider>;
  tokensSub: Subscription;
  errorSub: Subscription;
  returnUrl: string;
  public loggedIn$: Observable<boolean>;

  constructor(
	public translate: TranslateService,
    public dialog: MatDialog,
    private store: Store<fromRoot.State>,
    private router: Router,
    private formBuilder: FormBuilder,
    private jwtHelper: JwtHelperService
  ) {
	// translate.use('nl')
  }

  ngOnInit() {
    this.store.dispatch(new errorActions.ClearErrors());
    this.error$ = this.store.select(fromRoot.getErrors).pipe(
      skip(1),
      filter(errors => {
        return (
          errors !== undefined && errors[0] && errors[0].type === authActions.AuthActionTypes.Login
        );
      }),
      map(errors => errors[0])
    );

    this.verifyError$ = this.store.select(fromRoot.getErrors).pipe(
      skip(1),
      filter(errors => {
        return (
          errors !== undefined && errors[0] && errors[0].type === authActions.AuthActionTypes.Verify
        );
      }),
      map(errors => errors[0])
    );


    this.careProvider$ = this.store.select(fromRoot.getCareProvider);

    this.loginForm = this.formBuilder.group({
      email: [''],
      password: ['']
    });

    this.verifyForm = this.formBuilder.group({
      pin: [null, [Validators.required, Validators.maxLength(4), Validators.minLength(4), Validators.pattern(/^[0-9]\d*$/)]]
    });

    this.verification$ = this.store.select(fromRoot.getVerification).pipe(filter(t => t !== undefined));

    this.loggedIn$ = combineLatest(
      this.store.select(fromRoot.getTokens),
      this.careProvider$
    ).pipe(
      filter(([_, careProvider]) => careProvider !== undefined),
      map(([tokens, careProvider]) => {
        return (
          tokens &&
          careProvider.email &&
          tokens.accesstoken &&
          this.jwtHelper.isTokenExpired(tokens.accesstoken) === false
        );
      })
    );

    combineLatest(this.loggedIn$, this.careProvider$)
      .pipe(
        filter(([loggedIn, _]) => loggedIn === true),
        take(1),
        delay(500)
      )
      .subscribe(([loggedIn, careProvider]) => {
        if (loggedIn) {
          // You are logged in
          if (careProvider.teams && careProvider.teams.length > 1) {
            this.router.navigate(['/switcher']);
          } else {
            this.store.dispatch(
              new settingsActions.SelectTeam({
                orgId: careProvider.organizations[0],
                teamId: (careProvider.teams as string[])[0]
              })
            );
            this.router.navigate(['/']);
          }
        }
      });
  }

  ngOnDestroy() {
    if (this.tokensSub) {
      this.tokensSub.unsubscribe();
    }
    if (this.errorSub) {
      this.errorSub.unsubscribe();
    }
  }

  get pin() {
    return this.verifyForm.get('pin');
  }

  get email() {
    return this.loginForm.get('email');
  }

  get password() {
    return this.loginForm.get('password');
  }

  showInfoDialog() {
    this.displayError(
		null, 
		this.translate.instant('loginPage.noLoginCode'),
		this.translate.instant('noLoginCodeDesc')
		// 'Geen inlogcode ontvangen?', 
		// 'Het kan enkele minuten duren voordat u de pincode ontvangt. Controleer ook uw spamfolder. Neem bij problemen contact op met Astmakompas Support via <a href="mailto:support@astmakompas.nl">support@astmakompas.nl</a> of 085 004 3303.'
	);
  }

  login() {
    const email = this.loginForm.value.email;
    const password = this.loginForm.value.password;
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.errorSub = this.error$.pipe(take(1)).subscribe(err => {
      console.log(err);
      this.displayError(err, null, 
		this.translate.instant('loginPage.unknownEmailPassword')
		// 'E-mail / wachtwoord combinatie niet bekend.'
	);
      this.loading = false;
    });
    this.store.dispatch(new authActions.LoginAction({ email, password }));
  }

  verify(token: string) {
    const pin = this.verifyForm.value.pin;

    if (this.verifyLoading) {
      return;
    }
    this.verifyLoading = true;
    this.errorSub = this.verifyError$.pipe(take(1)).subscribe(err => {
      console.log(err);
      this.displayError(err, 
		this.translate.instant('loginPage.invalidCode'),
		this.translate.instant('loginPage.invalidCodeDesc'),
		// 'Onjuiste inlogcode', 
		// 'Uw inlogcode is niet juist. Probeer het opnieuw.<br><br>Lukt het niet, neem dan contact op met Astmakompas Support via <a href="mailto:support@astmakompas.nl">support@astmakompas.nl</a> of 085 004 3303.'
	);
      this.verifyLoading = false;
    });
    this.store.dispatch(new authActions.VerifyAction({ pin, token }));
  }

  displayError(err: StoreError, title: string,  message: string) {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        messageOnly: true,
        title: title ? title : 'Error',
        message:
          err ? err.status === 401 ? message : this.translate.instant('loginPage.unknownError') : message
      }
    });
  }
}
