import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Team } from './team.model';
import { TeamActions, TeamActionTypes } from './team.actions';
import { AuthActions, AuthActionTypes } from '../auth/auth.actions';

export interface State extends EntityState<Team> {
  // additional entities state properties
  selectedId?: string;
  isPosting: boolean;
}

export const adapter: EntityAdapter<Team> = createEntityAdapter<Team>({
  selectId: (item: Team) => item._id
});

export const initialState: State = adapter.getInitialState({
  isPosting: false
});

export function reducer(state = initialState, action: TeamActions | AuthActions): State {
  switch (action.type) {
    case TeamActionTypes.AddTeam: {
      return {
        ...state,
        isPosting: true
      };
    }

    case TeamActionTypes.AddTeamSuccess: {
      return {
        ...adapter.addOne(action.payload.item, state),
        isPosting: false
      };
    }

    case TeamActionTypes.AddTeams: {
      return adapter.upsertMany(action.payload.items, state);
    }

    case TeamActionTypes.UpdateTeam: {
      return {
        ...state,
        isPosting: true
      };
    }

    case TeamActionTypes.UpdateTeamSuccess: {
      return {
        ...adapter.updateOne(action.payload, state),
        isPosting: false
      };
    }

    case TeamActionTypes.DeleteTeam: {
      return {
        ...state,
        isPosting: true
      };
    }

    case TeamActionTypes.DeleteTeamSuccess: {
      return {
        ...adapter.removeOne(action.payload.id, state),
        isPosting: false
      };
    }

    case TeamActionTypes.DeleteTeams: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case TeamActionTypes.LoadTeamsSuccess: {
      return adapter.addAll(action.payload.items, state);
    }

    case TeamActionTypes.GetOneSuccess: {
      return adapter.upsertOne(action.payload, state);
    }

    case TeamActionTypes.ClearTeams: {
      return adapter.removeAll(state);
    }

    case TeamActionTypes.Select: {
      return {
        ...state,
        selectedId: action.payload
      };
    }

    case AuthActionTypes.LogoutSuccess: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const getSelectedId = (state: State) => state.selectedId;
export const isPosting = (state: State) => state.isPosting;
