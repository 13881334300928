import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const isAdmin = location.href.includes('admin');
    const JSONtokens = isAdmin ? localStorage.getItem('adminTokens') : localStorage.getItem('tokens');
    const allow = JSONtokens !== null && JSON.parse(JSONtokens).accesstoken !== undefined;
    if (!allow) {
      this.router.navigate([isAdmin ? '/admin/login' : '/login']);
    }
    return allow;
  }
}
