import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, concatMap, mergeMap } from 'rxjs/operators';
import {
  PatientActionTypes,
  AddPatient,
  AddPatientSuccess,
  LoadPatients,
  LoadPatientsSuccess,
  LoadChats,
  LoadChatsSuccess,
  UpdatePatientSuccess,
  UpdatePatient,
  AddMedication,
  AddMedicationSuccess,
  RemoveMedication,
  RemoveMedicationSuccess,
  AddAction,
  AddActionSuccess,
  UpdateAction,
  UpdateActionSuccess,
  RemoveAction,
  RemoveActionSuccess,
  UpdateActionPlan,
  UpdateActionPlanSuccess,
  UpdateActionOrder,
  UpdateActionOrderSuccess,
  DeletePatient,
  DeletePatientSuccess,
  GetOne,
  GetOneSuccess,
  Select,
  SetChatResolved,
  SetChatResolvedSuccess,
  Invite,
  InviteSuccess,
  ArchivePatientSuccess,
  ArchivePatient,
  UnarchivePatient,
  UnarchivePatientSuccess
} from './patient.actions';
import { ApiService } from '../../services/api.service';

import * as errorActions from '../error/error.actions';
import * as authActions from '../auth/auth.actions';
import * as fromRoot from '../';
import { Store } from '@ngrx/store';

import { v4 as uuid } from 'uuid';
import { empty, of } from 'rxjs';
import { Patient } from './patient.model';
import { LoadTeamAction } from '../settings/settings.actions';

@Injectable()
export class PatientEffects {
  @Effect()
  add$ = this.actions$.pipe(
    ofType(PatientActionTypes.AddPatient),
    switchMap((action: AddPatient) => {
      return this.api.createPatient(action.payload.item).pipe(
        mergeMap(response => {
          return [new Select(response._id), new AddPatientSuccess({ item: response })];
        }),
        catchError(err => {
          this.store.dispatch(
            new errorActions.AddError({
              error: {
                id: uuid(),
                created: new Date(),
                content: err,
                source: action
              }
            })
          );
          return empty();
        })
      );
    })
  );

  @Effect()
  getOne$ = this.actions$.pipe(
    ofType(PatientActionTypes.GetOne),
    switchMap((action: GetOne) => {
      return this.api.getPatient(action.payload).pipe(
        map((verifyResponse: Patient) => {
          return new GetOneSuccess(verifyResponse);
        }),
        catchError(err => {
          this.store.dispatch(
            new errorActions.AddError({
              error: {
                id: uuid(),
                created: new Date(),
                content: err,
                source: action
              }
            })
          );
          return empty();
        })
      );
    })
  );

  @Effect()
  load$ = this.actions$.pipe(
    ofType(PatientActionTypes.LoadPatients),
    mergeMap((action: LoadPatients) => {
      const apiCall = (action.payload && action.payload.unresolvedOnly) ? this.api.getUnresolvedPatients() : this.api.getAllPatients();
      return apiCall.pipe(
        map((patients: Patient[]) => {
          patients.forEach(patient => {
            if (!patient.isPending) {
              patient.isPending = false;
            }
            if (!patient.inConcept) {
              patient.inConcept = false;
            }
          });

          return new LoadPatientsSuccess({ items: patients });
        }),
        catchError(err => {
          this.store.dispatch(
            new errorActions.AddError({
              error: {
                id: uuid(),
                created: new Date(),
                content: err,
                source: action
              }
            })
          );
          return empty();
        })
      );
    })
  );

  @Effect()
  loadChats$ = this.actions$.pipe(
    ofType(PatientActionTypes.LoadChats),
    mergeMap((action: LoadChats) => {
      return this.api.getChats().pipe(
        map((patients: Patient[]) => {
          return new LoadChatsSuccess({ items: patients });
        }),
        catchError(err => {
          this.store.dispatch(
            new errorActions.AddError({
              error: {
                id: uuid(),
                created: new Date(),
                content: err,
                source: action
              }
            })
          );
          return empty();
        })
      );
    })
  );

  @Effect()
  updatePatientSuccess$ = this.actions$.pipe(
    ofType(PatientActionTypes.UpdatePatientSuccess),
    mergeMap((action: UpdatePatientSuccess) => {
      return of(new LoadTeamAction());
    })
  );

  @Effect()
  update$ = this.actions$.pipe(
    ofType(PatientActionTypes.UpdatePatient),
    switchMap((action: UpdatePatient) => {
      console.log('UpdatePatient 1', action.payload)
      if (action.payload.patchNotifyMeAt) {
        return this.api.patchNotifyMeAt(action.payload.id, action.payload.item.notifyMeAt).pipe(switchMap(res => {
          return this.api.patchPatient(action.payload.id, action.payload.item).pipe(
            map(verifyResponse => {
              return new UpdatePatientSuccess({ changes: verifyResponse, id: action.payload.id });
            }),
            catchError(err => {
              this.store.dispatch(
                new errorActions.AddError({
                  error: {
                    id: uuid(),
                    created: new Date(),
                    content: err,
                    source: action
                  }
                })
              );
              return empty();
            })
          );
        }));
      }
      return this.api.patchPatient(action.payload.id, action.payload.item).pipe(
        map(verifyResponse => {
          console.log('UpdatePatient 2 Success', verifyResponse);
          return new UpdatePatientSuccess({ changes: verifyResponse, id: action.payload.id });
        }),
        catchError(err => {
          this.store.dispatch(
            new errorActions.AddError({
              error: {
                id: uuid(),
                created: new Date(),
                content: err,
                source: action
              }
            })
          );
          return empty();
        })
      );
    })
  );

  @Effect()
  addMedication$ = this.actions$.pipe(
    ofType(PatientActionTypes.AddMedication),
    switchMap((action: AddMedication) => {
      return this.api.postPatientMedication(action.payload.id, action.payload.item).pipe(
        map(verifyResponse => {
          return new AddMedicationSuccess({ changes: { medication: verifyResponse }, id: action.payload.id });
        }),
        catchError(err => {
          this.store.dispatch(
            new errorActions.AddError({
              error: {
                id: uuid(),
                created: new Date(),
                content: err,
                source: action
              }
            })
          );
          return empty();
        })
      );
    })
  );

  @Effect()
  removeMedication$ = this.actions$.pipe(
    ofType(PatientActionTypes.RemoveMedication),
    switchMap((action: RemoveMedication) => {
      return this.api.removePatientMedication(action.payload.id, action.payload.medicineId).pipe(
        map(verifyResponse => {
          return new RemoveMedicationSuccess({ changes: verifyResponse, id: verifyResponse._id });
        }),
        catchError(err => {
          this.store.dispatch(
            new errorActions.AddError({
              error: {
                id: uuid(),
                created: new Date(),
                content: err,
                source: action
              }
            })
          );
          return empty();
        })
      );
    })
  );

  @Effect()
  addAction$ = this.actions$.pipe(
    ofType(PatientActionTypes.AddAction),
    switchMap((action: AddAction) => {
      return this.api.postPatientAction(action.payload.id, action.payload.colorZone, action.payload.item).pipe(
        map(verifyResponse => {
          return new AddActionSuccess({ changes: { actionPlan: verifyResponse }, id: action.payload.id });
        }),
        catchError(err => {
          this.store.dispatch(
            new errorActions.AddError({
              error: {
                id: uuid(),
                created: new Date(),
                content: err,
                source: action
              }
            })
          );
          return empty();
        })
      );
    })
  );

  @Effect()
  updateAction$ = this.actions$.pipe(
    ofType(PatientActionTypes.UpdateAction),
    switchMap((action: UpdateAction) => {
      return this.api
        .patchPatientAction(action.payload.id, action.payload.actionId, action.payload.colorZone, action.payload.item)
        .pipe(
          map(verifyResponse => {
            return new UpdateActionSuccess({ changes: { actionPlan: verifyResponse }, id: action.payload.id });
          }),
          catchError(err => {
            this.store.dispatch(
              new errorActions.AddError({
                error: {
                  id: uuid(),
                  created: new Date(),
                  content: err,
                  source: action
                }
              })
            );
            return empty();
          })
        );
    })
  );

  @Effect()
  removeAction$ = this.actions$.pipe(
    ofType(PatientActionTypes.RemoveAction),
    switchMap((action: RemoveAction) => {
      return this.api.removePatientAction(action.payload.id, action.payload.colorZone, action.payload.actionId).pipe(
        map(verifyResponse => {
          return new RemoveActionSuccess({ changes: { actionPlan: verifyResponse }, id: action.payload.id });
        }),
        catchError(err => {
          this.store.dispatch(
            new errorActions.AddError({
              error: {
                id: uuid(),
                created: new Date(),
                content: err,
                source: action
              }
            })
          );
          return empty();
        })
      );
    })
  );

  @Effect()
  updateActionPlan$ = this.actions$.pipe(
    ofType(PatientActionTypes.UpdateActionPlan),
    switchMap((action: UpdateActionPlan) => {
      return this.api.patchPatientActionPlan(action.payload.id, action.payload.colorZone, action.payload.patch).pipe(
        map(verifyResponse => {
          return new UpdateActionPlanSuccess({ changes: { actionPlan: verifyResponse }, id: action.payload.id });
        }),
        catchError(err => {
          this.store.dispatch(
            new errorActions.AddError({
              error: {
                id: uuid(),
                created: new Date(),
                content: err,
                source: action
              }
            })
          );
          return empty();
        })
      );
    })
  );

  @Effect()
  updateActionOrder$ = this.actions$.pipe(
    ofType(PatientActionTypes.UpdateActionOrder),
    switchMap((action: UpdateActionOrder) => {
      return this.api.changePatientActionOrder(action.payload.id, action.payload.colorZone, { order: action.payload.order }).pipe(
        map(verifyResponse => {
          return new UpdateActionOrderSuccess({ changes: { actionPlan: verifyResponse }, id: action.payload.id });
        }),
        catchError(err => {
          this.store.dispatch(
            new errorActions.AddError({
              error: {
                id: uuid(),
                created: new Date(),
                content: err,
                source: action
              }
            })
          );
          return empty();
        })
      );
    })
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(PatientActionTypes.DeletePatient),
    switchMap((action: DeletePatient) => {
      return this.api.deletePatient(action.payload.id).pipe(
        map(() => {
          return new DeletePatientSuccess({ id: action.payload.id });
        }),
        catchError(err => {
          this.store.dispatch(
            new errorActions.AddError({
              error: {
                id: uuid(),
                created: new Date(),
                content: err,
                source: action
              }
            })
          );
          return empty();
        })
      );
    })
  );

  @Effect()
  archive$ = this.actions$.pipe(
    ofType(PatientActionTypes.ArchivePatient),
    switchMap((action: ArchivePatient) => {
      console.log('archive patient');
      return this.api.archivePatient(action.payload.id, action.payload.reason).pipe(
        map((patient: Patient) => {
          console.log('archive effect', patient)
          return new ArchivePatientSuccess({changes: patient, id: action.payload.id});
        }),
        catchError(err => {
          this.store.dispatch(
            new errorActions.AddError({
              error: {
                id: uuid(),
                created: new Date(),
                content: err,
                source: action
              }
            })
          );
          return empty();
        })
      );
    })
  );

  @Effect()
  unarchive$ = this.actions$.pipe(
    ofType(PatientActionTypes.UnarchivePatient),
    switchMap((action: UnarchivePatient) => {
      return this.api.unarchivePatient(action.payload.id,).pipe(
        map((patient: Patient) => {
          return new UnarchivePatientSuccess({changes: patient, id: action.payload.id});
        }),
        catchError(err => {
          this.store.dispatch(
            new errorActions.AddError({
              error: {
                id: uuid(),
                created: new Date(),
                content: err,
                source: action
              }
            })
          );
          return empty();
        })
      );
    })
  );

  @Effect()
  setChatResolved$ = this.actions$.pipe(
    ofType(PatientActionTypes.SetChatResolved),
    switchMap((action: SetChatResolved) => {
      return this.api.setChatResolved(action.payload.id).pipe(
        map(patient => {
          return new SetChatResolvedSuccess({ changes: patient, id: patient._id });
        }),
        catchError(err => {
          this.store.dispatch(
            new errorActions.AddError({
              error: {
                id: uuid(),
                created: new Date(),
                content: err,
                source: action
              }
            })
          );
          return empty();
        })
      );
    })
  );
  @Effect()
  invite$ = this.actions$.pipe(
    ofType(PatientActionTypes.Invite),
    switchMap((action: Invite) => {
      return this.api.invitePatient(action.payload.id).pipe(
        map(() => {
          return new InviteSuccess({ changes: { inConcept: false, isPending: true }, id: action.payload.id });
        }),
        catchError(err => {
          this.store.dispatch(
            new errorActions.AddError({
              error: {
                id: uuid(),
                created: new Date(),
                content: err,
                source: action
              }
            })
          );
          return empty();
        })
      );
    })
  );
  constructor(private actions$: Actions, private api: ApiService, private store: Store<fromRoot.State>) { }
}
