import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

import * as fromRoot from '../../store';
import * as authAdminActions from '../../store/authAdmin/authAdmin.actions';
import * as errorActions from '../../store/error/error.actions';
import { Tokens } from '../../store/auth/auth.model';
import { take, map, filter, delay, skip, tap, takeLast } from 'rxjs/operators';

import { StoreError } from '../../store/error/error.model';
import { Observable, Subscription } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/dialogs/confirm-dialog/confirm-dialog.component';
import { Title } from '@angular/platform-browser';
import { VerificationToken } from 'src/app/store/authAdmin/authAdmin.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.scss']
})
export class AdminLoginPageComponent implements OnInit, OnDestroy {
  adminloginForm: FormGroup;
  adminVerificationForm: FormGroup;
  message: string;
  loading = false;
  verifyLoading = false;
  tokens$: Observable<Tokens>;
  error$: Observable<StoreError>;
  verifyError$: Observable<StoreError>;
  verifcation$: Observable<VerificationToken>;
  subs: Subscription[] = [];
  returnUrl: string;
  public loggedIn$: Observable<boolean>;

  constructor(
    public dialog: MatDialog,
    private store: Store<fromRoot.State>,
    private router: Router,
    private formBuilder: FormBuilder,
    private jwtHelper: JwtHelperService,
    private titleService: Title,
	private translateService: TranslateService
  ) { }

  ngOnInit() {
	// TODO: this title needs to be changed
    this.titleService.setTitle('LungBeat Admin Login');
    this.store.dispatch(new errorActions.ClearErrors());

    this.error$ = this.store
      .select(fromRoot.getErrors).pipe(skip(1), filter(errors => {
        return errors !== undefined && errors[0] && errors[0].type === authAdminActions.AuthActionTypes.AdminLogin;
      }), map(errors => errors[0]));

      this.verifyError$ = this.store
      .select(fromRoot.getErrors).pipe(skip(1), filter(errors => {
        return errors !== undefined && errors[0] && errors[0].type === authAdminActions.AuthActionTypes.AdminVerify;
      }), map(errors => errors[0]));

    this.adminloginForm = this.formBuilder.group({
      email: [''],
      password: ['']
    });

    this.adminVerificationForm = this.formBuilder.group({
      pin: [null, [Validators.required, Validators.maxLength(4), Validators.minLength(4), Validators.pattern(/^[0-9]\d*$/)]]
    })

    this.verifcation$ = this.store.select(fromRoot.getAdminVerification).pipe(filter(t => t !== undefined));

    this.loggedIn$ = this.store
      .select(fromRoot.getAdminTokens)
      .pipe(
        tap((t) => console.log(t)),
        map(tokens => tokens && tokens.accesstoken && this.jwtHelper.isTokenExpired(tokens.accesstoken) === false));

    this.subs.push(this.loggedIn$
      .pipe(
        tap((t) => console.log(t)),
        filter(loggedin => loggedin === true),
        delay(500)
      )
      .subscribe(loggedIn => {
        if (loggedIn) {
          // You are logged in
          this.router.navigate(['admin']);
        }
      }));
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

  get pin() {
    return this.adminVerificationForm.get('pin');
  }

  get email() {
    return this.adminloginForm.get('email');
  }

  get password() {
    return this.adminloginForm.get('password');
  }

  adminLogin() {
    const email = this.adminloginForm.value.email;
    const password = this.adminloginForm.value.password;
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.subs.push(this.error$.pipe(
      take(1)
    ).subscribe(err => {
      this.displayError(err, this.translateService.instant('adminLogin.unknownCombinationError')); // 'E-mail / wachtwoord combinatie niet bekend.');
      this.loading = false;
    }));

    this.store.dispatch(new authAdminActions.AdminLoginAction({ email, password }));

  }

  adminVerify(token: string) {
    console.log('adminVerify')
    const pin = this.adminVerificationForm.get('pin').value;

    if (this.verifyLoading) {
      return;
    }


    console.log('adminVerify', token, pin);

    this.verifyLoading = true;
    this.subs.push(this.verifyError$.pipe(
      take(1)
    ).subscribe(err => {
      this.displayError(err, this.translateService.instant('adminLogin.invalidCode')); //'Verificatie code is niet geldig');
      this.verifyLoading = false;
    }));

    this.store.dispatch(new authAdminActions.AdminVerifyAction({ token, pin }));

  }

  displayError(err: StoreError, message: string) {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        messageOnly: true,
        title: 'Error',
        message:
          err.status === 401 ? message : this.translateService.instant('adminLogin.unknownError')
      }
    });
  }
}
