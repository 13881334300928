import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, Inject, OnInit } from '@angular/core';
import * as fromRoot from '../../store';
import { Store } from '@ngrx/store';
import { OptionsDialogOption } from 'src/app/store/shared.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-options-dialog',
  templateUrl: './options-dialog.component.html',
  styleUrls: ['./options-dialog.component.scss']
})
export class OptionsDialogComponent implements OnInit {
  selectedOption: OptionsDialogOption;
  otherInputValue: string;

  constructor(
	private translateService: TranslateService,
    public dialogRef: MatDialogRef<OptionsDialogComponent>,
    private store: Store<fromRoot.State>,
    @Inject(MAT_DIALOG_DATA) public data: {
      options?: OptionsDialogOption[],
      title?: string,
      description?: string,
      confirmText?: string,
      cancelText?: string,
    }
  ) { }

  ngOnInit() {
    if (!this.data) {
      this.data = {};
    }

    if (!this.data.title) {
      this.data.title = this.translateService.instant('optionsDialog.selectAnOption') //'Selecteer een optie'; 
    }

    if (!this.data.confirmText) {
      this.data.confirmText = this.translateService.instant('optionsDialog.select');
    }
  }

  get submitDisabled() {
    if (this.selectedOption !== undefined) {
      if (this.selectedOption.value === 'other') {
        return this.otherInputValue === undefined || this.otherInputValue.length <= 4;
      }
      return false;
    }
    return true;
  }

  select() {
    let selectedReason: OptionsDialogOption;

    if (!this.selectedOption) {
      return;
    }

    if (this.selectedOption.value === 'other') {
      selectedReason = {
        title: this.selectedOption.title || this.translateService.instant('optionsDialog.other'),
        value: this.selectedOption.value,
        description: this.otherInputValue
      }
    } else {
      selectedReason = this.selectedOption;
    }

    this.dialogRef.close(selectedReason);
  }
}
