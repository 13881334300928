import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import * as fromRoot from '../../store';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit, OnDestroy {
  formString: string;


  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<fromRoot.State>,
	private translateService: TranslateService
  ) { }

  ngOnInit() {
    if (!this.data) {
      this.data = {};
    }
    if (!this.data.title) {
      this.data.title = this.translateService.instant('confirmDialog.title'); // 'Waarschuwing!';
    }
    if (!this.data.message) {
      this.data.message = this.translateService.instant('confirmDialog.message'); //'Weet u zeker dat u dit wilt doen? Deze actie kan niet teruggedraaid worden!';
    }
  }

  ngOnDestroy() { }
}
