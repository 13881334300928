import { Component, Input, EventEmitter, Output, OnInit, OnChanges, SimpleChanges } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "app-toggle",
	templateUrl: "./toggle.component.html",
	styleUrls: ["./toggle.component.scss"],
})
export class ToggleComponent implements OnInit, OnChanges {
	@Input() onText = "Aan";
	@Input() offText = "Uit";
	@Input() value: boolean;
	@Output() valueChange = new EventEmitter();

	constructor(private translateService: TranslateService) { }

	ngOnChanges(changes: SimpleChanges): void {
		this.onText = this.translateService.instant('toggle.onText');
		this.offText = this.translateService.instant('toggle.offText');
	}

	ngOnInit(): void {
		this.onText = this.translateService.instant('toggle.onText');
		this.offText = this.translateService.instant('toggle.offText');
	}

	toggle() {
		this.valueChange.emit(!this.value);
	}
}
