import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { switchMap, map, catchError, mergeMap } from "rxjs/operators";
import {
  CareProviderActionTypes,
  AddCareProvider,
  AddCareProviderSuccess,
  LoadCareProviders,
  LoadCareProvidersSuccess,
  UpdateCareProviderSuccess,
  UpdateCareProvider,
  GetOne,
  GetOneSuccess,
  Select,
  DeleteCareProvider,
  DeleteCareProviderSuccess
} from "./careprovider.actions";
import { ApiService } from "../../services/api.service";

import * as errorActions from "../error/error.actions";
import * as fromRoot from "../";
import { Store } from "@ngrx/store";

import { v4 as uuid } from "uuid";
import { empty } from "rxjs";
import { CareProvider } from "./careprovider.model";

@Injectable()
export class CareProviderEffects {
  @Effect()
  add$ = this.actions$.pipe(
    ofType(CareProviderActionTypes.AddCareProvider),
    switchMap((action: AddCareProvider) => {
      return this.api.createCareProviderAdmin(action.payload.careProvider).pipe(
        mergeMap((response: CareProvider) => {
          return [
            new Select(response._id),
            new AddCareProviderSuccess({ item: response })
          ];
        }),
        catchError(err => {
          this.store.dispatch(
            new errorActions.AddError({
              error: {
                id: uuid(),
                created: new Date(),
                content: err,
                source: action
              }
            })
          );
          return empty();
        })
      );
    })
  );

  @Effect()
  getOne$ = this.actions$.pipe(
    ofType(CareProviderActionTypes.GetOne),
    switchMap((action: GetOne) => {
      return this.api.getCareProviderAdmin(action.payload.careProviderId).pipe(
        map((verifyResponse: CareProvider) => {
          return new GetOneSuccess(verifyResponse);
        }),
        catchError(err => {
          this.store.dispatch(
            new errorActions.AddError({
              error: {
                id: uuid(),
                created: new Date(),
                content: err,
                source: action
              }
            })
          );
          return empty();
        })
      );
    })
  );

  @Effect()
  load$ = this.actions$.pipe(
    ofType(CareProviderActionTypes.LoadCareProviders),
    mergeMap((action: LoadCareProviders) => {
      const apiCall = this.api.getAllCareProvidersAdmin();
      return apiCall.pipe(
        map((careProviders: CareProvider[]) => {
          return new LoadCareProvidersSuccess({ items: careProviders });
        }),
        catchError(err => {
          this.store.dispatch(
            new errorActions.AddError({
              error: {
                id: uuid(),
                created: new Date(),
                content: err,
                source: action
              }
            })
          );
          return empty();
        })
      );
    })
  );

  @Effect()
  update$ = this.actions$.pipe(
    ofType(CareProviderActionTypes.UpdateCareProvider),
    switchMap((action: UpdateCareProvider) => {
      return this.api
        .patchCareProviderAdmin(
          action.payload.careProviderId,
          action.payload.careProvider
        )
        .pipe(
          map(verifyResponse => {
            return new UpdateCareProviderSuccess({
              changes: verifyResponse,
              id: action.payload.careProviderId
            });
          }),
          catchError(err => {
            this.store.dispatch(
              new errorActions.AddError({
                error: {
                  id: uuid(),
                  created: new Date(),
                  content: err,
                  source: action
                }
              })
            );
            return empty();
          })
        );
    })
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(CareProviderActionTypes.DeleteCareProvider),
    switchMap((action: DeleteCareProvider) => {
      return this.api
        .deleteCareProviderAdmin(action.payload.careProviderId)
        .pipe(
          map(() => {
            return new DeleteCareProviderSuccess({
              id: action.payload.careProviderId
            });
          }),
          catchError(err => {
            this.store.dispatch(
              new errorActions.AddError({
                error: {
                  id: uuid(),
                  created: new Date(),
                  content: err,
                  source: action
                }
              })
            );
            return empty();
          })
        );
    })
  );

  constructor(
    private actions$: Actions,
    private api: ApiService,
    private store: Store<fromRoot.State>
  ) {}
}
