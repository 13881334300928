import { Component, OnInit, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromRoot from "../../store";
import * as authActions from "../../store/auth/auth.actions";
import * as patientActions from "../../store/patient/patient.actions";
import * as messageActions from "../../store/message/message.actions";
import { Router } from "@angular/router";
import { filter, take, map } from "rxjs/operators";
import { StoreError } from "../../store/error/error.model";
import {
	Observable,
	Subscription,
	interval,
	Subject,
	combineLatest,
} from "rxjs";
import { Org } from "src/app/store/org/org.model";
import { Team } from "src/app/store/team/team.model";
import { TranslateService } from "@ngx-translate/core";
import { MatSelectChange } from "@angular/material/select";

@Component({
	selector: "app-home",
	templateUrl: "./home.component.html",
	styleUrls: ["./home.component.scss"],
})
export class HomePageComponent implements OnInit, OnDestroy {
	public currentLang: string;
	public languages: string[];

	constructor(
		private store: Store<fromRoot.State>,
		private router: Router,
		private translateService: TranslateService) {
		this.languages = this.translateService.getLangs();
		this.currentLang = this.translateService.currentLang;
	}

	alertCount$: Observable<number>;
	updateSub: Subscription;
	currentTeam$: Observable<{ organization: Org; team: Team }>;
	destroy$ = new Subject<boolean>();

	onLanguageChange(e: MatSelectChange) {
		const newLang: string = e.value;

		this.currentLang = e.value;
		this.translateService.use(newLang);
		localStorage.setItem("defaultLang", newLang);
	}
	
	ngOnInit() {
		this.alertCount$ = this.store.select(fromRoot.getAlertAndMessagesCount);
		this.store.dispatch(new patientActions.LoadChats());
		this.store.dispatch(
			new patientActions.LoadPatients({ unresolvedOnly: true })
		);

		this.currentTeam$ = combineLatest(
			this.store.select(fromRoot.getCareProvider),
			this.store.select(fromRoot.getCareProviderSelectedTeam)
		).pipe(
			filter(([careProvider, selectedTeam]) => {
				return (
					careProvider !== undefined &&
					careProvider.email !== undefined &&
					careProvider.teams.length > 1 &&
					selectedTeam !== undefined
				);
			}),
			take(1),
			map(([careProvider, selectedTeam]) => {
				const organization = ((careProvider.organizations as unknown) as Org[]).find(
					(org) => org._id === selectedTeam.orgId
				);
				const team = ((careProvider.teams as unknown) as Team[]).find(
					(t) => t._id === selectedTeam.teamId
				);
				return { organization, team };
			})
		);
		this.updateSub = interval(10000).subscribe(async () => {
			this.store.dispatch(
				new patientActions.LoadPatients({ unresolvedOnly: true })
			);
		});
	}

	ngOnDestroy() {
		if (this.updateSub) {
			this.updateSub.unsubscribe();
		}
	}

	logout() {
		this.store.dispatch(new authActions.LogoutAction());

		const loggedIn$ = this.store
			.select(fromRoot.getTokens)
			.pipe(map((tokens) => tokens && tokens.accesstoken));

		loggedIn$
			.pipe(
				filter((loggedin) => loggedin === undefined),
				take(1)
			)
			.subscribe((loggedIn) => {
				if (!loggedIn) {
					// You are logged in
					this.router.navigate(["/login"]);
				}
			});
	}

	switchTeams() {
		this.store.dispatch(new patientActions.ClearPatients());
		this.store.dispatch(new messageActions.ClearMessages());
		this.router.navigate(["/switcher"]);
	}
}
