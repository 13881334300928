declare var require: any;
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { MedicineActionTypes, LoadMedicines, LoadMedicinesSuccess } from './medicine.actions';
import { ApiService } from '../../services/api.service';

import * as fromRoot from '../';
import { Store } from '@ngrx/store';
import { Medicine } from './medicine.model';
const medicines = require('./../../shared/medicines.json') as Medicine[];

@Injectable()
export class MedicineEffects {
  @Effect()
  load$ = this.actions$.pipe(
    ofType(MedicineActionTypes.LoadMedicines),
    map((action: LoadMedicines) => {
      return new LoadMedicinesSuccess({ items: medicines });
    })
  );

  constructor(private actions$: Actions, private api: ApiService, private store: Store<fromRoot.State>) {}
}
