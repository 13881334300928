import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { ColorZone } from 'src/app/store/org/org.model';

@Component({
  selector: 'app-input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.scss']
})
export class InputDialogComponent implements OnInit {
  value: string;

  constructor(@Inject(MAT_DIALOG_DATA)
  public data: {
    title: string,
    confirmBtnText: string;
    cancelBtnText: string;
    placeholderText: string;
    colorZone: ColorZone,
  }, private dialogRef: MatDialogRef<InputDialogComponent>,
  private translateService: TranslateService 
) { }

  ngOnInit() {

    if (!this.data.confirmBtnText) {
      this.data.confirmBtnText = this.translateService.instant('button.save'); // 'Opslaan'
    }

    if (!this.data.cancelBtnText) {
      this.data.cancelBtnText = this.translateService.instant('button.cancelS');// 'Annuleer'
    }
  }

  close() {
    this.dialogRef.close();
  }

  add() {
    this.dialogRef.close({value: this.value})
  }

}
