import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

import * as fromRoot from '../../store';
import * as authActions from '../../store/auth/auth.actions';
import * as errorActions from '../../store/error/error.actions';
import { Tokens } from '../../store/auth/auth.model';
import { take, map, filter, delay, skip } from 'rxjs/operators';

import { StoreError } from '../../store/error/error.model';
import { Observable, Subscription } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/dialogs/confirm-dialog/confirm-dialog.component';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-request-password-change',
  templateUrl: './request-password-change.component.html',
  styleUrls: ['./request-password-change.component.scss']
})
export class RequestPasswordChangePageComponent implements OnInit, OnDestroy {
  requestPasswordChangeForm: FormGroup;
  message: string;
  loading = false;
  tokens$: Observable<Tokens>;
  error$: Observable<StoreError>;
  tokensSub: Subscription;
  errorSub: Subscription;
  formSub: Subscription;
  returnUrl: string;
  success: boolean;
  public loggedIn$: Observable<boolean>;

  constructor(
    public dialog: MatDialog,
    public api: ApiService,
    private store: Store<fromRoot.State>,
    private router: Router,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.requestPasswordChangeForm = this.formBuilder.group({
      email: ['']
    });
    this.formSub = this.requestPasswordChangeForm.valueChanges.subscribe(_ =>
      this.success = false
    );
  }

  ngOnDestroy() {
    if (this.formSub) {
      this.formSub.unsubscribe();
    }
  }

  get email() {
    return this.requestPasswordChangeForm.get('email');
  }

  async requestPasswordChange() {
    const email = this.requestPasswordChangeForm.value.email;
    if (this.loading) {
      return;
    }
    this.loading = true;
    try {
      await this.api.requestPasswordChange(email).toPromise();
      this.success = true;
    } catch (e) {
      this.success = false;
      console.log(e);
    }
    this.loading = false;
  }
}
