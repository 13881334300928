import { take } from "rxjs/operators";
import { ApiService } from "src/app/services/api.service";
import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-admin-users",
  templateUrl: "./admin-users.component.html",
  styleUrls: ["./admin-users.component.scss"],
})
export class AdminUsersComponent implements OnInit {
  studyNumber: string;
  downloadJsonHref: SafeUrl;
  downloadTextString: string;
  resultText: string;
  loading = false;

  constructor(
    private apiService: ApiService,
    private sanitizer: DomSanitizer,
	private translateService: TranslateService
  ) {}

  ngOnInit() {}

  async getUsers() {
    this.loading = true;
    const users = await this.apiService
      .getUsersByStudyNumber(this.studyNumber)
      .pipe(take(1))
      .toPromise();

    if (users && users.length > 0) {
    //   this.resultText = `${users.length} patiënten gevonden met studienummer ${this.studyNumber}`;
      this.resultText = `${users.length} ${this.translateService.instant('adminUsers.resultsFound')} ${this.studyNumber}`;
    } else {
    //   this.resultText = `Geen patiënten gevonden met studienummer ${this.studyNumber}`;
      this.resultText = `${this.translateService.instant('adminUsers.noResults')} ${this.studyNumber}`;
      return;
    }

    this.downloadTextString = "study-" + this.studyNumber + "-users.json";
    this.generateDownloadJsonUri(users);
    this.loading = false;
  }

  async getAllUsers() {
    this.loading = true;
    const users = await this.apiService
      .getAllUsersWithStudyNumber()
      .pipe(take(1))
      .toPromise();

    if (users && users.length > 0) {
    //   this.resultText = `${users.length} patiënten gevonden met een studienummer.`;
      this.resultText = `${users.length} ${this.translateService.instant('adminUsers.resultsFound')}.`;
    } else {
    //   this.resultText = `Geen patiënten gevonden met een studienummer.`;
      this.resultText = `${this.translateService.instant('adminUsers.noResults')} ${this.studyNumber}`;
      return;
    }

    const now = new Date().toISOString();
    this.downloadTextString = "all-patients-with-studynumber-" + now + ".json";
    this.generateDownloadJsonUri(users);
    this.loading = false;
  }

  async getAllACSResults() {
    this.loading = true;
    const results = await this.apiService
      .getAllACSResults()
      .pipe(take(1))
      .toPromise();

    if (results && results.length > 0) {
    //   this.resultText = `${results.length} resultaten gevonden.`;
      this.resultText = `${results.length} ${this.translateService.instant('adminUsers.acsResultsFound')}.`;
    } else {
    //   this.resultText = `Geen resultaten gevonden.`;
      this.resultText = `${this.translateService.instant('adminUsers.noACSResults')}`;
      return;
    }

    const now = new Date().toISOString();
    this.downloadTextString = "all-acs-results-" + now + ".json";
    this.generateDownloadJsonUri(results);
    this.loading = false;
  }

  generateDownloadJsonUri(response) {
    var theJSON = JSON.stringify(response);
    var uri = this.sanitizer.bypassSecurityTrustUrl(
      "data:text/json;charset=UTF-8," + encodeURIComponent(theJSON)
    );
    this.downloadJsonHref = uri;
  }

  onChange() {
    this.downloadJsonHref = undefined;
    this.resultText = undefined;
  }
}
