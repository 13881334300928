import { Selectors } from './../../store/root';
import { PatientDialogComponent } from './../../dialogs/patient-dialog/patient-dialog.component';

import { Component, OnInit } from '@angular/core';

import * as fromRoot from '../../store';
import * as patientActions from '../../store/patient/patient.actions';
import * as moment from 'moment';
import { Store } from '@ngrx/store';

import { Patient } from '../../store/patient/patient.model';
import { MatDialogRef, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { getColor } from './../../util/helper-functions';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-patients',
  templateUrl: './patients.component.html',
  styleUrls: ['./patients.component.scss']
})
export class PatientsViewComponent implements OnInit {
  selectedFilter: 'active' | 'archived' = 'active';
  allPatients$: Observable<Patient[]>;
  archivedPatients$: Observable<Patient[]>;
  activePatients$: Observable<Patient[]>;
  patients: Patient[];
  filterValue: string;
  patientDialog: MatDialogRef<PatientDialogComponent>;
  getColor = getColor;

  constructor(private store: Store<fromRoot.State>, private dialog: MatDialog, private router: Router) { }

  ngOnInit() {
    this.store.dispatch(new patientActions.LoadPatients());

    this.allPatients$ = this.store.select(Selectors.getPatients);

    this.archivedPatients$ = this.store.select(Selectors.getArchivedPatients).pipe(filter(patients => patients !== undefined), map(patients => {
      return this.sortAlphabetically(patients)
    }))

    this.activePatients$ = this.store.select(Selectors.getActivePatients).pipe(filter(patients => patients !== undefined), map(patients => {
      return this.sortAlphabetically(patients)
    }));
  }

  applyFilter(filterValue: string) {
    this.filterValue = filterValue;
  }

  editPatient(patient: Patient) {
    this.router.navigate(['patienten/' + patient._id]);
  }

  createPatient() {
    this.patientDialog = this.dialog.open(PatientDialogComponent, { data: {} });
  }

  // returns true if lastACS.createdAt > 8 days
  skippedACS(createdAt: Date): boolean {
    return Date.now() - createdAt.getTime() > 8 * 24 * 60 * 60 * 1000;
  }

  getLastACSDate(date: Date): string {
    const currentDate = moment(date);
    const today = moment().startOf('day');
    const yesterday = moment()
      .subtract(1, 'days')
      .startOf('day');
    const lastWeek = moment()
      .subtract(7, 'days')
      .startOf('day');

    if (currentDate.isSame(today, 'day')) {
      return 'vandaag';
    } else if (currentDate.isSame(yesterday, 'day')) {
      return 'gisteren';
    } else if (currentDate.isSame(lastWeek, 'day')) {
      return currentDate.format(`dddd 'YY`);
    } else {
      return currentDate.format(`D MMM 'YY`);
    }
  }

  sortAlphabetically(patients: Patient[]): Patient[] {
    return patients.sort((patientA, patientB) => {
      // Sort by alphabetically
      const nameA = patientA.lastName.toLowerCase();
      const nameB = patientB.lastName.toLowerCase();
      return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
    });
  }

  selectFilter(filter: 'active' | 'archived') {
    this.selectedFilter = filter;
  }
 }
