export interface PatientAction {
  _id: string;
  __t:
    | "UserActionACS"
    | "UserActionGeneral"
    | "UserActionSelfHelp"
    | "UserActionMeasurement";
  user: string;
  title?: string;
  name?: GeneralAction;
  result?: number;
  colorZone?: ColorZone;
  acsForm?: string | any;
  selfHelpForm?: any;
  measurementResult?: MeasurementResult;
  createdAt: Date;
  resolutionRequired?: boolean;
  resolvedAt: Date;
  generalAction?: {
    title?: string;
    name?: GeneralAction;
    message?: string;
  }
}

export interface MeasurementResult {
  _id: string;
  referenceId?: string;
  score?: number;
  createdAt: Date;
  deviceResult?: {
    device: string;
    deviceId: string;
    spirometerMeasurementResult: SpirometryMeasurementResult;
  };
  measurement: string;
  measurementResultType: string;
  name: MeasurementResultTypes.ACS | MeasurementResultTypes.ACS_VALIDATION | MeasurementResultTypes.SPIROMETER | MeasurementResultTypes.ACTIONPLAN | MeasurementResultTypes.COVID19;
  questions: any; // Make model for questions
  resultType: string;
  team: string;
  title: string;
  user: string;
  calculatedResult: number;
}

export interface SpirometryMeasurementResult {
  bestFev1TestResultIndex: number;
	bestFvcTestResultIndex: number;
  patientNote?: string;
  turbine?: "reusable" | "disposable" | "unknown";
  testType?: string;
  spirometerTestResults: SpirometerTestResult[];
}

export interface SpirometerTestResult {
  pefLs: number; // Peakflow, Ls
  fev1L: number; // Forced exp vol at 1th sec in L
  fvcL: number; // Forced exp Capacity in cL
  fev1FvcPct: number; // Fev1% in percentage
  fev6L: number; // Forced exp volume at 6th sec in L
  fef2575Ls: number; // Max mid-expiratory flow in L/sec
  eVolMl: number; // Extrapolated volume in mL
  pefTimeSec: number; // time to reach Peakfow in sec

  // Predicted target
  pefLsTarget: number;
  fev1LTarget: number;
  fvcLTarget: number;
  fev1FvcPctTarget: number;
  fev6LTarget: number;
  fef2575LsTarget: number;

  // % Predicted
  pefPctPred: number;
  fev1PctPred: number;
  fvcPctPred: number;
  fev1FvcPctPctPred:number;
  fev6PctPred: number;
  fef2575PctPred: number;

  qualityMessage: QualityMessage;
}

export enum MeasurementResultTypes {
 ACS = 'acs',
 ACS_VALIDATION = 'acs_validation',
 SPIROMETER = 'spirometer',
 ACTIONPLAN = 'action_plan_triage',
 COVID19 = 'covid19'
}

export type QualityMessage =
  | QualityMessageTypes.DONT_HESITATE
  | QualityMessageTypes.BLOW_OUT_FASTER
  | QualityMessageTypes.BLOW_OUT_LONGER
  | QualityMessageTypes.ABRUPT_END
  | QualityMessageTypes.GOOD_BLOW
  | QualityMessageTypes.DONT_START_TOO_EARLY
  | QualityMessageTypes.AVOID_COUGHING;

  export enum QualityMessageTypes {
    DONT_HESITATE = "dontHesitate",
    BLOW_OUT_FASTER = "blowOutFaster",
    BLOW_OUT_LONGER = "blowOutLonger",
    ABRUPT_END = "abruptEnd",
    GOOD_BLOW = "goodBlow",
    DONT_START_TOO_EARLY = "dontStartTooEarly",
    AVOID_COUGHING = "avoidCoughing"
  }


  export const QualityMessageLabels = [
    {
      message: QualityMessageTypes.DONT_HESITATE,
      label: 'Patiënt heeft inconsequent geblazen'
    },
    {
      message: QualityMessageTypes.BLOW_OUT_FASTER,
      label: 'Patiënt heeft te langzaam geblazen'
    },
    {
      message: QualityMessageTypes.BLOW_OUT_LONGER,
      label: 'Patiënt heeft te kort geblazen'
    }
    ,{
      message: QualityMessageTypes.ABRUPT_END,
      label: 'Test is abrupt beëindigd'
    }
    ,{
      message: QualityMessageTypes.GOOD_BLOW,
      label: 'Geslaagd'
    }
    ,{
      message: QualityMessageTypes.DONT_START_TOO_EARLY,
      label: 'Patiënt heeft te vroeg geblazen'
    },
    {
      message: QualityMessageTypes.AVOID_COUGHING,
      label: 'Patiënt heeft gehoest'
    }
  ]


export type ColorZone = "green" | "yellow" | "orange" | "red";

export enum GeneralActionTypes {
  INVITATION_NOT_ACCEPTED = "invitationNotAccepted",
  QUESTIONNAIRE_CHAT_REMINDER = "questionnaireChatReminder",
  ACCOUNT_REMOVAL_REMINDER = "accountRemovalReminder",
  PATIENT_ARCHIVED = "patientArchived",
  FIRST_TIME_VERIFIED = "firstTimeVerified"
}

export type GeneralAction = GeneralActionTypes.FIRST_TIME_VERIFIED | GeneralActionTypes.PATIENT_ARCHIVED | GeneralActionTypes.INVITATION_NOT_ACCEPTED | GeneralActionTypes.QUESTIONNAIRE_CHAT_REMINDER | GeneralActionTypes.ACCOUNT_REMOVAL_REMINDER;
