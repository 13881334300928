import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MonitoringSettings } from './../../store';
import { StoreError } from '../../store/error/error.model';
import { filter, take, map } from 'rxjs/operators';
import * as fromRoot from '../../store';
import * as errorActions from '../../store/error/error.actions';
import * as settingsActions from '../../store/settings/settings.actions';
import { Observable } from 'rxjs';
import { Team } from 'src/app/store/team/team.model';
import { WEEKDAYS } from '../../store';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-monitoring-settings-dialog',
  templateUrl: './monitoring-settings-dialog.component.html',
  styleUrls: ['./monitoring-settings-dialog.component.scss'],
})
export class MonitoringSettingsDialogComponent implements OnInit, OnDestroy {
  dialogForm: FormGroup;
  dayForm: FormArray;
  isPosting: boolean;
  errorMessage: string;
  team$: Observable<Team>;
  weekdays = WEEKDAYS;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { monitoringSettings: MonitoringSettings },
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<MonitoringSettingsDialogComponent>,
    private store: Store<fromRoot.State>,
    private formBuilder: FormBuilder,
	private translateService: TranslateService
  ) {
	this.weekdays = Object.values(this.translateService.instant('monitoringSettings.days'))
  }

  ngOnDestroy() {
    this.store.dispatch(new errorActions.ClearErrors());
  }

  ngOnInit() {
    this.isPosting = false;

    this.store.dispatch(new errorActions.ClearErrors());
    let monitoringSettings = this.data.monitoringSettings
      ? (this.data.monitoringSettings as MonitoringSettings)
      : undefined;
    if (!monitoringSettings) {
      monitoringSettings = {
        days: [
          { maxPatients: 0, weekDay: 0 },
          { maxPatients: 0, weekDay: 1 },
          { maxPatients: 0, weekDay: 2 },
          { maxPatients: 0, weekDay: 3 },
          { maxPatients: 0, weekDay: 4 },
          { maxPatients: 0, weekDay: 5 },
          { maxPatients: 0, weekDay: 6 },
        ],
      };
    }

    const array = monitoringSettings.days.map((day) =>
      this.formBuilder.group({
        maxPatients: this.formBuilder.control(day.maxPatients, [
          Validators.min(0),
          Validators.max(9999999),
        ]),
        weekDay: this.formBuilder.control(day.weekDay),
      })
    );

    this.dayForm = this.formBuilder.array(array);

    this.dialogForm = this.formBuilder.group({
      days: this.dayForm,
    });

    this.store
      .select(fromRoot.getErrors)
      .pipe(filter((errors) => errors && errors.length > 0))
      .pipe(map((errors) => errors[0]))
      .pipe(filter((error) => error.apiError !== undefined))
      .subscribe((error: StoreError) => {
        this.isPosting = false;
        switch (error.apiError.code) {
          case 42203:
            this.errorMessage = 'Dit telefoonnummer staat al in de database';
            break;
          default:
            this.errorMessage = 'Er is iets fout gegaan';
            break;
        }
      });
  }

  isPosting$() {
    return this.store
      .select(fromRoot.isPostingAuth)
      .pipe(filter((isPosting) => isPosting === false))
      .pipe(take(1))
      .toPromise();
  }

  // createMonitoringSettings() {
  //   if (this.isPosting) {
  //     return;
  //   }
  //   this.isPosting = true;
  //   this.errorMessage = undefined;
  //   const monitoringSettings = this.parseForm(this.dialogForm.value);
  //   this.store.dispatch(new authActions.AddMonitoringSettings({ item: monitoringSettings }));

  //   this.isPosting$().then(() => {
  //     this.isPosting = false;
  //     this.dialogRef.close();
  //     this.store
  //       .select(fromRoot.getSelectedMonitoringSettingsId)
  //       .pipe(take(1))
  //       .subscribe(selectedId => {
  //         this.router.navigate(['monitoringSettingsen/' + selectedId, {
  //           tab: 'plan'
  //         }]);
  //       });
  //   });
  // }
  patchMonitoringSettings() {
    if (this.isPosting) {
      return;
    }
    this.isPosting = true;
    this.errorMessage = undefined;
    this.store.dispatch(
      new settingsActions.UpdateMonitoringSettings(this.dialogForm.value)
    );
    this.isPosting$().then(() => {
      this.isPosting = false;
      this.dialogRef.close();
    });
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
