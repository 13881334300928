import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Medicine } from '../../store/medicine/medicine.model';
import { ColorZone, Patient } from '../../store/patient/patient.model';
import { StoreError } from '../../store/error/error.model';
import { filter, skip, take, map } from 'rxjs/operators';
import * as fromRoot from '../../store';
import * as errorActions from '../../store/error/error.actions';
import * as patientActions from '../../store/patient/patient.actions';
import { Observable, Subscription } from 'rxjs';
import { PatientAction } from '../../store/patientAction/patientAction.model';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-invite-dialog',
  templateUrl: './invite-dialog.component.html',
  styleUrls: ['./invite-dialog.component.scss']
})
export class InviteDialogComponent implements OnInit, OnDestroy {
  actionControl: FormControl;
  isPosting: boolean;
  errorMessage: string;
  selectedMedicine: Medicine;
  patient$: Observable<Patient>;
  errorSub: Subscription;
  patientActions$: Observable<PatientAction[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { useEpipen: boolean; patient: Patient; colorZone: ColorZone },
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<InviteDialogComponent>,
    private store: Store<fromRoot.State>
  ) { }

  ngOnDestroy() {
    this.store.dispatch(new errorActions.ClearErrors());
    if (this.errorSub) {
      this.errorSub.unsubscribe();
    }
  }

  ngOnInit() {
    this.isPosting = false;
    this.patient$ = this.store.select(fromRoot.getSelectedPatient);
    this.patientActions$ = this.store.select(fromRoot.getSelectedPatientActions);
    this.store.dispatch(new errorActions.ClearErrors());
  }

  isPosting$() {
    return this.store
      .select(fromRoot.isPostingPatient)
      .pipe(filter(isPosting => isPosting === false))
      .pipe(take(1))
      .toPromise();
  }

  cancel() {
    this.dialogRef.close();
  }

  invite() {
    this.errorSub = this.store
      .select(fromRoot.getErrors).pipe(skip(1), filter(errors => {
        return errors !== undefined && errors[0] && errors[0].type === patientActions.PatientActionTypes.Invite;
      }), map(errors => errors[0])).pipe(take(1)).subscribe(err => {
        console.log(err);
        this.dialog.open(ConfirmDialogComponent, {
          data: {
            messageOnly: true,
            title: 'Geen plaatsen op deze dag beschikbaar.',
            message:
              err.apiError && err.apiError.code === 42209 ?
                'U kunt het aantal plaatsen aanpassen bij instellingen.' : 'Onbekende fout'
          }
        });
      });
    this.patient$.pipe(take(1)).subscribe(patient => {
      this.store.dispatch(new patientActions.Invite({ id: patient._id }));
      this.dialogRef.close();
    });
  }
}
