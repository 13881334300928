import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { HttpModule } from "@angular/http";
import { NgModule, LOCALE_ID } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { JwtModule } from "@auth0/angular-jwt";
import { DragulaModule } from "ng2-dragula";
import { registerLocaleData } from "@angular/common";
import { TranslateModule, TranslateLoader } from '@ngx-translate/core'
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

// Redux etc.
import { StoreModule } from "@ngrx/store";
import { reducers } from "./store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { AuthEffects } from "./store/auth/auth.effects";
import { SettingsEffects } from "./store/settings/settings.effects";
import { PatientEffects } from "./store/patient/patient.effects";
import { PatientActionEffects } from "./store/patientAction/patientAction.effects";
import { MedicineEffects } from "./store/medicine/medicine.effects";
import { MessageEffects } from "./store/message/message.effects";
import { AuthAdminEffects } from "./store/authAdmin/authAdmin.effects";
import { TeamEffects } from "./store/team/team.effects";
import { OrgEffects } from "./store/org/org.effects";
import { CareProviderEffects } from "./store/careprovider/careprovider.effects";
import { EffectsModule } from "@ngrx/effects";

// Pages
import { AppComponent } from "./app.component";
import { LoginPageComponent } from "./pages/login/login.component";
import { RequestPasswordChangePageComponent } from "./pages/request-password-change/request-password-change.component";
import { ChangePasswordPageComponent } from "./pages/change-password/change-password.component";
import { HomePageComponent } from "./pages/home/home.component";
import { AdminLoginPageComponent } from "./pages/admin-login/admin-login.component";
import { AdminHomePageComponent } from "./pages/admin-home/admin-home.component";

// Views
import { ChatViewComponent } from "./views/chat/chat.component";
import { PatientsViewComponent } from "./views/patients/patients.component";
import { PatientDetailViewComponent } from "./views/patient-detail/patient-detail.component";
import { ProfileViewComponent } from "./views/profile/profile.component";
import { TeamViewComponent } from "./views/team/team.component";
import { AlertsViewComponent } from "./views/alerts/alerts.component";
import { OrgsViewComponent } from "./views/admin-orgs/admin-orgs.component";
import { OrgDetailViewComponent } from "./pages/admin-org-detail/admin-org-detail.component";
import { TeamDetailViewComponent } from "./pages/admin-team-detail/admin-team-detail.component";
import { CareProvidersViewComponent } from "./views/admin-careproviders/admin-careproviders.component";
import { SettingsViewComponent } from "./views/settings/settings.component";

// Dialogs
import { ActionDialogComponent } from "./dialogs/action-dialog/action-dialog.component";
import { ConfirmDialogComponent } from "./dialogs/confirm-dialog/confirm-dialog.component";
import { CustomMedicineDialogComponent } from "./dialogs/custom-medicine-dialog/custom-medicine-dialog.component";
import { EvaluateAfterDialogComponent } from "./dialogs/evaluate-after-dialog/evaluate-after-dialog.component";
import { MedicineDialogComponent } from "./dialogs/medicine-dialog/medicine-dialog.component";
import { PatientDialogComponent } from "./dialogs/patient-dialog/patient-dialog.component";
import { UseEpipenDialogComponent } from "./dialogs/use-epipen-dialog/use-epipen-dialog.component";
import { InviteDialogComponent } from "./dialogs/invite-dialog/invite-dialog.component";
import { MonitoringSettingsDialogComponent } from "./dialogs/monitoring-settings-dialog/monitoring-settings-dialog.component";
import { ErrorDialogComponent } from "./dialogs/error-dialog/error-dialog.component";
import { CareProviderDialogComponent } from "./dialogs/admin-careprovider-dialog/admin-careprovider-dialog.component";
import { MeasurementConfigDialogComponent } from "./dialogs/measurement-config-dialog/measurement-config-dialog.component";
import { TeamDialogComponent } from "./dialogs/admin-team-dialog/admin-team-dialog.component";
import { OrgDialogComponent } from "./dialogs/admin-org-dialog/admin-org-dialog.component";
import { ResultDialogComponent } from "./dialogs/result-dialog/result-dialog.component";

import localeNL from "@angular/common/locales/nl";

// the second parameter 'fr' is optional
registerLocaleData(localeNL, "nl");

// Misc
import { AuthGuard } from "./guards/auth.guard";
import { ApiService } from "./services/api.service";
import { SwitcherPageComponent } from "./pages/switcher/switcher.component";
import { AuthAdminGuard } from "./guards/authAdmin.guard";
import { AcsChartComponent } from "./views/acs-chart/acs-chart.component";
import { TimeAgoPipe } from "./pipes/time-ago.pipe";

import { FAApiService } from "./services/fa-api.service";

// Material
import {
  MatCheckboxModule,
  MatToolbarModule,
  MatMenuModule,
  MatIconModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatDialogModule,
  MatFormFieldModule,
  MatSelectModule,
  MatInputModule,
  MatListModule,
  MatTabsModule,
  MatCardModule,
  MatPaginatorModule,
  MatTableModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatSortModule,
  MatProgressSpinnerModule,
  MatAutocompleteModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatRippleModule,
  MatRadioModule,
} from "@angular/material";
import { ToggleComponent } from "./components/toggle/toggle.component";
import { ActionPointStepComponent } from "./components/action-point-step/action-point-step.component";
import { ActionPlanComponent } from "./components/action-plan/action-plan.component";
import { ChangePasswordDialogComponent } from "./dialogs/change-password-dialog/change-password-dialog.component";
import { ChatReplyDialogComponent } from "./dialogs/chat-reply-dialog/chat-reply-dialog.component";
import { StandardReplySelectorComponent } from "./components/standard-reply-selector/standard-reply-selector.component";
import { DeeplinkPipe } from "./pipes/deeplink.pipe";
import { ChatMessageComponent } from "./components/chat-message/chat-message.component";
import { FevChartComponent } from "./views/fev-chart/fev-chart.component";
import { PatientChartComponent } from "./components/patient-chart/patient-chart.component";
import { ResultQualityDialogComponent } from "./dialogs/result-quality-dialog/result-quality-dialog.component";
import { AdminReminderDialogComponent } from "./dialogs/admin-reminder-dialog/admin-reminder-dialog.component";
import { OptionsDialogComponent } from "./dialogs/options-dialog/options-dialog.component";
import { PatientsTableComponent } from "./components/patients-table/patients-table.component";
import { ConditionsDialogComponent } from "./dialogs/conditions-dialog/conditions-dialog.component";
import { InputDialogComponent } from "./dialogs/input-dialog/input-dialog.component";
import { AdminUsersComponent } from "./pages/admin-users/admin-users.component";

// Used in the TranslateModule.forRoot in the imports of the AppModule
export function httpLoaderFactory(http: HttpClient)  {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


@NgModule({
  declarations: [
    AppComponent,
    // Pages
    LoginPageComponent,
    AdminLoginPageComponent,
    HomePageComponent,
    AdminHomePageComponent,
    RequestPasswordChangePageComponent,
    ChangePasswordPageComponent,
    SwitcherPageComponent,
    // Views
    AlertsViewComponent,
    ChatViewComponent,
    PatientsViewComponent,
    PatientDetailViewComponent,
    ProfileViewComponent,
    TeamViewComponent,
    OrgsViewComponent,
    AdminUsersComponent,
    OrgDetailViewComponent,
    TeamDetailViewComponent,
    CareProvidersViewComponent,
    // Dialogs
    OrgDialogComponent,
    TeamDialogComponent,
    CareProviderDialogComponent,
    ActionDialogComponent,
    ConfirmDialogComponent,
    CustomMedicineDialogComponent,
    EvaluateAfterDialogComponent,
    MedicineDialogComponent,
    PatientDialogComponent,
    UseEpipenDialogComponent,
    ErrorDialogComponent,
    TimeAgoPipe,
    InviteDialogComponent,
    ResultDialogComponent,
    MonitoringSettingsDialogComponent,
    MeasurementConfigDialogComponent,
    AcsChartComponent,
    SettingsViewComponent,
    ToggleComponent,
    ActionPointStepComponent,
    ActionPlanComponent,
    ChangePasswordDialogComponent,
    ChatReplyDialogComponent,
    StandardReplySelectorComponent,
    DeeplinkPipe,
    ChatMessageComponent,
    FevChartComponent,
    PatientChartComponent,
    ResultQualityDialogComponent,
    AdminReminderDialogComponent,
    OptionsDialogComponent,
    PatientsTableComponent,
    ConditionsDialogComponent,
    InputDialogComponent,
    AdminUsersComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpModule,
    HttpClientModule,
	
    ReactiveFormsModule,
    FormsModule,
    RouterModule.forRoot([
      { path: "login/:email", component: LoginPageComponent },
      { path: "login", component: LoginPageComponent },
      { path: "admin/login", component: AdminLoginPageComponent },
      {
        path: "switcher",
        component: SwitcherPageComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "admin",
        component: AdminHomePageComponent,
        canActivate: [AuthAdminGuard],
        children: [
          { path: "", pathMatch: "full", redirectTo: "organisaties" },
          { path: "organisaties", component: OrgsViewComponent },
          { path: "careproviders", component: CareProvidersViewComponent },
          {
            path: "users",
            component: AdminUsersComponent,
          },
        ],
      },
      {
        path: "admin/organisaties/:orgId",
        component: OrgDetailViewComponent,
        canActivate: [AuthAdminGuard],
      },
      {
        path: "admin/organisaties/:orgId/teams/:teamId",
        component: TeamDetailViewComponent,
        canActivate: [AuthAdminGuard],
      },

      {
        path: "wachtwoord-wijziging-aanvragen",
        component: RequestPasswordChangePageComponent,
      },
      {
        path: "wachtwoord-wijzigen/:email/:token",
        component: ChangePasswordPageComponent,
      },
      {
        path: "",
        component: HomePageComponent,
        canActivate: [AuthGuard],
        children: [
          { path: "", pathMatch: "full", redirectTo: "meldingen" },
          { path: "meldingen", component: AlertsViewComponent },
          { path: "patienten", component: PatientsViewComponent },
          { path: "profiel", component: ProfileViewComponent },
          { path: "team", component: TeamViewComponent },
          { path: "settings", component: SettingsViewComponent },
        ],
      },
      {
        path: "patienten/:patientId",
        component: PatientDetailViewComponent,
        canActivate: [AuthGuard],
      },
      // otherwise redirect to home
      { path: "**", redirectTo: "login" },
    ]),
    // Material
    MatCheckboxModule,
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatCardModule,
    MatTableModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSortModule,
    MatPaginatorModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatRippleModule,
    DragulaModule.forRoot(),
    // Store
    StoreModule.forRoot(reducers, {}),
    StoreDevtoolsModule.instrument({
      maxAge: 10,
    }),
    EffectsModule.forRoot([
      SettingsEffects,
      AuthEffects,
      AuthAdminEffects,
      PatientEffects,
      PatientActionEffects,
      MedicineEffects,
      MessageEffects,
      OrgEffects,
      TeamEffects,
      CareProviderEffects,
    ]),
    JwtModule.forRoot({}),
	
	// Translate
	TranslateModule.forRoot()
	// TranslateModule.forRoot({
	// 	loader: {
	// 		provide: TranslateLoader,
	// 		useFactory: httpLoaderFactory,
	// 		deps: [ HttpClient ]
	// 	}
	// }),
  ],
  providers: [AuthGuard, ApiService, FAApiService, { provide: LOCALE_ID, useValue: "nl" }],
  entryComponents: [
    OrgDialogComponent,
    TeamDialogComponent,
    CareProviderDialogComponent,
    ActionDialogComponent,
    ConfirmDialogComponent,
    CustomMedicineDialogComponent,
    EvaluateAfterDialogComponent,
    MedicineDialogComponent,
    PatientDialogComponent,
    UseEpipenDialogComponent,
    InviteDialogComponent,
    ResultDialogComponent,
    ErrorDialogComponent,
    MonitoringSettingsDialogComponent,
    ChangePasswordDialogComponent,
    ChatReplyDialogComponent,
    MeasurementConfigDialogComponent,
    ResultQualityDialogComponent,
    AdminReminderDialogComponent,
    OptionsDialogComponent,
    ConditionsDialogComponent,
    InputDialogComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
