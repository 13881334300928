import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, mergeMap } from 'rxjs/operators';
import { MessageActionTypes, AddMessage, AddMessageSuccess, LoadMessages, LoadMessagesSuccess } from './message.actions';
import { UpdatePatientSuccess } from './../patient/patient.actions';
import { ApiService } from '../../services/api.service';

import * as errorActions from '../error/error.actions';
import * as fromRoot from '..';
import { Store } from '@ngrx/store';

import { v4 as uuid } from 'uuid';
import { empty } from 'rxjs';
import { Message } from './message.model';

@Injectable()
export class MessageEffects {
  @Effect()
  add$ = this.actions$.pipe(
    ofType(MessageActionTypes.AddMessage),
    switchMap((action: AddMessage) => {
      return this.api.createChatMessage(action.payload.id, action.payload.item).pipe(
        mergeMap(response => {
          return [
            new UpdatePatientSuccess({ changes: { lastChatMessage: response }, id: action.payload.id }),
            new AddMessageSuccess({ item: response })
          ];
        }),
        catchError(err => {
          this.store.dispatch(
            new errorActions.AddError({
              error: {
                id: uuid(),
                created: new Date(),
                content: err,
                source: action
              }
            })
          );
          return empty();
        })
      );
    })
  );

  @Effect()
  load$ = this.actions$.pipe(
    ofType(MessageActionTypes.LoadMessages),
    mergeMap((action: LoadMessages) => {
      return this.api.getChatMessages(action.payload.id).pipe(
        map((verifyResponse: Message[]) => {
          return new LoadMessagesSuccess({ items: verifyResponse });
        }),
        catchError(err => {
          this.store.dispatch(
            new errorActions.AddError({
              error: {
                id: uuid(),
                created: new Date(),
                content: err,
                source: action
              }
            })
          );
          return empty();
        })
      );
    })
  );

  constructor(private actions$: Actions, private api: ApiService, private store: Store<fromRoot.State>) {}
}
