import { ErrorDialogComponent } from './dialogs/error-dialog/error-dialog.component';
import { combineLatest } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { JwtHelperService } from '@auth0/angular-jwt';

// Translation
import { TranslateService } from '@ngx-translate/core';
// import NL from '../assets/i18n/nl.json'
// import EN from '../assets/i18n/en.json'

import * as fromRoot from './store';
import * as authActions from './store/auth/auth.actions';
import * as adminAuthActions from './store/authAdmin/authAdmin.actions';
import * as patientActions from './store/patient/patient.actions';
import * as messageActions from './store/message/message.actions';

import { filter, take, map } from 'rxjs/operators';
import { trigger, transition, style, query } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { Team } from './store/team/team.model';
import { StoreError } from './store/error/error.model';
import { MatDialog } from '@angular/material';
import { ChangePasswordDialogComponent } from './dialogs/change-password-dialog/change-password-dialog.component';
import { FAApiService, LANG_TYPES } from './services/fa-api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    trigger('routeAnimations', [
      transition('* <=> *', [
        query(':enter, :leave', [
          style({
            opacity: '0'
          })
        ]),
        query(':enter', [style({ opacity: '1' })]),
        query(':leave', [style({ opacity: '0' })])
      ])
    ])
  ]
})
export class AppComponent implements OnInit {
  private _dialogIsOpen = false;
  public isLoading: boolean = false;

  constructor(
	public translate: TranslateService,
    private jwtHelper: JwtHelperService,
    private store: Store<fromRoot.State>,
    private router: Router,
    private dialog: MatDialog,
	private faService: FAApiService,
    private activatedRoute: ActivatedRoute,
  ) {

    this.reloadTokenData();
  }

  private async loadLabels(): Promise<any> {
	const en: any = await this.faService.getLabels(LANG_TYPES.EN).toPromise();
	const nl: any = await this.faService.getLabels(LANG_TYPES.NL).toPromise();
	return {
		en: en,
		nl: nl 
	}
  }

  ngOnInit() {
	this.isLoading = true;
	this.loadLabels().then((labels) => {
		console.log(labels)
		this.translate.setTranslation('nl', labels.nl);
		this.translate.setTranslation('en', labels.en);
	
		this.translate.addLangs(['nl', 'en']);
		this.translate.setDefaultLang('nl');

		let storedLang: string | null = localStorage.getItem('defaultLang') 
		if (storedLang !== null && (storedLang === 'nl' || storedLang === 'en')) {
			this.translate.use(storedLang);
		} else {
			this.translate.use('nl')
		}
	}).finally(() => {
		this.isLoading = false;
	});

    this.store
      .select(fromRoot.getErrors)
      .pipe(filter(errors => errors && errors.length > 0))
      .pipe(map(errors => errors[0]))
      .pipe(
        filter(error => {
          return (
            (error.content !== undefined &&
              error.content.status !== undefined) ||
            (error.apiError !== undefined && error.apiError.code !== undefined)
          );
        })
      )
      .pipe(
        filter(
          error =>
            error.apiError.code === 40103 ||
            error.apiError.code === 40104 ||
            error.content && error.content.status === 401
        )
      )
      .subscribe((error: StoreError) => {
        // TODO: We probably don't need this anymore
        // if (!this._dialogIsOpen) {
        //   this._dialogIsOpen = true;
        //   const dialog = this.dialog.open(ErrorDialogComponent);
        //   dialog.afterClosed().subscribe((action) => {
        //     if (action === 'refresh') {
        //       window.location.reload();
        //     } else {

        //     }
        //     this._dialogIsOpen = false;
        //   })
        // }

        const isAdmin = location.href.includes('admin');
        isAdmin ? localStorage.removeItem('adminTokens') : localStorage.removeItem('tokens');
        localStorage.removeItem('selectedTeam');
        const loggedIn$ = isAdmin ? this.store
          .select(fromRoot.getAdminTokens)
          .pipe(map(tokens => tokens && tokens.accesstoken)) : this.store
            .select(fromRoot.getTokens)
            .pipe(map(tokens => tokens && tokens.accesstoken));
        loggedIn$
          .pipe(
            filter(loggedin => loggedin === undefined),
            take(1)
          )
          .subscribe(loggedIn => {
            if (!loggedIn) {
              // You are not logged in
              isAdmin ? this.router.navigate(['/admin/login']) : this.router.navigate(['/login']);
            }
          });
        this.store.dispatch(isAdmin ? new adminAuthActions.AdminLogoutSuccessAction() : new authActions.LogoutSuccessAction());
      });

    combineLatest(
      this.store.select(fromRoot.getCareProvider),
      this.store.select(fromRoot.getTokens)
    )
      .pipe(
        filter(([careProvider, tokens]) => {
          return (
            careProvider &&
            tokens !== undefined &&
            careProvider.email !== undefined
          );
        }),
        take(1)
      )
      .subscribe(([careProvider, tokens]) => {
        const decoded = this.jwtHelper.decodeToken(tokens.accesstoken);
        const teams = (careProvider.teams as Team[]).map(team => team._id);
        const decodedTeams = decoded.careProvider.teams;
        const valid =
          teams.length === decodedTeams.length &&
          teams.every((element, index) => {
            return element === decodedTeams[index];
          });

        const careProviderId = careProvider._id;
        const isAdmin = location.href.includes('admin');
          console.log('login')
        if (careProvider.forceResetPassword && !isAdmin) {
          console.log('show forcereset');
          const changePasswordDialog = this.dialog.open(ChangePasswordDialogComponent, {
            disableClose: true,
            data: {
              careProviderId,
              createPassword: true,
            }
          });
        }

        if (!valid) {
          this.store.dispatch(new authActions.LogoutAction());
          this.store.dispatch(new authActions.ClearStateAction(undefined));
          this.store.dispatch(new patientActions.ClearPatients());
          this.store.dispatch(new messageActions.ClearMessages());
          this.router.navigate(['/login']);
        }
      });
  }

  private async reloadTokenData() {
    this.store
      .select(fromRoot.getTokens)
      .pipe(filter(tokens => tokens !== undefined))
      .pipe(filter(tokens => tokens.accesstoken !== undefined))
      .pipe(take(1))
      .subscribe(tokens => {
        const decoded = this.jwtHelper.decodeToken(tokens.accesstoken);
        if (!decoded || !decoded.careProvider || !decoded.careProvider._id) {
          return;
        }
        const careProvider = decoded.careProvider;

        this.store.dispatch(new authActions.ReloadTokenData(careProvider));
        this.store.dispatch(
          new authActions.CareProviderGetAction(decoded.careProvider._id)
        );
      });

    this.store
      .select(fromRoot.getCareProviderSelectedTeam)
      .pipe(take(1))
      .subscribe((selectedTeam) => {

        const isAdmin = location.href.includes('admin');
        const isPasswordReset = location.href.includes('wachtwoord-wijzigen');

        if (!selectedTeam && !isAdmin && !isPasswordReset) {
          this.router.navigate(['/switcher']);
        }
      });
  }
}
