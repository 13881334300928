import { TeamDialogComponent } from "../../dialogs/admin-team-dialog/admin-team-dialog.component";

import { Component, OnInit, AfterViewInit, OnDestroy } from "@angular/core";

import * as fromRoot from "../../store";
import * as orgActions from "../../store/org/org.actions";
import * as careproviderAction from "../../store/careprovider/careprovider.actions";
import * as teamActions from "../../store/team/team.actions";
import { Store } from "@ngrx/store";

import { Team } from "../../store/team/team.model";
import {
  MatTableDataSource,
  MatDialogRef,
  MatDialog,
  Sort,
} from "@angular/material";
import { Router, ActivatedRoute } from "@angular/router";
import {
  formatBirthDate,
  getColor,
  sortData,
} from "../../util/helper-functions";
import { Observable, Subscription } from "rxjs";
import { filter, map, take } from "rxjs/operators";
import { Org } from "src/app/store/org/org.model";
import { OrgDialogComponent } from "src/app/dialogs/admin-org-dialog/admin-org-dialog.component";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-admin-org-detail",
  templateUrl: "./admin-org-detail.component.html",
  styleUrls: ["./admin-org-detail.component.scss"],
})
export class OrgDetailViewComponent
  implements OnInit, AfterViewInit, OnDestroy {
  teams$: Observable<Team[]>;
  org$: Observable<Org>;
  teams: Team[];
  displayedColumns = ["name"];
  dataSource = new MatTableDataSource<any>([]);
  teamSub: Subscription;
  teamDialog: MatDialogRef<TeamDialogComponent>;
  orgDialog: MatDialogRef<OrgDialogComponent>;
  sort: Sort;
  getColor = getColor;
  formatBirthDate: (date: string) => string;

  constructor(
    private store: Store<fromRoot.State>,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.store.dispatch(new careproviderAction.LoadCareProviders());
    this.store.dispatch(new orgActions.LoadOrgs());
    this.store.dispatch(new teamActions.LoadTeams());

    this.formatBirthDate = formatBirthDate;
    this.store.dispatch(
      new orgActions.Select(this.route.snapshot.params.orgId)
    );
    this.org$ = this.store.select(fromRoot.getSelectedOrg);
    this.org$
      .pipe(
        filter((o) => o !== undefined),
        take(1)
      )
      .subscribe((o) =>
        this.titleService.setTitle("LungBeat Admin - " + o.name)
      );
    this.teams$ = this.store.select(fromRoot.getTeams).pipe(
      filter((teams) => teams !== undefined),
      map((teams) => {
        return teams
          .filter(
            (team) => team.organization === this.route.snapshot.params.orgId
          )
          .sort((teamA, teamB) => {
            // Sort by alphabetically
            const nameA = teamA.name.toLowerCase();
            const nameB = teamB.name.toLowerCase();
            return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
          });
      })
    );
    this.teamSub = this.teams$
      .pipe(filter((teams) => teams !== undefined))
      .subscribe((teams) => {
        this.teams = teams;
        this.sortData(this.sort);
      });
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    if (this.teamSub) {
      this.teamSub.unsubscribe();
    }
  }

  sortData(sort: Sort) {
    this.dataSource = sortData(sort, this.dataSource, this.teams);
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  editOrg(org) {
    this.orgDialog = this.dialog.open(OrgDialogComponent, { data: { org } });
  }

  editTeam(team) {
    this.router.navigate([
      "admin/organisaties/" +
        this.route.snapshot.params.orgId +
        "/teams/" +
        team._id,
    ]);
  }

  createTeam() {
    this.teamDialog = this.dialog.open(TeamDialogComponent, {
      data: { orgId: this.route.snapshot.params.orgId },
    });
  }

  // returns true if lastACS.createdAt > 8 days
  skippedACS(createdAt: Date): boolean {
    return Date.now() - createdAt.getTime() > 8 * 24 * 60 * 60 * 1000;
  }

  back() {
    this.router.navigate(["admin/organisaties"]);
  }
}
