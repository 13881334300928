import { Component, OnInit } from '@angular/core';
import * as authActions from '../../store/auth/auth.actions';
import * as fromRoot from '../../store/index';
import { Store } from '@ngrx/store';
import { MatDialogRef } from '@angular/material';
import { ActionDialogComponent } from '../action-dialog/action-dialog.component';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements OnInit {

  constructor(private store: Store<fromRoot.State>, public dialogRef: MatDialogRef<ErrorDialogComponent>,) { }

  ngOnInit() {
  }

  refresh() {
    this.dialogRef.close('refresh');
  }

  logout() {
    this.dialogRef.close('logout');
  }

}
