import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import {
  MatTableDataSource,
  Sort
} from '@angular/material';
import { WEEKDAYS } from '../../store';
import { sortData } from 'src/app/util/helper-functions';
import { CareProvider } from 'src/app/store/careprovider/careprovider.model';
import { Observable, Subject } from 'rxjs';
import { filter, take, map, takeUntil } from 'rxjs/operators';
import { Team } from 'src/app/store/team/team.model';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamViewComponent implements OnInit, OnDestroy {
  @Input() team$: Observable<Team>;
  weekdays = WEEKDAYS;
  displayedColumns = ['combinedName', 'dateOfBirth'];
  dataSource = new MatTableDataSource<any>([]);
  sort: Sort;
  destroy$ = new Subject<boolean>();

  constructor() {}

  ngOnInit() {
    this.team$.pipe(filter(t => t !== undefined), takeUntil(this.destroy$)).subscribe(() => {
      this.sortData(this.sort);
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  async sortData(sort: Sort) {
    const careProviders = await this.team$.pipe(filter(t => t.careProviders !== undefined), take(1), map(t => t.careProviders)).toPromise();
    this.dataSource = sortData(sort, this.dataSource, careProviders);
  }


  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
}
