import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, mergeMap } from 'rxjs/operators';
import {
  OrgActionTypes,
  AddOrg,
  AddOrgSuccess,
  LoadOrgs,
  LoadOrgsSuccess,
  UpdateOrgSuccess,
  UpdateOrg,
  GetOne,
  GetOneSuccess,
  Select,
  DeleteOrg,
  DeleteOrgSuccess
} from './org.actions';
import { ApiService } from '../../services/api.service';

import * as errorActions from '../error/error.actions';
import * as fromRoot from '../';
import { Store } from '@ngrx/store';

import { v4 as uuid } from 'uuid';
import { empty } from 'rxjs';
import { Org } from './org.model';

@Injectable()
export class OrgEffects {
  @Effect()
  add$ = this.actions$.pipe(
    ofType(OrgActionTypes.AddOrg),
    switchMap((action: AddOrg) => {
      return this.api.createOrgAdmin(action.payload.item).pipe(
        mergeMap(response => {
          return [new Select(response._id), new AddOrgSuccess({ item: response })];
        }),
        catchError(err => {
          this.store.dispatch(
            new errorActions.AddError({
              error: {
                id: uuid(),
                created: new Date(),
                content: err,
                source: action
              }
            })
          );
          return empty();
        })
      );
    })
  );

  @Effect()
  getOne$ = this.actions$.pipe(
    ofType(OrgActionTypes.GetOne),
    switchMap((action: GetOne) => {
      return this.api.getOrgAdmin(action.payload).pipe(
        map((verifyResponse: Org) => {
          return new GetOneSuccess(verifyResponse);
        }),
        catchError(err => {
          this.store.dispatch(
            new errorActions.AddError({
              error: {
                id: uuid(),
                created: new Date(),
                content: err,
                source: action
              }
            })
          );
          return empty();
        })
      );
    })
  );

  @Effect()
  load$ = this.actions$.pipe(
    ofType(OrgActionTypes.LoadOrgs),
    mergeMap((action: LoadOrgs) => {
      const apiCall = this.api.getAllOrgsAdmin();
      return apiCall.pipe(
        map((orgs: Org[]) => {
          return new LoadOrgsSuccess({ items: orgs });
        }),
        catchError(err => {
          this.store.dispatch(
            new errorActions.AddError({
              error: {
                id: uuid(),
                created: new Date(),
                content: err,
                source: action
              }
            })
          );
          return empty();
        })
      );
    })
  );


  @Effect()
  update$ = this.actions$.pipe(
    ofType(OrgActionTypes.UpdateOrg),
    switchMap((action: UpdateOrg) => {
      return this.api.patchOrgAdmin(action.payload.id, action.payload.item).pipe(
        map(verifyResponse => {
          return new UpdateOrgSuccess({ changes: verifyResponse, id: action.payload.id });
        }),
        catchError(err => {
          this.store.dispatch(
            new errorActions.AddError({
              error: {
                id: uuid(),
                created: new Date(),
                content: err,
                source: action
              }
            })
          );
          return empty();
        })
      );
    })
  );


  @Effect()
  delete$ = this.actions$.pipe(
    ofType(OrgActionTypes.DeleteOrg),
    switchMap((action: DeleteOrg) => {
      return this.api.deleteOrgAdmin(action.payload.id).pipe(
        map(() => {
          return new DeleteOrgSuccess({ id: action.payload.id });
        }),
        catchError(err => {
          this.store.dispatch(
            new errorActions.AddError({
              error: {
                id: uuid(),
                created: new Date(),
                content: err,
                source: action
              }
            })
          );
          return empty();
        })
      );
    })
  );

  constructor(private actions$: Actions, private api: ApiService, private store: Store<fromRoot.State>) { }
}
