import { Medicine } from "./../medicine/medicine.model";
import { Message } from "../message/message.model";
import { ActionPlan, OptionsDialogOption } from "../shared.model";

export interface Patient {
  _id?: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  phone: Phone;
  email?: string;
  patientNumber: string;
  studyNumber?: string;
  patientCharacteristics?: {
    weight: number;
    height: number;
    gender: "male" | "female";
    ethnicity: Ethnicity;
  };
  medication?: Medicine[];
  actionPlan?: ActionPlan;
  thresholdACS?: {
    active: boolean;
    value: number;
  };
  lastACS?: {
    result: number;
    createdAt: Date;
  };
  inConcept?: boolean;
  isPending?: boolean;
  isArchived?: boolean;
  archiveReason?: {
    reasonType?: ArchiveReasonTypes;
    reasonMessage?: string;
  };
  lastChatMessage?: Message;
  unresolvedActionsCount?: number;
  unresolvedMessagesCount?: number;
  unresolvedAt?: Date;
  createdAt?: Date;
  team?: string;
  organization?: string;
  notifyMeAt?: {
    day: number;
    time: string;
  };
  measurements?: PatientMeasurements[];
  sendOrderInstructionsScheduled: boolean;
  orderInstructionsSentAt?: Date;
}

export interface ArchiveReason extends OptionsDialogOption {}

export enum ArchiveReasonType {
  AUTO_ARCHIVED = "auto-archive",
  FIRST_LINE = "first-line",
  NOT_ACTIVE = "not-active",
  NOT_PARTICIPATING = "not-participating",
  OTHER = "other",
}

export type ArchiveReasonTypes =
  | ArchiveReasonType.AUTO_ARCHIVED
  | ArchiveReasonType.FIRST_LINE
  | ArchiveReasonType.NOT_ACTIVE
  | ArchiveReasonType.NOT_PARTICIPATING
  | ArchiveReasonType.OTHER;

export const ArchivedOptions: ArchiveReason[] = [
  {
    value: ArchiveReasonType.FIRST_LINE,
    title: "Terug naar 1e lijn",
  },
  {
    value: ArchiveReasonType.NOT_ACTIVE,
    title: "Patiënt doet niet actief mee",
  },
  {
    value: ArchiveReasonType.NOT_PARTICIPATING,
    title: "Patiënt wil niet meer meedoen",
  },
  {
    value: ArchiveReasonType.OTHER,
    title: "Anders",
    showInput: true,
  },
];

export interface Phone {
  phonenumber: string;
  countryCode: "NL" | "BE";
}

export type ColorZone = "green" | "yellow" | "orange" | "red";

export type Ethnicity =
  | "caucasian"
  | "afro_american"
  | "north_east_asian"
  | "south_east_asian"
  | "unknown"
  | "other";

export const ETHNICITIES: Array<{ label: string; value: Ethnicity }> = [
  { label: "Kaukasisch (Europa, Amerika, Australië)", value: "caucasian" },
  { label: "Afro-Amerikaans", value: "afro_american" },
  { label: "Noord-oost Aziatisch", value: "north_east_asian" },
  { label: "Zuid-oost Aziatisch", value: "south_east_asian" },
  { label: "Andere afkomst", value: "other" },
  { label: "Onbekend", value: "unknown" },
];

export interface ACS {
  questions: { title: string; possibleAnswers: string[] }[];
}

export interface PatientMeasurements {
  measurementId: string;
  enabled: boolean;
  name: string;
  monitoringEnabled: boolean;
  measurementConfiguration: {
    notificationSettings?: {
      day: 0 | 1 | 2 | 3 | 4 | 5 | 6;
      time: string;
      frequency: "day" | "week" | "twoweek" | "monthly";
    };
    turbine?: "reusable" | "disposable" | "unknown";
    delivery:
      | "hospital_visit"
      | "shipment_by_careteam"
      | "patient_orders"
      | "already_owned";
    instructionsBy: string[];
    sendOrderInstructionsScheduled: boolean;
    orderInstructionsSentAt: Date;
  };
}
