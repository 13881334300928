import { VerificationToken } from './../authAdmin/authAdmin.model';
import { AuthActions, AuthActionTypes } from './auth.actions';
import { Client, Tokens } from './auth.model';
import { CareProvider } from '../careprovider/careprovider.model';

export interface State {
  client?: Client;
  tokens?: Tokens;
  verification?: VerificationToken;
  careProvider?: CareProvider;

  refreshing?: boolean;
  loading?: boolean;
  activated?: boolean;
  loginRequested?: boolean;
  isPosting?: boolean;
}

const tokens = JSON.parse(localStorage.getItem('tokens'));

export const initialState: State = {
  tokens: tokens ? tokens : undefined,
  isPosting: false,
};

export function reducer(state = initialState, action: AuthActions): State {
  switch (action.type) {
    case AuthActionTypes.Client:
      return {
        ...state,
        client: action.payload,
      };

    case AuthActionTypes.Tokens:
      return {
        ...state,
        refreshing: false,
        tokens: action.payload,
      };

    case AuthActionTypes.CareProvider:
      return {
        ...state,
        careProvider: action.payload,
      };

    case AuthActionTypes.RefreshToken:
      return {
        ...state,
        refreshing: true,
      };

    case AuthActionTypes.SignupSuccessAction:
      return state;

    case AuthActionTypes.ActivateSuccess:
      return {
        ...state,
        activated: true,
      };

    case AuthActionTypes.VerificationToken:
      return {
        ...state,
        verification: action.payload
      };

      case AuthActionTypes.VerifySuccess:
        return {
          ...state,
          verification: null
        };

    case AuthActionTypes.ReloadTokenData: {
      return {
        ...state,
        careProvider: {
          _id: action.payload._id,
          teams: action.payload.teams,
          organizations: action.payload.organizations,
        },
      };
    }

    case AuthActionTypes.ClearState: {
      return {};
    }

    case AuthActionTypes.LogoutSuccess: {
      return {};
    }

    default:
      return state;
  }
}

export const getState = (state: State) => state;

export const isPosting = (state: State) => state.isPosting;

export const getClient = (state: State) => state.client;

export const getTokens = (state: State) => state.tokens;

export const getVerification = (state: State) => state.verification;

export const getCareProvider = (state: State) => state.careProvider;

export const isRefreshing = (state: State) => state.refreshing;

export const isLoading = (state: State) => state.loading;

export const loginRequested = (state: State) => state.loginRequested;
