import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Patient } from './patient.model';
import { PatientActions, PatientActionTypes } from './patient.actions';
import { AuthActions, AuthActionTypes } from '../auth/auth.actions';

export interface State extends EntityState<Patient> {
  // additional entities state properties
  selectedId?: string;
  isPosting: boolean;
}

export const adapter: EntityAdapter<Patient> = createEntityAdapter<Patient>({
  selectId: (item: Patient) => item._id
});

export const initialState: State = adapter.getInitialState({
  isPosting: false
});

export function reducer(state = initialState, action: PatientActions | AuthActions): State {
  switch (action.type) {
    case PatientActionTypes.AddPatient: {
      return {
        ...state,
        isPosting: true
      };
    }

    case PatientActionTypes.AddPatientSuccess: {
      return {
        ...adapter.addOne(action.payload.item, state),
        isPosting: false
      };
    }

    case PatientActionTypes.UpsertPatient: {
      return adapter.upsertOne(action.payload.item, state);
    }

    case PatientActionTypes.AddPatients: {
      return adapter.addMany(action.payload.items, state);
    }

    case PatientActionTypes.UpsertPatients: {
      return adapter.upsertMany(action.payload.items, state);
    }

    case PatientActionTypes.UpdatePatient: {
      return {
        ...state,
        isPosting: true
      };
    }

    case PatientActionTypes.UpdatePatientSuccess: {
      return {
        ...adapter.updateOne(action.payload, state),
        isPosting: false
      };
    }

    case PatientActionTypes.AddMedication: {
      return {
        ...state,
        isPosting: true
      };
    }

    case PatientActionTypes.AddMedicationSuccess: {
      return {
        ...adapter.updateOne(action.payload, state),
        isPosting: false
      };
    }

    case PatientActionTypes.RemoveMedication: {
      return {
        ...state,
        isPosting: true
      };
    }

    case PatientActionTypes.RemoveMedicationSuccess: {
      return {
        ...adapter.updateOne(action.payload, state),
        isPosting: false
      };
    }

    case PatientActionTypes.AddAction: {
      return {
        ...state,
        isPosting: true
      };
    }

    case PatientActionTypes.AddActionSuccess: {
      return {
        ...adapter.updateOne(action.payload, state),
        isPosting: false
      };
    }

    case PatientActionTypes.UpdateAction: {
      return {
        ...state,
        isPosting: true
      };
    }

    case PatientActionTypes.UpdateActionSuccess: {
      return {
        ...adapter.updateOne(action.payload, state),
        isPosting: false
      };
    }

    case PatientActionTypes.Invite: {
      return {
        ...state,
        isPosting: true
      };
    }

    case PatientActionTypes.InviteSuccess: {
      return {
        ...adapter.updateOne(action.payload, state),
        isPosting: false
      };
    }

    case PatientActionTypes.RemoveAction: {
      return {
        ...state,
        isPosting: true
      };
    }

    case PatientActionTypes.UpdateActionPlan: {
      return {
        ...state,
        isPosting: true
      };
    }

    case PatientActionTypes.UpdateActionPlanSuccess: {
      return {
        ...adapter.updateOne(action.payload, state),
        isPosting: false
      };
    }

    case PatientActionTypes.RemoveActionSuccess: {
      return {
        ...adapter.updateOne(action.payload, state),
        isPosting: false
      };
    }

    case PatientActionTypes.UpdateActionOrder: {
      return {
        ...state,
        isPosting: true
      };
    }

    case PatientActionTypes.UpdateActionOrderSuccess: {
      return {
        ...adapter.updateOne(action.payload, state),
        isPosting: false
      };
    }

    case PatientActionTypes.UpdatePatients: {
      return adapter.updateMany(action.payload.items, state);
    }

    case PatientActionTypes.DeletePatient: {
      return {
        ...state,
        isPosting: true
      };
    }

    case PatientActionTypes.DeletePatientSuccess: {
      return {
        ...adapter.removeOne(action.payload.id, state),
        isPosting: false
      };
    }

    case PatientActionTypes.ArchivePatientSuccess: {
      return {
        ...adapter.updateOne(action.payload, state),
        isPosting: false
      };
    }

    case PatientActionTypes.UnarchivePatientSuccess: {
      return {
        ...adapter.updateOne(action.payload, state),
        isPosting: false
      }
    }

    case PatientActionTypes.DeletePatients: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case PatientActionTypes.LoadPatientsSuccess: {
      return adapter.upsertMany(action.payload.items, state);
    }

    case PatientActionTypes.LoadChatsSuccess: {
      return adapter.upsertMany(action.payload.items, state);
    }

    case PatientActionTypes.GetOneSuccess: {
      return adapter.upsertOne(action.payload, state);
    }

    case PatientActionTypes.ClearPatients: {
      return adapter.removeAll(state);
    }

    case PatientActionTypes.Select: {
      return {
        ...state,
        selectedId: action.payload
      };
    }

    case PatientActionTypes.SetChatResolved: {
      return {
        ...state,
        isPosting: true
      };
    }

    case PatientActionTypes.SetChatResolvedSuccess: {
      return {
        ...adapter.updateOne(action.payload, state),
        isPosting: false
      };
    }

    case AuthActionTypes.LogoutSuccess: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const getSelectedId = (state: State) => state.selectedId;
export const isPosting = (state: State) => state.isPosting;
