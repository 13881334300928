import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Medicine } from '../../store/medicine/medicine.model';
import { ColorZone, Patient } from '../../store/patient/patient.model';
import { StoreError } from '../../store/error/error.model';
import { filter, skip, take, map } from 'rxjs/operators';
import * as fromRoot from '../../store';
import * as errorActions from '../../store/error/error.actions';
import * as patientActions from '../../store/patient/patient.actions';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-evaluate-after-dialog',
  templateUrl: './evaluate-after-dialog.component.html',
  styleUrls: ['./evaluate-after-dialog.component.scss']
})
export class EvaluateAfterDialogComponent implements OnInit, OnDestroy {
  dialogForm: FormGroup;
  actionControl: FormControl;
  isPosting: boolean;
  errorMessage: string;
  selectedMedicine: Medicine;
  evaluateAfterOptions: {value: string, text: string}[] = [
    // '4 uur',
    // '6 uur',
    // '12 uur',
    // '24 uur',
    // '2 dagen',
    // '3 dagen',
    // '7 dagen',
    // '10 dagen',
    // '14 dagen'
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { evaluateAfter: string; patient: Patient; colorZone: ColorZone },
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EvaluateAfterDialogComponent>,
    private store: Store<fromRoot.State>,
    private formBuilder: FormBuilder,
	private translateService: TranslateService
  ) {
	this.evaluateAfterOptions = Object.values(this.translateService.instant('evaluateAfterDialog.evaluateAfter.hours'));

	this.evaluateAfterOptions = [
		{ value: "2 dagen", text: this.translateService.instant('evaluateAfterDialog.evaluateAfter.hours.2Days') },
		{ value: "3 dagen", text: this.translateService.instant('evaluateAfterDialog.evaluateAfter.hours.3Days') },
		{ value: "4 uur", text: this.translateService.instant('evaluateAfterDialog.evaluateAfter.hours.4Hours') },
		{ value: "6 uur", text: this.translateService.instant('evaluateAfterDialog.evaluateAfter.hours.6Hours') },
		{ value: "7 dagen", text: this.translateService.instant('evaluateAfterDialog.evaluateAfter.hours.7Days') },
		{ value: "10 dagen", text: this.translateService.instant('evaluateAfterDialog.evaluateAfter.hours.10Days') },
		{ value: "12 uur", text: this.translateService.instant('evaluateAfterDialog.evaluateAfter.hours.12Hours') },
		{ value: "14 dagen", text: this.translateService.instant('evaluateAfterDialog.evaluateAfter.hours.14Days') },
		{ value: "24 uur", text: this.translateService.instant('evaluateAfterDialog.evaluateAfter.hours.24Hours') },
	]



  }

  ngOnDestroy() {
    this.store.dispatch(new errorActions.ClearErrors());
  }

  ngOnInit() {
    this.isPosting = false;
    this.store.dispatch(new errorActions.ClearErrors());

    let evaluateAfter = this.data.evaluateAfter ? (this.data.evaluateAfter as string) : undefined;
    if (!evaluateAfter) {
      evaluateAfter = '';
    }

    this.dialogForm = this.formBuilder.group({
      evaluateAfter: [evaluateAfter, Validators.required]
    });

    this.store
      .select(fromRoot.getErrors)
      .pipe(filter(errors => errors && errors.length > 0))
      .pipe(map(errors => errors[0]))
      .pipe(filter(error => error.apiError !== undefined))
      .subscribe((error: StoreError) => {
        this.isPosting = false;
        switch (error.apiError.code) {
          default:
            this.errorMessage = this.translateService.instant('evaluateAfterDialog.error');
            break;
        }
      });
  }

  isPosting$() {
    return this.store
      .select(fromRoot.isPostingPatient)
      .pipe(filter(isPosting => isPosting === false))
      .pipe(take(1))
      .toPromise();
  }

  patchEvaluateAfter() {
    if (this.isPosting) {
      return;
    }
    const patch = this.dialogForm.value;
    if (this.isPosting || !patch.evaluateAfter) {
      return;
    }
    this.isPosting = true;
    this.errorMessage = undefined;
    this.store.dispatch(
      new patientActions.UpdateActionPlan({ id: this.data.patient._id, colorZone: this.data.colorZone, patch })
    );
    this.isPosting$().then(() => {
      this.isPosting = false;
      this.dialogRef.close();
    });
  }

  cancel() {
    this.dialogRef.close();
  }
}
