import { Action } from "@ngrx/store";

import { Client, Tokens, RefreshToken } from "./settings.model";
import { MonitoringSettings, MeasurementConfig } from "..";
import { Team } from "../team/team.model";
import { CareProvider } from "../careprovider/careprovider.model";
import { ActionPlan, MedicineActionPoint, ActionPoint, Measurement } from "../shared.model";
import { Update } from "@ngrx/entity";
import { ColorZone } from "../org/org.model";

// tslint:disable:max-classes-per-file

export enum SettingsActionTypes {
  LoadTeam = "[Settings] Load Team",
  LoadTeamSuccess = "[Settings] Load Team Success",
  UpdateMonitoringSettings = "[Settings] Update Monitoring Settings",
  UpdateMonitoringSettingsSuccess = "[Settings] Update Monitoring Settings Success",
  UpdateBaseActionPlan = "[Settings] Update Base Action Plan",
  UpdateBaseActionPlanSuccess = "[Settings] Update Base Action Plan Success",
  AddBaseAction = "[Settings] Add Base Action",
  AddBaseActionSuccess = "[Settings] Add Base Action Success",
  UpdateBaseAction = "[Settings] Update Base Action",
  UpdateBaseActionSuccess = "[Settings] Update Base Action Success",
  RemoveBaseAction = "[Settings] Remove Base Action",
  RemoveBaseActionSuccess = "[Settings] Remove Base Action Success",
  UpdateBaseActionOrder = "[Settings] Update Base Action Order",
  UpdateBaseActionOrderSuccess = "[Settings] Update Base Action Order Success",
  UpdateMeasurementConfig = "[Settings] Update Measurement Config",
  UpdateMeasurementConfigSuccess = "[Settings] Update Measurement Config Success",
  SelectTeam = "[Settings] Select Team",
  SelectTeamSuccess = "[Settings] Select Team Success",
  ClearState = "[Auth] Clear State",
}

export class LoadTeamAction implements Action {
  readonly type = SettingsActionTypes.LoadTeam;

  constructor() {}
}

export class LoadTeamSuccessAction implements Action {
  readonly type = SettingsActionTypes.LoadTeamSuccess;

  constructor(public payload: { team: Team; measurements: Measurement[] }) {}
}

export class UpdateMonitoringSettings implements Action {
  readonly type = SettingsActionTypes.UpdateMonitoringSettings;

  constructor(public payload: MonitoringSettings) {}
}

export class UpdateMonitoringSettingsSuccess implements Action {
  readonly type = SettingsActionTypes.UpdateMonitoringSettingsSuccess;

  constructor(public payload: MonitoringSettings) {}
}

export class UpdateBaseActionPlan implements Action {
  readonly type = SettingsActionTypes.UpdateBaseActionPlan;

  constructor(public payload: ActionPlan) {}
}

export class UpdateBaseActionPlanSuccess implements Action {
  readonly type = SettingsActionTypes.UpdateBaseActionPlanSuccess;

  constructor(public payload: ActionPlan) {}
}

export class AddBaseAction implements Action {
  readonly type = SettingsActionTypes.AddBaseAction;

  constructor(
    public payload: {
      teamId: string;
      item: MedicineActionPoint | ActionPoint;
      colorZone: ColorZone;
    }
  ) {}
}

export class AddBaseActionSuccess implements Action {
  readonly type = SettingsActionTypes.AddBaseActionSuccess;
}

export class UpdateBaseAction implements Action {
  readonly type = SettingsActionTypes.UpdateBaseAction;

  constructor(
    public payload: {
      teamId: string;
      item: MedicineActionPoint | ActionPoint;
      colorZone: ColorZone;
      actionId: string;
    }
  ) {}
}

export class UpdateBaseActionSuccess implements Action {
  readonly type = SettingsActionTypes.UpdateBaseActionSuccess;
}

export class RemoveBaseAction implements Action {
  readonly type = SettingsActionTypes.RemoveBaseAction;

  constructor(
    public payload: { teamId: string; colorZone: ColorZone; actionId: string }
  ) {}
}

export class RemoveBaseActionSuccess implements Action {
  readonly type = SettingsActionTypes.RemoveBaseActionSuccess;
}

export class UpdateBaseActionOrder implements Action {
  readonly type = SettingsActionTypes.UpdateBaseActionOrder;

  constructor(
    public payload: {
      teamId: string;
      order: string[];
      colorZone: ColorZone;
      id: string;
    }
  ) {}
}

export class UpdateBaseActionOrderSuccess implements Action {
  readonly type = SettingsActionTypes.UpdateBaseActionOrderSuccess;
}

export class UpdateMeasurementConfig implements Action {
  readonly type = SettingsActionTypes.UpdateMeasurementConfig;

  constructor(public payload: MeasurementConfig) {}
}

export class UpdateMeasurementConfigSuccess implements Action {
  readonly type = SettingsActionTypes.UpdateMeasurementConfigSuccess;

  constructor(public payload: Team) {}
}

export class SelectTeam implements Action {
  readonly type = SettingsActionTypes.SelectTeam;

  constructor(public payload: { orgId: string; teamId: string }) {}
}

export class SelectTeamSuccess implements Action {
  readonly type = SettingsActionTypes.SelectTeamSuccess;

  constructor(public payload: { orgId: string; teamId: string }) {}
}

export class ClearStateAction implements Action {
  readonly type = SettingsActionTypes.ClearState;

  constructor(public payload: undefined) {}
}

export type SettingsActions =
  | LoadTeamAction
  | LoadTeamSuccessAction
  | UpdateMonitoringSettings
  | UpdateMonitoringSettingsSuccess
  | UpdateBaseActionPlan
  | UpdateBaseActionPlanSuccess
  | AddBaseAction
  | AddBaseActionSuccess
  | UpdateBaseAction
  | UpdateBaseActionSuccess
  | RemoveBaseAction
  | RemoveBaseActionSuccess
  | UpdateBaseActionOrder
  | UpdateBaseActionOrderSuccess
  | UpdateMeasurementConfig
  | UpdateMeasurementConfigSuccess
  | SelectTeam
  | SelectTeamSuccess
  | ClearStateAction;
