import { QualityMessageLabels, SpirometerTestResult, QualityMessageTypes, SpirometryMeasurementResult } from './../../store/patientAction/patientAction.model';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-result-quality-dialog',
  templateUrl: './result-quality-dialog.component.html',
  styleUrls: ['./result-quality-dialog.component.scss'],
})
export class ResultQualityDialogComponent implements OnInit {
  QualityMessageTypes = QualityMessageTypes;
  properties = [{property: 'fev1L', label: 'FEV1'}, {property: 'FEV1/FVC', label: 'fev1FvcPct'} , {property: 'fvcL', label: 'FVC'}, {property: 'fef2575Ls', label: 'FEF25-78'}, {property: 'pefLs', label: 'PEF'}];

  constructor(
	   private translateService: TranslateService,
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      spirometerMeasurementResult: SpirometryMeasurementResult;
    }
  ) { }

  ngOnInit() {}

  get patientNote() {
    return this.data.spirometerMeasurementResult.patientNote;
  }

  get testResults() {
    return this.data.spirometerMeasurementResult.spirometerTestResults;
  }

  getBestResults(index: number) {
    let string = '';
    this.properties.forEach((prop) => {
      let bestPropIndex;
      let highestValue = 0;
      this.data.spirometerMeasurementResult.spirometerTestResults.forEach((result, index) => {
        if (result[prop.property] && result[prop.property] > highestValue) {
          highestValue = result[prop.property];
          bestPropIndex = index;
        }
      })

      if (bestPropIndex === index) {
        string += prop.label + ", ";
      }
    });

    return string ? string.trim().slice(0,-1) : null;
  }

  getResultLabel(resultMessage: QualityMessageTypes) {
    // const qualityMessage = QualityMessageLabels.find(qualityMessage => qualityMessage.message === resultMessage);

	const qualityMessageLabels = this.translateService.instant('resultQualityDialog.qualityLabels');
	const transformedQualityMessageLabels = Object.keys(qualityMessageLabels).map(key => ({message: key, label: qualityMessageLabels[key]}))
    const qualityMessage = transformedQualityMessageLabels.find(qualityMessage => qualityMessage.message === resultMessage);

    return qualityMessage ? qualityMessage.label : this.translateService.instant('resultQualityDialog.unknown');
  }

  getResultStatus(result: SpirometerTestResult) {
    return result.qualityMessage === QualityMessageTypes.GOOD_BLOW || result.qualityMessage === QualityMessageTypes.AVOID_COUGHING
  }

}
