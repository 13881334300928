import { SpirometerTestResult, MeasurementResult, QualityMessageTypes, QualityMessage, GeneralActionTypes } from './../../store/patientAction/patientAction.model';
import { Component, OnInit, Inject, OnDestroy, EventEmitter, Output } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material";
import {
  getColor,
  isYesterday,
  isToday,
  getThresholdValueName,
  getColorZone,
  getBestFev1Result,
} from "./../../util/helper-functions";
import * as fromRoot from "../../store";
import * as patientActionActions from "../../store/patientAction/patientAction.actions";
import { Store } from "@ngrx/store";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { take, filter, map } from "rxjs/operators";
import { PatientAction, MeasurementResultTypes } from "src/app/store/patientAction/patientAction.model";
import { ApiService } from "src/app/services/api.service";
import { Patient } from "src/app/store/patient/patient.model";
import { PatientDialogComponent } from "../patient-dialog/patient-dialog.component";
import { Observable } from 'rxjs';
import { ResultQualityDialogComponent } from '../result-quality-dialog/result-quality-dialog.component';
import { Team } from 'src/app/store/team/team.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "app-result-dialog",
  templateUrl: "./result-dialog.component.html",
  styleUrls: ["./result-dialog.component.scss"],
})
export class ResultDialogComponent implements OnInit, OnDestroy {
  deviceMeasurement$: Observable<MeasurementResult>;
  previousDeviceMeasurements$: Observable<MeasurementResult[]>;
  previousDeviceMeasurement$: Observable<MeasurementResult>;
  bestDeviceMeasurement$: Observable<MeasurementResult>;
  getColor = getColor;
  getColorZone = getColorZone;
  getBestFev1Result = getBestFev1Result;
  isYesterday = isYesterday;
  isToday = isToday;
  GeneralActionTypes = GeneralActionTypes;
  MeasurementResultTypes = MeasurementResultTypes;
  QualityMessageTypes = QualityMessageTypes;
  acs;
  currentThreshold = 1;
  patientThreshold: number;
  patientThresholdActive: boolean;
  thresholdValues: number[] = [];
  thresholdActive: boolean;
  currentView: 'comment' | 'device' | 'acs';
  resendInvitation: boolean;
  sendChatReminder: boolean;
  archivePatient: boolean;
  getThresholdValueName: (num: number) => number = getThresholdValueName;

  constructor(
    public dialogRef: MatDialogRef<ResultDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      patient: Patient;
      team: Team;
      patientAction: PatientAction;
      previousActions: PatientAction[];
      bestAction: PatientAction;
      isSelfHelp: boolean;
      showSpirometerView: boolean;
    },
    private store: Store<fromRoot.State>,
    private dialog: MatDialog,
	private translateService: TranslateService,
    private api: ApiService
  ) {}

  ngOnInit() {
    console.log('DATA', this.data);
    if (this.data.showSpirometerView === undefined) {
      this.data.showSpirometerView = false;
    }

    this.setReminderPropertiesDefault();

    this.currentView = !this.data.showSpirometerView ? 'acs' : 'device';

    console.log('currentview', this.currentView);

    if (!this.data.isSelfHelp) {

      this.api.getACS().subscribe((acs) => {
        this.acs = acs;
      });

      if (this.data.patient && this.data.patientAction && this.data.patientAction.__t === 'UserActionACS' || this.data.patientAction.__t === 'UserActionMeasurement' && this.data.patientAction.measurementResult.name === MeasurementResultTypes.ACS) {
        this.patientThreshold =
          this.data.patient.thresholdACS &&
          this.data.patient.thresholdACS.active
            ? this.data.patient.thresholdACS.value
            : undefined;
        this.patientThresholdActive = this.data.patient.thresholdACS.active;
        this.currentThreshold = parseFloat(
         this.getAcsResult(this.data.patientAction).toFixed(1)
        );
        let curr = 1.0;
        for (let i = 0; curr <= 4.1; i++) {
          this.thresholdValues.push(parseFloat(curr.toFixed(1)));
          curr += 0.1;
        }
      }

      if (this.isAcsMeasurement) {
        if (this.data.patientAction.measurementResult.referenceId) {
          const referenceId = this.data.patientAction.measurementResult.referenceId;
          // TODO: Get best result and 1 previous
          this.deviceMeasurement$ = this.store.select(fromRoot.getPatientActionByMeasurementResultId(referenceId)).pipe(filter(p => p !== undefined), map(p => p.measurementResult));
          this.previousDeviceMeasurements$ = this.store.select(fromRoot.getPreviousDeviceMeasurementResults(referenceId))
          // this.previousDeviceMeasurements$.subscribe(val => console.log(val));
          this.bestDeviceMeasurement$ = this.store.select(fromRoot.getBestDeviceMeasurementResult(referenceId));
        }
      }
    }
  }

  ngOnDestroy() {}

  get acsComment() {
    if (this.isAcsMeasurement) {
      return this.data.patientAction.measurementResult.questions.find(question => question.originalQuestion.questionType === 'textfield');
    } else {
      return false;
    }
  }

  get hasAcsComment() {
    return this.acsComment !== undefined && this.acsComment.textfieldAnswer && this.acsComment.textfieldAnswer.answer.length > 0;
  }

  get isDeviceMeasurement() {
    return (
      this.data.patientAction.measurementResult &&
      this.data.patientAction.measurementResult.name === MeasurementResultTypes.SPIROMETER
    );
  }

  get isActionPlan() {
    return (
      this.data.patientAction.measurementResult &&
      this.data.patientAction.measurementResult.name === MeasurementResultTypes.ACTIONPLAN
    );
  }

  get isCovid19() {
    return (
      this.data.patientAction.measurementResult &&
      this.data.patientAction.measurementResult.name === MeasurementResultTypes.COVID19
    );
  }

  get isAcsMeasurement() {
    return (
      this.data.patientAction.measurementResult &&
      this.data.patientAction.measurementResult.name === MeasurementResultTypes.ACS
    );
  }

  get isGeneralAction() {
    return this.data.patientAction.__t === 'UserActionGeneral';
  }

  get resolutionRequired() {
    return (this.data.patientAction.resolutionRequired);
  }

  get acsForm() {
    return this.data.patientAction.acsForm;
  }

  get questions() {
    return this.data.patientAction.measurementResult.questions;
  }

  get acsQuestions() {
    return this.data.patientAction.__t === 'UserActionACS' ? {measurementResult: false, questions: this.data.patientAction.acsForm.questions} : {measurementResult: true, questions: this.data.patientAction.measurementResult.questions.filter(question => question.originalQuestion.questionType === 'singleSelection')};
  }

  get patientName() {
    return (
      this.data.patient.firstName.substr(0, 1) +
      ". " +
      this.data.patient.lastName
    );
  }

  get invitationReminderAutoSend() {
    return this.data.team.reminders && this.data.team.reminders.invitationReminder && this.data.team.reminders.invitationReminder.autoSend;
  }

  get questionnaireSkippedReminderAutoSend() {
    return this.data.team.reminders && this.data.team.reminders.questionnaireSkippedReminder && this.data.team.reminders.questionnaireSkippedReminder.autoSend;
  }

  get accountRemovalReminderAutoSend() {
    return this.data.team.reminders && this.data.team.reminders.accountRemovalReminder && this.data.team.reminders.accountRemovalReminder.autoSend;
  }

  get reminderInterval() {
    if (!this.isGeneralAction || !this.data.team.reminders) return false;

    const generalActionType = this.data.patientAction.generalAction.name;
    const reminder = this.data.team.reminders;

    switch (generalActionType) {
      case GeneralActionTypes.INVITATION_NOT_ACCEPTED:
        return reminder.invitationReminder && reminder.invitationReminder.interval;
      case GeneralActionTypes.QUESTIONNAIRE_CHAT_REMINDER:
        return reminder.questionnaireSkippedReminder && reminder.questionnaireSkippedReminder.interval;
      case GeneralActionTypes.ACCOUNT_REMOVAL_REMINDER:
        return reminder.accountRemovalReminder && reminder.accountRemovalReminder.interval;
      default:
        return false;
    }
  }

  get isGeneralActionReminder() {
    return this.reminderInterval;
  }

  setReminderPropertiesDefault() {
    if (!(this.data.patientAction.__t === 'UserActionGeneral') && !this.data.patientAction.generalAction) return;

      const generalActionType = this.data.patientAction.generalAction.name;

      switch (generalActionType) {
        case GeneralActionTypes.INVITATION_NOT_ACCEPTED:
          if (this.invitationReminderAutoSend) return;
          this.resendInvitation = true;
          break;
        case GeneralActionTypes.QUESTIONNAIRE_CHAT_REMINDER:
          if (this.questionnaireSkippedReminderAutoSend) return;
          this.sendChatReminder = true;
          break;
        case GeneralActionTypes.ACCOUNT_REMOVAL_REMINDER:
          if (this.accountRemovalReminderAutoSend) return;
          this.archivePatient = true;
          break;
        default:
          break;
      }

  }

  getAcsResult(patientAction: PatientAction): number {
    let acsResult: number;

    if (patientAction.measurementResult &&
      patientAction.measurementResult.name === MeasurementResultTypes.ACS) {
        acsResult = patientAction.measurementResult.score;
      } else {
        acsResult = patientAction.acsForm.result;
      }

    return acsResult;
  }

  getAnswerByIndex(patientAction: PatientAction, index: number) {
    if (!patientAction) return;

    let answer: number;

    if (patientAction.__t === 'UserActionACS') {
      answer = patientAction.acsForm.questions[index].answer;
    } else {
      if (patientAction.measurementResult.questions[index].singleSelectionAnswer) {
        answer = patientAction.measurementResult.questions[index].singleSelectionAnswer.selectedAnswer.index;
      }

      if (patientAction.measurementResult.questions[index].textfieldAnswer) {
        answer = patientAction.measurementResult.questions[index].textfieldAnswer.answer;
      }
    }

    return answer.toString();
  }

  getBestResult(): SpirometerTestResult {
    return getBestFev1Result(this.data.patientAction, true) as SpirometerTestResult;
  }

  getAnswer(qIndex, aIndex) {
    // check if question list is saved in form, otherwise user current json from server
    if (
      !this.data.patientAction.acsForm.questions[qIndex].answerText &&
      this.acs.questions[qIndex]
    ) {
      return this.acs.questions[qIndex].possibleAnswers[aIndex];
    }
    return this.data.patientAction.acsForm.questions[qIndex].answerText;
  }

  toggleThreshold(event) {
    this.thresholdActive = event.checked;
  }

  async editThreshold() {
    const patientDialog = await this.dialog.open(PatientDialogComponent, {
      data: { patient: this.data.patient, editThreshold: true },
    });

    patientDialog
      .afterClosed()
      .pipe(take(1))
      .subscribe((patient) => {
        if (patient) {
          this.patientThresholdActive = patient.thresholdACS
            ? patient.thresholdACS.active
            : false;
          this.patientThreshold =
            patient.thresholdACS && patient.thresholdACS.active
              ? patient.thresholdACS.value
              : undefined;
        }
      });
  }

  async resolveAlert() {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: this.translateService.instant('resolveAlert.title'),// "Afhandelen",
        message: this.translateService.instant('resolveAlert.message') //"Weet u zeker dat u de melding wil afhandelen?",
      },
    });
    const confirmation = await confirmDialog
      .afterClosed()
      .pipe(take(1))
      .toPromise();
    if (!confirmation) {
      return;
    }

    /**
     *  TODO: We do a UpdatePatient action in the resolve success, this conflicts how thresholds are handled and for this reason
     *  we need to pass it to the Resolve action. We can't call an UpdatePatient action here to avoid race conditions.
     */
    const thresholdACS = this.thresholdActive
      ? { value: this.currentThreshold, active: this.thresholdActive }
      : undefined;

    this.store.dispatch(
      new patientActionActions.Resolve({
        patientId: this.data.patientAction.user,
        actionId: this.data.patientAction._id,
        thresholdACS,
      })
    );

    if (this.isGeneralActionReminder) {
      if (this.data.patient.isPending && this.resendInvitation) {
        this.dialogRef.close({resend: true});
        return;
      }

      if (this.sendChatReminder) {
        this.dialogRef.close({chatReminder: true});
        return;
      }


      if (this.archivePatient) {
        this.dialogRef.close({archivePatient: true});
        return;
      }
    }

    this.dialogRef.close();

  }

  async allResultsDialog(result: MeasurementResult) {
    const resultsDialog = this.dialog.open(ResultQualityDialogComponent, {
      data: {
        spirometerMeasurementResult: result.deviceResult.spirometerMeasurementResult
      },
      panelClass: 'result-quality-dialog'
    })
  }

  closeDialog() {
    this.dialogRef.close();
  }

  // TODO: Move all of this in a seperate function, we need to split the result dialog in several minor components depending on the measurement rseult type
  // Device Related Functions
  getMeasurementResultStatus(result: MeasurementResult): SpirometerTestResult[] {
    const goodResults = result.deviceResult.spirometerMeasurementResult.spirometerTestResults.filter(result => result.qualityMessage === QualityMessageTypes.GOOD_BLOW || result.qualityMessage === QualityMessageTypes.AVOID_COUGHING );
    return goodResults;
  }

  getBestPropertytResult(result: MeasurementResult, property: string) {
    const allResults = result.deviceResult.spirometerMeasurementResult.spirometerTestResults.map(result => result[property]);
    const bestResult = Math.max(...allResults);
    return bestResult ? bestResult : 0;
  }

  getMatchingPropertyResult(result: MeasurementResult, match: string, property: string) {
    const bestResult = result.deviceResult.spirometerMeasurementResult.spirometerTestResults.reduce((prev, curr) => {
      return prev[match] > curr[match] ? prev : curr;
    }, {});
    return bestResult ? bestResult[property] : 0;
  }

}
