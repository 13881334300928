import { Message } from 'src/app/store/message/message.model';
import { ActionPointType, ActionPointTypes, DeviceMeasurement } from "./../../store/shared.model";
import { ActionDialogComponent } from "../../dialogs/action-dialog/action-dialog.component";
import { EvaluateAfterDialogComponent } from "../../dialogs/evaluate-after-dialog/evaluate-after-dialog.component";
import { MedicineDialogComponent } from "../../dialogs/medicine-dialog/medicine-dialog.component";
import { InviteDialogComponent } from "../../dialogs/invite-dialog/invite-dialog.component";
import { PatientDialogComponent } from "./../../dialogs/patient-dialog/patient-dialog.component";
import { UseEpipenDialogComponent } from "../../dialogs/use-epipen-dialog/use-epipen-dialog.component";

import { Component, OnInit, OnDestroy, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import * as fromRoot from "../../store";
import * as patientActions from "../../store/patient/patient.actions";
import * as patientActionActions from "../../store/patientAction/patientAction.actions";
import * as messageActions from "../../store/message/message.actions";
import { Store } from "@ngrx/store";

import { ArchiveReasonType, ArchivedOptions, ColorZone, Patient } from "../../store/patient/patient.model";
import { PatientAction, MeasurementResultTypes, GeneralActionTypes } from "../../store/patientAction/patientAction.model";
import { Medicine } from "../../store/medicine/medicine.model";
import { MatDialogRef, MatDialog, Sort } from "@angular/material";
import {
	formatPhoneNumber,
	formatBirthDate,
	getColor,
	isYesterday,
	isToday,
	getThresholdValueName,
	getColorZone,
	getBestFev1Result,
} from "./../../util/helper-functions";
import { ConfirmDialogComponent } from "../../dialogs/confirm-dialog/confirm-dialog.component";
import { take, filter, skip, map, tap } from "rxjs/operators";
import { Observable, interval, Subscription, combineLatest } from "rxjs";
import { ResultDialogComponent } from "src/app/dialogs/result-dialog/result-dialog.component";
import { WEEKDAYS } from "../../store";
import { ApiService } from "src/app/services/api.service";
import { Team } from "src/app/store/team/team.model";
import { LoadTeamAction } from "src/app/store/settings/settings.actions";
import {
	ActionPlan,
	MedicineActionPoint,
	ActionPoint,
} from "src/app/store/shared.model";
import { OptionsDialogComponent } from 'src/app/dialogs/options-dialog/options-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: "app-patient-detail",
	templateUrl: "./patient-detail.component.html",
	styleUrls: ["./patient-detail.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class PatientDetailViewComponent implements OnInit, OnDestroy {
	chartView: "acs" | "fev1" = "acs";
	team$: Observable<Team>;
	patient$: Observable<Patient>;
	patientActions$: Observable<PatientAction[]>;
	patientActions: PatientAction[];
	filteredPatientActions$: Observable<PatientAction[]>;
	medicationById$: Observable<Medicine[]>;
	spirometerActive$: Observable<boolean>;
	displaySpirometerToggle$: Observable<boolean>;
	spirometerMeasurement$: Observable<DeviceMeasurement>;
	isPosting: boolean;
	actionDialog: MatDialogRef<ActionDialogComponent>;
	evaluateAfterDialog: MatDialogRef<EvaluateAfterDialogComponent>;
	patientDialog: MatDialogRef<PatientDialogComponent>;
	medicationDialog: MatDialogRef<MedicineDialogComponent>;
	inviteDialog: MatDialogRef<InviteDialogComponent>;
	useEpipenDialog: MatDialogRef<UseEpipenDialogComponent>;
	sort: Sort;
	selectedIndex = 0;
	allowBack = false;
	origin: string;
	getColor = getColor;
	getColorZone = getColorZone;
	isYesterday = isYesterday;
	isToday = isToday;
	updateSub: Subscription;
	updateSubChat: Subscription;
	prod: boolean;
	weekdays = WEEKDAYS;
	GeneralActionTypes = GeneralActionTypes;
	MeasurementResultTypes = MeasurementResultTypes;
	optionalSettings: { instructionsBy: string[], delivery: string, sendOrderInstructionsScheduled: boolean, orderInstructionsSentAt: Date };
	acsActions$: Observable<any>;
	notifyDay$: Observable<
		| "maandag"
		| "dinsdag"
		| "woensdag"
		| "donderdag"
		| "vrijdag"
		| "zaterdag"
		| "zondag"
	>;
	formatBirthDate: (date: string) => string = formatBirthDate;
	formatPhoneNumber: (phonenumber: string, countryCode: string) => string = formatPhoneNumber;
	getThresholdValueName: (num: number) => number = getThresholdValueName;

	constructor(
		private store: Store<fromRoot.State>,
		private router: Router,
		private route: ActivatedRoute,
		private dialog: MatDialog,
		private api: ApiService,
		private translateService: TranslateService
	) {
		this.weekdays = Object.values(this.translateService.instant('daysOfWeek'));
	}

	ngOnInit() {
		this.route.params.pipe(take(1)).subscribe((params) => {
			this.origin = params.origin;
			if (params.chat) {
				this.allowBack = params.chat;
			}
			switch (params.tab) {
				case "chat":
					this.selectedIndex = 1;
					break;
				case "plan":
					this.selectedIndex = 2;
					break;
				default:
					break;
			}

			this.store.dispatch(new LoadTeamAction());

			// get one patient
			this.store.dispatch(new patientActions.Select(params.patientId));
			this.store.dispatch(new patientActions.GetOne(params.patientId));

			// get patient actions
			this.store.dispatch(new patientActionActions.Select(params.patientId));
			this.store.dispatch(
				new patientActionActions.LoadPatientActions({
					patientId: params.patientId,
				})
			);
		});

		this.team$ = this.store.select(fromRoot.getTeam);
		this.patient$ = this.store.select(fromRoot.getSelectedPatient);
		this.medicationById$ = this.store.select(
			fromRoot.getSelectedPatientMedicationById
		);

		this.patientActions$ = this.store
			.select(fromRoot.getSelectedPatientActions)
			.pipe(
				filter((actions) => actions !== undefined),
				map((actions) => {
					this.patientActions = actions;
					const reversed = Array.from(actions).reverse();
					return reversed;
				})
			);

		this.acsActions$ = this.patientActions$.pipe(
			filter((actions) => actions !== undefined),
			map((actions) =>
				actions
					.filter((action) => action.acsForm && action.result !== undefined || action.__t === 'UserActionMeasurement' && action.measurementResult.name === MeasurementResultTypes.ACS)
					.slice(0, 15)
					.reverse()
			)
		);

		this.isPosting = false;
		const full = location.host;
		const subDomain = full.split(".")[0];
		this.prod = !(
			location.hostname === "localhost" ||
			location.hostname === "127.0.0.1" ||
			subDomain === "test" ||
			subDomain === "staging"
		);

		this.updateSub = interval(10000).subscribe(async () => {
			const p = await this.patient$.pipe(take(1)).toPromise();

			if (p === undefined) {
				this.router.navigate(['/']);
				return;
			}

			this.store.dispatch(
				new patientActions.LoadPatients({ unresolvedOnly: false })
			);
			this.store.dispatch(
				new patientActionActions.LoadPatientActions({ patientId: p._id })
			);
		});

		this.updateSubChat = interval(7000).subscribe(async () => {
			const p = await this.patient$.pipe(take(1)).toPromise();
			if (p) {
				this.store.dispatch(new messageActions.LoadMessages({ id: p._id }));
			}
		});

		this.spirometerMeasurement$ = this.store.select(
			fromRoot.getMeasurementByName("spirometer")
		) as Observable<DeviceMeasurement>;

		this.displaySpirometerToggle$ = combineLatest(this.patient$, this.patientActions$).pipe(
			filter(([p, _]) => p !== undefined),
			map(([patient, patientActions]) => {

				if (!patientActions || patientActions.length === 0) return false;

				if (patientActions && patientActions.length > 0) {
					return patientActions.find(action => action.__t === 'UserActionMeasurement' && action.measurementResult.name === MeasurementResultTypes.SPIROMETER) !== undefined;
				}

				if (patient.measurements && patient.measurements.length > 0) {
					const patientSpirometerMeasurement = patient.measurements.find(measurement => measurement.name === MeasurementResultTypes.SPIROMETER);

					if (!patientSpirometerMeasurement) return false;

					return patientSpirometerMeasurement.enabled;

				}

				return false;
			})
		)

		this.spirometerActive$ = combineLatest(
			this.patient$,
			this.spirometerMeasurement$
		).pipe(
			filter(([p, _]) => p !== undefined),
			map(([patient, spirometerMeasurement]) => {
				if (!spirometerMeasurement) return false;
				const hasSpirometer = patient.measurements.find(
					(measurement) =>
						measurement.measurementId === spirometerMeasurement._id
				);

				// Get optional settings related to the spirometer
				if (hasSpirometer) {
					let instructionsBy: string[] = [];
					if (hasSpirometer.measurementConfiguration) {
						// translate
						const translatedInstructions = this.translateService.instant('patient.extraSettings.optionalSettings.instruction');
						for (let instr of hasSpirometer.measurementConfiguration.instructionsBy) {
							if (instr === 'longfunctie') {
								instructionsBy.push(translatedInstructions.lungFunction);
							} else if (instr === 'verpleegkundige') {
								instructionsBy.push(translatedInstructions.nurse);
							} else if (instr === 'arts') {
								instructionsBy.push(translatedInstructions.doctor);
							}
						}
					}


					this.optionalSettings = {
						instructionsBy: instructionsBy,
						delivery: hasSpirometer.measurementConfiguration ? hasSpirometer.measurementConfiguration.delivery : '',
						sendOrderInstructionsScheduled: hasSpirometer.measurementConfiguration ? patient.sendOrderInstructionsScheduled : false,
						orderInstructionsSentAt: hasSpirometer.measurementConfiguration ? patient.orderInstructionsSentAt : null
					}
				}

				return hasSpirometer ? hasSpirometer.enabled : false;
			})
		);

	}

	ngOnDestroy() {
		if (this.updateSub) {
			this.updateSub.unsubscribe();
		}
		if (this.updateSubChat) {
			this.updateSub.unsubscribe();
		}
	}

	isPosting$() {
		return this.store
			.select(fromRoot.isPostingPatient)
			.pipe(filter((isPosting) => isPosting === false))
			.pipe(take(1))
			.toPromise();
	}

	changeActionPlanOrder({
		colorZone,
		actions,
	}: {
		colorZone: ColorZone;
		actions: MedicineActionPoint[];
	}) {

		const actionIds = actions.map((action) => action._id);

		this.patient$.pipe(take(1)).subscribe((patient) => {
			this.store.dispatch(
				new patientActions.UpdateActionOrder({
					order: actionIds,
					colorZone,
					id: patient._id,
				})
			);
		});
	}

	get hasOptionalSettings() {
		return this.optionalSettings !== undefined && this.optionalSettings.instructionsBy && this.optionalSettings.instructionsBy.length > 0 || this.optionalSettings !== undefined && this.optionalSettings.delivery && this.optionalSettings.delivery.length > 0
	}

	selectedIndexChange(val: number) {
		this.selectedIndex = val;
	}

	editEvaluateAfter(event) {
		this.patient$.pipe(take(1)).subscribe((patient) => {
			this.evaluateAfterDialog = this.dialog.open(
				EvaluateAfterDialogComponent,
				{
					data: {
						evaluateAfter: patient.actionPlan[event.colorZone].evaluateAfter,
						patient,
						colorZone: event.colorZone,
					},
					panelClass: event.colorZone,
				}
			);
		});
	}

	medicineAction(action: MedicineActionPoint | ActionPoint) {
		return (action as MedicineActionPoint).medicine !== undefined
			? (action as MedicineActionPoint)
			: undefined;
	}

	generalAction(action: MedicineActionPoint | ActionPoint) {
		return (action as MedicineActionPoint).medicine === undefined
			? (action as ActionPoint)
			: undefined;
	}

	editUseEpipen() {
		this.patient$.pipe(take(1)).subscribe((patient) => {
			this.useEpipenDialog = this.dialog.open(UseEpipenDialogComponent, {
				data: {
					useEpipen: patient.actionPlan.orange.useEpipen,
					patient,
					colorZone: "orange",
				},
				panelClass: "orange",
			});
		});
	}

	async editPatient() {
		const patient = await this.patient$.pipe(take(1)).toPromise();
		const team = await this.team$.pipe(take(1)).toPromise();
		let hideSpirometerForm;
		let patientSpirometer;
		let teamSpirometer;

		if (patient.measurements) {
			const spirometerMeasurement = patient.measurements.find(
				(measurement) =>
					measurement.name === MeasurementResultTypes.SPIROMETER
			);

			patientSpirometer = spirometerMeasurement !== undefined ? spirometerMeasurement.enabled : false;
		}

		if (team.devices) {
			teamSpirometer = team.devices.spirometer;
		}

		hideSpirometerForm = !(patientSpirometer && teamSpirometer);

		this.patientDialog = this.dialog.open(PatientDialogComponent, {
			data: { patient, hideSpirometerForm },
		});
	}

	async toggleSpirometer(patient: Patient) {
		const spirometer = await this.spirometerMeasurement$
			.pipe(
				filter((m) => m !== undefined),
				take(1)
			)
			.toPromise();

		const patientSpirometer = patient.measurements.find(
			(measurement) =>
				measurement.measurementId === (spirometer as DeviceMeasurement)._id
		);

		if (patientSpirometer && patientSpirometer.enabled) {
			const measurementIndex = patient.measurements.findIndex(
				(measurement) =>
					measurement.measurementId === patientSpirometer.measurementId
			);
			const updatedPatientSpirometer = {
				...patientSpirometer,
				monitoringEnabled: false,
				enabled: false,
			};

			patient.measurements[measurementIndex] = updatedPatientSpirometer;

			this.store.dispatch(
				new patientActions.UpdatePatient({
					item: patient,
					id: patient._id,
				})
			);
		} else {
			const patientDialog = this.dialog.open(PatientDialogComponent, {
				data: {
					patient,
					device: true,
					deviceId: (spirometer as DeviceMeasurement)._id,
					hasDevice: patientSpirometer !== undefined,
				},
			});

			patientDialog
				.afterClosed()
				.pipe(take(1))
				.toPromise()
				.then((response) => {
					console.log("res", response);
				});
		}
	}

	async toggleOptionalSettings(patient: Patient) {
		const spirometer = await this.spirometerMeasurement$
			.pipe(
				filter((m) => m !== undefined),
				take(1)
			)
			.toPromise();

		const patientSpirometer = patient.measurements.find(
			(measurement) =>
				measurement.measurementId === (spirometer as DeviceMeasurement)._id
		);

		const patientDialog = this.dialog.open(PatientDialogComponent, {
			data: {
				patient,
				device: true,
				optionalSettings: true,
				deviceId: (spirometer as DeviceMeasurement)._id,
				hasDevice: patientSpirometer !== undefined,
			},
		});

		patientDialog
			.afterClosed()
			.pipe(take(1))
			.toPromise()
			.then((response) => {
				console.log("res", response);
			});
	}

	async archivePatient() {
		if (this.isPosting) {
			return;
		}

		const options = [
			{
				value: ArchiveReasonType.FIRST_LINE,
				title: this.translateService.instant('patientDialog.archiveOptions.firstLine'),
			},
			{
				value: ArchiveReasonType.NOT_ACTIVE,
				// title: "Patiënt doet niet actief mee",
				title: this.translateService.instant('patientDialog.archiveOptions.notActive'),
			},
			{
				value: ArchiveReasonType.NOT_PARTICIPATING,
				// title: "Patiënt wil niet meer meedoen",
				title: this.translateService.instant('patientDialog.archiveOptions.notParticipating'),
			},
			{
				value: ArchiveReasonType.OTHER,
				// title: "Anders",
				title: this.translateService.instant('patientDialog.archiveOptions.other'),
				showInput: true,
			},
		]

		const optionsDialog = this.dialog.open(OptionsDialogComponent, {
			data: {
				title: this.translateService.instant('patientDialog.archivePatient.title'), //"Deactiveer patiënt",
				description: this.translateService.instant('patientDialog.archivePatient.description'),
				options: ArchivedOptions,
				confirmText: this.translateService.instant('patientDialog.archivePatient.confirmText')
			},
		});

		const reason = await optionsDialog.afterClosed().pipe(take(1)).toPromise();
		if (!reason) {
			return;
		}

		this.patient$.pipe(take(1)).subscribe((patient) => {
			this.store.dispatch(
				new patientActions.ArchivePatient({ id: patient._id, reason })
			);
			this.isPosting$().then(() => {
				this.isPosting = false;
				this.router.navigate(["patienten"]);
			});
		});
	}

	async removePatient() {
		if (this.isPosting) {
			return;
		}
		const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
			data: {
				title: this.translateService.instant('patientDialog.removePatient.title'), //"Verwijder patient",
				message: this.translateService.instant('patientDialog.removePatient.message'),
			},
		});
		console.log("open delete dialog");
		const confirmation = await confirmDialog
			.afterClosed()
			.pipe(take(1))
			.toPromise();
		if (!confirmation) {
			return;
		}
		this.patient$.pipe(take(1)).subscribe((patient) => {
			console.log(" delete ");
			this.store.dispatch(
				new patientActions.DeletePatient({ id: patient._id })
			);
			this.isPosting$().then(() => {
				this.isPosting = false;
				this.router.navigate(["patienten"]);
			});
		});
	}

	createMedication(medicine) {
		this.patient$.pipe(take(1)).subscribe((patient) => {
			this.medicationDialog = this.dialog.open(MedicineDialogComponent, {
				data: { medicine, patient },
			});
		});
	}

	createInvite(medicine) {
		this.patient$.pipe(take(1)).subscribe((patient) => {
			this.inviteDialog = this.dialog.open(InviteDialogComponent, {
				data: { medicine, patient },
			});
		});
	}

	async resendInvite(skipConfirm = false) {
		let agree = skipConfirm;

		if (!skipConfirm) {
			const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
				data: {
					title: this.translateService.instant('patientDialog.resendInvite.title'),
					message: this.translateService.instant('patientDialog.resendInvite.message'),
				},
			});

			agree = await confirmDialog.afterClosed().pipe(take(1)).toPromise();
		}

		if (agree) {
			console.log('resendInvite')
			this.patient$.pipe(take(1)).subscribe(async (patient) => {
				return this.api.resendInvite(patient._id).subscribe(console.log);
			});
		}
	}


	async unarchivePatient(patientId) {
		let agree = false;

		const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
			data: {
				title: this.translateService.instant('patientDialog.resendInvite.title'),
				message: this.translateService.instant('patientDialog.resendInvite.message'),
			},
		});

		agree = await confirmDialog.afterClosed().pipe(take(1)).toPromise();


		if (agree) {
			this.store.dispatch(new patientActions.UnarchivePatient({ id: patientId }))
		}
	}


	getFev1ResultValue(patientAction: PatientAction) {
		if (patientAction.__t !== 'UserActionMeasurement' || patientAction.measurementResult === undefined) return false;

		const devicePatientAction = this.patientActions.filter(action => action.measurementResult && action.measurementResult._id === patientAction.measurementResult.referenceId);

		if (!devicePatientAction || devicePatientAction.length === 0) return false;

		return getBestFev1Result(devicePatientAction[0]) ? (getBestFev1Result(devicePatientAction[0]) as number).toFixed(0) : 0;
	}

	createAction({
		colorZone,
		action,
		type,
	}: {
		colorZone: ColorZone;
		action: MedicineActionPoint;
		type: ActionPointType;
	}) {
		if (action && action.actionType === ActionPointTypes.GENERAL) {
			type = ActionPointTypes.GENERAL;
		}

		this.patient$.pipe(take(1)).subscribe((patient) => {
			this.actionDialog = this.dialog.open(ActionDialogComponent, {
				data: {
					colorZone,
					patient,
					action,
					type,
				},
				panelClass: colorZone,
			});
		});
	}

	back() {
		if (this.origin) {
			return this.router.navigate([this.origin]);
		}

		if (this.allowBack) {
			this.router.navigate(["berichten"]);
		} else {
			console.log("patienten url");
			this.router.navigate(["patienten"]);
		}
	}

	answerList(answers: Array<any> | undefined) {
		if (!answers || answers.length < 0) {
			return;
		}
		return answers.map((a) => a.title).join(", ");
	}

	sendChatReminder(patient: Patient, message: Message) {
		this.store.dispatch(
			new messageActions.AddMessage({ id: patient._id, item: message })
		);
	}

	async openPatientAction(patientAction: PatientAction) {
		const team = await this.team$.pipe(filter(t => t !== undefined), take(1)).toPromise();
		const patient = await this.patient$
			.pipe(
				filter((p) => p !== undefined),
				take(1)
			)
			.toPromise();

		let showSpirometerView = false;
		let previousActions: PatientAction[];

		if (patientAction.__t === 'UserActionMeasurement' && patientAction.measurementResult.name === MeasurementResultTypes.SPIROMETER) {
			const relatedPatientAction = await this.patientActions$.pipe(take(1), map(actions => {
				return actions.filter(action => action.measurementResult && action.measurementResult._id === patientAction.measurementResult.referenceId);
			})).toPromise();
			patientAction = relatedPatientAction[0];
			showSpirometerView = true;
		}

		if (patientAction.__t === "UserActionACS" || patientAction.__t === 'UserActionMeasurement' && patientAction.measurementResult.name === MeasurementResultTypes.ACS) {
			previousActions = await this.patientActions$
				.pipe(
					take(1),
					map((actions) => {
						return actions
							.filter(
								(a) =>
									a.__t === "UserActionACS" &&
									a._id !== patientAction._id &&
									a.createdAt < patientAction.createdAt ||
									a.__t === "UserActionMeasurement" &&
									a._id !== patientAction._id &&
									a.measurementResult.name === MeasurementResultTypes.ACS &&
									a.createdAt < patientAction.createdAt
							)
							.slice(0, 2);
					})
				)
				.toPromise();
		}

		if (
			!(
				patientAction.__t === "UserActionACS" ||
				patientAction.__t === "UserActionMeasurement" ||
				patientAction.__t === "UserActionSelfHelp" ||
				patientAction.resolutionRequired
			) ||
			(patientAction.__t === "UserActionGeneral" && patientAction.resolvedAt)
		) {
			return;
		}

		const panelClass =
			patientAction.__t === "UserActionGeneral" || patientAction.__t === "UserActionACS" || patientAction.__t === 'UserActionMeasurement' && patientAction.measurementResult.name === MeasurementResultTypes.ACS
				? ["result-dialog", "result-dialog--acs"]
				: "result-dialog";
		const isSelfHelp = patientAction.__t === "UserActionSelfHelp" || patientAction.measurementResult !== undefined && patientAction.measurementResult.name === MeasurementResultTypes.ACTIONPLAN;

		const resultDialog = this.dialog.open(ResultDialogComponent, {
			data: {
				patient,
				team,
				patientAction,
				previousActions,
				isSelfHelp,
				showSpirometerView,
			},
			width: "100vw",
			panelClass,
		});

		resultDialog
			.afterClosed()
			.pipe(take(1))
			.subscribe(data => {
				if (data && data.resend) {
					this.resendInvite(true);
				}

				if (data && data.chatReminder) {
					const message: Message = {
						automated: true,
						text: team.reminders.questionnaireSkippedReminder.message
					}
					this.sendChatReminder(patient, message);
				}

				if (data && data.archivePatient) {
					this.archivePatient();
				}
			})
	}
}
