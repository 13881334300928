import { MonitoringDay } from "../auth/auth.model";
import { CareProvider } from "../careprovider/careprovider.model";
import { Org } from "../org/org.model";
import { MeasurementConfig } from "..";
import { ActionPlan } from "../shared.model";

export interface ChatReply {
  _id?: string;
  title: string;
  message: string;
}

export interface Reminder {
  _id?: string;
  interval: number;
  autoSend: boolean;
  active: boolean;
  message?: string;
}

export interface Alerts {
  chatMailAlerts?: boolean;
}

export interface Team {
  _id?: string;
  name: string;
  email: string;
  organization?: any; // Org | string
  careProviders?: CareProvider[];
  devices?: {
    spirometer?: boolean;
    order_by_patient?: boolean;
    order_message?: string;
    order_subject?: string;
  };
  standardChatReplies?: ChatReply[];
  createdAt?: Date;
  modifiedAt?: Date;
  measurementConfigurations?: MeasurementConfig[];
  onboardingQuestionaire: string;
  baseActionPlan?: ActionPlan;
  reminders?: {
    invitationReminder?: Reminder;
    questionnaireSkippedReminder?: Reminder;
    accountRemovalReminder?: Reminder;
  };
  alerts?: Alerts;
  monitoringSettings?: {
    days: [
      MonitoringDay,
      MonitoringDay,
      MonitoringDay,
      MonitoringDay,
      MonitoringDay,
      MonitoringDay,
      MonitoringDay
    ];
  };
}

export enum ReminderTypes {
  INVITATION_REMINDER = "invitationReminder",
  QUESTIONNAIRE_SKIPPED_REMINDER = "questionnaireSkippedReminder",
  ACCOUNT_REMOVAL_REMINDER = "accountRemovalReminder",
}

export type ReminderType =
  | ReminderTypes.INVITATION_REMINDER
  | ReminderTypes.QUESTIONNAIRE_SKIPPED_REMINDER
  | ReminderTypes.ACCOUNT_REMOVAL_REMINDER;
