import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { StoreError } from './error.model';

export enum ErrorActionTypes {
  LoadErrors = '[Error] Load Errors',
  AddError = '[Error] Add Error',
  UpsertError = '[Error] Upsert Error',
  AddErrors = '[Error] Add Errors',
  UpsertErrors = '[Error] Upsert Errors',
  UpdateError = '[Error] Update Error',
  UpdateErrors = '[Error] Update Errors',
  DeleteError = '[Error] Delete Error',
  DeleteErrors = '[Error] Delete Errors',
  ClearErrors = '[Error] Clear Errors'
}

export class LoadErrors implements Action {
  readonly type = ErrorActionTypes.LoadErrors;

  constructor(public payload: { errors: StoreError[] }) {}
}

export class AddError implements Action {
  readonly type = ErrorActionTypes.AddError;

  constructor(public payload: { error: StoreError }) {}
}

export class UpsertError implements Action {
  readonly type = ErrorActionTypes.UpsertError;

  constructor(public payload: { error: StoreError }) {}
}

export class AddErrors implements Action {
  readonly type = ErrorActionTypes.AddErrors;

  constructor(public payload: { errors: StoreError[] }) {}
}

export class UpsertErrors implements Action {
  readonly type = ErrorActionTypes.UpsertErrors;

  constructor(public payload: { errors: StoreError[] }) {}
}

export class UpdateError implements Action {
  readonly type = ErrorActionTypes.UpdateError;

  constructor(public payload: { error: Update<StoreError> }) {}
}

export class UpdateErrors implements Action {
  readonly type = ErrorActionTypes.UpdateErrors;

  constructor(public payload: { errors: Update<StoreError>[] }) {}
}

export class DeleteError implements Action {
  readonly type = ErrorActionTypes.DeleteError;

  constructor(public payload: { id: string }) {}
}

export class DeleteErrors implements Action {
  readonly type = ErrorActionTypes.DeleteErrors;

  constructor(public payload: { ids: string[] }) {}
}

export class ClearErrors implements Action {
  readonly type = ErrorActionTypes.ClearErrors;
}

export type ErrorActions =
 LoadErrors
 | AddError
 | UpsertError
 | AddErrors
 | UpsertErrors
 | UpdateError
 | UpdateErrors
 | DeleteError
 | DeleteErrors
 | ClearErrors;
