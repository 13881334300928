import { CareProviderDialogComponent } from 'src/app/dialogs/admin-careprovider-dialog/admin-careprovider-dialog.component';
import { Component, OnInit, OnDestroy } from '@angular/core';
import * as fromRoot from '../../store';
import { Store } from '@ngrx/store';
import { CareProvider } from 'src/app/store/careprovider/careprovider.model';
import { Observable, Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { MatTableDataSource, Sort, MatDialogRef, MatDialog } from '@angular/material';
import { sortData } from 'src/app/util/helper-functions';
import { Org } from 'src/app/store/org/org.model';
import { Team } from 'src/app/store/team/team.model';

@Component({
  selector: 'app-admin-careproviders',
  templateUrl: './admin-careproviders.component.html',
  styleUrls: ['./admin-careproviders.component.scss']
})
export class CareProvidersViewComponent implements OnInit, OnDestroy {
  public careProviders$: Observable<CareProvider[]>;
  public displayedColumns = ['firstName', 'lastName', 'email'];
  public dataSource = new MatTableDataSource<any>([]);
  public sort: Sort;
  private teams$: Observable<Team[]>;
  private orgs$: Observable<Org[]>;
  private careProviderDialog: MatDialogRef<CareProviderDialogComponent>;
  private careProvidersSub: Subscription;
  private careProviders: CareProvider[];

  constructor(private store: Store<fromRoot.State>, private dialog: MatDialog,) { }

  ngOnInit() {
    this.teams$ = this.store.select(fromRoot.getTeams).pipe(take(1));
    this.orgs$ = this.store.select(fromRoot.getOrgs).pipe(take(1));
    this.careProviders$ = this.store.select(fromRoot.getCareProviders).pipe(
      filter(c => c !== undefined),
      map(careProviders => {
        return careProviders.sort((cpA, cpB) => {
          // Sort by alphabetically
          const nameA = cpA.email.toLowerCase();
          const nameB = cpB.email.toLowerCase();
          return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
        });
    }));

    this.careProvidersSub = this.careProviders$.pipe(filter(c => c !== undefined)).subscribe(careProviders => {
      this.careProviders = careProviders;
      this.sortData(this.sort);
    });
  }

  ngOnDestroy(): void {
    if (this.careProvidersSub) {
      this.careProvidersSub.unsubscribe();
    }
  }

  async createCareProvider() {
    const teams = await this.teams$.toPromise();
    const orgs = await this.orgs$.toPromise();
    this.careProviderDialog = this.dialog.open(CareProviderDialogComponent, { data: {
      fromOverview: true,
      teams,
      orgs
    } });
  }

  async editCareProvider(careProvider) {
    const teams = await this.teams$.toPromise();
    const orgs = await this.orgs$.toPromise();
    this.careProviderDialog = this.dialog.open(CareProviderDialogComponent, { data: {
      fromOverview: true,
      careProvider,
      teams,
      orgs
    } });
  }


  sortData(sort: Sort) {
    this.dataSource = sortData(sort, this.dataSource, this.careProviders);
  }

}
