import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Org } from './../../store/org/org.model';
import { StoreError } from '../../store/error/error.model';
import { filter, take, map } from 'rxjs/operators';
import * as fromRoot from '../../store';
import * as orgActions from '../../store/org/org.actions';
import * as errorActions from '../../store/error/error.actions';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-admin-org-dialog',
  templateUrl: './admin-org-dialog.component.html',
  styleUrls: ['./admin-org-dialog.component.scss']
})

export class OrgDialogComponent implements OnInit, OnDestroy {
  dialogForm: FormGroup;
  phoneForm: FormGroup;
  contactInfoForm: FormArray;
  isPosting: boolean;
  errorMessage: string;
  orgTemplate = {
    name: '',
    contactInfo: [{
      title: '',
      info: '',
      number: ''
    }]
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { org: Org },
	private translateService: TranslateService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<OrgDialogComponent>,
    private router: Router,
    private store: Store<fromRoot.State>,
    private formBuilder: FormBuilder
  ) { }

  ngOnDestroy() {
    this.store.dispatch(new errorActions.ClearErrors());
  }

  ngOnInit() {
    this.isPosting = false;

    this.store.dispatch(new errorActions.ClearErrors());

    let org = this.data.org ? (this.data.org as Org) : undefined;
    if (!org) {
      org = this.orgTemplate;
    }
    if (!org.contactInfo || !org.contactInfo.length) {
      org.contactInfo = this.orgTemplate.contactInfo;
    }

    this.contactInfoForm = this.formBuilder.array(org.contactInfo.map(c => {
      return this.createContactForm(c);
    }));

    console.log(this.contactInfoForm);
    this.dialogForm = this.formBuilder.group({
      _id: [org._id],
      name: [org.name],
      contactInfo: this.contactInfoForm
    });


    this.store
      .select(fromRoot.getErrors)
      .pipe(filter(errors => errors && errors.length > 0))
      .pipe(map(errors => errors[0]))
      .pipe(filter(error => error.apiError !== undefined))
      .subscribe((error: StoreError) => {
        this.isPosting = false;
        switch (error.apiError.code) {
          default:
            this.errorMessage = this.translateService.instant('adminOrgDialog.errorMessage') // 'Er is iets fout gegaan';
            break;
        }
      });
  }

  isPosting$() {
    return this.store
      .select(fromRoot.isPostingOrg)
      .pipe(filter(isPosting => isPosting === false))
      .pipe(take(1))
      .toPromise();
  }

  createOrg() {
    if (this.isPosting) {
      return;
    }
    this.isPosting = true;
    this.errorMessage = undefined;
    const org = this.parseForm(this.dialogForm.value);
    this.store.dispatch(new orgActions.AddOrg({ item: org }));

    this.isPosting$().then(() => {
      this.isPosting = false;
      this.dialogRef.close();
      this.store
        .select(fromRoot.getSelectedOrgId)
        .pipe(take(1))
        .subscribe(selectedId => {
          this.router.navigate(['admin/organisaties/' + selectedId]);
        });
    });
  }

  patchOrg() {
    if (this.isPosting) {
      return;
    }
    this.isPosting = true;
    this.errorMessage = undefined;
    const org = this.parseForm(this.dialogForm.value);
    console.log('patch org', org);
    this.store.dispatch(new orgActions.UpdateOrg({ item: org, id: org._id }));
    this.isPosting$().then(() => {
      this.isPosting = false;
      this.dialogRef.close();
    });
  }

  async deleteOrg(org: Org) {
    console.log(org);
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: this.translateService.instant('adminOrgDialog.errorMessage'), //'Afhandelen',
        message:this.translateService.instant('adminOrgDialog.errorMessage')
        //   'Weet je zeker dat je de organisatie wilt verwijderen?'
      }
    });
    const confirmation = await confirmDialog
      .afterClosed()
      .pipe(take(1))
      .toPromise();
    if (!confirmation) {
      return;
    }
    this.store.dispatch(new orgActions.DeleteOrg({ id: org._id }));
  }

  cancel() {
    this.dialogRef.close();
  }

  createContactForm(c) {
    return new FormGroup({
      title: new FormControl(c.title),
      info: new FormControl(c.info),
      number: new FormControl(c.number)
    });
  }

  deleteContactInfo(form: FormArray, i: number) {
    console.log(i, (form as FormArray).controls);
    if (form.controls.length > 1) {
      (form as FormArray).removeAt(i);
    }
  }

  addContactInfo(form: FormArray) {
    form.push(this.createContactForm(this.orgTemplate.contactInfo[0]));
  }

  pad(padData) {
    return ('0' + padData).slice(-2);
  }

  private parseForm(formValue) {
    if (!formValue) {
      return formValue;
    }
    return formValue;
  }
}
