import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { StoreError } from './error.model';
import { ErrorActions, ErrorActionTypes } from './error.actions';
import { createFeatureSelector } from '@ngrx/store';
import { v4 as uuid } from 'uuid';
import { AuthActions, AuthActionTypes } from '../auth/auth.actions';

export interface State extends EntityState<StoreError> {
  // additional entities state properties
}

// Newest errors first
const sortByDate = (a: StoreError, b: StoreError) => {
  if (a.created > b.created) {
    return -1;
  } else if (a.created < b.created) {
    return 1;
  } else {
    return 0;
  }
};

export const adapter: EntityAdapter<StoreError> = createEntityAdapter<StoreError>({
  sortComparer: sortByDate
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export function reducer(state = initialState, action: ErrorActions | AuthActions): State {
  switch (action.type) {
    case ErrorActionTypes.AddError: {
      const payloadError = action.payload.error;
      const error = { id: uuid(), created: new Date(), status: 0, apiError: { code: 0, message: '' }, type: '' };
      if (payloadError.content && payloadError.content.status) {
        error.status = payloadError.content.status;
      }
      if (payloadError.content && payloadError.content._body) {
        console.log('payloadError.content._body', payloadError.content._body);
        let response;
        try {
          response = JSON.parse(payloadError.content._body);
        } catch {
          response = {
            code: payloadError.content.status,
            message: payloadError.content._body,
          };
        }
        if (response && response.code && response.message) {
          error.apiError = { code: response.code, message: response.message };
        }
      }
      if (payloadError.source) {
        error.type = payloadError.source.type;
      }
      return adapter.addOne(error, state);
    }

    case ErrorActionTypes.UpsertError: {
      return adapter.upsertOne(action.payload.error, state);
    }

    case ErrorActionTypes.AddErrors: {
      return adapter.addMany(action.payload.errors, state);
    }

    case ErrorActionTypes.UpsertErrors: {
      return adapter.upsertMany(action.payload.errors, state);
    }

    case ErrorActionTypes.UpdateError: {
      return adapter.updateOne(action.payload.error, state);
    }

    case ErrorActionTypes.UpdateErrors: {
      return adapter.updateMany(action.payload.errors, state);
    }

    case ErrorActionTypes.DeleteError: {
      return adapter.removeOne(action.payload.id, state);
    }

    case ErrorActionTypes.DeleteErrors: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case ErrorActionTypes.LoadErrors: {
      return adapter.addAll(action.payload.errors, state);
    }

    case ErrorActionTypes.ClearErrors: {
      return adapter.removeAll(state);
    }

    case AuthActionTypes.LogoutSuccess: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const getState = (state: State) => state;

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors(getState);
