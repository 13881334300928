import { Action } from "@ngrx/store";
import { Update } from "@ngrx/entity";
import { Team } from "./team.model";

export enum TeamActionTypes {
  LoadTeams = "[Team] Load Teams",
  LoadTeamsSuccess = "[Team] Load Teams Success",
  GetOne = "[Team] Get One",
  GetOneSuccess = "[Team] Get One Success",
  AddTeam = "[Team] Add Team",
  AddTeamSuccess = "[Team] Add Team Complete",
  UpsertTeam = "[Team] Upsert Team",
  AddTeams = "[Team] Add Teams",
  UpdateTeam = "[Team] Update Team",
  UpdateTeamSuccess = "[Team] Update Team Success",
  DeleteTeam = "[Team] Delete Team",
  DeleteTeamSuccess = "[Team] Delete Team Success",
  DeleteTeams = "[Team] Delete Teams",
  ClearTeams = "[Team] Clear Teams",
  Select = "[Team] Select Team",
}

export class LoadTeams implements Action {
  readonly type = TeamActionTypes.LoadTeams;
}

export class LoadTeamsSuccess implements Action {
  readonly type = TeamActionTypes.LoadTeamsSuccess;

  constructor(public payload: { items: Team[] }) {}
}

export class GetOne implements Action {
  readonly type = TeamActionTypes.GetOne;

  constructor(public payload: { orgId: string; teamId: string }) {}
}

export class GetOneSuccess implements Action {
  readonly type = TeamActionTypes.GetOneSuccess;

  constructor(public payload: Team) {}
}

export class AddTeam implements Action {
  readonly type = TeamActionTypes.AddTeam;

  constructor(public payload: { orgId: string; team: Team }) {}
}

export class AddTeamSuccess implements Action {
  readonly type = TeamActionTypes.AddTeamSuccess;

  constructor(public payload: { item: Team }) {}
}

export class AddTeams implements Action {
  readonly type = TeamActionTypes.AddTeams;

  constructor(
    public payload: { orgId: string; items: Team[]; type: "teams" }
  ) {}
}

export class UpdateTeam implements Action {
  readonly type = TeamActionTypes.UpdateTeam;

  constructor(public payload: { orgId: string; teamId: string; team: Team }) {}
}

export class UpdateTeamSuccess implements Action {
  readonly type = TeamActionTypes.UpdateTeamSuccess;

  constructor(public payload: Update<Team>) {}
}

export class DeleteTeam implements Action {
  readonly type = TeamActionTypes.DeleteTeam;

  constructor(public payload: { orgId: string; teamId: string }) {}
}

export class DeleteTeamSuccess implements Action {
  readonly type = TeamActionTypes.DeleteTeamSuccess;

  constructor(public payload: { id: string }) {}
}

export class DeleteTeams implements Action {
  readonly type = TeamActionTypes.DeleteTeams;

  constructor(public payload: { ids: string[] }) {}
}

export class ClearTeams implements Action {
  readonly type = TeamActionTypes.ClearTeams;
}

export class Select implements Action {
  readonly type = TeamActionTypes.Select;

  constructor(public payload: string) {}
}

export type TeamActions =
  | LoadTeams
  | LoadTeamsSuccess
  | GetOne
  | GetOneSuccess
  | AddTeam
  | AddTeamSuccess
  | AddTeams
  | UpdateTeam
  | UpdateTeamSuccess
  | DeleteTeam
  | DeleteTeamSuccess
  | DeleteTeams
  | ClearTeams
  | Select;
