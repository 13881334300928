import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "deeplink",
})
export class DeeplinkPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    console.log("deeplink pipe", value);
    return null;
  }
}
