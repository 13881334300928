import {
  SpirometerTestResult,
  MeasurementResultTypes,
} from "./../store/patientAction/patientAction.model";
import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  FormControl,
  FormGroupDirective,
  NgForm,
} from "@angular/forms";
import * as moment from "moment";
import { MatTableDataSource, Sort, ErrorStateMatcher } from "@angular/material";
import { PatientAction } from "../store/patientAction/patientAction.model";

// TODO: Substitude [getColor] function with [getColorZone] function due to transitioning form ActionPlanTriage to MeasurementResult
export function getColor(patientAction: PatientAction) {
  if (patientAction.colorZone) {
    return patientAction.colorZone;
  }

  let result;
  if (patientAction.__t === "UserActionACS") {
    result = patientAction.result;
  } else if (
    patientAction.__t === "UserActionMeasurement" &&
    patientAction.measurementResult.name === MeasurementResultTypes.ACS
  ) {
    result = patientAction.measurementResult.score;
  }

  if (result >= 1) {
    return "red";
  } else {
    return "green";
  }
}

export function getColorZone(patientAction: PatientAction): string {
  const result = Math.floor(patientAction.measurementResult.calculatedResult);

  switch (result) {
    case 0:
    case 1:
      return "green";
    case 2:
      return "yellow";
    case 3:
      return "orange";
    case 4:
      return "red";
    default:
      return "red";
  }
}

export function getBestFev1Result(
  patientAction: PatientAction,
  fullResult = false
): number | SpirometerTestResult {
  const spirometerResult =
    patientAction.measurementResult.deviceResult.spirometerMeasurementResult;
  const bestResultIndex = spirometerResult.bestFev1TestResultIndex || 0;
  const bestSpirometerResult =
    spirometerResult.spirometerTestResults[bestResultIndex];

  return fullResult ? bestSpirometerResult : bestSpirometerResult.fev1PctPred;
}

export function skippedACS(createdAt: Date): boolean {
  return Date.now() - createdAt.getTime() > 8 * 24 * 60 * 60 * 1000;
}

export interface AllValidationErrors {
  control_name: string;
  error_name: string;
  error_value: any;
}

export interface FormGroupControls {
  [key: string]: AbstractControl;
}

export function getFormValidationErrors(
  controls: FormGroupControls
): AllValidationErrors[] {
  let errors: AllValidationErrors[] = [];
  Object.keys(controls).forEach((key) => {
    const control = controls[key];
    if (control instanceof FormGroup) {
      errors = errors.concat(getFormValidationErrors(control.controls));
    }
    const controlErrors: ValidationErrors = controls[key].errors;
    if (controlErrors !== null) {
      Object.keys(controlErrors).forEach((keyError) => {
        errors.push({
          control_name: key,
          error_name: keyError,
          error_value: controlErrors[keyError],
        });
      });
    }
  });
  return errors;
}

export function dashify(input: string): string {
  return input.replace(/\s+/g, "-").toLowerCase();
}

export function formatBirthDate(date) {
  const momentDate = moment(date, "YYYY-MM-DD");
  if (!momentDate.isValid()) {
    return date;
  }

  return momentDate.format("DD-MM-YYYY");
}

export function formatDate(date: Date, format: string) {
  if (!date && !format) return false;

  const momentDate = moment(date, "YYYY-MM-DD");

  if (!momentDate.isValid()) {
    return date;
  }

  return momentDate.format(format);
}

export function formatPhoneNumber(phonenumber, countryCode) {
  const pattern =
    countryCode === "NL"
      ? "^\\+316[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]$"
      : "^\\+324[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]$";

  if (!phonenumber || !phonenumber.replace(/\s/g, "").match(pattern)) {
    return phonenumber;
  }
  return "0" + phonenumber.replace(/\s/g, "").substr(3, 11);
}

export function isDay(someDate: Date, day: Date) {
  return (
    someDate.getDate() === day.getDate() &&
    someDate.getMonth() === day.getMonth() &&
    someDate.getFullYear() === day.getFullYear()
  );
}

export function isYesterday(someDate) {
  const day = new Date();
  day.setDate(day.getDate() - 1);
  return isDay(someDate, day);
}

export function isToday(someDate) {
  const today = new Date();
  return isDay(someDate, today);
}

export function sortData(
  sort: Sort,
  dataSource: MatTableDataSource<any>,
  items: any[],
  filterPredicate?: any
) {
  if (!sort || !sort.active || sort.direction === "") {
    const unsortedDataSource = new MatTableDataSource(items);
    unsortedDataSource.filterPredicate = filterPredicate;
    unsortedDataSource.filter = dataSource.filter;
    return unsortedDataSource;
  }

  items.sort((a, b) => {
    const isAsc = sort.direction === "asc";
    switch (sort.active) {
      case "name":
        return compare(a.lastName, b.lastName, isAsc);
      case "lastName":
        return compare(a.lastName, b.lastName, isAsc);
      case "firstName":
        return compare(a.firstName, b.firstName, isAsc);
      case "combinedName":
        return compare(
          a.firstName + a.lastName,
          b.firstName + b.lastName,
          isAsc
        );
      case "email":
        return compare(a.email, b.email, isAsc);
      case "dateOfBirth":
        return compare(a.dateOfBirth, b.dateOfBirth, isAsc);
      case "acs":
        return compare(
          a.lastACS ? a.lastACS.result : 999,
          b.lastACS ? b.lastACS.result : 999,
          isAsc
        );
      case "createdAt":
        return compare(a.createdAt, b.createdAt, isAsc);
      case "patientNumber":
        return compare(a.patientNumber, b.patientNumber, isAsc);
      case "studyNumber":
        return compare(a.studyNumber, b.studyNumber, isAsc);
      default:
        return 0;
    }
  });
  const newDataSource = new MatTableDataSource(items);
  console.log("newDataSource", newDataSource.filteredData);
  newDataSource.filterPredicate = filterPredicate;
  newDataSource.filter = dataSource.filter;
  return newDataSource;
}

export function compare(
  a: number | string | Date,
  b: number | string | Date,
  isAsc: boolean
) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

export function getThresholdValueName(num) {
  if (num % 1 === 0) {
    return num + ".0";
  } else {
    return num.toString();
  }
}

export class PasswordRepeatValidator implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const invalidCtrl = !!(control && control.invalid && control.dirty);
    const invalidParent = !!(
      control &&
      control.parent &&
      control.parent.invalid &&
      control.parent.dirty &&
      control.dirty
    );

    return invalidCtrl || invalidParent;
  }
}

export function clean(obj) {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName].length === 0
    ) {
      delete obj[propName];
    }
  }
}
