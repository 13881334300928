import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import {
  SettingsActionTypes,
  LoadTeamAction,
  LoadTeamSuccessAction,
  UpdateMonitoringSettings,
  UpdateMonitoringSettingsSuccess,
  SelectTeam,
  SelectTeamSuccess,
  UpdateMeasurementConfig,
  UpdateMeasurementConfigSuccess,
  UpdateBaseActionPlan,
  UpdateBaseActionPlanSuccess,
  AddBaseAction,
  AddBaseActionSuccess,
  UpdateBaseActionOrder,
  UpdateBaseActionOrderSuccess,
  RemoveBaseAction,
  RemoveBaseActionSuccess,
  UpdateBaseActionSuccess,
  UpdateBaseAction,
} from "./settings.actions";

import { ApiService } from "../../services/api.service";
import * as errorActions from "../error/error.actions";
import * as teamActions from "../team/team.actions";
import * as fromRoot from "..";

import { map, switchMap, catchError } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { EMPTY } from "rxjs";

import { v4 as uuid } from "uuid";
import { Team } from "../team/team.model";

@Injectable()
export class SettingsEffects {
  @Effect()
  loadTeam$ = this.actions$.pipe(
    ofType(SettingsActionTypes.LoadTeam),
    switchMap((action: LoadTeamAction) => {
      return this.api.getTeam().pipe(
        switchMap((team: Team) => {
          return this.api.getAllMeasurements().pipe(
            map((measurements) => {
              return new LoadTeamSuccessAction({ team, measurements });
            })
          );
        }),
        catchError((err) => {
          this.store.dispatch(
            new errorActions.AddError({
              error: {
                id: uuid(),
                created: new Date(),
                content: err,
                source: action,
              },
            })
          );
          return EMPTY;
        })
      );
    })
  );

  @Effect()
  updateMonitoringSettings$ = this.actions$.pipe(
    ofType(SettingsActionTypes.UpdateMonitoringSettings),
    switchMap((action: UpdateMonitoringSettings) => {
      return this.api.patchMonitoringSettings(action.payload).pipe(
        map((team: Team) => {
          return new UpdateMonitoringSettingsSuccess(team.monitoringSettings);
        }),
        catchError((err) => {
          this.store.dispatch(
            new errorActions.AddError({
              error: {
                id: uuid(),
                created: new Date(),
                content: err,
                source: action,
              },
            })
          );
          return EMPTY;
        })
      );
    })
  );

  @Effect()
  updateBaseActionplan$ = this.actions$.pipe(
    ofType(SettingsActionTypes.UpdateBaseActionPlan),
    switchMap((action: UpdateBaseActionPlan) => {
      return this.api.patchBaseActionPlan(action.payload).pipe(
        map((team: Team) => {
          return new UpdateBaseActionPlanSuccess(team.baseActionPlan);
        }),
        catchError((err) => {
          this.store.dispatch(
            new errorActions.AddError({
              error: {
                id: uuid(),
                created: new Date(),
                content: err,
                source: action,
              },
            })
          );
          return EMPTY;
        })
      );
    })
  );

  @Effect()
  updateMeasurementConfig$ = this.actions$.pipe(
    ofType(SettingsActionTypes.UpdateMeasurementConfig),
    switchMap((action: UpdateMeasurementConfig) => {
      return this.api.patchMeasurementConfig(action.payload).pipe(
        map((res: Team) => {
          return new UpdateMeasurementConfigSuccess(res);
        }),
        catchError((err) => {
          this.store.dispatch(
            new errorActions.AddError({
              error: {
                id: uuid(),
                created: new Date(),
                content: err,
                source: action,
              },
            })
          );
          return EMPTY;
        })
      );
    })
  );

  @Effect()
  addBaseAction$ = this.actions$.pipe(
    ofType(SettingsActionTypes.AddBaseAction),
    switchMap((action: AddBaseAction) => {
      return this.api
        .postBaseAction(
          action.payload.colorZone,
          action.payload.teamId,
          action.payload.item
        )
        .pipe(
          switchMap((team) => {
            console.log("AddBaseAction Success", team);
            return [
              new AddBaseActionSuccess(),
              new teamActions.UpdateTeamSuccess({
                changes: team,
                id: team._id,
              }),
            ];
          }),
          catchError((err) => {
            this.store.dispatch(
              new errorActions.AddError({
                error: {
                  id: uuid(),
                  created: new Date(),
                  content: err,
                  source: action,
                },
              })
            );
            return EMPTY;
          })
        );
    })
  );

  @Effect()
  updateBaseAction$ = this.actions$.pipe(
    ofType(SettingsActionTypes.UpdateBaseAction),
    switchMap((action: UpdateBaseAction) => {
      return this.api
        .patchBaseAction(
          action.payload.actionId,
          action.payload.colorZone,
          action.payload.teamId,
          action.payload.item
        )
        .pipe(
          switchMap((team) => {
            return [
              new UpdateBaseActionSuccess(),
              new teamActions.UpdateTeamSuccess({
                changes: team,
                id: team._id,
              }),
            ];
          }),
          catchError((err) => {
            this.store.dispatch(
              new errorActions.AddError({
                error: {
                  id: uuid(),
                  created: new Date(),
                  content: err,
                  source: action,
                },
              })
            );
            return EMPTY;
          })
        );
    })
  );

  @Effect()
  removeBaseAction$ = this.actions$.pipe(
    ofType(SettingsActionTypes.RemoveBaseAction),
    switchMap((action: RemoveBaseAction) => {
      return this.api
        .removeBaseAction(
          action.payload.colorZone,
          action.payload.teamId,
          action.payload.actionId
        )
        .pipe(
          switchMap((team) => {
            return [
              new RemoveBaseActionSuccess(),
              new teamActions.UpdateTeamSuccess({
                changes: team,
                id: team._id,
              }),
            ];
          }),
          catchError((err) => {
            this.store.dispatch(
              new errorActions.AddError({
                error: {
                  id: uuid(),
                  created: new Date(),
                  content: err,
                  source: action,
                },
              })
            );
            return EMPTY;
          })
        );
    })
  );

  @Effect()
  updateBaseActionOrder$ = this.actions$.pipe(
    ofType(SettingsActionTypes.UpdateBaseActionOrder),
    switchMap((action: UpdateBaseActionOrder) => {
      console.log("updateActionOrder");
      return this.api
        .changeBaseActionOrder(
          action.payload.colorZone,
          action.payload.teamId,
          { order: action.payload.order }
        )
        .pipe(
          switchMap((team) => {
            return [
              new UpdateBaseActionOrderSuccess(),
              new teamActions.UpdateTeamSuccess({
                changes: team,
                id: team._id,
              }),
            ];
          }),
          catchError((err) => {
            this.store.dispatch(
              new errorActions.AddError({
                error: {
                  id: uuid(),
                  created: new Date(),
                  content: err,
                  source: action,
                },
              })
            );
            return EMPTY;
          })
        );
    })
  );

  @Effect()
  selectTeam$ = this.actions$.pipe(
    ofType(SettingsActionTypes.SelectTeam),
    map((action: SelectTeam) => {
      // Store tokens in storage
      localStorage.setItem("selectedTeam", JSON.stringify(action.payload));
      return action.payload;
    }),
    switchMap(({ teamId, orgId }) => {
      return [new SelectTeamSuccess({ teamId, orgId })];
    })
  );

  constructor(
    private actions$: Actions,
    private api: ApiService,
    private store: Store<fromRoot.State>
  ) {}
}
