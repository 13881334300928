import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PatientAction, MeasurementResultTypes } from 'src/app/store/patientAction/patientAction.model';
import { getColor } from 'src/app/util/helper-functions';
@Component({
  selector: 'app-acs-chart',
  templateUrl: './acs-chart.component.html',
  styleUrls: ['./acs-chart.component.scss']
})
export class AcsChartComponent implements OnInit {
  @Input() patientActions: PatientAction[];
  getColor = getColor;
  @Output() selectACS = new EventEmitter();

  constructor() { }

  ngOnInit() {
    console.log('chart', this.acsActions);
  }

  get acsActions() {
    return this.patientActions
      .filter(action => action.acsForm && action.result !== undefined || action.__t === 'UserActionMeasurement' && action.measurementResult.name === MeasurementResultTypes.ACS).slice(0, 14).reverse();
  }

  getAcsResult(action: PatientAction) {
    return action.acsForm !== undefined ? action.result : action.measurementResult.score
  }


}
