import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { MeasurementConfig } from './../../store';
import { StoreError } from '../../store/error/error.model';
import { filter, take, map } from 'rxjs/operators';
import * as fromRoot from '../../store';
import * as errorActions from '../../store/error/error.actions';
import * as settingsActions from '../../store/settings/settings.actions';
import { Observable } from 'rxjs';
import { Team } from 'src/app/store/team/team.model';
import { WEEKDAYS } from '../../store';
import { Measurement } from 'src/app/store/shared.model';

@Component({
  selector: 'app-measurement-config-dialog',
  templateUrl: './measurement-config-dialog.component.html',
  styleUrls: ['./measurement-config-dialog.component.scss'],
})
export class MeasurementConfigDialogComponent implements OnInit, OnDestroy {
  dialogForm: FormGroup;
  isPosting: boolean;
  errorMessage: string;
  team$: Observable<Team>;
  weekdays = WEEKDAYS;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      measurementConfiguration: MeasurementConfig;
      measurements: Measurement[];
    },
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<MeasurementConfigDialogComponent>,
    private store: Store<fromRoot.State>,
    private formBuilder: FormBuilder
  ) {}

  ngOnDestroy() {
    this.store.dispatch(new errorActions.ClearErrors());
  }

  ngOnInit() {
    this.isPosting = false;
    this.store.dispatch(new errorActions.ClearErrors());
    let measurementConfiguration = this.data.measurementConfiguration;

    if (!measurementConfiguration) {
      measurementConfiguration = {
        name: this.data.measurements[0].name,
        enabled: true,
      };
    }

    this.dialogForm = this.formBuilder.group({
      name: this.formBuilder.control(measurementConfiguration.name, [
        Validators.required,
      ]),
      enabled: this.formBuilder.control(measurementConfiguration.enabled),
    });

    this.store
      .select(fromRoot.getErrors)
      .pipe(filter((errors) => errors && errors.length > 0))
      .pipe(map((errors) => errors[0]))
      .pipe(filter((error) => error.apiError !== undefined))
      .subscribe((error: StoreError) => {
        this.isPosting = false;
        switch (error.apiError.code) {
          default:
            this.errorMessage = 'Er is iets fout gegaan';
            break;
        }
      });
  }

  isPosting$() {
    return this.store
      .select(fromRoot.isPostingAuth)
      .pipe(filter((isPosting) => isPosting === false))
      .pipe(take(1))
      .toPromise();
  }

  patchMeasurementConfig() {
    if (this.isPosting) {
      return;
    }
    this.isPosting = true;
    this.errorMessage = undefined;
    this.store.dispatch(
      new settingsActions.UpdateMeasurementConfig(this.dialogForm.value)
    );
    this.isPosting$().then(() => {
      this.isPosting = false;
      this.dialogRef.close();
    });
  }

  cancel() {
    this.dialogRef.close();
  }
}
