import { SettingsActions, SettingsActionTypes } from "./settings.actions";
import { Team } from "../team/team.model";
import { CareProvider } from "../careprovider/careprovider.model";
import { DeviceMeasurement, Measurement } from '../shared.model';

export interface State {
  careProvider?: CareProvider;
  selectedTeam?: {
    teamId: string;
    orgId: string;
  };
  team?: Team;
  teams?: string[];
  measurements?: Measurement[] | DeviceMeasurement[];
  isPosting: boolean;
}

const selectedTeam = JSON.parse(localStorage.getItem("selectedTeam"));

export const initialState: State = {
  selectedTeam: selectedTeam ? selectedTeam : undefined,
  isPosting: false,
};

export function reducer(state = initialState, action: SettingsActions): State {
  switch (action.type) {
    case SettingsActionTypes.LoadTeamSuccess:
      return {
        ...state,
        team: action.payload.team,
        measurements: action.payload.measurements,
      };

    case SettingsActionTypes.SelectTeamSuccess:
      return {
        ...state,
        selectedTeam: action.payload,
      };

    case SettingsActionTypes.UpdateMonitoringSettingsSuccess:
      return {
        ...state,
        team: { ...state.team, monitoringSettings: action.payload },
        isPosting: false,
      };

    case SettingsActionTypes.UpdateBaseActionPlanSuccess:
      return {
        ...state,
        team: { ...state.team, baseActionPlan: action.payload },
        isPosting: false,
      };

    case SettingsActionTypes.UpdateBaseActionOrder: {
      console.log("UpdateBaseActionOrder");
      return state;
    }

    case SettingsActionTypes.UpdateBaseActionOrderSuccess: {
      return {
        ...state,
        isPosting: false,
      };
    }

    case SettingsActionTypes.UpdateMeasurementConfigSuccess:
      return {
        ...state,
        team: {
          ...state.team,
          measurementConfigurations: action.payload.measurementConfigurations,
        },
        isPosting: false,
      };

    case SettingsActionTypes.UpdateMonitoringSettings:
      return {
        ...state,
        isPosting: true,
      };

    case SettingsActionTypes.AddBaseAction: {
      return {
        ...state,
        isPosting: true,
      };
    }

    case SettingsActionTypes.AddBaseActionSuccess: {
      return {
        ...state,
        isPosting: false,
      };
    }

    case SettingsActionTypes.RemoveBaseAction: {
      return {
        ...state,
        isPosting: true,
      };
    }

    case SettingsActionTypes.RemoveBaseActionSuccess: {
      return {
        ...state,
        isPosting: false,
      };
    }

    case SettingsActionTypes.UpdateBaseAction: {
      return {
        ...state,
        isPosting: true,
      };
    }

    case SettingsActionTypes.UpdateBaseActionSuccess: {
      return {
        ...state,
        isPosting: false,
      };
    }

    case SettingsActionTypes.ClearState: {
      return initialState;
    }

    default:
      return state;
  }
}

export const getState = (state: State) => state;

export const isPosting = (state: State) => state.isPosting;

export const getTeam = (state: State) => state.team;

export const getCareProvider = (state: State) => state.careProvider;

export const getSelectedTeam = (state: State) => state.selectedTeam;

export const getMeasurements = (state: State) => state.measurements;
