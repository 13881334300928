import { ConfirmDialogComponent } from './../confirm-dialog/confirm-dialog.component';
import { Team } from 'src/app/store/team/team.model';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Store } from '@ngrx/store';
import { CareProvider } from './../../store/careprovider/careprovider.model';
import { StoreError } from '../../store/error/error.model';
import { filter, take, map, startWith } from 'rxjs/operators';
import * as fromRoot from '../../store';
import * as careProviderActions from '../../store/careprovider/careprovider.actions';
import * as errorActions from '../../store/error/error.actions';
import { Observable } from 'rxjs';
import { Org } from 'src/app/store/org/org.model';
import { formatDate } from 'src/app/util/helper-functions';

@Component({
  selector: 'app-admin-careprovider-dialog',
  templateUrl: './admin-careprovider-dialog.component.html',
  styleUrls: ['./admin-careprovider-dialog.component.scss'],
})
export class CareProviderDialogComponent implements OnInit, OnDestroy {
  dialogForm: FormGroup;
  phoneForm: FormGroup;
  existingCareProviderController: FormControl;
  addNew = true;
  addExisting = false;
  isPosting: boolean;
  errorMessage: string;
  filteredCareProviders$: Observable<CareProvider[]>;
  careProviders: CareProvider[];
  groupedTeams: { organization: Org; teams: Team[] }[];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      fromOverview: boolean;
      orgId: string;
      teamId: string;
      careProvider: CareProvider;
      teams: Team[];
      orgs: Org[];
    },
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CareProviderDialogComponent>,
    private router: Router,
    private store: Store<fromRoot.State>,
    private formBuilder: FormBuilder
  ) { }

  ngOnDestroy() {
    this.store.dispatch(new errorActions.ClearErrors());
  }

  ngOnInit() {
    this.isPosting = false;
    this.store.dispatch(new errorActions.ClearErrors());
    let careProvider = this.data.careProvider
      ? (this.data.careProvider as CareProvider)
      : undefined;
    if (!careProvider) {
      careProvider = {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
      };
    }

    if (this.data.teams && this.data.orgs) {
      this.groupedTeams = this.data.orgs.reduce((groups, organization) => {
        const group = groups.find((g) => g.organization === organization._id);
        if (group) {
          group.teams.push(
            this.data.teams.filter(
              (team) => team.organization === organization._id
            )
          );
        } else {
          const teams = this.data.teams.filter(
            (team) => team.organization === organization._id
          );
          if (teams.length > 0) {
            groups.push({ organization, teams });
          }
        }
        return groups;
      }, []);
    }

    this.existingCareProviderController = new FormControl('', [
      Validators.required,
    ]);
    const teamsController = { teams: [careProvider.teams] };
    this.dialogForm = this.formBuilder.group({
      firstName: [careProvider.firstName],
      lastName: [careProvider.lastName],
      email: [careProvider.email],
      password: [''],
      forceResetPassword: [careProvider.forceResetPassword !== undefined ? careProvider.forceResetPassword : false],
      ...((this.data.fromOverview && teamsController) ||
        (this.data.careProvider && teamsController)),
    });

    this.store
      .select(fromRoot.getCareProviders)
      .pipe(
        filter((careProviders) => careProviders !== undefined),
        take(1)
      )
      .subscribe((careProviders) => {
        this.careProviders = careProviders;
        this.filteredCareProviders$ = this.existingCareProviderController.valueChanges.pipe(
          startWith(''),
          map((value) => this._filter(value))
        );
      });

    this.store
      .select(fromRoot.getErrors)
      .pipe(filter((errors) => errors && errors.length > 0))
      .pipe(map((errors) => errors[0]))
      .pipe(filter((error) => error.apiError !== undefined))
      .subscribe((error: StoreError) => {
        this.isPosting = false;
        switch (error.apiError.code) {
          default:
            this.errorMessage = 'Er is iets fout gegaan';
            break;
        }
      });
  }

  get formatTwoFactorDate() {
    if (!this.data.careProvider || !this.data.careProvider.twoFactor) return false;

    return formatDate(this.data.careProvider.twoFactor.createdAt, 'DD-MM-YYYY HH:mm');
  }

  isPosting$() {
    return this.store
      .select(fromRoot.isPostingCareProvider)
      .pipe(filter((isPosting) => isPosting === false))
      .pipe(take(1))
      .toPromise();
  }

  switcher(newUser: boolean) {
    if (newUser) {
      this.addNew = true;
      this.addExisting = false;
    } else {
      this.addNew = false;
      this.addExisting = true;
    }
  }

  createCareProvider(newUser: boolean) {
    if (this.isPosting) {
      return;
    }
    this.isPosting = true;
    this.errorMessage = undefined;
    let careProvider: CareProvider;

    if (newUser) {
      careProvider = this.parseForm(this.dialogForm.value);
      if (!this.data.fromOverview) {
        if (!careProvider.organizations) {
          careProvider.organizations = [this.data.orgId];
        }

        if (!careProvider.teams) {
          careProvider.teams = [this.data.teamId];
        }
      }
      this.store.dispatch(
        new careProviderActions.AddCareProvider({ careProvider })
      );
    } else {
      careProvider = this.careProviders.find(
        (c) => c.email === this.existingCareProviderController.value
      );
      if (!careProvider.organizations.includes(this.data.orgId)) {
        careProvider.organizations.push(this.data.orgId);
      }
      if (!(careProvider.teams as string[]).includes(this.data.teamId)) {
        (careProvider.teams as string[]).push(this.data.teamId);
      }
      this.store.dispatch(
        new careProviderActions.UpdateCareProvider({
          careProviderId: careProvider._id,
          careProvider,
        })
      );
    }

    this.isPosting$().then(() => {
      this.isPosting = false;
      this.dialogRef.close();
    });
  }

  patchCareProvider() {
    if (this.isPosting) {
      return;
    }
    this.isPosting = true;
    this.errorMessage = undefined;
    const careProvider = this.parseForm(this.dialogForm.value);
    console.log('patch', careProvider);
    this.store.dispatch(
      new careProviderActions.UpdateCareProvider({
        careProviderId: this.data.careProvider._id,
        careProvider,
      })
    );
    this.isPosting$().then(() => {
      this.isPosting = false;
      this.dialogRef.close();
    });
  }

  async deleteCareProvider() {
    if (this.isPosting) {
      return;
    }
    this.isPosting = true;

    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {},
    });

    const confirmation = await confirmDialog
      .afterClosed()
      .pipe(take(1))
      .toPromise();

    if (!confirmation) {
      return;
    }

    this.store.dispatch(
      new careProviderActions.DeleteCareProvider({
        careProviderId: this.data.careProvider._id,
      })
    );

    this.isPosting$().then(() => {
      this.isPosting = false;
      this.dialogRef.close();
    });
  }

  updateOrgIds(careProvider: CareProvider): string[] {
    const orgIds = (careProvider.teams as string[])
      .map((teamId) => this.data.teams.find((t) => t._id === teamId))
      .map((team) => team.organization);
    return [...new Set(orgIds)];
  }

  cancel() {
    this.dialogRef.close();
  }

  private parseForm(formValue): CareProvider {
    if (!formValue) {
      return formValue;
    }
    if (formValue.dateOfBirth) {
      formValue.dateOfBirth = moment(
        formValue.dateOfBirth,
        'DD-MM-YYYY'
      ).format('YYYY-MM-DD');
    }

    if (formValue.teams && formValue.teams.length > 0) {
      formValue.organizations = this.updateOrgIds(formValue);
    }

    return formValue;
  }

  private _filter(email: string): CareProvider[] {
    const filterValue = email.toLowerCase();
    return this.careProviders.filter(
      (careProvider) =>
        careProvider.email.toLowerCase().includes(filterValue) &&
        !(careProvider.teams as string[]).includes(this.data.teamId)
    );
  }
}
