export interface Medicine {
  _id?: string;
  name: string;
  brand: string;
  strength: string;
  dosageForm: string;
  dosageType?: string;
  dosageDimension?: string;
  packageForm: string;
  type: string;
  userCustom?: string;
}

export interface MedicineFrequency {
  title: string;
  value: number;
}

export const MEDICINE_FREQUENCIES = [
  { title: "Zo nodig", value: -1 },
  { title: "1x per dag", value: 1 },
  { title: "2x per dag", value: 2 },
  { title: "3x per dag", value: 3 },
  { title: "4x per dag", value: 4 },
  { title: "5x per dag", value: 5 },
  { title: "3x per week", value: 3 / 7 },
];

export const BIOLOGICAL_FREQUENCIES = [
  { title: "1x per 2 weken", value: 1 / 14 },
  { title: "1x per 4 weken", value: 1 / 28 },
  { title: "1x per 8 weken", value: 1 / 56 },
];

export const DOSAGE_DIMENSIONS = [
  "tabletten",
  "capsules",
  "eenheden",
  "ml",
  "mg",
  "injecties",
  "druppels",
  "stuks",
  "sachets",
  "sprays",
  "zetpillen",
  "zuigtabletten",
  "watten",
  "verbanden",
  "pleisters",
  "mg/ml",
  "geen",
];
