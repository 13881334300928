import { PatientDialogComponent } from './../../dialogs/patient-dialog/patient-dialog.component';

import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';

import * as fromRoot from '../../store';
import * as moment from 'moment';
import { Store } from '@ngrx/store';

import { Patient } from '../../store/patient/patient.model';
import { MatTableDataSource, MatDialogRef, MatDialog, Sort } from '@angular/material';
import { Router } from '@angular/router';
import { formatBirthDate, getColor, skippedACS, isYesterday, isToday, sortData } from './../../util/helper-functions';
import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-patients',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsViewComponent implements OnInit, AfterViewInit, OnDestroy {
  patients$: Observable<Patient[]>;
  patients: Patient[];
  displayedColumns = ['date', 'combinedName', 'alerts'];
  dataSource = new MatTableDataSource<any>([]);
  patientSub: Subscription;
  patientDialog: MatDialogRef<PatientDialogComponent>;
  sort: Sort;
  skippedACS = skippedACS;
  getColor = getColor;
  isYesterday = isYesterday;
  isToday = isToday;
  alertsOnly: boolean;
  formatBirthDate: (date: string) => string;

  constructor(private store: Store<fromRoot.State>, private dialog: MatDialog, private router: Router) {
  }

  ngOnInit() {
    this.formatBirthDate = formatBirthDate;
    this.patients$ = this.store.select(fromRoot.getPatients).pipe(filter(patients => patients !== undefined),
      map(patients => {
        return patients
          .filter(patient => {
            return ((patient.unresolvedMessagesCount > 0) || (patient.unresolvedActionsCount > 0)) && patient.unresolvedAt;
          }).sort((patientA, patientB) => {
            // Sort by date, newest first
            const alertDateA = patientA.unresolvedAt;
            const alertDateB = patientB.unresolvedAt;
            return (alertDateA > alertDateB) ? -1 : (alertDateA < alertDateB) ? 1 : 0;
          });
      }));
    this.patientSub = this.patients$.pipe(filter(patients => patients !== undefined)).subscribe(patients => {
      this.patients = patients;
      this.sortData(this.sort);
    });
  }

  ngAfterViewInit() { }

  ngOnDestroy() {
    if (this.patientSub) {
      this.patientSub.unsubscribe();
    }
  }

  sortData(sort: Sort) {
    this.dataSource = sortData(sort, this.dataSource, this.patients);
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  editPatient(patient) {
    this.router.navigate(['patienten/' + patient._id, {
      origin: 'meldingen'
    }]);
  }

  openChat(patient) {
    this.router.navigate(['patienten/' + patient._id, {
      tab: 'chat',
      origin: 'meldingen'
    }]);
  }


  getLastACSDate(date: Date): string {
    const currentDate = moment(date);
    const today = moment().startOf('day');
    const yesterday = moment()
      .subtract(1, 'days')
      .startOf('day');
    const lastWeek = moment()
      .subtract(7, 'days')
      .startOf('day');

    if (currentDate.isSame(today, 'day')) {
      return 'vandaag';
    } else if (currentDate.isSame(yesterday, 'day')) {
      return 'gisteren';
    } else if (currentDate.isSame(lastWeek, 'day')) {
      return currentDate.format(`dddd 'YY`);
    } else {
      return currentDate.format(`D MMM 'YY`);
    }
  }
}
