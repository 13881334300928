import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { CareProvider } from './careprovider.model';
import { CareProviderActions, CareProviderActionTypes } from './careprovider.actions';
import { AuthActions, AuthActionTypes } from '../auth/auth.actions';

export interface State extends EntityState<CareProvider> {
  // additional entities state properties
  selectedId?: string;
  isPosting: boolean;
}

export const adapter: EntityAdapter<CareProvider> = createEntityAdapter<CareProvider>({
  selectId: (item: CareProvider) => item._id
});

export const initialState: State = adapter.getInitialState({
  isPosting: false
});

export function reducer(state = initialState, action: CareProviderActions | AuthActions): State {
  switch (action.type) {
    case CareProviderActionTypes.AddCareProvider: {
      return {
        ...state,
        isPosting: true
      };
    }

    case CareProviderActionTypes.AddCareProviderSuccess: {
      return {
        ...adapter.addOne(action.payload.item, state),
        isPosting: false
      };
    }

    case CareProviderActionTypes.AddCareProviders: {
      return adapter.addAll(action.payload.items, state);
    }

    case CareProviderActionTypes.UpdateCareProvider: {
      return {
        ...state,
        isPosting: true
      };
    }

    case CareProviderActionTypes.UpdateCareProviderSuccess: {
      return {
        ...adapter.updateOne(action.payload, state),
        isPosting: false
      };
    }

    case CareProviderActionTypes.DeleteCareProvider: {
      return {
        ...state,
        isPosting: true
      };
    }

    case CareProviderActionTypes.DeleteCareProviderSuccess: {
      return {
        ...adapter.removeOne(action.payload.id, state),
        isPosting: false
      };
    }

    case CareProviderActionTypes.DeleteCareProviders: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case CareProviderActionTypes.LoadCareProvidersSuccess: {
      return adapter.upsertMany(action.payload.items, state);
    }

    case CareProviderActionTypes.GetOneSuccess: {
      return adapter.upsertOne(action.payload, state);
    }

    case CareProviderActionTypes.ClearCareProviders: {
      return adapter.removeAll(state);
    }

    case CareProviderActionTypes.Select: {
      return {
        ...state,
        selectedId: action.payload
      };
    }

    case AuthActionTypes.LogoutSuccess: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const getSelectedId = (state: State) => state.selectedId;
export const isPosting = (state: State) => state.isPosting;
