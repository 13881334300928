import { ActionPointTypes, ActionPlan } from "src/app/store/shared.model";
import { DOSAGE_DIMENSIONS } from "./../../store/medicine/medicine.model";
import { AddMedicationSuccess } from "./../../store/patient/patient.actions";
import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
  MatCheckboxChange,
} from "@angular/material";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { Store, ActionsSubject } from "@ngrx/store";
import { Medicine } from "../../store/medicine/medicine.model";
import { Patient } from "../../store/patient/patient.model";
import { StoreError } from "../../store/error/error.model";
import { filter, map, take, skip, takeUntil } from "rxjs/operators";
import * as fromRoot from "../../store";
import * as errorActions from "../../store/error/error.actions";
import * as patientActions from "../../store/patient/patient.actions";
import { Observable, Subject } from "rxjs";
import { ActionDialogComponent } from "../action-dialog/action-dialog.component";

@Component({
  selector: "app-custom-medicine-dialog",
  templateUrl: "./custom-medicine-dialog.component.html",
  styleUrls: ["./custom-medicine-dialog.component.scss"],
})
export class CustomMedicineDialogComponent implements OnInit, OnDestroy {
  dosageDimensions = DOSAGE_DIMENSIONS;
  dialogForm: FormGroup;
  medicineControl: FormControl;
  medicines$: Observable<Medicine[]>;
  isPosting: boolean;
  inputValue: string;
  errorMessage: string;
  addedMedicine: Medicine;
  filteredMedicines$: Observable<Medicine[]>;
  greenSelected: boolean;
  yellowSelected: boolean;
  orangeSelected: boolean;
  redSelected: boolean;
  selectedActionPlan$: Observable<ActionPlan>;
  dosageForms: string[] = [
    "inhalatie",
    "nasaal",
    "oraal",
    "pulmonaal",
    "cutaan",
    "subcutaan",
    "intraveneus",
    "anders",
  ];
  destroy$ = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { medicine?: Medicine; patient: Patient },
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CustomMedicineDialogComponent>,
    private store: Store<fromRoot.State>,
    private formBuilder: FormBuilder,
    private action$: ActionsSubject
  ) {}

  ngOnDestroy() {
    this.store.dispatch(new errorActions.ClearErrors());
    this.destroy$.next(true);
  }

  ngOnInit() {
    this.isPosting = false;
    this.store.dispatch(new errorActions.ClearErrors());
    let medicine = this.data.medicine
      ? (this.data.medicine as Medicine)
      : undefined;

    this.selectedActionPlan$ = this.store
      .select(fromRoot.getSelectedPatient)
      .pipe(
        filter((p) => p !== undefined),
        map((patient) => patient.actionPlan)
      );

    this.action$
      .pipe(
        filter(
          (action) =>
            action.type ===
            patientActions.PatientActionTypes.AddMedicationSuccess
        ),
        take(1),
        takeUntil(this.destroy$)
      )
      .subscribe((action: AddMedicationSuccess) => {
        const patientMedications = action.payload.changes.medication;
        const addedMedicine = patientMedications[patientMedications.length - 1];

        this.isPosting$().then(async () => {
          this.isPosting = false;

          // add dialog for green actionplan
          if (this.greenSelected === true) {
            const greenDialog = this.openDialog("green", addedMedicine);
            await greenDialog.afterClosed().toPromise();
          }

          // add dialog for yellow actionplan
          if (this.yellowSelected === true) {
            const yellowDialog = this.openDialog("yellow", addedMedicine);
            await yellowDialog.afterClosed().toPromise();
          }

          // add dialog for orange actionplan
          if (this.orangeSelected === true) {
            const orangeDialog = this.openDialog("orange", addedMedicine);
            await orangeDialog.afterClosed().toPromise();
          }

          if (this.redSelected === true) {
            const redDialog = this.openDialog("red", addedMedicine);
            await redDialog.afterClosed().toPromise();
          }

          this.dialogRef.close();
        });
      });

    medicine = {
      name: "",
      brand: "",
      strength: "",
      dosageForm: "",
      dosageDimension: "",
      packageForm: "",
      type: "",
      userCustom: "true",
    };

    this.dialogForm = this.formBuilder.group({
      name: [medicine.name],
      brand: [medicine.brand],
      strength: [medicine.strength],
      dosageForm: [medicine.dosageForm],
      dosageDimension: [medicine.dosageDimension],
      packageForm: [medicine.packageForm],
      type: [medicine.type],
      userCustom: [medicine.userCustom],
      generic: [true],
    });

    this.store
      .select(fromRoot.getErrors)
      .pipe(filter((errors) => errors && errors.length > 0))
      .pipe(map((errors) => errors[0]))
      .pipe(filter((error) => error.apiError !== undefined))
      .subscribe((error: StoreError) => {
        this.isPosting = false;
        switch (error.apiError.code) {
          default:
            this.errorMessage = "Er is iets fout gegaan";
            break;
        }
      });
  }

  isPosting$() {
    return this.store
      .select(fromRoot.isPostingPatient)
      .pipe(filter((isPosting) => isPosting === false))
      .pipe(take(1))
      .toPromise();
  }

  addToMedicine(
    event: MatCheckboxChange,
    selected: "green" | "yellow" | "orange" | "red"
  ) {
    switch (selected) {
      case "green":
        this.greenSelected = event.checked;
        break;
      case "yellow":
        this.yellowSelected = event.checked;
        break;
      case "orange":
        this.orangeSelected = event.checked;
        break;
      case "red":
        this.redSelected = event.checked;
        break;
      default:
        this.greenSelected = false;
        this.yellowSelected = false;
        this.orangeSelected = false;
        this.redSelected = false;
    }
  }

  openDialog(color: "green" | "yellow" | "orange" | "red", medicine: Medicine) {
    console.log("opendialog, medicine", medicine);
    return this.dialog.open(ActionDialogComponent, {
      data: {
        colorZone: color,
        patient: this.data.patient,
        action: "ActionPoint",
        type: ActionPointTypes.MEDICATION,
        fromMedicineDialog: true,
        addedMedicine: medicine,
      },
      panelClass: color,
    });
  }

  createMedication() {
    if (this.isPosting) {
      return;
    }
    this.isPosting = true;
    this.errorMessage = undefined;
    const medicine = this.dialogForm.value;
    medicine["dosageType"] = medicine.dosageForm;

    if (medicine.generic) {
      medicine.brand = medicine.name;
    }
    delete medicine.generic;
    this.store.dispatch(
      new patientActions.AddMedication({
        id: this.data.patient._id,
        item: medicine,
      })
    );
    this.addedMedicine = medicine;
  }

  cancel() {
    this.dialogRef.close();
  }
}
