declare var require: any

import { Injectable } from '@angular/core';
import { Medicine } from '../store/medicine/medicine.model';

const medicines = require('../shared/medicines.json') as Medicine[];
const questionnaire_action_plan = require('../shared/questionnaire_action_plan.json');
const questionnaire_acs = require('../shared/questionnaire_acs.json');
const questionnaire_covid19 = require('../shared/questionnaire_covid19.json');
const questionnaire_acs_validation = require('../shared/questionnaire_acs_validation.json');

const SHARED_ASSETS = {
  MEDICINES: medicines,
  ACTION_PLAN: questionnaire_action_plan,
  ACS: questionnaire_acs,
  ACS_VALIDATION: questionnaire_acs_validation,
  COVID19: questionnaire_covid19
}

@Injectable({
  providedIn: 'root'
})
export class AssetsService {

  constructor() { }

  getMedicines() {
    if (!SHARED_ASSETS.MEDICINES) return false;
    return SHARED_ASSETS.MEDICINES;
  }

  getActionPlan() {
    if (!SHARED_ASSETS.ACTION_PLAN) return false;
    return SHARED_ASSETS.ACTION_PLAN;
  }

  getAcs() {
    if (!SHARED_ASSETS.ACS) return false;
    return SHARED_ASSETS.ACS;
  }

  getAcsValidation() {
    if (!SHARED_ASSETS.ACS_VALIDATION) return false;
    return SHARED_ASSETS.ACS_VALIDATION;
  }

  getCovid19() {
    if (!SHARED_ASSETS.COVID19) return false;
    return SHARED_ASSETS.COVID19;
  }

}
