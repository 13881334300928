import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import * as fromRoot from "../../store";
import * as errorActions from "../../store/error/error.actions";
import * as teamActions from "../../store/team/team.actions";
import { Store } from "@ngrx/store";
import { Team, ChatReply } from "src/app/store/team/team.model";
import { filter, map, take } from "rxjs/operators";
import { StoreError } from "src/app/store/error/error.model";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-chat-reply-dialog",
  templateUrl: "./chat-reply-dialog.component.html",
  styleUrls: ["./chat-reply-dialog.component.scss"],
})
export class ChatReplyDialogComponent implements OnInit, OnDestroy {
  dialogForm: FormGroup;
  isPosting: boolean;
  errorMessage: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { team: Team; orgId: string; reply: ChatReply },
    public dialogRef: MatDialogRef<ChatReplyDialogComponent>,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private store: Store<fromRoot.State>,
	private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.isPosting = false;

    this.store.dispatch(new errorActions.ClearErrors());

    let reply = this.data.reply ? this.data.reply : { title: "", message: "" };

    this.dialogForm = this.formBuilder.group({
      title: [reply.title],
      message: [reply.message],
    });

    this.store
      .select(fromRoot.getErrors)
      .pipe(filter((errors) => errors && errors.length > 0))
      .pipe(map((errors) => errors[0]))
      .pipe(filter((error) => error.apiError !== undefined))
      .subscribe((error: StoreError) => {
        this.isPosting = false;
        switch (error.apiError.code) {
          default:
            this.errorMessage = this.translateService.instant('chatReplyDialog.errorMessage') // "Er is iets fout gegaan";
            break;
        }
      });
  }

  ngOnDestroy(): void {
    this.store.dispatch(new errorActions.ClearErrors());
  }

  isPosting$() {
    return this.store
      .select(fromRoot.isPostingTeam)
      .pipe(filter((isPosting) => isPosting === false))
      .pipe(take(1))
      .toPromise();
  }

  createReply() {
    if (this.isPosting) {
      return;
    }
    this.isPosting = true;
    this.errorMessage = undefined;
    const reply = this.dialogForm.value;

    this.data.team.standardChatReplies.push(reply);

    this.store.dispatch(
      new teamActions.UpdateTeam({
        orgId: this.data.orgId,
        teamId: this.data.team._id,
        team: this.data.team,
      })
    );

    // this.store.dispatch(
    //   new teamActions.AddTeam({ orgId: this.data.orgId, team })
    // );

    this.isPosting$().then(() => {
      this.isPosting = false;
      this.dialogRef.close();
    });
  }

  patchReply() {
    if (this.isPosting) {
      return;
    }
    this.isPosting = true;
    this.errorMessage = undefined;

    const replyIndex = this.data.team.standardChatReplies.findIndex(
      (reply) => reply._id === this.data.reply._id
    );
    const reply = this.data.team.standardChatReplies[replyIndex];
    const updatedReply = {
      _id: reply._id,
      title: this.dialogForm.value.title,
      message: this.dialogForm.value.message,
    };
    this.data.team.standardChatReplies.splice(replyIndex, 1, updatedReply);

    this.store.dispatch(
      new teamActions.UpdateTeam({
        orgId: this.data.orgId,
        teamId: this.data.team._id,
        team: this.data.team,
      })
    );

    this.isPosting$().then(() => {
      this.isPosting = false;
      this.dialogRef.close();
    });
  }

  async deleteReply() {
    if (this.isPosting) {
      return;
    }
    this.isPosting = true;
    this.errorMessage = undefined;

    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: this.translateService.instant('chatReplyDialog.dialogTitle'), // "Verwijderen",
        message: this.translateService.instant('chatReplyDialog.dialogMessage') //"Weet je zeker dat je de standaard reactie wilt verwijderen?",
      },
    });
    const confirmation = await confirmDialog
      .afterClosed()
      .pipe(take(1))
      .toPromise();
    if (!confirmation) {
      return;
    }

    this.data.team.standardChatReplies = this.data.team.standardChatReplies.filter(
      (reply) => reply._id !== this.data.reply._id
    );

    this.store.dispatch(
      new teamActions.UpdateTeam({
        orgId: this.data.orgId,
        teamId: this.data.team._id,
        team: this.data.team,
      })
    );

    this.isPosting$().then(() => {
      this.isPosting = false;
      this.dialogRef.close();
    });
  }

  cancel() {
    this.dialogRef.close();
  }
}
