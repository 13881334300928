import { PatientAction } from "src/app/store/patientAction/patientAction.model";
import { Component, OnInit, AfterViewInit, Input } from "@angular/core";
import * as d3 from "d3";
import { getBestFev1Result } from 'src/app/util/helper-functions';

@Component({
  selector: "app-patient-chart",
  templateUrl: "./patient-chart.component.html",
  styleUrls: ["./patient-chart.component.scss"],
})
export class PatientChartComponent implements OnInit, AfterViewInit {
  @Input() patientActions: PatientAction[];
  dates: Date[];
  acsData: PatientAction[];
  fev1Data: PatientAction[];
  margin: { top: number; right: number; bottom: number; left: number };
  width: number;
  height: number;
  chart: any;
  x: any;
  y1: any;
  y2: any;
  acsLine: any;
  fev1Line: any;
  allValues = [];
  selectedMonth: number;
  selectedYear: number;

  constructor() {}

  ngOnInit() {
    const currentDate = new Date();
    this.selectedMonth = currentDate.getMonth();
    this.selectedYear = currentDate.getFullYear();
    this.setPeriod(this.selectedMonth);

    this.margin = {
      top: 30,
      right: 150,
      bottom: 80,
      left: 60,
    };
    this.width = 960 - this.margin.left - this.margin.right;
    this.height = 360 - this.margin.top - this.margin.bottom;

    this.fev1Data = this.getFev1Data(this.selectedMonth);
    this.acsData = this.getAcsData(this.selectedMonth);
  }

  ngAfterViewInit(): void {
    console.log("fev1", this.fev1Data);
    this.initChart();
  }

  initChart() {
    this.chart = d3
      .select("#patient-chart")
      .attr("width", this.width + this.margin.left + this.margin.right)
      .attr("height", this.height + this.margin.top + this.margin.bottom)
      .append("g")
      .attr(
        "transform",
        "translate(" + this.margin.left + ", " + this.margin.top + ")"
      );

    this.xAxis();
    this.y1Axis();
    this.y2Axis();
    this.plotAcsLine();
    this.plotFev1Line();
    this.plotDots("acs");
    this.plotDots("fev1");
  }

  setPeriod(month, reInit = false) {
    if (month === -1) {
      this.selectedMonth = 11;
      this.selectedYear = this.selectedYear - 1;
    } else if (month === 12) {
      this.selectedMonth = 0;
      this.selectedYear = this.selectedYear + 1;
    } else {
      this.selectedMonth = month;
    }

    console.log(this.selectedMonth, this.selectedYear);

    this.dates = this.getDaysInMonth(this.selectedMonth, this.selectedYear);
    console.log("this dates", this.dates);
    if (reInit) {
      this.chart = d3.select("#patient-chart").selectAll("svg > *").remove();
      this.acsData = this.getAcsData(this.selectedMonth);
      this.initChart();
    }
  }

  getAcsData(selectedMonth) {
    return this.patientActions
      .filter((action) => {
        return (
          action.acsForm &&
          action.result !== undefined &&
          action.createdAt.getMonth() === selectedMonth
        );
      })
      .reverse();
  }

  getFev1Data(selectedMonth) {
    return this.patientActions
      .filter(
        (action) =>
          action.measurementResult &&
          action.measurementResult.resultType &&
          action.measurementResult.resultType === "deviceResult" &&
          action.createdAt.getMonth() === selectedMonth
      )
      .reverse();
  }

  plotAcsLine() {
    this.acsLine = d3
      .line<PatientAction>()
      .x((d: any) => this.x(d.createdAt))
      .y((d) => this.y1(d.result));

    this.chart
      .append("path")
      .datum(this.acsData)
      .attr("fill", "none")
      .attr("stroke", "steelblue")
      .attr("stroke-width", 1.5)
      .attr("stroke-linejoin", "round")
      .attr("stroke-linecap", "round")
      .attr("d", this.acsLine);
  }

  plotFev1Line() {
    this.fev1Line = d3
      .line<PatientAction>()
      .x((d: any) => this.x(d.createdAt))
      .y((d) => this.y2(this.getFev1Result(d)));

    this.chart
      .append("path")
      .datum(this.fev1Data)
      .attr("fill", "none")
      .attr("stroke", "red")
      .attr("stroke-width", 1.5)
      .attr("stroke-linejoin", "round")
      .attr("stroke-linecap", "round")
      .attr("d", this.fev1Line);
  }

  plotDots(type: "acs" | "fev1") {
    const data = type === "acs" ? this.acsData : this.fev1Data;
    console.log("type", type, data);
    this.chart
      .selectAll("." + type + "-dot")
      .data(data)
      .enter()
      .append("circle")
      .attr("class", "dot")
      .attr("cx", (d) => this.x(d.createdAt))
      .attr("cy", (d) => {
        const result =
          type === "acs" ? this.y1(d.result) : this.y2(this.getFev1Result(d));
        console.log("res", result);
        return result;
      })
      .attr("r", 5);
  }

  xAxis() {
    this.x = d3
      .scaleTime()
      .domain(d3.extent(this.dates, (d) => d))
      .nice(this.dates.length)
      .range([0, this.width]);

    const chartXAxisGroup = this.chart
      .append("g")
      .attr("class", "xAxisGroup")
      .attr("transform", "translate(0," + this.height + ")");

    const chartXAxis = d3
      .axisBottom(this.x)
      // .ticks(d3.timeDay.filter((d) => d3.timeDay.count(0, d) % N === 0))
      .tickFormat(d3.timeFormat("%d %b"))
      .ticks(d3.timeDay.every(2))
      .tickSizeOuter(0);

    chartXAxis(chartXAxisGroup);
  }

  y1Axis() {
    this.y1 = d3
      .scaleLinear()
      .domain([d3.max([0, 1, 2, 3, 4], (d) => d), 0])
      .nice()
      .range([0, this.height]);

    const chartY1AxisGroup = this.chart
      .append("g")
      .attr("class", "y1AxisGroup")
      .attr("transform", "translate(" + this.width + "," + -15 + ")");
    // .attr("transform", "translate(0," + (this.margin.bottom + 10) + ")");

    const chartY1Axis = d3.axisRight(this.y1).ticks(5);

    chartY1Axis(chartY1AxisGroup);
  }

  y2Axis() {
    this.y2 = d3
      .scaleLinear()
      // .domain([d3.max([0, 50, 75, 100, 125, 150], (d) => d), 0])
      .domain(d3.extent([150, 0]))
      .nice()
      .range([this.height, 0]);

    const chartY2AxisGroup = this.chart
      .append("g")
      .attr("class", "y2AxisGroup")
      .attr("transform", "translate(0, " + -15 + ")");

    const chartY2Axis = d3
      .axisLeft(this.y2)
      .tickValues([0, 25, 50, 75, 100, 125, 150]);

    chartY2Axis(chartY2AxisGroup);
  }

  getDaysInMonth(month, year) {
    var date = new Date(year, month, 1);
    var days = [];

    while (date.getMonth() === month) {
      // console.log("getDays", date, date.getMonth(), month);
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    // console.log("getDay day", days);
    return days;
  }

  getFev1Result(patientAction: PatientAction): number {
    const result = getBestFev1Result(patientAction) as number;
    return result !== undefined ? result : 0;
  }
}
