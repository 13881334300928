import { OrgDialogComponent } from '../../dialogs/admin-org-dialog/admin-org-dialog.component';

import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';

import * as fromRoot from '../../store';

import { Store } from '@ngrx/store';

import { Org } from '../../store/org/org.model';
import { MatTableDataSource, MatDialogRef, MatDialog, Sort } from '@angular/material';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { sortData } from 'src/app/util/helper-functions';

@Component({
  selector: 'app-admin-orgs',
  templateUrl: './admin-orgs.component.html',
  styleUrls: ['./admin-orgs.component.scss']
})
export class OrgsViewComponent implements OnInit, AfterViewInit, OnDestroy {
  orgs$: Observable<Org[]>;
  orgs: Org[];
  displayedColumns = ['name'];
  dataSource = new MatTableDataSource<any>([]);
  orgSub: Subscription;
  orgDialog: MatDialogRef<OrgDialogComponent>;
  sort: Sort;
  formatBirthDate: (date: string) => string;

  constructor(private store: Store<fromRoot.State>, private dialog: MatDialog, private router: Router) { }

  ngOnInit() {
    this.orgs$ = this.store.select(fromRoot.getOrgs).pipe(filter(orgs => orgs !== undefined), map(orgs => {
      return orgs.sort((orgA, orgB) => {
        // Sort by alphabetically
        const nameA = orgA.name.toLowerCase();
        const nameB = orgB.name.toLowerCase();
        return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
      });
    }));
    this.orgSub = this.orgs$.pipe(filter(orgs => orgs !== undefined)).subscribe(orgs => {
      this.orgs = orgs;
      this.sortData(this.sort);
    });
  }

  ngAfterViewInit() { }

  ngOnDestroy() {
    if (this.orgSub) {
      this.orgSub.unsubscribe();
    }
  }

  editOrg(org) {
    this.router.navigate(['admin/organisaties/' + org._id]);
  }

  createOrg() {
    this.orgDialog = this.dialog.open(OrgDialogComponent, { data: {} });
  }

  sortData(sort: Sort) {
    this.dataSource = sortData(sort, this.dataSource, this.orgs);
  }

}
