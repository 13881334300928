import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { PatientAction, MeasurementResultTypes } from "src/app/store/patientAction/patientAction.model";
import { getColor, getBestFev1Result } from "src/app/util/helper-functions";

@Component({
  selector: "app-fev-chart",
  templateUrl: "./fev-chart.component.html",
  styleUrls: ["./fev-chart.component.scss"],
})
export class FevChartComponent implements OnInit {
  @Input() patientActions: PatientAction[];
  getColor = getColor;
  getBestFev1Result = getBestFev1Result;
  @Output() selectFev1 = new EventEmitter();

  constructor() {}

  ngOnInit() {
  }

  get fev1Actions() {
    return this.patientActions
      .filter(
        (action) =>
          action.__t === 'UserActionMeasurement' &&
          action.measurementResult &&
          action.measurementResult.name === MeasurementResultTypes.SPIROMETER
      )
      .slice(0, 14)
      .reverse();
  }
}
