import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthAdminGuard implements CanActivate {
  constructor(private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const JSONtokens = localStorage.getItem('adminTokens');
    const allow = JSONtokens !== null && JSON.parse(JSONtokens).accesstoken !== undefined;
    if (!allow) {
      this.router.navigate(['/admin/login']);
    }
    return allow;
  }
}
