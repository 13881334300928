import { Action } from "@ngrx/store";
import { Update } from "@ngrx/entity";
import { CareProvider } from "./careprovider.model";

export enum CareProviderActionTypes {
  LoadCareProviders = "[CareProvider] Load CareProviders",
  LoadCareProvidersSuccess = "[CareProvider] Load CareProviders Success",
  GetOne = "[CareProvider] Get One",
  GetOneSuccess = "[CareProvider] Get One Success",
  AddCareProvider = "[CareProvider] Add CareProvider",
  AddCareProviderSuccess = "[CareProvider] Add CareProvider Complete",
  UpsertCareProvider = "[CareProvider] Upsert CareProvider",
  AddCareProviders = "[CareProvider] Add CareProviders",
  UpdateCareProvider = "[CareProvider] Update CareProvider",
  UpdateCareProviderSuccess = "[CareProvider] Update CareProvider Success",
  DeleteCareProvider = "[CareProvider] Delete CareProvider",
  DeleteCareProviderSuccess = "[CareProvider] Delete CareProvider Success",
  DeleteCareProviders = "[CareProvider] Delete CareProviders",
  ClearCareProviders = "[CareProvider] Clear CareProviders",
  Select = "[CareProvider] Select CareProvider"
}

export class LoadCareProviders implements Action {
  readonly type = CareProviderActionTypes.LoadCareProviders;
  constructor() {}
}

export class LoadCareProvidersSuccess implements Action {
  readonly type = CareProviderActionTypes.LoadCareProvidersSuccess;

  constructor(public payload: { items: CareProvider[] }) {}
}

export class GetOne implements Action {
  readonly type = CareProviderActionTypes.GetOne;

  constructor(public payload: { careProviderId: string }) {}
}

export class GetOneSuccess implements Action {
  readonly type = CareProviderActionTypes.GetOneSuccess;

  constructor(public payload: CareProvider) {}
}

export class AddCareProvider implements Action {
  readonly type = CareProviderActionTypes.AddCareProvider;

  constructor(
    public payload: {
      careProvider: CareProvider;
    }
  ) {}
}

export class AddCareProviderSuccess implements Action {
  readonly type = CareProviderActionTypes.AddCareProviderSuccess;

  constructor(public payload: { item: CareProvider }) {}
}

export class AddCareProviders implements Action {
  readonly type = CareProviderActionTypes.AddCareProviders;

  constructor(
    public payload: {
      orgId: string;
      teamId: string;
      items: CareProvider[];
      type: "careProviders";
    }
  ) {}
}

export class UpdateCareProvider implements Action {
  readonly type = CareProviderActionTypes.UpdateCareProvider;

  constructor(
    public payload: {
      careProviderId: string;
      careProvider: CareProvider;
    }
  ) {}
}

export class UpdateCareProviderSuccess implements Action {
  readonly type = CareProviderActionTypes.UpdateCareProviderSuccess;

  constructor(public payload: Update<CareProvider>) {}
}

export class DeleteCareProvider implements Action {
  readonly type = CareProviderActionTypes.DeleteCareProvider;

  constructor(public payload: { careProviderId: string }) {}
}

export class DeleteCareProviderSuccess implements Action {
  readonly type = CareProviderActionTypes.DeleteCareProviderSuccess;

  constructor(public payload: { id: string }) {}
}

export class DeleteCareProviders implements Action {
  readonly type = CareProviderActionTypes.DeleteCareProviders;

  constructor(public payload: { ids: string[] }) {}
}

export class ClearCareProviders implements Action {
  readonly type = CareProviderActionTypes.ClearCareProviders;
}

export class Select implements Action {
  readonly type = CareProviderActionTypes.Select;

  constructor(public payload: string) {}
}

export type CareProviderActions =
  | LoadCareProviders
  | LoadCareProvidersSuccess
  | GetOne
  | GetOneSuccess
  | AddCareProvider
  | AddCareProviderSuccess
  | AddCareProviders
  | UpdateCareProvider
  | UpdateCareProviderSuccess
  | DeleteCareProvider
  | DeleteCareProviderSuccess
  | DeleteCareProviders
  | ClearCareProviders
  | Select;
