import { Component, OnInit, OnDestroy } from '@angular/core';
import * as fromRoot from '../../store/index';
import * as authActions from '../../store/auth/auth.actions';
import { Store } from '@ngrx/store';
import { CareProvider } from 'src/app/store/careprovider/careprovider.model';
import { Observable, Subject } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { filter, take, map, takeUntil } from 'rxjs/operators';
import { Org } from 'src/app/store/org/org.model';
import { Team } from 'src/app/store/team/team.model';
import { StoreError } from 'src/app/store/error/error.model';
import { Router } from '@angular/router';
import { SelectTeam } from 'src/app/store/settings/settings.actions';

@Component({
  selector: 'app-switcher',
  templateUrl: './switcher.component.html',
  styleUrls: ['./switcher.component.scss']
})
export class SwitcherPageComponent implements OnInit, OnDestroy {
  careProvider$: Observable<CareProvider>;
  groupedTeams$: Observable<{ organization: Org; teams: Team[] }[]>;
  selectedTeam$: Observable<{ orgId: string; teamId: string }>;
  destroy$ = new Subject<boolean>();

  constructor(
    private store: Store<fromRoot.State>,
    private jwtHelper: JwtHelperService,
    private router: Router
  ) {}

  ngOnInit() {
    this.careProvider$ = this.store.select(fromRoot.getCareProvider);
    this.selectedTeam$ = this.store.select(
      fromRoot.getCareProviderSelectedTeam
    );

    this.groupedTeams$ = this.careProvider$.pipe(
      filter(c => c !== undefined && c.email !== undefined),
      take(1),
      map(careProvider => this.groupCareProviderTeams(careProvider))
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  private groupCareProviderTeams(careProvider: CareProvider) {
    const careProviderOrgs = (careProvider.organizations as unknown) as Org[];
    return careProviderOrgs.reduce((groups, organization) => {
      const group = groups.find(
        g => g.organization === organization._id
      );
      const careProviderTeams = (careProvider.teams as unknown) as Team[];

      if (group) {
        group.teams.push(
          careProviderTeams.filter(
            team => team.organization === organization._id
          )
        );
      } else {
        const teams = careProviderTeams.filter(
          team => team.organization === organization._id
        );
        if (teams.length > 0) {
          groups.push({ organization, teams });
        }
      }
      return groups;
    }, []);
  }

  selectTeam(orgId, teamId) {
    this.store.dispatch(new SelectTeam({ orgId, teamId }));
    this.router.navigate(['/']);
  }
}
