import { Action } from '@ngrx/store';
import { Medicine } from './medicine.model';

export enum MedicineActionTypes {
  LoadMedicines = '[Medicine] Load Medicines',
  LoadMedicinesSuccess = '[Medicine] Load Medicines Success'
}

export class LoadMedicines implements Action {
  readonly type = MedicineActionTypes.LoadMedicines;
  constructor() {}
}

export class LoadMedicinesSuccess implements Action {
  readonly type = MedicineActionTypes.LoadMedicinesSuccess;

  constructor(public payload: { items: Medicine[] }) {}
}

export type MedicineActions = LoadMedicines | LoadMedicinesSuccess;
