import { Action } from '@ngrx/store';

import { Client, Tokens, RefreshToken, VerificationToken } from './authAdmin.model';

// tslint:disable:max-classes-per-file

export enum AuthActionTypes {
  AdminLogin = '[Auth] Admin Login',
  AdminLoginSuccess = '[Auth] Admin Login Success',
  AdminLogout = '[Auth] Admin Logout',
  AdminLogoutSuccess = '[Auth] Admin Logout Success',
  AdminChangePassword = '[Auth] Admin Change Password',
  AdminClient = '[Auth] Admin Client',
  AdminTokens = '[Auth] Admin Tokens',
  AdminRefreshToken = '[Auth] Admin Refresh Token',
  AdminClearState = '[Auth] Admin Clear State',
  AdminVerify = '[Auth] Admin Verify',
  AdminVerifySuccss = '[Auth] Admin Verify Success',
  AdminVerificationToken = '[Auth] Admin Verification Token'
}

export class AdminVerifyAction implements Action {
  readonly type = AuthActionTypes.AdminVerify;

  constructor(public payload: VerificationToken) {}
}

export class AdminVerifySuccessAction implements Action {
  readonly type = AuthActionTypes.AdminVerifySuccss;

  constructor(public payload: Tokens) {};
}

export class AdminVerificationTokenAction implements Action {
  readonly type = AuthActionTypes.AdminVerificationToken;

  constructor(public payload: VerificationToken) { }
}

export class AdminLoginAction implements Action {
  readonly type = AuthActionTypes.AdminLogin;

  constructor(public payload: { email: string; password: string }) { }
}

export class AdminLoginSuccessAction implements Action {
  readonly type = AuthActionTypes.AdminLoginSuccess;

  constructor(public payload: Tokens) { }
}

export class AdminLogoutAction implements Action {
  readonly type = AuthActionTypes.AdminLogout;

  constructor() { }
}

export class AdminLogoutSuccessAction implements Action {
  readonly type = AuthActionTypes.AdminLogoutSuccess;

  constructor() { }
}

export class AdminClientAction implements Action {
  readonly type = AuthActionTypes.AdminClient;

  constructor(public payload: Client) { }
}

export class AdminTokensAction implements Action {
  readonly type = AuthActionTypes.AdminTokens;

  constructor(public payload: Tokens) { }
}

export class AdminRefreshTokenAction implements Action {
  readonly type = AuthActionTypes.AdminRefreshToken;

  constructor(public payload: RefreshToken) { }
}

export class AdminClearStateAction implements Action {
  readonly type = AuthActionTypes.AdminClearState;

  constructor(public payload: undefined) { }
}

export type AuthActions =
  AdminLoginAction
  | AdminVerifyAction
  | AdminVerifySuccessAction
  | AdminVerificationTokenAction
  | AdminLoginSuccessAction
  | AdminLogoutAction
  | AdminLogoutSuccessAction
  | AdminTokensAction
  | AdminRefreshTokenAction
  | AdminClientAction
  | AdminClearStateAction;
